import axios from 'axios'
import { PageDataResponse ,PageResponse,DataObjectResponse, DataArrayResponse } from '../models/PageResponse'
import { Parameter,QueryDataModel,RequestNewCarton,RequestDetail , RequestUpdateStatus ,UpdateNewCarton } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class NewCartonService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async GetDetail(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestDetail>>(`${apiUrl}/v1/new/detail?id=${id}`,{}, options )
        
        return data;
    }

    async GetNote(id:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<string>>(`${apiUrl}/v1/new/note?id=${id}`, options )
        
        return data;
    }

    async Search(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/new/search`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchDraft(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/new/searchdraft`,JSON.stringify(parameter),options )
        return data;
    }


    async Create(requestNewCarton: RequestNewCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/new/create`,JSON.stringify(requestNewCarton),options )
        return data;
    }

    async Update(requestNewCarton: RequestNewCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/new/update`,JSON.stringify(requestNewCarton),options )
        return data;
    }

    async UpdateAdmin(requestNewCarton: UpdateNewCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/new/admin/update`,JSON.stringify(requestNewCarton),options )
        return data;
    }

    async UpdateStatus(requestUpdateStatus: RequestUpdateStatus) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/new/status/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }

}