import { Parameter,RequestDetail,QueryDataModel,OrgUnitParamter } from "../models";

export default class InitHelper {
    
    static InitialParameter(){
        let parameter:Parameter = {
            page:1,
            limit: 20,
            recordIds:[],
            requester:"",
            requesters: [],
            prevOwners:[],
            owners: [],
            approvers: [],
            approvers2: [],
            destructionApprover2: [],
            onBehalf:0,
            requestNo: "",
            requestNoList:[],
            cartonNoList:[],
            requestTypeIds:[],
            requesterOrgUnit:"",
            orgUnits: [],
            ownerOrgUnits: [],
            prevOrgUnits:[],
            newOrgUnits:[],
            statusIds: [],
            destructionStatusIds:[],
            approveStatusIds:[],
            cartonLocation: "",
            cartonNo: "",
            projectId: 0,
            projectIds:[],
            projectName: "",
            description: "",
            destructionTypeId: 0,
            employeeID: "",
            employeeName: "",
            userName: "",
            roleIds: -1,
            active: -1,
            orderBy:"",
            distinctBy: "",
            expire: 0,
            requestStartDate: null,
            requestEndDate: null,
            expireStartDate:null,
            expireEndDate:null,
            documentYear1:0,
            documentYear2: 0,
            documentStartYear:0,
            documentEndYear: 0,
            changeOwner: -1,
            cartonStatusIds: [],
            totalCarton:0,
            confidentialLevel: -1,
            employeeLevel: 0,
            itemNoList: 0,
            memo:"",
            archive:0,
            manual: 0,
            system: 0,
            emailSend: [],
            warehouseId: 0,
            userConfidential: "",
            orgUnitConfidential: "",
            approverCondition: "",
            condition: "",
            autoExtend: 0
        }

       return parameter
    }
    
    static InitialRequestDetail(){
        let detail:RequestDetail ={
            recordNo:0,
            requestTypeId:0,
            requestNo:"",
            requestDate:null,
            requester:"",
            requesterName:"",
            orgUnit: "",
            position:"",
            contactPerson:"",
            contactPersonName:"",
            onBehalf:"",
            onBehalfName:"",
            deliverto:"",
            delivertoName:"",
            tel:"",
            owner:"",
            ownerName: "",
            ownerOrgUnit: "",
            receiveDate: null,
            location:"",
            cartonLocation:"",
            floor:"",
            memo:"",
            place:"",
            quantity:0,
            assignDelivery:0,
            outstandingQuantity:0,
            statusId:0,
            status:"",
            cartonStatus:"",
            serviceDate:null,
            returnDate:null,
            lastedNote:"",
            note:[],
            projectId:0,
            projectLocation: "",
            projectName: "",
            documentYear: null,
            totalStorageYear: 0,
            destructionDueDate: null,
            pickupDate: null,
            confidentialityLevel:1,
            warehouseId:0,
            remark:"",
            approver:"",
            approverName:"",
            approverDate:null,
            approver2:"",
            approverName2:"",
            approverDate2:null,
            autoExtend:0,
            valveRoom:0,
            cartons:[],
            cartonList:[],
            files:[],
            extendReason:"",
            notApproveReason:""
        }
        return detail;
    }

    static InitialQueryDataModel (){
        let data:QueryDataModel = {
            recordNo:0,
            collectRequestNo:"",
            requestNo:"",
            tempNo: "",
            storageRecordNo: 0,
            storageRequestNo:"",
            requestTypeId:0,
            requestType:"",
            requester:"",
            requesterName:"",
            owner:"",
            ownerName:"",
            newOwner:"",
            newOwnerName:"",
            deliverto:"",
            delivertoName:"",
            contactPerson:"",
            contactPersonName:"",
            onBehalf:"",
            onBehalfName:"",
            approver:"",
            approver2:"",
            approverName:"",
            approverName2:"",
            storageRequestDate: new Date(),
            requestDate:new Date(),
            receiveDate:new Date(),
            pickupDate:new Date(),
            collectDate:new Date(),
            expectReturnDate:new Date(),
            returnDate:new Date(),
            serviceDate:new Date(),
            approveDate:new Date(),
            approveDate2:new Date(),
            closeJobDate:null,
            place:"",
            quantity:0,
            description:"",
            statusId:0,
            status:"",
            approveStatusId:0,
            approveStatus:"",
            approveStatusId2:0,
            approveStatus2:"",
            cartonStatus:"",
            projectName:"",
            destructionType:"",
            requesterOrgUnit: "",
            orgUnit: "",
            ownerOrgUnit: "",
            returnBy:"",
            collectBy:"",
            nextActionBy:"",
            expireDate:null,
            newExtensionDate:null,
            latestExtensionDate:null,
            extendAmt:0,
            extendReason:"",
            valveRoom:0,
            cartonNo:"",
            cartonLocation:"",
            documentYear:0,
           confidentialLevel:0,
            destructionRequestTypeId: 0,
            cartonStatusInPrcess: "",
            remark: "",
            storageCartonStatusId: 0
        }
        return data
    }

    static InitialOrgUnitParameter (){
        let data:OrgUnitParamter = {
            page:1,
            limit:20,
            owners:[],
            requestNoList:[],
            orgUnits:[],
            newOrgUnits:[],
            statusIds:[],
            requestStartDate:null,
            requestEndDate:null,
            confidentialLevel:-1,
            manual:0,
            system:0,
            storage:0,
        }
        return data
    }
}