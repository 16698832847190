import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin ,Pagination ,Popconfirm,message} from 'antd';
import { AiFillDelete,AiOutlineRotateRight } from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { QueryDataModel ,RequestUpdateStatus ,Parameter,User} from '../../models';
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus } from '../../enum';
import { CollectCartonService } from '../../services';
import CollectCartonRequestDetail from './CollectCartonRequestDetail';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

interface StorageCarton{
  recordNo:number,
  statusId:number,
  valvRoom:number,
  approveLevel:number
}

const CollectCartonStatusTracking = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const pageSizeOptions = user.systemConfig.tablePageSize
  const navigate = useNavigate();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [storageCarton, setStorageCarton] = useState<StorageCarton>({ recordNo:0, statusId:0, valvRoom:0, approveLevel:0 });
  const [selectItems, setSelectItems] = useState<number[]>([])
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })

  useEffect(() => {
      getData(appState.currentPage,appState.perpage)
  }, [])

  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter:Parameter = InitHelper.InitialParameter();
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.orderBy = "StatusId"
      parameter.statusIds = [CartonStatus.WaitAdmin,CartonStatus.WaitApproval,CartonStatus.Inprogress]
      parameter.requesters = [user.employeeId]
     
      let token = await getToken()
      let result = await new CollectCartonService(token).Search(parameter)
      
      if (!result)
          return null;
      let {page,limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false
          state.perpage = perpage

        setTimeout(() => {
          setAppState(state)
        }, 500);
       
  }
  
  const handleAction = async (recordIds:number[],statusId: number) => {
    let token = await getToken()
    let requestUpdateStatus:RequestUpdateStatus = {
      recordIds: recordIds,
      statusId: statusId
    }
   
    let result = await new CollectCartonService(token).UpdateStatus(requestUpdateStatus)
    if(result && result.success){
      let element = document.getElementById('table-container') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
        getData(1,appState.perpage)
      }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }


  const showModalCarton = async (item:QueryDataModel) => {
    let _storageCarton = Object.assign({},storageCarton)
    _storageCarton.recordNo = item.recordNo
    _storageCarton.valvRoom = item.valveRoom
    _storageCarton.statusId = item.statusId
    setStorageCarton(_storageCarton)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:number[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        if(!element.disabled){
          element.checked = true
          items.push(parseFloat(element.value))
        }
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = parseFloat(e.target.value)
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
       
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length === 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

   
    setSelectItems(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
  
    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }
 
  return (
    <div>
      
      <div className='float-left'>
      { selectItems.length > 0 ?
        <>
        <Popconfirm
            title="Recall the task"
            description="Confirm to recall?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => handleAction(selectItems,CartonStatus.SaveDraftRecall) }
          >
          <Button type="primary" className='mr-btn'>
            <AiOutlineRotateRight className='icn-btn' /> Recall
          </Button>
        </Popconfirm>
        <Popconfirm
            title="Cancel the task"
            description="Confirm to cancel?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => handleAction(selectItems,CartonStatus.Cancel) }
          >
           <Button type="primary" className='btn-red mr-btn'>
            <AiFillDelete className='icn-btn' /> Cancel
          </Button>
        </Popconfirm>
        </>:
        <>
          <Button type="primary" className='mr-btn' disabled>
            <AiOutlineRotateRight className='icn-btn' /> Recall
          </Button>
          <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Cancel
          </Button>
        </>
      }
      </div>
       
      <div className='float-right'>
         
      </div>
      <div></div>
      { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
            <div id="table-container" className='padding-top-45'>
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th className='text-left w50'>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                      
                    </th>
                  		
                    <th >Request No.</th>
                    <th >Requested Date</th>
                    <th >Expected Receive Date	</th>
                    <th >Deliver to..</th>
                    <th >Place</th>
                    <th >Status</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:QueryDataModel) => (
                    <tr>
                      <td className='text-center w50'>
                        <div className="form-check">
                          <input  
                              type="checkbox" 
                              id={'check-item-' + item.recordNo} 
                              disabled = { item.statusId !== CartonStatus.WaitAdmin || user.employeeId !== item.requester }
                              value={item.recordNo} 
                              className={item.statusId !== CartonStatus.WaitAdmin ? 'form-check-input check-item bg-grey' : "form-check-input check-item"}
                              onChange={(e) => onCheckChange(e)}
                            />
                          <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                        </div>
                      </td>
                      <td className='text-center'>
                        <a className='a-table' onClick={() => showModalCarton(item)}>{item.requestNo}</a>
                      </td>
                      <td className='text-center'>{ dayjs(item.requestDate).format("DD MMM YYYY") }</td>
                      <td className='text-center'>{ dayjs(item.receiveDate).format("DD MMM YYYY") }</td>
                      <td className='text-center'>{ item.delivertoName }</td>
                      <td className='text-center'>{ item.place }</td>
                      <td className='text-center'>{ item.status }</td>
                     
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className='margin-top-10 text-right'>
            { appState.total > 0 ?
                <Pagination 
                    defaultCurrent={1} 
                    defaultPageSize={appState.perpage}
                    total={appState.total} 
                    onChange={onPageChange} 
                    pageSizeOptions={pageSizeOptions}
                    showSizeChanger={true}
                />:<></>
            }
            </div>
          </>
        }
        <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
          <CollectCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={storageCarton.recordNo}  
            />
        </Modal>
        {contextHolderMessage}
    </div >
  );

}

export default CollectCartonStatusTracking
