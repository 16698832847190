import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import OnBehalfRequest from './OnBehalfRequest';
import OnBehalfTracking from './OnBehalfTracking';
import OnBehalfSaveDraft from './OnBehalfSaveDraft';
import { useNavigate } from 'react-router-dom';

const OnBehalf3 = () => {
  const navigate = useNavigate();
  useEffect(() => {

  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `On Behalf`,
      children: <OnBehalfRequest/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <OnBehalfTracking/>
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <OnBehalfSaveDraft/>
    },

  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/onbehalf')
    }
    else{
      navigate('/onbehalf-' + tab)
    }
  }
  

  return (
    <Card >
      <Tabs defaultActiveKey={'3'} items={items}  onChange={onTabChange}  destroyInactiveTabPane/>
  </Card>
  );

}

export default OnBehalf3
