import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin ,Pagination ,Popconfirm, Row, Col,message} from 'antd';
import { AiFillDelete,AiOutlineClose,AiFillSave ,AiOutlineCheck} from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined } from '@ant-design/icons';
import { QueryDataModel ,UpdateApproval ,Parameter,User} from '../../models';
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus  ,Message} from '../../enum';
import { RemoveCartonService } from '../../services';
import RemoveCartonRequestDetail from '../RemoveCarton/RemoveCartonRequestDetail';
import secureLocalStorage  from  "react-secure-storage";
import dayjs from 'dayjs'
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ApproveRemoveCartonRequest = () => {
    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize

  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [recordNo, setRecordNo] = useState(0)
  const [selectItems, setSelectItems] = useState<number[]>([])
  const [isErrorNote,setIsErrorNote] = useState(false)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })

  useEffect(() => {
      getData(1,appState.perpage)
  }, [])



  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter:Parameter = InitHelper.InitialParameter();
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.orderBy = "StatusId"
      parameter.statusIds = [CartonStatus.WaitApproval]
      parameter.approvers = [user.employeeId]

      let token = await getToken()
      let result = await new RemoveCartonService(token).SearchApprover(parameter)
      
      if (!result)
          return null;
      let {limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.currentPage = currentPage
          state.perpage = perpage
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false


        setTimeout(() => {
          setAppState(state)
        }, 500);
       
  }

  const handleCancelReject = () => {
    let element = document.getElementById('note-approve-remove-reject') as HTMLInputElement
     element.value = ""
     setIsModalRejectOpen(false)
  }
  
  const handleReject = () => {
    let element = document.getElementById('note-approve-remove-reject') as HTMLInputElement
    if(element && element.value){
      handleAction(selectItems, CartonStatus.Reject,element.value) 
    }
    else{
      setIsErrorNote(true)
      return false
    }
    
  }
  
  const handleAction = async (recordIds:number[],approvesTatusId: number,noteReject:string|null = null) => {

      let token = await getToken()
    let requestUpdateStatus:UpdateApproval = {
      recordIds: recordIds,
      statusId: 0,
      approveStatusId: approvesTatusId,
      noteReject: noteReject ? noteReject : "",
      updateBy: user.employeeId,
      updateDate: new Date()
    }
   
    let result = await new RemoveCartonService(token).UpdateApproval(requestUpdateStatus)
    
    if(result && result.success){
      let element = document.getElementById('table-container') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
        setSelectItems([])
        getData(1,appState.perpage)
      }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
    setIsModalRejectOpen(false)
  }


  const showModalCarton = async (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalCartonOpen(true);
  };
  
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);
    getData(1,appState.perpage)
  };

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:number[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = true
        items.push(parseFloat(element.value))
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = parseFloat(e.target.value)
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length === 0){
        let element = document.getElementById('check-item-all') as HTMLInputElement
        element.checked = false
        element.indeterminate = false
      }
    }

   
    setSelectItems(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }



  const onPageChange = (page:number,perpage:number) => {
    
    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
 }
 
 const handleRejectOpen = () => {
    setIsErrorNote(false)
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let element = document.getElementById('note-approve-remove-reject') as HTMLTextAreaElement
        element.value = ""
    }, 100);
   
 }

  return (
    <div>
      
      <div className='float-left'>
      { selectItems.length > 0 ?
        <>
         <Button type="primary" className='btn-red mr-btn w140' onClick={() => handleRejectOpen()}>
            <AiFillDelete className='icn-btn' /> Reject
          </Button>

        <Popconfirm
            title="Approve the task"
            description="Confirm to approve?"
            icon={<QuestionCircleOutlined className='c-red'/>}
             onConfirm = { () => handleAction(selectItems,CartonStatus.Approve) }
          >
           <div className="idoc-tooltip">
            <span className="tooltiptext">
              <ul>
                <li className='text-left'>
                  {Message.Verify}
                </li>
                <li className='text-left'>
                    {Message.Approve}
                </li>
              </ul>
            </span>
            <Button type="primary" className='w140'>
              <AiFillSave className='icn-btn' /> Verify/Approve
            </Button>
          </div>
        </Popconfirm>
        </>:
        <>
          <Button type="primary" className='btn-red mr-btn w140' disabled>
            <AiFillDelete className='icn-btn' /> Reject
          </Button>
          <div className="idoc-tooltip">
            <span className="tooltiptext">
              <ul>
                <li className='text-left'>
                  {Message.Verify}
                </li>
                <li className='text-left'>
                    {Message.Approve}
                </li>
              </ul>
            </span>
            <Button type="primary" className='w140' disabled>
              <AiFillSave className='icn-btn' /> Verify/Approve
            </Button>
          </div>
        </>
      }
      </div>
       
      <div></div>
      { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
            <div id="table-container" className='padding-top-45'>
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th className='text-left w50'>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                      
                    </th>
                 
                    <th className='text-center'>Request No.</th>
                    <th >Requester</th>
                    <th >Requested Date</th>
                    <th >Owner</th>
                    <th >Org. Unit</th>
                    <th >Carton Quantity</th>
                    <th >Request Type</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:QueryDataModel) => (
                    <tr>
                      <td className='text-center w50'>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.recordNo} value={item.recordNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                        </div>
                      </td>
                      <td className='text-center'>
                        <a className='a-table' onClick={() => showModalCarton(item.recordNo)}>{item.requestNo}</a>
                      </td>
                      <td className='text-center'>{ item.requesterName }</td>
                      <td className='text-center'>{ dayjs(item.requestDate).format("DD MMM YYYY") }</td>
                      <td className='text-center'>{ item.ownerName }</td>
                      <td className='text-center'>{ item.orgUnit }</td>
                      <td className='text-center'>{ item.quantity }</td>
                      <td className='text-center'>Remove Carton</td>
                     
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className='margin-top-10 text-right'>
            { appState.total > 0 ?
                <Pagination 
                    key={Helper.Uniqid()}
                    current={appState.currentPage} 
                    pageSize={appState.perpage}
                    total={appState.total} 
                    onChange={onPageChange} 
                    showSizeChanger={true}
                    pageSizeOptions={pageSizeOptions}
                />:<></>
            }
            </div>
          </>
        }
        
        <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton} maskClosable={false}>
            <RemoveCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={recordNo}
              approveLevel={1}
            />
        </Modal>

        <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
            <textarea id="note-approve-remove-reject" className='text-confirm' rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn w100' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn w100'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>

      {contextHolderMessage}
    </div >
  );

}

export default ApproveRemoveCartonRequest
