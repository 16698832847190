import { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Pagination,Spin,Modal,message } from 'antd';
import {  AiOutlinePrinter, AiOutlineSearch, AiOutlineSync,AiFillSave,AiFillEye ,AiOutlineRight,AiOutlineDown} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import {RequestType, CartonStatus, ExportType  } from '../../enum';
import { Parameter ,Item, User,QueryDataModel,RequestUpdateAdminStatus,Option,Element} from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { FileManageService,HrWebService,QueryService ,WarehouseService, PropertyService,EmailService,StorageCartonService,DestructionCartonService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import DestructionCartonRequestDetail from '../DestructionCarton/DestructionCartonRequestDetail'
import secureLocalStorage  from  "react-secure-storage";
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import CartonDetail from '../Share/CartonDetail';

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:QueryDataModel[]
  itemNoList:string[]
  isLoading:boolean
}


const OfficerDestructionCarton = () => {
  const stickyHelper = new StickyHelper("office-desturction",50,50)
  const stickyElement:Element = stickyHelper.getElement()
  stickyHelper.setMinHight(900)
  
    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const navigate = useNavigate();
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [expireStartDate, setExpireStartDate] = useState<Date|null>(null);
  const [expireEndDate, setExpireEndDate] =  useState<Date|null>(null);
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [checkAll, setCheckAll] = useState(false)
    const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
    const [isModalDestructionDetailOpen, setIsModalDestructionDetailOpen] = useState(false);
  const [statusId,setStatusId] = useState(CartonStatus.DueDate)
  const [isPrint,setIsprint] = useState(true)
  const [btnId,setBtnId] = useState(CartonStatus.DueDate)
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: 100,
    items: [],
    itemNoList:[],
    isLoading : true
  })
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
    const [approvers, setApprovers] = useState<Item[]>([])
    const [recordNo, setRecordNo] = useState(0)
    const [requestNo, setRequestNo] = useState("")
    const [cartonNumber, setCartonNumber] = useState("")
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [searchParam,SetSearchParam] = useState<Parameter>();
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [clientHeight,setClientHeight] = useState(0)

  const onFinish = (values: any) => {
  };


  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

    const handleCartonDetailOpen = (_requestNo: string, _cartonNo: string) => {
        setCartonNumber(_cartonNo)
        setRequestNo(_requestNo)
        setIsModalCartonDetailOpen(true);
    }

    const handleCartonDetailCancel = () => {
        setIsModalCartonDetailOpen(false);
    }

    const handleDestructionDetailOpen = (_requestNo: number, _cartonNo: string) => {
        setCartonNumber(_cartonNo)
        setRecordNo(_requestNo)
    setIsModalDestructionDetailOpen(true);
  }

    const handleDestructionDetailCancel = () => {
    setIsModalDestructionDetailOpen(false);
  }
  
    const getData = async (currentPage: number, perpage: number, _parameter: Parameter | null = null, isExport: boolean | null = false) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
    
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.itemNoList = 1
        parameter.distinctBy = "cartonNo"
        parameter.orgUnits = parameter.orgUnits
      
      //  parameter.statusIds = [CartonStatus.Done]
      console.log(parameter)
        let token = await getToken()
        try {
            if (isExport) {
                await new FileManageService(token).DownloadReport(parameter, ExportType.DESTRUCTION_CARTON_QUERY);
                let state = Object.assign({}, appState)
                state.isLoading = false
                setAppState(state)
            } else {

                let result = await new QueryService(token).SearchAdminDestruction(parameter)
                console.log(result)
                if (!result)
                    return null;
                if (result && result.success) {
                    let state = Object.assign({}, appState)
                    state.total = result.data.total
                    state.currentPage = currentPage
                    state.perpage = perpage
                    state.items = result.data.items
                    state.items.forEach(e =>
                        e.orgUnit = e.ownerOrgUnit
                    )

                    state.isLoading = false

                    console.log(state.items)
                    let data = result.data
                    setTimeout(() => {
                        if (data.total > 0) {
                            setIsprint(false)
                        } else {
                            setIsprint(true)
                        }
                        setSaveParameter(parameter)
                        setAppState(state)
                        setTimeout(() => {
                          onSetClientHeight()
                        }, 100);
                    }, 500);
                }
                else {
                    let state = Object.assign({}, appState)
                    state.isLoading = false
                    setAppState(state)
                    if (checkAll) {
                        onCheckAll(true)
                    }

                }
            }
      }
      catch(error)
      {
        console.log(error)
        let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
      }
      
      

  }

    const getDataStorge = async (currentPage: number, perpage: number, _parameter: Parameter | null = null, isExport: boolean | null=false) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
    
      parameter.page = currentPage
      parameter.limit = perpage
        parameter.itemNoList = 1
      console.log(parameter)
      let token = await getToken()
      try{
          SetSearchParam(parameter)
          
          if (isExport) {
              await new FileManageService(token).DownloadReport(parameter, ExportType.DESTRUCTION_CARTON_STORAGE);
              let state = Object.assign({}, appState)
              state.isLoading = false
              setAppState(state)
          } else {

              let result = await new StorageCartonService(token).SearchAdminDestructionDueDate(parameter)
              console.log(result)
              if (result && result.success) {
                  let items = result.data.items.map(item => {
                      let itemData = InitHelper.InitialQueryDataModel()
                      itemData.recordNo = item.recordNo
                      itemData.storageRecordNo = item.storageRecordNo
                      itemData.requestNo = item.requestNo
                      itemData.cartonNo = item.cartonNo
                      itemData.cartonLocation = item.cartonLocation
                      itemData.requestDate = item.requestDate
                      itemData.requesterName = item.requesterName
                      itemData.ownerName = item.ownerName
                      itemData.orgUnit = item.orgUnit
                      itemData.latestExtensionDate = item.latestExtensionDate
                      itemData.expireDate = item.expireDate
                      itemData.destructionRequestTypeId = item.destructionRequestTypeId

                      return itemData
                  })
                  let state = Object.assign({}, appState)
                  state.total = result.data.total
                  state.currentPage = currentPage
                  state.perpage = perpage
                  state.items = items
                  state.isLoading = false


                  let data = result.data
                  setTimeout(() => {
                      if (data.total > 0) {
                          setIsprint(false)
                      } else {
                          setIsprint(true)
                      }
                      setSaveParameter(parameter)
                      setAppState(state)
                      setTimeout(() => {
                        onSetClientHeight()
                      }, 100);
                  }, 500);
              }
              else {
                  let state = Object.assign({}, appState)
                  state.isLoading = false
                  setAppState(state)
                  if (checkAll) {
                      onCheckAll(true)
                  }

              }
          }
      }
      catch(error)
      {
        console.log(error)
        let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
      }
      
      

  }

  const onCheckAll = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      setSelectItems(appState.items.map(x => x.cartonNo))
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }

  };

  const onCheckAllItem = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      setSelectItems(appState.itemNoList)
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }

  };
  
  const onCheckChange = (checked:boolean,cartonNo:string) => {
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    let _selectItems:string[] = Object.assign([],selectItems)
    if(checked){
        _selectItems.push(cartonNo)

          checkAllElement.checked = false
          checkAllElement.indeterminate = true
        
    }
    else{
      let index = _selectItems.indexOf(cartonNo)
      if (index > -1) {
        _selectItems.splice(index, 1);
      }

      if(_selectItems.length == 0){
        checkAllElement.checked = false
        checkAllElement.indeterminate = false
      }
      else{
        checkAllElement.checked = false
        checkAllElement.indeterminate = true
      }
    }

    console.log(_selectItems)
    setSelectItems(_selectItems)
  }
  const resetItem = () => {
    let state = Object.assign({},appState)
    state.total = 0
    state.currentPage = 1
    state.items = []
    state.isLoading = false
    setAppState(state)
  }
  
  const onRequesterSelect = (item:any) => {
    setRequesters(item)
  }
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
  }

  const onApproverSelect = (item:any) => {
    setApprovers(item)
  }
  
  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }

  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        return result.data
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}
  
const onSetClientHeight = () => {
    
  setTimeout(() => {
    stickyHelper.updatePosition()
    window.scrollTo(0,0)
    let element = document.getElementById(stickyElement.container) as HTMLInputElement
    if(element){
      let value = element.clientHeight
      setClientHeight(value)
    }
  }, 100);

}

  useEffect(() => {
    if(user.permissions.destructionCarton === 0){
        navigate('/permissiondenided')
    }

    resetItem()
    getWarehouse()
    let parameter = Object.assign({},InitHelper.InitialParameter())
      parameter.requestTypeIds = [RequestType.StorageCarton]
      
    parameter.emailSend = [0]
    parameter.statusIds =[CartonStatus.Done]
    parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect,CartonStatus.Return]
    parameter.expire = 1

 
    getDataStorge(1,appState.perpage,parameter)

    stickyHelper.removeEvent()
    stickyHelper.addEvent()

    window.addEventListener("resize", () => { 
       onSetClientHeight()
    });

  }, [])

  const getWarehouse = async () => {
    let token = await getToken()
      let result = await new WarehouseService(token).GetAllWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: 0 }]
        let Locationoptions:Option[] = result.data.map((item) => { return { value: item.warehouseId, label: item.warehouseName  }})
        setWarehouseOptions(options.concat(Locationoptions))
    }
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  const onPageChange = (page:number,perpage:number) => {
    
    if(perpage !== appState.perpage){
        searchData(1,perpage)
    }
    else{
      searchData(page,appState.perpage)
    }
    
  }

    const fieldParameterMap =  (field: any, parameter: any) => {
        if (field.requestNo) {
            parameter.requestNoList = field.requestNo.trim().split(",")
        }
        if (field.cartonLocation) {
            parameter.warehouseId = field.cartonLocation
        }
        if (field.cartonNoList) {
            parameter.cartonNo = field.cartonNoList.trim()
        }

        if (field.cartonNo) {
            parameter.cartonNo = field.cartonNo.trim()
        }

        if (field.destructionType && field.destructionType > 0) {
            parameter.requestTypeIds = [field.destructionType]
            
        }
        else {
            parameter.requestTypeIds = [RequestType.DestructionCarton, RequestType.ExtendCarton]
            parameter.destructionTypeId = -1

        }
    }

    const searchData = async (currentPage: number, perpage: number, isExport: boolean | null = false) => {
      
    let field = dataForm.getFieldsValue()
    let parameter = InitHelper.InitialParameter();
      fieldParameterMap(field, parameter)

    if(requesters.length > 0){
      parameter.requesters = requesters.map(item => item.key)
    }
    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }
    if(approvers.length > 0){
      parameter.approvers2 = approvers.map(item => item.key)
    }
    if(orgunits.length > 0){
        parameter.orgUnits = orgunits.map(item => item.key)
    }
    if(expireStartDate && !expireEndDate){
        parameter.expireStartDate = expireStartDate
    }

    if(expireStartDate && expireEndDate){
        parameter.expireStartDate = expireStartDate
        parameter.expireEndDate = expireEndDate
    }


    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    setSelectItems([])
      setBtnId(statusId)

        setCheckAll(false)
    if(statusId === CartonStatus.DueDate){
        parameter.requestTypeIds = [ RequestType.StorageCarton ]
        parameter.emailSend = [0]
        parameter.statusIds =[CartonStatus.Done]
        parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect,CartonStatus.Return]
        
            getDataStorge(currentPage, perpage, parameter, isExport).then(() => {
              stickyHelper.updatePosition()
          
            })
    }
    else if(statusId === CartonStatus.WaitingUserConfirm){
        parameter.requestTypeIds = [ RequestType.StorageCarton ]
        parameter.emailSend = [1]
        parameter.statusIds =[CartonStatus.Done]
        parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect,CartonStatus.Return]
        
            getDataStorge(currentPage, perpage, parameter, isExport).then(() => {
              stickyHelper.updatePosition()
          
            })
    }
    else{
        parameter.statusIds = [statusId]
        
          //parameter.destructionStatusIds = parameter.statusIds

            getData(currentPage, perpage, parameter, isExport).then(() => {
              stickyHelper.updatePosition()
          
            })
    }
    
  }
  const reset = () => {
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""

    dataForm.resetFields()
    setStatusId(CartonStatus.DueDate)
    setBtnId(CartonStatus.DueDate)
    setOwners([])
    setRequesters([])
    setOrgUnits([])
    setExpireStartDate(null)
    setExpireEndDate(null)
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.requestTypeIds = [ RequestType.StorageCarton ]
         
    parameter.emailSend = [0]
    parameter.statusIds =[CartonStatus.Done]
    parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect,CartonStatus.Return]
    parameter.expire = 1
    getDataStorge(1,appState.perpage,parameter).then(() => {
      stickyHelper.updatePosition()
  
    })
  }

  const onExpireStartDateChange = (date: any) => {
    setExpireStartDate(date);
  }

  const onExpireEndDateChange = (date: any) => {
    setExpireEndDate(date)
  }

  const onRequestTypeChange = (value:number) => {
  }
  
  const onStatusChange = (value:number) => {
    setStatusId(value)
  }

  const handleDestroyConfirmation = async () => {
    let token = await getToken()
     new EmailService(token).sendDestroyConfirmation(selectItems)
    
        let element = document.getElementById('action-panel') as HTMLElement
        messageApi.open({
            type: 'success',
            content: 'System already sent email to Email Server, maybe take a few minutes.',
            duration: 1,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
            searchData(1,appState.perpage)
        }, 1000);
    
  }

  const handleSendEmail = async () => {

    if(btnId == CartonStatus.WaitApproval)
    {
        let token = await getToken()
        new EmailService(token).sendToApproverCarton(selectItems);
       
            let element = document.getElementById('action-panel') as HTMLElement
            messageApi.open({
                type: 'success',
                content: 'System already sent email to Email Server, maybe take a few minutes.',
                duration: 1,
                style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
            });
            setTimeout(() => {
                searchData(1,appState.perpage)
            }, 1000);
        
    }

  }


  const handleUpdateStatus = async (statusId:number) => {
    let token = await getToken()
    let requestUpdateStatus:RequestUpdateAdminStatus = {
        cartonNoList: selectItems,
        statusId: statusId,
        updateBy: user.employeeId
      }
      let result = await new DestructionCartonService(token).UpdateAdminStatus(requestUpdateStatus)
        if(result && result.success){
            let element = document.getElementById('action-panel') as HTMLElement
            messageApi.open({
                type: 'success',
                content: 'Save success',
                duration: 1,
                style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
            });
            setTimeout(() => {
                searchData(1,appState.perpage)
            }, 1000);
            
        }
  }

  
    const onFinishPrint = async () => {

        let token = await getToken()
        if (!searchParam) return

        try {
            setIsLoadingPrint(true)
            await new FileManageService(token).DownloadReport(searchParam, ExportType.DESTRUCTION_CARTON_QUERY);
        } catch (error) {
            console.log("🚀 ~ file: OfficerDestructionCarton.tsx:641 ~ onFinishPrint ~ error:", error)
        } finally {
            setIsLoadingPrint(false)

        }
    }

    const destructionTypeDesc = (item: QueryDataModel) => {

        let destructionType = item.destructionType
        if (item.destructionRequestTypeId === RequestType.ExtendCarton)
            destructionType = 'Extend'
        if (item.destructionRequestTypeId === RequestType.DestructionCarton)
            destructionType = 'Destroy'
        return destructionType
    }

   
  return (

    <div>
       <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>
        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
            {...layout}
            form={dataForm}
            name="search-panel"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >

            <Row>
              <Col md={11} >
                <Form.Item name="requestNo" label="Request No."  >
                    <Input placeholder="ex. B230001,B230002,B230003" />
                </Form.Item>
              </Col>
              <Col md={2} >

              </Col>
              <Col md={11} >
              <Form.Item name="requester" label="Requester"  >
                    <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item label="Carton No:(Range)" >
                      <table className='w100c'>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-5'>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                      </Form.Item>
              </Col>
              <Col md={2} >

              </Col>
              <Col md={11} >
              <Form.Item name="owner" label="Owner"  >
                  <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                      </Form.Item>
              </Col>
              <Col md={2} >

              </Col>
              <Col md={11} >
              <Form.Item name="approver" label="Approver"  >
                    <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={approvers ? approvers : []}
                            onChange={item => onApproverSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                </Form.Item>
                <Form.Item name="documentYear" label="Destruction Year">
                      <table className='w100c'>
                        <tr>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate} 
                                picker='month' 
                                format="MMM YYYY"  
                                className='date-picker-full'
                                onChange={onExpireStartDateChange}
                            />
                          </td>
                          <td className='vertical-align-top'>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate} 
                                picker='month' 
                                format="MMM-YYYY" 
                                className='date-picker-full'
                                onChange={onExpireEndDateChange}
                            />
                          </td>
                        </tr>
                      </table>


                    </Form.Item>
                <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                </Form.Item>
              
              </Col>
            </Row>

            <Row>
              <Col md={11} >

                <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                      </Form.Item>
              </Col>
              <Col md={2} >

              </Col>
              <Col md={11} >
              <Form.Item name="cartonLocation" label="Carton Location"  initialValue={''}>
                            <Select 
                                showSearch
                                placeholder="Please select..." 
                                options={warehouseOptions} 
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
            
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="status" label="Status"  initialValue={CartonStatus.DueDate}>
                  <Select placeholder={MessageCommon.TitleSelect} 
                    onChange={onStatusChange}
                    options={[
                        { value: CartonStatus.DueDate, label: 'Destruction Due Date' },
                      { value: CartonStatus.WaitingUserConfirm, label: 'Wait for User Confirm' },
                      { value: CartonStatus.WaitApproval, label: 'Wait For Approval' },
                      { value: CartonStatus.WaitAdmin, label: "Wait For Admin Service" },
                      { value: CartonStatus.Inprogress, label: 'On Going' },
                      { value: CartonStatus.Done, label: 'Completed Service' },
                    ]}
                  />
                </Form.Item>
             
               
              </Col>
              <Col md={2} >

              </Col>
              <Col md={11} >
                              {
                                  [CartonStatus.DueDate, CartonStatus.WaitingUserConfirm].indexOf(statusId) > -1
                                      ?
                                  <></>
                                      :
                                      <Form.Item name="destructionType" label="Destruction Type"  >
                                          <Select
                                                defaultValue={0}
                                              placeholder={MessageCommon.TitleSelect}
                                              options={[

                                                  { value: RequestType.All, label: 'All' },
                                                  { value: RequestType.ExtendCarton, label: 'Extend' },
                                                  { value: RequestType.DestructionCarton, label: 'Destroy' }
                                              ]}
                                              onChange={onRequestTypeChange}


                                          />
                                      </Form.Item>
                              }
              
                
              </Col>
            </Row>
      
      

          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"  onClick={() => searchData(1,appState.perpage)}>
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-grey'></div>

    <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
        <div id="action-panel" className='margin-right-10 margin-top-15 margin-bottom-5'>
            <div  className='div-select'>
                          All {selectItems.length} cartons on this page are selected.<a className='a-table mlt-label' onClick={() => onCheckAllItem(true)}>Select all {appState.total} cartons in Primary</a>
            </div>
            <br></br>
                      <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={() => searchData(1, 9999999,true)}>
              <AiOutlinePrinter className='icn-btn' />Print
            </Button>
            { btnId === CartonStatus.DueDate ?
                          <Button type="primary" className='mr-btn' onClick={handleDestroyConfirmation} disabled={selectItems.length === 0 && !(checkAll && appState.items.length>0)}>
                    <AiFillSave className='icn-btn' /> Destroy Confirmation
                </Button>:<></>
            }

            {btnId === CartonStatus.WaitAdmin ?
                          <Button type="primary" className='mr-btn' onClick={() => handleUpdateStatus(CartonStatus.Inprogress)} disabled={selectItems.length === 0 && !(checkAll && appState.items.length > 0)}>
                    <AiFillSave className='icn-btn' /> Accept
                </Button>:<></>
            }

            { btnId === CartonStatus.Inprogress ?
                          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => handleUpdateStatus(CartonStatus.Done)} disabled={selectItems.length === 0 && !(checkAll && appState.items.length > 0)}>
                    <AiFillSave className='icn-btn' />Close Job
                </Button>:<></>
            }

            { btnId === CartonStatus.WaitApproval ?
                          <Button type="primary" className='mr-btn' onClick={handleSendEmail} disabled={selectItems.length === 0 && !(checkAll && appState.items.length > 0)}>
                    <AiFillSave className='icn-btn' /> Resend Email
                    </Button>:<></>
            }

            { btnId === CartonStatus.WaitingUserConfirm ?
                          <Button type="primary" className='mr-btn' onClick={handleDestroyConfirmation} disabled={selectItems.length === 0 && !(checkAll && appState.items.length > 0)}>
                    <AiFillSave className='icn-btn' /> Resend Email
                    </Button>:<></>
            }
                   { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}

          </div>

          <br></br>
          <div>
          <div className='search-result' >Search found { appState.total } record(s).</div>
          </div>  
    </div>
    
    <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
      <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
              <tr>
              <th className="thfix1 w50">
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} checked={checkAll} className="form-check-input check-item" onChange={(e) => onCheckAll(e.target.checked)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                      
                </th>
                <th className="s-th thfix2">Carton No.</th>
                <th className="s-th">Carton Location</th>
                <th className="s-th">Storage Request No</th>
                <th className="s-th">Storage Request Date</th>
                <th className="s-th">Requester</th>
                <th className="s-th">Owner</th>
                <th className="s-th">Org. Unit</th>
                <th className="s-th">Previous Destruction Year</th>
                <th className="s-th">New Destruction Year(Extend)</th>
                <th className="s-th">Destruction Type</th>
                <th className="s-th">Detail</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                  <td className="tdfix1 w50 text-center">
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.cartonNo} value={item.cartonNo} checked={checkAll || selectItems.indexOf(item.cartonNo) > -1 ? true : false} className="form-check-input check-item" onChange={(e) => onCheckChange(e.target.checked,item.cartonNo)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo}/>
                        </div>
                      </td>
                  <td className="s-td tdfix2 text-center">
                        <div className={(item.storageCartonStatusId === CartonStatus.Remove || item.storageCartonStatusId === CartonStatus.Destruction) ? 'c-red' : ''}>{item.cartonNo}</div>
                  </td>
                  <td className="s-td text-center">{item.cartonLocation}</td>
                  <td className="s-td text-center">{ statusId === CartonStatus.DueDate || statusId === CartonStatus.WaitingUserConfirm ? item.requestNo : item.storageRequestNo}</td>
                  <td className="s-td text-center">{dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                  <td className="s-td text-left break-word">{item.requesterName}</td>
                  <td className="s-td text-left break-word">{item.ownerName}</td>
                  <td className="s-td text-center">{item.orgUnit}</td>
                  <td className="s-td text-center">{ item.latestExtensionDate  ? dayjs(item.latestExtensionDate).format('DD MMM YYYY') : dayjs(item.expireDate).format('DD MMM YYYY')}</td>
                    <td className="s-td text-center">{item.requestTypeId === RequestType.ExtendCarton ? dayjs(item.newExtensionDate).format('DD MMM YYYY') : '-'}</td>
                    <td className="s-td text-center">
                        {
                            item.destructionType
                        }
                    </td>
                    <td className="s-td text-center">
                        {
                            [CartonStatus.DueDate, CartonStatus.WaitingUserConfirm].indexOf(statusId)>-1 ?
                                <a onClick={() => handleCartonDetailOpen(item.requestNo, item.cartonNo)}>
                                    <AiFillEye className='icon-table'></AiFillEye>
                                </a>
                                :
                                <a onClick={() => handleDestructionDetailOpen(item.recordNo, item.cartonNo)}>
                                    <AiFillEye className='icon-table'></AiFillEye>
                                </a>
                        }

                        
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange}
                showSizeChanger={false}
              />:<></>
        }
        </div>
    </div> 
    
    <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
      
          <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1500} onCancel={handleCartonDetailCancel}>
              <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={cartonNumber} />
          </Modal>

          <Modal title="" open={isModalDestructionDetailOpen} footer={''} width={1500} onCancel={handleDestructionDetailCancel}>
              <DestructionCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={cartonNumber} approveLevel={0} noHeadDetail/>

          </Modal>

        {contextHolderMessage}
      
    </div>
  );

}

export default OfficerDestructionCarton
