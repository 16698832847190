import axios from 'axios'
import {  DataArrayResponse  ,PageResponse } from '../models/PageResponse'
import { UploadFile,Carton, RequestDetail } from '../models'

import { config } from '../config'
import dayjs from 'dayjs'
import { ExportType } from '../enum'
import { CookieHelper } from '../helpers'


const apiUrl = config.API_URL

interface FileResponse {
    success: boolean
    data: string,
    message: string | null
}

export default class FileManageService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    protected headerFile = {
        'Content-Type': 'multipart/form-data',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }

            
        this.headerFile = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
            'X-XSRF-TOKEN': ""
        }
        }

    }

    async loadFileBlob(response:any,filename:string){
        const urlres = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = urlres;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlres);
    }

    async getFiles(projectNo:string,phase:string,stage:string,deliverable:string){
        if(!this.token) return null

        let options = {
            headers:this.headers,
            params: {
                projectNo: projectNo,
                phase:phase,
                stage:stage,
                deliverable:deliverable
            }
        }
        const { data }  = await axios.get<PageResponse>(`${apiUrl}/v1/filemanagement/call`,options )
        return data;
    }

    async downloadFile(directory:string, filename:string){
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download?directory=` + encodeURI(directory) + '&filename=' + encodeURI(filename)
       
        const response = await axios.post(url, {},{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
    }

    async downloadStorageFile(directory:string, filename:string,guid:string) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/downloadstorage?directory=` + encodeURI(directory) + '&filename=' + encodeURI(filename)+ '&guid=' + encodeURI(guid)
    
        const response = await axios.post(url, {},{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
    }

    async DownloadTemplate(id:number,valveRoom:number,total:number){
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/downloadtemplate?id=` + id + "&valveRoom=" + valveRoom + "&total=" + total
        
        const response = await axios.post(url, {},{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,"iDocTemplate.xlsx")
        return response;
    }

    async uploadFiles(formData: FormData) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headerFile['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<UploadFile>>(`${apiUrl}/v1/filemanagement/uploadfile`,formData,options )
        return data;
    }

    async UploadTemplateFiles(formData: FormData) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headerFile['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<Carton>>(`${apiUrl}/v1/filemanagement/uploadtemplate`,formData,options )
        return data;
    }

    async uploadProgressFile(formData: FormData) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headerFile['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<UploadFile>>(`${apiUrl}/v1/filemanagement/progress/file`,formData,options )
        return data;
    }

    async uploadUserManualFile(formData: FormData) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headerFile['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<UploadFile>>(`${apiUrl}/v1/filemanagement/manual/file`,formData,options )
        return data;
    }
    async DownloadStorageUser(body: RequestDetail, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download/storage/user?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "StorageUser" + dayjs().format('YYYYMMDD_HHmmss') + (pdf===0?'.xlsx':'.pdf');          
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
    }
    async DownloadStorageAdmin(body: any, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download/storage/admin?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "StorageUserAdmin" + dayjs().format('YYYYMMDD_HHmmss') + (pdf===0?'.xlsx':'.pdf'); 
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
    }
    async DownloadRequestReturn(body: RequestDetail, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download/request/return?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "Return" + dayjs().format('YYYYMMDD_HHmmss') + (pdf===0?'.xlsx':'.pdf');
       
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
        
        
    }
    async DownloadRequestCollect(body: RequestDetail, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download/request/collect?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "Collect" + dayjs().format('YYYYMMDD_HHmmss') +(pdf===0?'.xlsx':'.pdf');
        
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
        
    }
    async DownloadRequestDestruction(body: RequestDetail, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download/request/destruction?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "Destruction" + dayjs().format('YYYYMMDD_HHmmss') + (pdf===0?'.xlsx':'.pdf');
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
        
    }
    async DownloadRequestRemove(body: RequestDetail, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken


        let url = `${apiUrl}/v1/filemanagement/download/request/remove?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "Remove" + dayjs().format('YYYYMMDD_HHmmss') + (pdf===0?'.xlsx':'.pdf');
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
        
    }
    async DownloadNewBox(body: RequestDetail, pdf: number) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let url = `${apiUrl}/v1/filemanagement/download/newbox?type=`+(pdf===0?'EXCEL':'PDF');
        let filename = "NewCarton" + dayjs().format('YYYYMMDD_HHmmss') + (pdf===0?'.xlsx':'.pdf');
        
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
        
    }

    async DownloadPdfImgage(body: any,fname:string) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let  url = `${apiUrl}/v1/filemanagement/download/pdf-imgage`;
        let filename = fname + dayjs().format('YYYYMMDD_HHmmss') + ".pdf";
        const response = await axios.post(url, body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
        
    }

    async DownloadReport(body:any,type: ExportType,refId?:number){
        let url = apiUrl;
        let filename ="";

        switch (type) {
            case ExportType.ALL_TRANSACTION:
                url = url+'/v1/queries/searchtransaction_all_excel'
                filename = "AllTransaction_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.TOTAL_CARTON_LIST:
                url = url+'/v1/filemanagement/download/cartons'
                filename = "TotalCartonList_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.DESTRUCTION_CARTON_LIST:
                url = url+'/v1/queries/searchtransaction_destruction_excel'
                filename = "DestructionCartonList_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.STATUS_TRACKING:
                url = url+'/v1/queries/search_statustracking_excel'
                filename = "StatusTracking_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.ORG_UNIT_DOCUMENT:
                url = url+'/v1/filemanagement/download/orgunit/document'
                filename = "OrgUnitDocument_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.QUERY_DATA:
                url = url+'/v1/queries/search_querydata_excel'
                filename = "QueryData_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.WAREHOUSE:
                url = url+'/v1/filemanagement/download/query/warehouse'
                filename = "ManageExternalWarehouse_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.APPROVAL_TRACKING:
                url = url+'/v1/filemanagement/download/approvaltracking'
                filename = "ApprovalTracking_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.DESTRUCTION_CARTON_QUERY:
                url = url + '/v1/queries/search_excel'
                filename = "DestructionCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.DESTRUCTION_CARTON_STORAGE:
                url = url + '/v1/storage/searchcarton_without_document_excel'
                filename = "DestructionCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.ONBEHALF:
                url = url+'/v1/queries/search_onbehalf_excel'
                filename = "OnBehalf_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.CHANGE_OWNER:
                url = url+'/v1/filemanagement/download/changeowner'
                filename = "ChangeOwner_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.WAREHOUSE_LIST:
                url = url+'/v1/warehouses/list_excel'
                filename = "WarehouseList_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.MANAGE_ORG_UNIT:
                url = url+'/v1/filemanagement/download/orgunit/manage'
                filename = "ManageOrgUnit_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.HISTORY:
                url = url+'/v1/filemanagement/download/history'
                filename = "ManageChangeOwnerHistorical_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.MANUAL_UPDATE_ORG_UNIT:
                url = url+'/v1/filemanagement/download/manualupdate'
                filename = "ManualUpdateOrgUnit_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.ARCHIVE_OFFICER_UPDATE_ORG_UNIT:
                url = url+'/v1/filemanagement/download/archiveofficerupdate'
                filename = "ArchiveOfficerOrgUnit_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.AUTO_CHANGE_ORG_UNIT:
                url = url+'/v1/filemanagement/download/authochange'
                filename = "AutochangeOrgUnit_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.UPDATE_HISTORY_ORG_UNIT:
                url = url+'/v1/filemanagement/download/updatehistory'
                filename = "UpdateHistoryOrgUnit_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.EXPORT_STORAGE_LIST:
                url = url+`/v1/filemanagement/download/storagecartons?refId=${refId}`
                filename = "StorageCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.EXPORT_COLLECT_LIST:
                url = url+`/v1/filemanagement/download/collectcartons?id=${refId}`
                filename = "CollectCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.EXPORT_RETURN_LIST:
                url = url+`/v1/filemanagement/download/returncartons?refId=${refId}`
                filename = "ReturnCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.EXPORT_REMOVE_COLLECT_LIST:
                    url = url+`/v1/filemanagement/download/removecollectcartons?refId=${refId}`
                    filename = "RemoveCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                    break;
            case ExportType.EXPORT_REMOVE_STORAGE_LIST:
                url = url+`/v1/filemanagement/download/removestoragecartons?refId=${refId}`
                filename = "RemoveCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.EXPORT_DESTRUCTION_LIST:
                url = url+`/v1/filemanagement/download/destructioncartons?refId=${refId}`
                filename = "DestructionCarton_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;
            case ExportType.EXPORT_PROJECTNAME_LIST:
                url = url+`/v1/filemanagement/download/projectname`
                filename = "ProjectName_" + dayjs().format('YYYYMMDD_HHmmss') + '.xlsx';
                break;

            default:
            
                break;
        }

        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        const response = await axios.post(url,body,{headers:this.headers,responseType:'blob'});
        this.loadFileBlob(response,filename)
        return response;
    }
       
}