import { useEffect } from 'react';
import { Card, Tabs, Modal } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import StorageCartonRequest from './StorageCartonRequest'
import StorageCartonStatusTracking from './StorageCartonStatusTracking';
import StorageCartonSaveDraft from './StorageCartonSaveDraft';
import secureLocalStorage from "react-secure-storage";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const StorageCarton3 = () => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
  
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Storage`,
      children: <StorageCartonRequest/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <StorageCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <StorageCartonSaveDraft/>,
    },

  ];

  const saveDraft = (page:string) => {
    secureLocalStorage.setItem('saveStorage',page)
  
    let saveDraftBtn1 = document.getElementById('storage-save-draft1') as HTMLButtonElement
    if(saveDraftBtn1){
      saveDraftBtn1.click()
    }
    else{
      let saveDraftBtn2 = document.getElementById('storage-save-draft2') as HTMLButtonElement
      if(saveDraftBtn2){
        saveDraftBtn2.click()
      }
    }
  }

  const changePage = (page:string) => {
    secureLocalStorage.setItem("saveStorage","")
    removeStorageLink()
    navigate('/' + page)
  }

  
  const removeStorageLink = () => {
    let elements = document.getElementsByClassName("ant-menu-item") as HTMLCollection
   
     setTimeout(() => {
         let length = elements.length
         for(let i =0; i< length ;i++){
           let id = elements[i].getAttribute("data-menu-id")
      
           if(id && id.indexOf("-storage-carton") > -1 && elements[i].classList.contains('ant-menu-item-selected')){
             elements[i].classList.remove('ant-menu-item-selected')
           }
         }
     }, 1000);
 }

 const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/storage-carton')
    }
    else{
       let tabstr = tab === '2' ? 'storage-carton-tracking' : 'storage-carton-save'
        let saveStorage = secureLocalStorage.getItem('saveStorage')
        console.log(saveStorage)
        if(saveStorage){
          modal.confirm({
            title: 'Confirm to save draft before leaving this page?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Save Draft',
            cancelText: 'Cancel',
            width: 430,
            onCancel: () => changePage(tabstr),
            onOk: () => saveDraft(tabstr)
          });
     
      }
      else{
        secureLocalStorage.setItem("saveStorage","")
        navigate('/' + tabstr)
      }
        
    }
  }

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey='3' items={items} onTabClick={onTabChange} destroyInactiveTabPane/>
      </Card>

    </div>
  );

}

export default StorageCarton3
