import { IBasePickerStyles, ITag, ITagPickerProps, TagPicker } from "@fluentui/react/lib/Pickers";
import { useId } from "@fluentui/react-hooks";
import { ILabelProps, Label } from "@fluentui/react/lib/Label";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import './autocomplete.css'

initializeIcons()

export interface IModel {
    key: string;
    name?: string | null;
}

export interface IPickerItem<T> extends ITag {
    data?: T;
}

export interface PickerProps<T> {
    service?: any;
    tagPickerProps?: ITagPickerProps;
    pickerStyles?: Partial<IBasePickerStyles>;
    pickOnlyOne?: boolean;
    borderColor?: string;
    placeholder?: string;
    disabled?: boolean;
    removeDuplicateKey?: boolean;
    selectedItems?: IPickerItem<T>[];
    maximumResults?: number;
    label?: string;
    labelProps?: ILabelProps;
    required?: boolean;
    defaultSelectedItems?: IPickerItem<T>[];
    displayOnFocus?: boolean;
    minimumTextLength?: number;
    onMapResults?: (data: T[]) => IPickerItem<T>[];
    onSelect?: (item: IPickerItem<T> | undefined) => IPickerItem<T> | Promise<IPickerItem<T>> | null;
    onChange?: (item: IPickerItem<T>[] | undefined) => void | Promise<void> | undefined;
}

export const OrgUnitAutoComplete = <T extends IModel>(props: PickerProps<T>) => {
    const pickerId = useId('inline-picker');

    const {
        service,
        pickOnlyOne = false,
        borderColor = "",
        disabled = false,
        selectedItems,
        placeholder,
        pickerStyles,
        label,
        labelProps,
        required,
        defaultSelectedItems,
        displayOnFocus = true,
        minimumTextLength,
        onSelect,
        onChange,
    } = props;

    const _onResolveSuggestions = (filter: string, selectedItems?: IPickerItem<T>[] | undefined) => {
        if (minimumTextLength && (!filter || filter.length < minimumTextLength)) {
            return [];
        }

        if (service) {
            const promise = service(filter)
                return promise.then((results:string[]) => {
                let result_search:IModel[] = [];
                
                results.forEach((p:string) => {
                    result_search.push({
                        name: p,
                        key: p,
                      
                    });
                })
                return result_search;
              
            });
           
        }
        else{
            return [];
        }
        
    }

    const tagPickerProps: ITagPickerProps = {
        onResolveSuggestions: _onResolveSuggestions,
        inputProps: {
            placeholder: placeholder || 'Select',
            id: pickerId,
        },
        styles: pickerStyles,
    }

    if (pickOnlyOne) {
        tagPickerProps.itemLimit = 1;

        let pickerOnlyOneClassName = mergeStyles({
            '& span.ms-BasePicker-itemsWrapper': {
                width: '100%',
            }, '& .ms-BasePicker-text': {
                border: "1px solid " + (borderColor.length === 0 ? '#ced4da' : borderColor) , minHeight: "40px", marginBottom: "10px", fontFamily: "tahoma"
                ,
                '& .ms-BasePicker-input': {
                    marginBottom: "3px",
                    fontFamily: "tahoma"
                }
            },
            '& .ms-TagItem': {
                width: '100%',
                maxWidth: '100%',
                '& .ms-TagItem-text': {
                    flexGrow: 1,
                    fontFamily: "tahoma"
                }
            }
        });

        tagPickerProps.className = pickerOnlyOneClassName;
    } else {
        let pickerMultiClassName = mergeStyles({
            '& .ms-BasePicker-text': {
                border: "1px solid " + (borderColor.length === 0 ? '#ced4da' : borderColor), minHeight: "40px", marginBottom: "10px", backgroundColor: '', fontFamily: "tahoma"
                ,
                '& .ms-BasePicker-input': {
                    marginBottom: "3px",
                    fontFamily: "tahoma"
                }
            },
            '& .ms-TagItem': {
                '& .ms-TagItem-text': {
                    fontFamily: "tahoma"
                }
            }
        });

        tagPickerProps.className = pickerMultiClassName;
    }

    if (displayOnFocus) {
        tagPickerProps.onEmptyResolveSuggestions = (selecteItems) => _onResolveSuggestions('', selecteItems);
    }

    const renderPicker = () => {
        return (
            <TagPicker
                {...tagPickerProps}
                onItemSelected={onSelect}
                defaultSelectedItems={defaultSelectedItems}
                onChange={onChange}
                disabled={disabled}
                selectedItems={selectedItems}
                resolveDelay={300}
            />
        );
    }

    return (
        <>
            {label ? (
                <div className='ms-TextField-wrapper'>
                    <Label {...labelProps} required={required} htmlFor={pickerId} disabled={disabled}>{label}</Label>
                    {renderPicker()}
                </div>
            ) : renderPicker()}

        </>
    );
};
