import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Modal,Pagination,Spin } from 'antd';
import { AiOutlinePrinter, AiOutlineSearch, AiOutlineSync,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import secureLocalStorage  from  "react-secure-storage";
import {RequestType, CartonStatus ,StatusOption, ExportType } from '../../enum';
import { QueryDataModel ,Parameter ,Item,Element} from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { QueryService ,HrWebService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import DestructionCartonRequestDetail from '../DestructionCarton/DestructionCartonRequestDetail';
import ReturnCartonRequestDetail from '../ReturnCarton/ReturnCartonRequestDetail';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const OnBehalfTracking = () => {
    const stickyHelper = new StickyHelper("onbehalf-tracking",150,0)
    stickyHelper.setThElement('on-behalf-s-th')
    stickyHelper.setTdElement('on-behalf-s-td')
    const stickyElement:Element = stickyHelper.getElement()

    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const initialRequestTypeIds:number[] = [ 
    RequestType.CollectCarton,
    RequestType.ReturnCarton,  
    RequestType.ExtendCarton,
    RequestType.DestructionCarton,
  ]
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [recordNo, setRecordNo] = useState(0);
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
  const [isPrint,setIsprint] = useState(true)
  const [modal, contextHolder] = Modal.useModal();
  const [searchParam,SetSearchParam] = useState<Parameter>();
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 100,
    items: [],
    isLoading : true
  })
  const [owners, setOwners] = useState<Item[]>([])
  const [approvers, setOnApprovers] = useState<Item[]>([])
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [clientHeight,setClientHeight] = useState(0)

  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

  useEffect(() => {
    if(user.permissions.onBehalf === 0){
        navigate('/permissiondenided')
    }
    
 
    getData(1,appState.perpage,initialRequestTypeIds).then(() => {
      stickyHelper.updatePosition()
  
    })

    stickyHelper.removeEvent()
    stickyHelper.addEvent()
    window.addEventListener("resize", () => { 
     onSetClientHeight()
  });
  }, [])

  const onFinish = (values: any) => {
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
 
  const showModalCarton = async (_recordNo:number,_requestTypeId: number) => {
    setRecordNo(_recordNo)
    setRequestTypeId(_requestTypeId)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const getData = async (currentPage: number,perpage: number,_requestTypeIds: number[],_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = InitHelper.InitialParameter();
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
    else{
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.requestTypeIds = _requestTypeIds
      parameter.orderBy = "StatusId"
      parameter.statusIds = [
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.WaitApproval,
        CartonStatus.Done,
        CartonStatus.Reject
      ]
    }
      parameter.onBehalf = 1
      SetSearchParam(parameter)
      let token = await getToken()
      let result = await new QueryService(token).SearchOnbehalf(parameter)
      console.log(result)
      if (!result)
          return null;
      let {page,limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false
          state.perpage = perpage
      if(total>0){
        setIsprint(false)
      }else{
        setIsprint(true)
      }

        setTimeout(() => {
          setAppState(state)
          setTimeout(() => {
            onSetClientHeight()
          }, 100);
        }, 500);
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    console.log(field)
    let parameter = InitHelper.InitialParameter();
        parameter.page =1
        parameter.limit = appState.perpage
        parameter.requestTypeIds = requestTypeIds
      if (requestTypeIds.indexOf(RequestType.DestructionCarton) > -1 && requestTypeIds.indexOf(RequestType.ExtendCarton) === -1) {
            
            parameter.requestTypeIds.push(RequestType.ExtendCarton)
        }
        parameter.statusIds = [
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.WaitApproval,
            CartonStatus.Done,
            CartonStatus.Reject
          ]
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
          
    if(field.requestNo){
        parameter.requestNoList = field.requestNo.trim().split(",")
    }

    if(field.memo){
        parameter.memo = field.memo
    }

    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

    if(approvers.length > 0){
        parameter.approvers = approvers.map(item => item.key)
      }

      if(field.description){
        parameter.description = field.description
      }
    if(field.statusId){
        parameter.statusIds = field.statusId
    }

    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
      parameter.cartonNo = cartonNoStart + '&' + cartonNoEnd
    }

    if(field.cartonNoList){
      parameter.cartonNo = field.cartonNoList
    }

    if(field.cartonNo){
      parameter.cartonNo = field.cartonNo
    }

    console.log(parameter)
    getData(1,appState.perpage,requestTypeIds,parameter)
    
 }

 const onSearchEmployee = async(key:string) => {
   
  let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee(key)
  if(result && result.success)
  {
    return result.data
  }
  else{
    console.log(result?.message)
    return []
  }
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }

  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }

  const onPageChange = (page:number,perpage:number) => {
   
    if(perpage !== appState.perpage){
        getData(1,perpage,requestTypeIds)
    }
    else{
      getData(page,appState.perpage,requestTypeIds)
    }
  }

 const onRequestTypeChange = (_requestTypeId: number) => {
  if(_requestTypeId === 0){
    setRequestTypeIds(initialRequestTypeIds)
  }
  else{
    setRequestTypeIds([_requestTypeId])
  }
};

const onOwnerSelect = (item:any) => {
  setOwners(item)
}

const onApproversSelect = (item:any) => {
    setOnApprovers(item)
  }

const reset = () => {
  let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
  let elementEnd = document.getElementById('cartonNoStart') as HTMLInputElement
  elementStart.value = ""
  elementEnd.value = ""
   dataForm.resetFields()
   setOwners([])
   setRequestStartDate(null)
   setRequestEndDate(null)

   getData(1,appState.perpage,initialRequestTypeIds)
}

const onFinishPrint = async () => {
  setIsLoadingPrint(true)

  let token = await getToken()
  if(!searchParam)return
  try{
    await new FileManageService(token).DownloadReport(searchParam,ExportType.ONBEHALF);
  }catch(error){
    console.log("🚀 ~ file: OnBehalfTracking.tsx:302 ~ onFinishPrint ~ error:", error)
  }finally{
    setIsLoadingPrint(false)
  }
}
  return (
    <div id="carton-panel">
       <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom min-height-442'>
        <Form
            {...layout}
            form={dataForm}
            name="search-panel"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                  <Form.Item name="requestType" label="Request Type" initialValue={0} >
                      <Select placeholder={MessageCommon.TitleSelect}  onChange={onRequestTypeChange}
                        options={[
                            { value: 0, label: 'All' },
                            { value: RequestType.CollectCarton, label: 'Collect Carton' },
                            { value: RequestType.ReturnCarton, label: 'Return Carton' },
                            { value: RequestType.DestructionCarton, label: 'Destruction Carton' }
                        ] }
                      />
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
               
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                    <Form.Item name="requestNo" label="Request No."  >
                        <Input placeholder="ex. B230001,B230002,B230003" />
                    </Form.Item>
          
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="requestDate" label="Request Date">
                      <table className='w100c'>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
               
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonRange" label="Carton No:(Range)" >
                    <table className='w100c'>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-5'>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                      </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                  </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <Form.Item name="description" label="Description" >
                        <Input.TextArea rows={5} />
                      </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                  <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                  </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                    <Form.Item name="statusId" label="Status">
                        <Select 
                          mode="multiple"
                          allowClear
                          placeholder={MessageCommon.TitleSelect}
                          options={StatusOption}
                        />
                      </Form.Item>
              </Col>
            </Row>
            {requestTypeIds.indexOf(RequestType.DestructionCarton) > -1 ?
            <Row>
              <Col md={11} >
                  <Form.Item name="memo" label="Org. Unit Reference/Memo">
                        <Input />
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="approverName" label="Approver" >
                <EmployeeAutoComplete
                    placeholder='Search by Name'
                    selectedItems={approvers ? approvers : []}
                    onChange={item => onApproversSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                  />
              </Form.Item>
              </Col>
            </Row>:<></>
}
           

          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn'  onClick={() => reset()}  disabled={appState.isLoading}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"    onClick={() => searchData()} disabled={appState.isLoading}>
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>
          
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>

        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right '>
        <div id="panel-action"  className='padding-top-10'>
                <div>
                <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
                    <AiOutlinePrinter className='icn-btn' />Print
                </Button>
                { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                </div>
                <div>
                  <div className='search-result'>Search found { appState.total } record(s).</div>
             
                </div>
              </div>
    </div>
          
    <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
       
          <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
              <tr>
                <th className="thfix1 on-behalf-s-th">Request No.</th>
                <th className="on-behalf-s-th">Requested Date</th>
                <th className="on-behalf-s-th">Carton Quantity</th>
                <th className="on-behalf-s-th">Request Type</th>
                <th className="on-behalf-s-th">Status</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                  <td className='tdfix1 on-behalf-s-td text-center'><a className='a-table'onClick={() => showModalCarton(item.recordNo,item.requestTypeId)} >{item.requestNo}</a></td>
               
                  <td className='on-behalf-s-td text-center'>{ item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                  <td className='on-behalf-s-td text-center'>{item.quantity}</td>
                  <td className='on-behalf-s-td text-center'>{item.requestType}</td>
                  <td className='on-behalf-s-td text-center'>{item.status}</td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange}
                showSizeChanger={false}
              />:<></>
        }
        </div>
        
        
    </div>
    
    <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
    

      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
       
        { requestTypeId === RequestType.CollectCarton ?
          <CollectCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} onBehalf/>:<></>
        }
        
        {
          requestTypeId === RequestType.ReturnCarton ?
                      <ReturnCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} approveLevel={0} onBehalf />:<></>
        }
        {
          requestTypeId === RequestType.ExtendCarton || requestTypeId === RequestType.DestructionCarton ?
                      <DestructionCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""} approveLevel={0} onBehalf/>:<></>
        }
        
          </Modal>
          {modal ? <span></span> : <></>}
        {contextHolder}
    </div >
  );

}

export default OnBehalfTracking
