import { useEffect, useState } from 'react';
import { Collapse, Form, message,Modal,Row,Spin,Card,Col,Popconfirm,Input,Typography,Pagination,Button,Select,DatePicker } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import { Label } from 'reactstrap';
import {RequestType, CartonStatus ,ExportType} from '../../enum';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { AiOutlineSync, AiOutlineSearch, AiFillSave,AiFillDelete,AiFillEdit,AiOutlinePrinter,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item,RequestChangeOrgUnit,RequestChangeOrgUnits} from '../../models'
import { Helper, InitHelper ,StickyHelper} from '../../helpers';
import { HrWebService,PropertyService,ChangeOrgUnitService,FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { useNavigate } from 'react-router-dom';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { OrgUnitAutoComplete2 } from '../../autocomplete/OrgUnitAutoComplete2';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    orgUnits:string[]
    isLoading:boolean
  }

const ManualUpdate = () => {
    const stickyHelper = new StickyHelper("manual-update",50,50)
    const stickyElement = stickyHelper.getElement()
    stickyHelper.setIncDataTop(28)
    
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
   

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [modal, contextHolder] = Modal.useModal();
    const [recordNo, setRecordNo] = useState(0);
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      orgUnits:[],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [searchOwners, setSearchOwners] = useState<Item[]>([])
    const [searchPrevOrgunits, setSearchPrevOrgUnits] = useState<Item[]>([])
    const [searchNewOrgunits, setSearchNewOrgUnits] = useState<Item[]>([])
    const [selectItems, setSelectItems] = useState<number[]>([])
    const [requestTypeId, setRequestTypeId] = useState(RequestType.ChangeOwner)
    const [isLoadingPrint, setIsLoadingPrint] = useState(false)
    const [disableSubmit,setDisableSubmit] = useState(false)
    const [newOwners, setNewOwners] = useState<Item[]>([])
    const [newOrgUnits, setNewOrgUnits] = useState<Item[]>([])
    const [clientHeight,setClientHeight] = useState(0)

    const onSetClientHeight = () => {
    
        setTimeout(() => {
          stickyHelper.updatePosition()
          window.scrollTo(0,0)
          let element = document.getElementById(stickyElement.container) as HTMLInputElement
          if(element){
            let value = element.clientHeight
            setClientHeight(value)
          }
        }, 100);
      
      }

    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }

        
        setRequestTypeId(RequestType.ChangeOwner)
        setIsLoadingPrint(false)
        let params = InitHelper.InitialParameter();
        params.confidentialLevel = 1;
        dataForm.setFieldValue("confidential",1)

     
        getData(1, appState.perpage, params).then(() => {
            stickyHelper.updatePosition()
        
        })

        stickyHelper.removeEvent()
        stickyHelper.addEvent()

        window.addEventListener("resize", () => { 
           onSetClientHeight()
        });

    }, [])
    
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    
    
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.WaitAdmin]
        parameter.manual = 1
        let token = await getToken()
        
        let result = await new ChangeOrgUnitService(token).SearchRequest(parameter)
        
       
        if(result && result.success)
        {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            setTimeout(() => {
                setSaveParameter(parameter)
              setAppState(state)
              setSelectItems([])
              setTimeout(() => {
                onSetClientHeight()
              }, 100);
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
    
        let field = dataForm.getFieldsValue()
       
        let parameter = InitHelper.InitialParameter();
           
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }

        parameter.confidentialLevel = field.confidential
        
        if(searchOwners.length > 0){
            parameter.owners = searchOwners.map(item => item.key)
        }
    
        if(searchPrevOrgunits.length > 0){
            parameter.orgUnits = searchPrevOrgunits.map(item => item.key)
        }

        if(searchNewOrgunits.length > 0){
            parameter.newOrgUnits = searchNewOrgunits.map(item => item.key)
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
    
       
        getData(1,appState.perpage,parameter).then(() => {
            stickyHelper.updatePosition()
            
        }) 
    }

    const reset = () => {
        
        dataForm.resetFields()
        dataForm.setFieldValue("confidential",1)
        let parameter = InitHelper.InitialParameter()
        parameter.confidentialLevel = 1
        setSearchOwners([])
        setSearchPrevOrgUnits([])
        setSearchNewOrgUnits([])
        setRequestStartDate(null)
        setRequestEndDate(null)
        getData(1,appState.perpage,parameter)
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    
    const handleSave = async (statusId:number) => {
     
        let requestChangeOwner:RequestChangeOrgUnits = {
            recordIds: selectItems,
            statusId: statusId,
            newOwner:"",
            newOrgUnit:"",
            newOrgUnitId:""
        }
       
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).AdminUpdate(requestChangeOwner);
          
        
        let ele = document.getElementById(stickyElement.container) as HTMLElement
        if(result && result.success){
      
            messageApi.open({
              type: 'success',
              content: 'Save success',
              duration: 1,
              style: { marginTop: ele.getBoundingClientRect().top , marginLeft: ele.getBoundingClientRect().left  }
            });
          
              reset()
              
        }
        else{
            Modal.error({
                icon: <CloseCircleOutlined />,
                title: 'This is an error message',
                content: result?.message,
            });
        }
    };
    
    const handleSaveOrgUnit = async () => {
      
        let index = appState.items.findIndex(x => x.recordNo === recordNo)
        let item = appState.items[index]
        let state = Object.assign({},appState)

    
        let requestChangeOrgUnit:RequestChangeOrgUnit = {
            recordNo: recordNo,
            newOwner: newOwners.length > 0 ? newOwners[0].key : "",
            newOrgUnitId: newOrgUnits.length > 0 ? newOrgUnits[0].key : "",
            newOrgUnit: newOrgUnits.length > 0 ? newOrgUnits[0].name : "",
            confidentialLevel: item.confidentialLevel 
        }
       
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).UpdateChangeOrgUnit(requestChangeOrgUnit);
    
       
        let ele = document.getElementById(stickyElement.container) as HTMLElement
        if(result && result.success){
    
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
            style: { marginTop: ele.getBoundingClientRect().top , marginLeft: ele.getBoundingClientRect().left  }
          });
          
          if(newOwners.length > 0)
          {
            state.items[index].newOwner = newOwners[0].key
            state.items[index].newOwnerName = newOwners[0].name
         }
         else{
            state.items[index].newOwner = ""
            state.items[index].newOwnerName = ""
         }

         if(newOrgUnits.length > 0){
            state.items[index].newOrgUnit = newOrgUnits[0].name
          }
         else{
            state.items[index].newOrgUnit = ""
         }
          state.items[index].confidentialLevel = requestChangeOrgUnit.confidentialLevel
          setRecordNo(0)
          setAppState(state)
          setNewOwners([])
          setNewOrgUnits([])
          onCheckItem(selectItems)
       }
        else{
          Modal.error({
              icon: <CloseCircleOutlined />,
              title: 'This is an error message',
              content: result?.message,
          });
        }
      
     
    };

    const cancelSave = () => {
        setRecordNo(0)
        setNewOwners([])
        setNewOrgUnits([])
    }
    
    const onPageChange = (page:number,perpage:number) => {
       
        if(perpage !== appState.perpage){
            getData(1,perpage)
        }
        else{
            getData(page,appState.perpage)
        }
    }
    
    const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
        let items:number[] = []
        let _disableSubmit = false
        if(e.target.checked){
          appState.items.forEach(item => {
            let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
            element.checked = true
            items.push(parseFloat(element.value))
            if(!item.newOwner && !item.newOrgUnit)
            {
                _disableSubmit = true
            }
          })
        }
        else{
          appState.items.forEach(item => {
            let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
            element.checked = false
          })
        }
       
        onCheckItem(items)
      };
    
      const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked
        let value = parseFloat(e.target.value)
        let items = Object.assign([],selectItems)
        

        if(checked){
            items.push(value)
            let element = document.getElementById('check-item-all') as HTMLInputElement
            if(items.length === appState.items.length){
              element.checked = true
              element.indeterminate = false
            }
            else{
              element.checked = false
              element.indeterminate = true
            }
           
        }
        else{
          let index = items.indexOf(value);
          if (index !== -1) {
            items.splice(index, 1);
          }
    
          if(items.length == 0){
            let element = document.getElementById('check-item-all') as HTMLInputElement
            element.checked = false
            element.indeterminate = false
          }
    }
    
     
        
        onCheckItem(items)
      }
      
      const onCheckItem = (_selectItems:number[]) => {
        
        let _disableSubmit = false
        _selectItems.forEach(item => {
            let itemData = appState.items.find( x => x.recordNo === item)
            if(itemData && !itemData.newOwner && !itemData.newOrgUnit)
            {
                _disableSubmit = true
            }
           
        })
        
        setSelectItems(_selectItems)
        setDisableSubmit(_disableSubmit)
      }

      const handleEdit = (_recordNo:number) => {
        let item = appState.items.find(x => x.recordNo === _recordNo)
      
        if(item){
            let newOwners:Item[] = []
            let newOrgUnits:Item[] = []
            if(item.newOwner)
            {
                newOwners = [{ key:item.newOwner, name: item.newOwnerName, orgUnit:"",location:"",floor:""}]
               
            }
            if(item.newOrgUnit)
            {
                newOrgUnits = [{ key:item.newOrgUnit, name: item.newOrgUnit, orgUnit:"",location:"",floor:""}]
               
            }
            
            setNewOwners(newOwners)
            setNewOrgUnits(newOrgUnits)
            setRecordNo(_recordNo)
        }
        

     }

    const onSearchOwnerChange = (item:any) => {
        setSearchOwners(item)
        stickyHelper.updatePosition()
    }

    const onSearchPrevOrgunitChange = (value:any) => {
        setSearchPrevOrgUnits(value)
        stickyHelper.updatePosition()
    }

    const onSearchNewOrgunitChange = (value:any) => {
        setSearchNewOrgUnits(value)
        stickyHelper.updatePosition()
     }
 

    const onEditNewOwnerSelect = (item:any) => {
        setNewOwners(item)
    }
    
   
   
    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
        if(requestEndDate && date > requestEndDate){
            setRequestEndDate(null)
        }
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const disabledDate = (value:any) => {
        if(requestStartDate)
            return Helper.disabledDate(value, dayjs(requestStartDate).format('DD MMM YYYY'))
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    const onSearchHrOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetHrOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    const onEditNewOrgUnitSelect = (item:any) => {
        setNewOrgUnits(item)
      }
    
    
    const ConfidentialChange =(value:number) =>{
        let _state = Object.assign([],appState)
        let index = appState.items.findIndex(x => x.recordNo == recordNo)
        _state.items[index].confidentialLevel = value

        setAppState(_state)
    
    }

    const onFinishPrint = async () => {
        setIsLoadingPrint(true)
        let token = await getToken()
        if(!saveParameter)return
  
        try{
            saveParameter.recordIds = selectItems
            await new FileManageService(token).DownloadReport(saveParameter,ExportType.MANUAL_UPDATE_ORG_UNIT);
        }catch(error){
            console.log("🚀 ~ file: ManualUpdate.tsx:392 ~ onFinishPrint ~ error:", error)
  
        }finally{
            setIsLoadingPrint(false)
        }
    }

    return (
    <div>
        <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse2(28)}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
              {...layout}
              form={dataForm}
              name="orgunit-form"
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="Change Date">
                      <table className='w100c'>
                        <tbody>
                        <tr>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate}  
                                format={'DD MMM YYYY'} 
                                className='date-picker-full' 
                                onChange={onRequestStartDateChange}
                            />
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker 
                                value={requestEndDate ?  dayjs(requestEndDate) : null}
                                placeholder={MessageCommon.TitleDate}  
                                format={'DD MMM YYYY'} 
                                className='date-picker-full' 
                                onChange={onRequestEndDateChange}
                                disabledDate={disabledDate}
                            />
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Storage Request No."  >
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Owner'
                            selectedItems={searchOwners ? searchOwners : []}
                            onChange={item => onSearchOwnerChange(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="searchPrevOrgUnit" label="Previous Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Previous Org. Unit'
                            selectedItems={searchPrevOrgunits ? searchPrevOrgunits : []}
                            onChange={item => onSearchPrevOrgunitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="searchNewOrgUnit" label="New Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by New Org. Unit'
                            selectedItems={searchNewOrgunits ? searchNewOrgunits : []}
                            onChange={item => onSearchNewOrgunitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="confidential" label="Confidentiality Level"  >
                <Select placeholder={MessageCommon.TitleSelect} 
                  options={[
                    { value: -1, label: 'All' },
                    { value: 1, label: 'Confidential' },
                    { value: 0, label: 'Non-Confidential' },
                
                  ]}
                />
              </Form.Item>
                </Col>
              </Row>
      
                </Form>
                <div className='text-center'>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2-2 sticky-bg-white'></div>

        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
        { requestTypeId === RequestType.ChangeOwner ?
                <div className='margin-bottom-25'>
                    { selectItems.length > 0 ?
                        <>
                            <Popconfirm
                                title="Cancel the task"
                                description="Confirm to reject?"
                                icon={<QuestionCircleOutlined className='c-red' />}
                                onConfirm = { () =>  handleSave(CartonStatus.Cancel)}
                            >
                                <Button type="primary" className='btn-red mr-btn'>
                                    <AiFillDelete className='icn-btn' /> Reject
                                </Button>
                            </Popconfirm>
                             <Popconfirm
                                title="Update the task"
                                description="Confirm to submit?"
                                icon={<QuestionCircleOutlined className='c-red' />}
                                onConfirm = { () =>  handleSave(CartonStatus.Done) }
                            >
           
                                <Button type="primary" disabled={disableSubmit}>
                                    <AiFillSave className='icn-btn' /> Submit
                                </Button>
          
                            </Popconfirm>
                        
                        </>:
                        <>
                            <Button type="primary" className='btn-red mr-btn' disabled>
                                <AiFillDelete className='icn-btn' /> Reject
                            </Button>
          
                            <Button type="primary" disabled>
                                <AiFillSave className='icn-btn' /> Submit
                            </Button>
                            
                          
                            
                        </>
                    }
                    <Button type="primary" className='mr-btn margin-left-5'  onClick={()=>onFinishPrint()}>
                                <AiOutlinePrinter className='icn-btn' />Print
                     </Button>
                   
                    { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                </div>
                :<></>
            }
          
                
                <div className='search-result float-left'>Search found { appState.total } record(s).</div>
                <div className='float-right' >
                    <label >ระบบจะมีการแจ้ง Pending Request List ทุกๆ 15 วัน</label>
                </div>
            
        </div>
        
        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
            <div id={stickyElement.container}>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                                 <th className="thfix1 text-center w50">
                                    <div className="form-check">
                                        <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                                        <label className="form-check-label" htmlFor={'check-item-all'}/>
                                    </div>
                      
                                </th>
                            
                                <th className="s-th thfix2">Storage Request No.</th>
                                <th className="s-th">Owner</th>
                                <th className="s-th">Previous Org. Unit</th>
                                <th className="s-th">New Owner</th>
                                <th className="s-th">New Org. Unit</th>
                                <th className="s-th">Confidentiality Level</th>
                                <th className="s-th">System Date</th>
                                <th className="s-th">Edit</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                    <td className="tdfix1 text-center w50">
                                        <div className="form-check">
                                            <input  type="checkbox" id={'check-item-' + item.recordNo} value={item.recordNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                                            <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                                        </div>
                                    </td>
                                    <td className="s-td tdfix2 text-center">{item.requestNo }</td>
                                    <td className="s-td text-left-20">{item.ownerName}</td>
                                    <td className="s-td text-center">{item.orgUnit}</td>
                                    <td className="s-td text-left-20">
                                      { item.recordNo === recordNo ? 
                                        <EmployeeAutoComplete
                                            key={Helper.Uniqid()}
                                            placeholder='Search by Owner'
                                                selectedItems={returnIfElse(newOwners, newOwners, [] )}
                                            onChange={item => onEditNewOwnerSelect(item)}
                                            service={onSearchEmployee}
                                            minimumTextLength={3}
                                            pickOnlyOne
                                        /> : <>{item.newOwnerName}</> }
                                    </td>
                                    <td className='text-center padding-top-5'>
                                        { item.recordNo === recordNo ? 
                                             <OrgUnitAutoComplete2
                                             pickOnlyOne
                                             key={Helper.Uniqid()}
                                             placeholder='Search by Org. Unit'
                                             selectedItems={newOrgUnits ? newOrgUnits : []}
                                             onChange={item => onEditNewOrgUnitSelect(item)}
                                             service={onSearchHrOrgUnit}
                                             minimumTextLength={2}
                                         />
                                            :
                                            <span>{item.newOrgUnit}</span>
                                        }
                                    </td>
                                    <td className="s-td text-center">
                                            { item.recordNo === recordNo ?
                                             <Select placeholder={MessageCommon.TitleSelect} 
                                             defaultValue={item.confidentialLevel}
                                             onChange={ConfidentialChange}
                                             options={[
                                               { value: 1, label: 'Confidential' },
                                               { value: 0, label: 'Non-Confidential' },
                                           
                                             ]}
                                             />
                                             :
                                            <span>{returnIfElse(item.confidentialLevel === 0, 'Non-Confidential', 'Confidential'  )  }</span>
                                            }
                                    </td>
                        
                                    <td className="s-td text-center">
                                        {dayjs(item.requestDate).format('DD MMM YYYY')}
                                    </td>
                                    <td className="s-td text-center">
                                        { item.recordNo === recordNo ?
                                             <div>
                                                <span>
                                                    <Popconfirm title="Confirm to update?" onConfirm={handleSaveOrgUnit}>
                                                        <Typography.Link>Update</Typography.Link>
                                                    </Popconfirm>
                                                        <a className='margin-left-10' onClick={cancelSave}>
                                                            Cancel
                                                        </a>
                                                </span>
                                            </div>:
                                            <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo)} className='pointer'/>
                                        }
                                    </td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
                </div>
                <div className='margin-top-10 text-right'>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        </div>
   
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
        

            {modal ? <span></span> : <></>}
        {contextHolderMessage}
        {contextHolder}
       
    </div>
    );
  
  }
  
  export default ManualUpdate
  