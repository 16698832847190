import { useEffect, useState } from 'react';
import { Button, Col, Collapse, Form, Spin, Row, Select, Pagination ,Modal} from 'antd';
import {  AiOutlinePrinter,  AiOutlineSearch, AiOutlineSync,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import {  WarehouseListModel, Option ,WarehouseParameter,Element} from '../../models'
import { Helper ,StickyHelper} from '../../helpers';
import {  FileManageService,WarehouseService } from '../../services';
import secureLocalStorage  from  "react-secure-storage";
import WarehouseStatus from '../../enum/WarehouseStatus';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { ExportType,CartonStatus } from '../../enum';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';
import CartonDetail from '../Share/CartonDetail';

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items: WarehouseListModel[]
  isLoading:boolean
}

interface StorageCarton{
    requestNo:string
    cartonNo:string
  }

const WarehouseList = () => {
  const stickyHelper = new StickyHelper("warehouse-list",50,0)
    stickyHelper.setThElement("wl-s-th")
    stickyHelper.setTdElement("wl-s-td")
    const stickyElement:Element = stickyHelper.getElement()

    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isPrint,setIsprint] = useState(true)
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [warehouse, setWarehouse] = useState("")
  const [statusId, setStatusId] = useState(0)
  const [storageCarton,setStorageCarton] = useState<StorageCarton>({requestNo: "",cartonNo: ""})
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
    const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false)
    const [recordNo, setRecordNo] = useState(0);
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: 100,
    items: [],
    isLoading : true
  })
  const[saveParameter,setSaveParameter] = useState<WarehouseParameter>({
    page: 1,
    limit: 25,
    cartonLocation : [],
    contractNo: "",
    statusIds: [
        WarehouseStatus.Existing,
        WarehouseStatus.WaitForDelivery,
        WarehouseStatus.WaitReturnDelivery,
        WarehouseStatus.WaitChange
    ],
    active: -1
  })
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [clientHeight,setClientHeight] = useState(0)

  useEffect(() => {
    if(user.permissions.warehouse == 0){
        navigate('/permissiondenided')
    }

    initial()


  }, [])

  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

    const initial = async () => {
        
        let warehouses = await getWarehouse()
        let warehouseNames = warehouses?.filter(x => x.value != "").map(x => x.value.toString())
        let parameter:WarehouseParameter = {
            page: 1,
            limit: appState.perpage,
            cartonLocation : warehouseNames ? warehouseNames : [],
            contractNo: "",
            statusIds: [
                WarehouseStatus.Existing,
                WarehouseStatus.WaitForDelivery,
                WarehouseStatus.WaitReturnDelivery,
                WarehouseStatus.WaitChange
            ],
            active: -1
          }
        
          if(warehouses){
            setWarehouseOptions(warehouses)
          }
        
     
        getData(1, appState.perpage,parameter).then(() => {
          stickyHelper.updatePosition()
        })

        stickyHelper.removeEvent()
        stickyHelper.addEvent()

        window.addEventListener("resize", () => { 
            onSetClientHeight()
        });
        
    }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  

  const searchData = async () => {
    let parameter = Object.assign({},saveParameter)
    let warehouseNames:string[] = warehouseOptions.filter(x => x.value != "").map(x =>x.value.toString())
        parameter.cartonLocation = warehouseNames ? warehouseNames : []
        
        if(warehouse){
            parameter.cartonLocation = [warehouse]
        }
          
   
      if (statusId > 0) {
          parameter.statusIds = [statusId]
      } else {
          parameter.statusIds =[
              WarehouseStatus.Existing,
              WarehouseStatus.WaitForDelivery,
              WarehouseStatus.WaitReturnDelivery,
              WarehouseStatus.WaitChange
          ]
      }
    
    getData(1, appState.perpage, parameter)
    
  }
  const reset = () => {
   
    dataForm.resetFields()
    let warehouseNames:string[] = warehouseOptions.filter(x => x.value != "").map(x =>x.value.toString())
    let parameter:WarehouseParameter = {
        page: 1,
        limit: appState.perpage,
        cartonLocation : warehouseNames,
        contractNo: "",
        statusIds: [
            WarehouseStatus.Existing,
            WarehouseStatus.WaitForDelivery,
            WarehouseStatus.WaitReturnDelivery,
            WarehouseStatus.WaitChange
        ],
        active: -1
      }
    getData(1,appState.perpage,parameter)
  }

  const getData = async (currentPage: number,perpage: number , _parameter:WarehouseParameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
        _state.isLoading = true
        setAppState(_state)
    let parameter:WarehouseParameter = saveParameter

    if(_parameter !== null){
        parameter = _parameter;
    }

    parameter.page = currentPage
    parameter.limit = perpage
    
    
    let token = await getToken()

    let result = await new WarehouseService(token).SearchList(parameter)
  
    
    if(result && result.success)
    {
        let state = Object.assign({},appState)
            state.total =  result.data.total
            state.currentPage = currentPage
            state.perpage = perpage
            state.items =  result.data.items
            state.isLoading = false
        if(result.data.total > 0){
            setIsprint(false)
        }else{
            setIsprint(true)
        }
        setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
            setTimeout(() => {
              onSetClientHeight()
            }, 100);
        }, 500);
    }
    else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
    }
}
const getWarehouse = async () => {
    let token = await getToken()
    let result = await new WarehouseService(token).GetAllWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: "" }]
        let Locationoptions:Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
       
        return options.concat(Locationoptions)
    }
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(perpage !== appState.perpage){
      getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
   }
  }
  const onFinishPrint = async () => {
    setIsLoadingPrint(true)

    
    if(!saveParameter)return

    let token = await getToken()

    try{

      await new FileManageService(token).DownloadReport(saveParameter,ExportType.WAREHOUSE_LIST);
    }catch(error){
      console.log("🚀 ~ file: WarehouseList.tsx:169 ~ onFinishPrint ~ error:", error)
      
    }finally{
      setIsLoadingPrint(false)

    }
  }

    const onWarehouseChange = (value:string) => {
        setWarehouse(value)
    }

    const onStatusChange = (value: number) => {
        
        setStatusId(value)
    }

    const showModalCartonDetailOpen = async (_recordNo: number, _requestNo: string, _cartonNo:string) => {
        let _storageCarton:StorageCarton = {
            requestNo: _requestNo,
            cartonNo: _cartonNo
        }
        setRecordNo(_recordNo)
        setStorageCarton(_storageCarton)
        setIsModalCartonDetailOpen(true);
    }

    const handleCartonDetailCancel = () => {
        setIsModalCartonDetailOpen(false);
      }

    
  return (
    <div>
      <div  id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
          {...layout}
          form={dataForm}
          name="nest-messages"
          onFinish={onFinish}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={11} >
                <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>
            </Col>
            <Col md={2} >
            </Col>
            <Col md={11} >

            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Select placeholder={MessageCommon.TitleSelect} defaultValue={0}
                  options={[
                    { value: 0, label: 'All' },
                    { value: 1, label: 'Existing' },
                    { value: 2, label: 'Wait for Warehouse Delivery' },
                    { value: 3, label: 'Wait for Return Warehouse' },
                    { value: 4, label: 'Wait for Change Warehouse' },
                  ]}
                  onChange={onStatusChange}
                />
              </Form.Item>
            </Col>
            <Col md={2} >
            </Col>
            <Col md={11} >

            </Col>
          </Row>


        </Form>
        <div className='text-center'>
          <Button type="default" className='mr-btn'  onClick={reset}>
            <AiOutlineSync className='icn-btn' /> Reset
          </Button>
          <Button type="primary"  onClick={searchData}>
            <AiOutlineSearch className='icn-btn' /> Search
          </Button>


        </div>
        </div>
                  
        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>
        
        
        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
        <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
          <AiOutlinePrinter className='icn-btn' />Print
        </Button>
        { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                    <div id="panel-action"  className='padding-top-10'>
                  <div>
                  <div className='search-result'>Search found { appState.total } record(s).</div>
                  
                </div>
              </div>
        </div>

        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
    
        <div id={stickyElement.container}>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                                <th className="wl-s-th thfix1">Carton No.</th>
                                <th className="wl-s-th">Carton Status</th>
                                <th className="wl-s-th">Request No</th>
                                <th className="wl-s-th">Request Type</th>
                                <th className="wl-s-th">Owner</th>
                                <th className="wl-s-th">Carton Location</th>
                                <th className="wl-s-th">Status</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:WarehouseListModel) => (
                                <tr>
                                    
                                    <td className="wl-s-td tdfix1 text-center">
                                      <a className={ item.cartonStatusId === CartonStatus.Remove || item.cartonStatusId === CartonStatus.Destruction ? 'a-table c-red' : 'a-table'}  onClick={() => showModalCartonDetailOpen(item.storageRecordNo, item.requestNo, item.cartonNo)}>{item.cartonNo}</a>
                                    </td>
                                    <td className="wl-s-td text-center">{item.cartonStatus}</td>
                                    <td className="wl-s-td text-center">{item.requestNo}</td>
                                    <td className="wl-s-td text-center">{item.requestType}</td>
                                    <td className="wl-s-td text-center">{item.ownerName}</td>
                                    <td className="wl-s-td text-center">{item.cartonLocation}</td>
                                    <td className="wl-s-td text-center">
                                        {item.status}
                                    </td>
                                    
                                   
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
        </div>
         <div className='margin-top-10 text-right'>
         { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange}
                showSizeChanger={false}
            />:<></>
        }
        </div>
                    
        </div>
     
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
        
      
      
    <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
            <CartonDetail key={Helper.Uniqid()} requestNo={storageCarton.requestNo} box={storageCarton.cartonNo} />
            
      </Modal>
      {contextHolder}

      
        </div>
  );

}

export default WarehouseList
