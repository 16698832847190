import {useEffect, useState } from 'react';
import {  Spin } from 'antd';
import dayjs from 'dayjs';
import { NewCartonService ,StorageCartonService ,CollectCartonService,
    RemoveCartonService,DestructionCartonService,ReturnCartonService} from '../../services';
import { DataNote } from '../../models';
import { Helper } from '../../helpers';
import { RequestType } from '../../enum';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
  recordNo:number
  requestTypeId:number
}
const DisplayLogNote = (props: Props) => {
    const { instance, accounts } = useMsal();
  const [dataNotes, setDataNotes] = useState<DataNote[]>([]);
  const [isLoading, setIsLoading] = useState(true)

  const getData = async () => {

      let token = await getToken()
      let result:any = null
      if(props.requestTypeId === RequestType.NewCarton){
        result = await new NewCartonService(token).GetNote(props.recordNo)
      }
      if(props.requestTypeId === RequestType.StorageCarton){
        result = await new StorageCartonService(token).GetNote(props.recordNo)
      }
      if(props.requestTypeId === RequestType.CollectCarton){
        result = await new CollectCartonService(token).GetNote(props.recordNo)
      }
      if(props.requestTypeId === RequestType.ReturnCarton){
        result = await new ReturnCartonService(token).GetNote(props.recordNo)
      }
      if(props.requestTypeId === RequestType.RemoveCartonStorage || props.requestTypeId === RequestType.RemoveCarton){
        result = await new RemoveCartonService(token).GetNote(props.recordNo)
      }
      if(props.requestTypeId === RequestType.DestructionCarton){
        result = await new DestructionCartonService(token).GetNote(props.recordNo)
      }
      console.log(result)
      if(result && result.success){
        setDataNotes(result.data)
      }
      setIsLoading(false)
      
    }
    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  useEffect(() => {
    getData()
  }, [])

  return (

    <div className='min-height-150'>
      { isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>:
          <div id="table-container" className='padding-top-15'>
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th className='text-center'>Item No.</th>
                <th >Note</th>
                <th >Update By</th>
                <th >Update Date</th>
              </tr>
            </thead>
            <tbody >
              { dataNotes.map((item:DataNote,index) => (
                <tr>
                  <td className='text-center'>{index + 1}</td>
                  <td className='text-center'>{item.note}</td>
                  <td className='text-center'>{item.updateBy }</td>
                  <td className='text-center'>{ dayjs(item.updateDate).format("DD MMM YYYY") }</td>
               
                  
                </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      }
    
    </div>
  );

}

export default DisplayLogNote
