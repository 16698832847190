import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row ,Spin ,message,Popconfirm } from 'antd';
import {  AiOutlineClose, AiOutlineCheck,AiFillDelete, AiFillSave} from 'react-icons/ai';
import {  CloseCircleOutlined,QuestionCircleOutlined} from '@ant-design/icons';
import { Label } from 'reactstrap';
import DisplayLogLocation from '../Share/DisplayLogLocation';
import DisplayLogOwner from '../Share/DisplayLogOwner';
import CartonList from '../Share/CartonList'
import { RequestDetail ,UpdateApproval, User} from '../../models';
import {  StorageCartonService } from '../../services';
import { InitHelper, Helper } from '../../helpers';
import DisplayLogNote from '../Share/DisplayLogNote';
import dayjs from 'dayjs'
import { CartonStatus , RequestType, Message} from '../../enum';
import secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';

const StorageCartonView = () => {
    const { instance, accounts } = useMsal();
    const urlParams = new URLSearchParams(window.location.search);
    const id:string| null = urlParams.get('id')
    const recordNo = id ? parseInt(id) : 0
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [isModalLogOwnerOpen, setIsModalLogOwnerOpen] = useState(false);
  const [isModalLogLocationOpen, setIsModalLogLocationOpen] = useState(false);
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [dataForm] = Form.useForm();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isErrorNote,setIsErrorNote] = useState(false)
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  
  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
      setIsLoading(true)
      let token = await getToken()
    let result = await new StorageCartonService(token).GetDetail(recordNo)
    console.log(result)
    if(result && result.success){
        let detail = result.data
        if(detail.requester === user.employeeId 
            || detail.owner === user.employeeId 
            || detail.onBehalf == user.employeeId
            || detail.contactPerson === user.employeeId
            || detail.deliverto === user.employeeId
            || detail.approver === user.employeeId
            || detail.approver2 === user.employeeId
        ){
            setDetail(detail)
        }
        else{
            navigate('/permissiondenided')
        }
    }
    else{
        navigate('/permissiondenided')
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handleCancelReject = () => {
        setIsModalRejectOpen(false)
     }

  const handleNoteModalCancel = () => {

    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };


    const handleCancel = () => {
        let elements: any = Array.from(document.getElementsByClassName('ant-modal-close'))
        elements.forEach((el: any) => {
            el.click()
        })
    setIsModalRejectOpen(false)
    };


    const DisplayCondition = (detail: RequestDetail) => {
        return detail.statusId === CartonStatus.Done && detail.cartonLocation != null

    };
  
    const handleRejectOpen = () => {
        setIsErrorNote(false)
        setIsModalRejectOpen(true)
        setTimeout(() => {
            let element = document.getElementById('note-reject') as HTMLTextAreaElement
            element.value = ""
        }, 300);
       
     }
    

      const handleReject = () => {
        let element = document.getElementById('note-reject') as HTMLInputElement
        if(element && element.value){
          handleAction([recordNo], CartonStatus.Reject,CartonStatus.Reject,element.value) 
        }
        else{
          setIsErrorNote(true)
          return false
        }
        
      }
    
     const handleAction = async (recordIds:number[],statusId: number, approvesTatusId: number,noteReject: string | null = null) => {
    let token = await getToken()
    let requestUpdateStatus:UpdateApproval = {
      recordIds: recordIds,
      statusId: statusId,
      approveStatusId: approvesTatusId,
      noteReject:noteReject ? noteReject : "",
      updateBy: user.employeeId,
      updateDate: new Date()
    }
   
    let result = await new StorageCartonService(token).UpdateApproval(requestUpdateStatus)
    if(result && result.success){
      let element = document.getElementById('detail-form') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
        getData()
      }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
    setIsModalRejectOpen(false)
  }


  return (

    <div className='min-height-150'>
      { isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        :
      <>
    
       
        <Form
          {...layout}
          form={dataForm}
          name="detail-form"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={11} >
              <Form.Item name="requestNo" label="Request No"  >
                <Label className='label-det '>{ detail.requestNo }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Label className='label-det'> { detail.status } </Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="requester" label="Requester"  >
                <Label className='label-det'>{ detail.requesterName }</Label>

              </Form.Item>

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="owner" label="Owner"  >
                <Label className='label-det'>{ detail.ownerName }</Label>
                <a className='a-table ml-label' onClick={() => setIsModalLogOwnerOpen(true)}>Display Log</a>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
            <Form.Item name="contactPerson" label="Contact Person"  >
                <Label className='label-det'>{ detail.contactPersonName }</Label>
              </Form.Item>
              <Form.Item name="floor" label="Floor">
                <Label className='label-det'>{ detail.floor }</Label>
              </Form.Item>
             

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="location" label="Location">
                <Label className='label-det'>{ detail.location }</Label>
              </Form.Item>
              <Form.Item name="place" label="Place" >
                <Label className='label-det label-det-warp'>{ detail.place }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
          <Col md={11} >
            
            <Form.Item name="project" label="Project Name"  >
              <Label className='label-det'>{ detail.projectName }</Label>
            </Form.Item>
            <Form.Item name="approver" label="Carton Location" >
              <Label className='label-det'>{ detail.statusId === CartonStatus.Done ? detail.cartonLocation : "" }</Label>
                                  {DisplayCondition(detail) ?
                <a className='a-table ml-label' onClick={() => setIsModalLogLocationOpen(true)} >Display Log</a>:<></>
              }
            </Form.Item>
            <Form.Item name="document_year" label="Confidentiality Level" >
              <Label className='label-det'>{ detail.confidentialityLevel === 0 ? 'Non-Confidential' : 'Confidential'}</Label>
            </Form.Item>
            <Form.Item name="approver" label="Approver" >
              <Label className='label-det'>{ detail.approverName }</Label>
            </Form.Item>
            <Form.Item name="remark" label="Remark" >
              <Label className='label-det label-det-warp'>{ detail.remark }</Label>
            </Form.Item>
              <Form.Item name="note" label="Note" >
                  { detail.note.length > 0 ?
                <>
                    <Label className='label-det label-det-warp'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                </>:<></>
                }
              </Form.Item>
          </Col>
          
            <Col md={2} ></Col>
            <Col md={11} >
              <Form.Item name="total_qty" label="Total Carton Quantity" >
                <Label className='label-det'>{ detail.quantity } carton(s)</Label>
              </Form.Item>
              <Form.Item name="total_storage_year" label="Total Storage Year" >
                <Label className='label-det'>{ detail.totalStorageYear } year(s)</Label>
              </Form.Item>
              <Form.Item name="document_year" label="Document Year" >
                <Label className='label-det'>{ dayjs(detail.documentYear).format('MMM YYYY') }</Label>
              </Form.Item>
              <Form.Item name="destruction_due_date_month" label="Destruction Due Date" >
                <Label className='label-det'>{ dayjs(detail.destructionDueDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
              <Form.Item name="expected_pickup_date" label="Expected Pick up Date" >
                <Label className='label-det'>{ dayjs(detail.pickupDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
              { detail.serviceDate ?
              <Form.Item name="expected_pickup_date" label="Service Date" >
                <Label className='label-det'>{ dayjs(detail.serviceDate).format('DD MMM YYYY') }</Label>
              </Form.Item>:<></>
                }
            </Col>
          </Row>

        </Form>
    { !detail.approverDate && detail.approver === user.employeeId ?
        <div className='text-center' >
          <Button type="primary" className='btn-red mr-btn w140' onClick={handleRejectOpen} >
            <AiFillDelete className='icn-btn' /> Reject
          </Button>
        
        <Popconfirm
            title="Approve the task"
            description="Confirm to approve?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () =>  handleAction([recordNo],CartonStatus.WaitAdmin,CartonStatus.Approve) }
          >
           <div className="idoc-tooltip">
            <span className="tooltiptext">
              <ul>
                <li className='text-left'>
                  {Message.Verify}
                </li>
                <li className='text-left'>
                    {Message.Approve}
                </li>
              </ul>
            </span>
            <Button type="primary" className='w140'>
              <AiFillSave className='icn-btn' /> Verify/Approve
            </Button>
          </div>
        </Popconfirm>
        </div>
        :<></>
    }
                  <hr></hr>
                 
                  <CartonList requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={false} statusId={detail.statusId}/>

                  
            
                  
      </>
    }
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.StorageCarton}/>
      </Modal>

      <Modal title="Display Log" open={isModalLogOwnerOpen} footer={''} width={900} onCancel={() => setIsModalLogOwnerOpen(false)}>
        <DisplayLogOwner recordNo={detail.recordNo} />
      </Modal>

      <Modal title="Display Log" open={isModalLogLocationOpen} footer={''} width={900} onCancel={() => setIsModalLogLocationOpen(false)}>
        <DisplayLogLocation recordNo={detail.recordNo} />
      </Modal>

     
      <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
            <textarea className='text-confirm' id="note-reject" rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>

          {modal ? <span></span> : <></>}
      {contextHolderMessage}
      {contextHolder}
    </div>
  );

}

export default StorageCartonView
