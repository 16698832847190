enum ExportType {
    ALL_TRANSACTION,
    TOTAL_CARTON_LIST,
    DESTRUCTION_CARTON_LIST,
    STATUS_TRACKING,
    ORG_UNIT_DOCUMENT,
    QUERY_DATA,
    WAREHOUSE,
    APPROVAL_TRACKING,
    DESTRUCTION_CARTON_STORAGE,
    DESTRUCTION_CARTON_QUERY,
    ONBEHALF,
    CHANGE_OWNER,
    WAREHOUSE_LIST,
    MANAGE_ORG_UNIT,
    HISTORY,
    MANUAL_UPDATE_ORG_UNIT,
    ARCHIVE_OFFICER_UPDATE_ORG_UNIT,
    AUTO_CHANGE_ORG_UNIT,
    UPDATE_HISTORY_ORG_UNIT,
    EXPORT_STORAGE_LIST,
    EXPORT_COLLECT_LIST,
    EXPORT_RETURN_LIST,
    EXPORT_REMOVE_COLLECT_LIST,
    EXPORT_REMOVE_STORAGE_LIST,
    EXPORT_DESTRUCTION_LIST,
    EXPORT_PROJECTNAME_LIST
}

export default ExportType