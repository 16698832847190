import { AiOutlineCloseSquare, AiOutlineFileDone, AiOutlineInbox, AiOutlineMinusCircle, AiOutlineRotateLeft, AiOutlineRotateRight } from "react-icons/ai";
import NewCarton from "./pages/NewCarton/NewCarton";
import NewCarton2 from "./pages/NewCarton/NewCarton2";
import NewCarton3 from "./pages/NewCarton/NewCarton3";
import NewCartonRequest from "./pages/NewCarton/NewCartonRequest";
import StorageCarton from "./pages/StorageCarton/StorageCarton";
import StorageCarton2 from "./pages/StorageCarton/StorageCarton2";
import StorageCarton3 from "./pages/StorageCarton/StorageCarton3";
import StorageCartonRequest from "./pages/StorageCarton/StorageCartonRequest";
import CollectCarton from "./pages/CollectCarton/CollectCarton";
import CollectCarton2 from "./pages/CollectCarton/CollectCarton2";
import CollectCarton3 from "./pages/CollectCarton/CollectCarton3";
import CollectCartonRequest from "./pages/CollectCarton/CollectCartonRequest";
import ReturnCarton from "./pages/ReturnCarton/ReturnCarton";
import ReturnCarton2 from "./pages/ReturnCarton/ReturnCarton2";
import ReturnCarton3 from "./pages/ReturnCarton/ReturnCarton3";
import ReturnCartonRequest from "./pages/ReturnCarton/ReturnCartonRequest";
import RemoveCarton from "./pages/RemoveCarton/RemoveCarton";
import RemoveCarton2 from "./pages/RemoveCarton/RemoveCarton2";
import RemoveCarton3 from "./pages/RemoveCarton/RemoveCarton3";
import RemoveCartonRequest from "./pages/RemoveCarton/RemoveCartonRequest";
import DestructionCarton from "./pages/DestructionCarton/DestructionCarton";
import DestructionCarton2 from "./pages/DestructionCarton/DestructionCarton2";
import DestructionCarton3 from "./pages/DestructionCarton/DestructionCarton3";
import DestructionCartonRequest from "./pages/DestructionCarton/DestructionCartonRequest";
import DestructCartonDueDateView from "./pages/DestructionCarton/DestructCartonDueDateView";
import StatusTracking from "./pages/MyTask/StatusTracking";
import CollectCartonApproveList from "./pages/CollectCarton/CollectCartonApproveList";
import DestructionCartonApproveList from "./pages/DestructionCarton/DestructionCartonApproveList";
import OnBehalf from "./pages/OnBehalf/OnBehalf";
import OnBehalf2 from "./pages/OnBehalf/OnBehalf2";
import OnBehalf3 from "./pages/OnBehalf/OnBehalf3";
import QueryData from "./pages/QueryData/QueryData";
import OfficerDestructionCarton from "./pages/OfficerDestructionCarton/OfficerDestructionCarton";
import ManageExternalWarehouse from "./pages/MasterData/ManageExternalWarehouse";
import ManageChangeOwner from "./pages/MasterData/ManageChangeOwner";
import ManageChangeOwner2 from "./pages/MasterData/ManageChangeOwner2";
import ManageOrgUnit from "./pages/MasterData/ManageOrgUnit";
import Approval from "./pages/Approval/Approval";
import MyDocument from "./pages/MyTask/MyDocument";
import MyDocument2 from "./pages/MyTask/MyDocument2";
import ManageUser from "./pages/SystemManagement/ManageUser";
import SystemConfig from "./pages/SystemManagement/SystemConfig";
import MailMessage from "./pages/SystemManagement/MailMessage";
import TermsAndConditions from "./pages/SystemManagement/TermsAndConditions";
import RequestChangeOwner from "./pages/ChangeOwner/ChangeOwner";
import RequestChangeOwner2 from "./pages/ChangeOwner/ChangeOwner2";
import ReminderEmail from "./pages/MyTask/ReminderEmail";
import ManageProjectName from "./pages/MasterData/ManageProjectName";
import ReportAllTransaction from "./pages/Report/ReportAllTransaction";
import QueryDataNewCarton from "./pages/QueryData/QueryDataNewCarton";
import QueryDataStorageCarton from "./pages/QueryData/QueryDataStorageCarton";
import QueryDataCollectCarton from "./pages/QueryData/QueryDataCollectCarton";
import QueryDataReturnCarton from "./pages/QueryData/QueryDataReturnCarton";
import QueryDataRemoveCarton from "./pages/QueryData/QueryDataRemoveCarton";
import QueryDataDestructionCarton from "./pages/QueryData/QueryDataDestructionCarton";
import QueryDataRemoveCartonStorage from "./pages/QueryData/QueryDataRemoveCartonStorage";
import ReportTotalCartonList from "./pages/Report/ReportTotalCartonList";
import ReportDestructionCartonQuantity from "./pages/Report/ReportDestructionCartonQuantity";
import ReportCartonQuantity from "./pages/Report/ReportCartonQuantity";
import ReportDestructionCartonList from "./pages/Report/ReportDestructionCartonList";
import ApprovalTracking from "./pages/Approval/ApprovalTracking";
import NewCartonView from "./pages/NewCarton/NewCartonView";
import StorageCartonView from "./pages/StorageCarton/StorageCartonView";
import CollectCartonView from "./pages/CollectCarton/CollectCartonView";
import ReturnCartonView from "./pages/ReturnCarton/ReturnCartonView";
import RemoveCartonView from "./pages/RemoveCarton/RemoveCartonView";
import DestructionCartonView from "./pages/DestructionCarton/DestructCartonView";
import ExtendCartonView from "./pages/ReturnCarton/ExtendCartonView";
import Logout from "./Logout";
import PermissionDenied from './PermissionDenied';
import ManualUpdate3 from './pages/MasterData/ManualUpdate3';

const AppRoutes = [
  {
    path: '/index.html',
    element: <NewCarton />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/',
    element: <NewCarton />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/logout',
    element: <Logout />,
    icon: <AiOutlineInbox />,
    title: "Logout",
    breadcrumb: [{ title: 'Logout', path: '', isLast: true }]
  },
  {
    path: '/permissiondenided',
    element: <PermissionDenied />,
    icon: <AiOutlineInbox />,
    title: "PermissionDenied",
    breadcrumb: [{ title: 'PermissionDenied', path: '', isLast: true }]
  },
  {
    path: '/new-carton',
    element: <NewCarton />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/new-carton-edit',
    element: <NewCartonRequest />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '/new-carton-list', isLast: false }, { title: 'Edit', path: '', isLast: true }]
  },
  {
    path: '/new-carton-tracking',
    element: <NewCarton2 />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/new-carton-save',
    element: <NewCarton3 />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/new-carton-add',
    element: <NewCartonRequest />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '', isLast: false }, { title: 'List', path: '/new-carton-list', isLast: false }, { title: 'Add', path: '', isLast: true }]
  },
  {
    path: '/storage-carton',
    element: <StorageCarton />,
    icon: <AiOutlineInbox />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Storage Carton', path: '', isLast: true }]
  },
  {
    path: '/storage-carton-tracking',
    element: <StorageCarton2 />,
    icon: <AiOutlineInbox />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Storage Carton', path: '', isLast: true }]
  },
  {
    path: '/storage-carton-save',
    element: <StorageCarton3 />,
    icon: <AiOutlineInbox />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Storage Carton', path: '', isLast: true }]
  },
  {
    path: '/storage-carton-add',
    element: <StorageCartonRequest />,
    icon: <AiOutlineInbox />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Storage Carton', path: '', isLast: false }, { title: 'List', path: '/storage-carton-list', isLast: false }, { title: 'Add', path: '', isLast: true }]
  },
  {
    path: '/storage-carton-edit',
    element: <StorageCartonRequest />,
    icon: <AiOutlineInbox />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Storage Carton', path: '/storage-carton-list', isLast: false }, { title: 'Edit', path: '', isLast: true }]
  },
  {
    path: '/collect-carton',
    element: <CollectCarton />,
    icon: <AiOutlineRotateRight />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'Collect Carton', path: '', isLast: true }]
  },
  {
    path: '/collect-carton-tracking',
    element: <CollectCarton2 />,
    icon: <AiOutlineRotateRight />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'Collect Carton', path: '', isLast: true }]
  },
  {
    path: '/collect-carton-save',
    element: <CollectCarton3 />,
    icon: <AiOutlineRotateRight />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'Collect Carton', path: '', isLast: true }]
  },
  {
    path: '/collect-carton-add',
    element: <CollectCartonRequest onBehalf={0}/>,
    icon: <AiOutlineRotateRight />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'Collect Carton', path: '', isLast: false }, { title: 'List', path: '/collect-carton-list', isLast: false }, { title: 'Add', path: '', isLast: true }]
  },
  
  {
    path: '/return-carton',
    element: <ReturnCarton />,
    icon: <AiOutlineRotateLeft />,
    title: "Return Carton",
    breadcrumb: [{ title: 'Return Carton', path: '', isLast: true }]
  },
  {
    path: '/return-carton-tracking',
    element: <ReturnCarton2 />,
    icon: <AiOutlineRotateLeft />,
    title: "Return Carton",
    breadcrumb: [{ title: 'Return Carton', path: '', isLast: true }]
  },
  {
    path: '/return-carton-save',
    element: <ReturnCarton3 />,
    icon: <AiOutlineRotateLeft />,
    title: "Return Carton",
    breadcrumb: [{ title: 'Return Carton', path: '', isLast: true }]
  },
  {
    path: '/return-carton-add',
    element: <ReturnCartonRequest onBehalf={0}/>,
    icon: <AiOutlineRotateLeft />,
    title: "Return Carton",
    breadcrumb: [{ title: 'Return Carton', path: '', isLast: false }, { title: 'List', path: '/return-carton-list', isLast: false }, { title: 'Add', path: '', isLast: true }]
  },
  {
    path: '/remove-carton',
    element: <RemoveCarton />,
    icon: <AiOutlineCloseSquare />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/remove-carton-tracking',
    element: <RemoveCarton2 />,
    icon: <AiOutlineCloseSquare />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/remove-carton-save',
    element: <RemoveCarton3 />,
    icon: <AiOutlineCloseSquare />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/remove-carton-add',
    element: <RemoveCartonRequest />,
    icon: <AiOutlineCloseSquare />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Return Carton', path: '', isLast: false }, { title: 'List', path: '/remove-carton-list', isLast: false }, { title: 'Add', path: '', isLast: true }]
  },
  {
    path: '/destruction-carton',
    element: <DestructionCarton />,
    icon: <AiOutlineMinusCircle />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Destruction Carton', path: '', isLast: true }]
  },
  {
    path: '/destruction-carton-tracking',
    element: <DestructionCarton2 />,
    icon: <AiOutlineMinusCircle />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Destruction Carton', path: '', isLast: true }]
  },
  {
    path: '/destruction-carton-save',
    element: <DestructionCarton3 />,
    icon: <AiOutlineMinusCircle />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Destruction Carton', path: '', isLast: true }]
  },
  {
    path: '/destruction-carton-add',
    element: <DestructionCartonRequest onBehalf={0}/>,
    icon: <AiOutlineMinusCircle />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Destruction Carton', path: '', isLast: false }, { title: 'List', path: '/destruction-carton-list', isLast: false }, { title: 'Add', path: '', isLast: true }]
  },
  {
    path: '/destruction-duedate-view',
    element: <DestructCartonDueDateView/>,
    icon: <AiOutlineMinusCircle />,
    title: "Destruction Carton Duedate",
    breadcrumb: [{ title: 'Destruction Carton', path: '', isLast: false }, { title: 'DueDate', path: '', isLast: true }]
  },
  {
    path: '/onbehalf',
    element: <OnBehalf />,
    icon: <AiOutlineMinusCircle />,
    title: "On Behalf",
    breadcrumb: [{ title: 'On Behalf', path: '', isLast: true }]
  },
  {
    path: '/onbehalf-2',
    element: <OnBehalf2 />,
    icon: <AiOutlineMinusCircle />,
    title: "On Behalf",
    breadcrumb: [{ title: 'On Behalf', path: '', isLast: true }]
  },
  {
    path: '/onbehalf-3',
    element: <OnBehalf3 />,
    icon: <AiOutlineMinusCircle />,
    title: "On Behalf",
    breadcrumb: [{ title: 'On Behalf', path: '', isLast: true }]
  },
  {
    path: '/status-tracking',
    element: <StatusTracking />,
    icon: <AiOutlineFileDone />,
    title: "Status Tracking",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false }, { title: 'Status Tracking', path: '', isLast: true }]
  },
  {
    path: '/my-document',
    element: <MyDocument />,
    icon: <AiOutlineFileDone />,
    title: "My Org. Unit Document",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false }, { title: 'My Org. Unit Document', path: '', isLast: true }]
  },
  {
    path: '/my-document2',
    element: <MyDocument2 />,
    icon: <AiOutlineFileDone />,
    title: "My Org. Unit Document",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false }, { title: 'My Org. Unit Document', path: '', isLast: true }]
  },
  {
    path: '/mytask-savedraft-new-carton',
    element: <NewCartonRequest />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'Status Tracking', path: '', isLast: false }, { title: 'List', path: '/statustracking-list', isLast: false }, { title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/approve-collectcarton-list',
    element: <CollectCartonApproveList />,
    icon: <AiOutlineFileDone />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false }, { title: 'Approve', path: '', isLast: false }, { title: 'Collect Carton', path: '', isLast: true }]
  },
  {
    path: '/approve-destructioncarton-list',
    element: <DestructionCartonApproveList />,
    icon: <AiOutlineFileDone />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false }, { title: 'Approve', path: '', isLast: false }, { title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/query-data',
    element: <QueryData />,
    icon: <AiOutlineMinusCircle />,
    title: "Query data",
    breadcrumb: [{ title: 'Query data', path: '', isLast: true }]
  },
  {
    path: '/officer-destruction-carton',
    element: <OfficerDestructionCarton />,
    icon: <AiOutlineMinusCircle />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Destruction Carton', path: '', isLast: true }]
  },
  {
    path: '/manage-external-warehouse',
    element: <ManageExternalWarehouse />,
    icon: <AiOutlineFileDone />,
    title: "Manage External Warehouse",
    breadcrumb: [{ title: 'Master Data', path: '/manage-external-warehouse', isLast: false }, { title: 'Manage External Warehouse', path: '', isLast: true }]
  },
  {
    path: '/manage-change-owner',
    element: <ManageChangeOwner />,
    icon: <AiOutlineFileDone />,
    title: "Manage Change Owner",
    breadcrumb: [{ title: 'Master Data', path: '/manage-change-owner', isLast: false }, { title: 'Manage Change Owner', path: '', isLast: true }]
  },
  {
    path: '/manage-change-owner-2',
    element: <ManageChangeOwner2 />,
    icon: <AiOutlineFileDone />,
    title: "Manage Change Owner",
    breadcrumb: [{ title: 'Master Data', path: '/manage-change-owner', isLast: false }, { title: 'Manage Change Owner', path: '', isLast: true }]
  },
  {
    path: '/manage-org-unit',
    element: <ManageOrgUnit />,
    icon: <AiOutlineFileDone />,
    title: "Manage Org. Unit",
    breadcrumb: [{ title: 'Master Data', path: '/manage-org-unit', isLast: false }, { title: 'Manage Org. Unit', path: '', isLast: true }]
  },
  {
    path: '/manage-project-name',
    element: <ManageProjectName />,
    icon: <AiOutlineFileDone />,
    title: "Manage Project Name",
    breadcrumb: [{ title: 'Master Data', path: '', isLast: false }, { title: 'Manage Project Name', path: '', isLast: true }]
  },
  {
    path: '/approval',
    element: <Approval />,
    icon: <AiOutlineMinusCircle />,
    title: "Waiting for Approval",
    breadcrumb: [{ title: 'Approval', path: '', isLast: false }, { title: 'Waiting for Approval', path: '', isLast: true }]
  },

  {
    path: '/approve-tracking',
    element: <ApprovalTracking />,
    icon: <AiOutlineMinusCircle />,
    title: "My Approval Tracking",
    breadcrumb: [{ title: 'Approval', path: '', isLast: false }, { title: 'My Approval Tracking', path: '', isLast: true }]
  },
  {
    path: '/manage-user',
    element: <ManageUser />,
    icon: <AiOutlineFileDone />,
    title: "Manage User",
    breadcrumb: [{ title: 'System Management', path: '', isLast: false }, { title: 'Manage User', path: '', isLast: true }]
  },
  {
    path: '/system-config',
    element: <SystemConfig />,
    icon: <AiOutlineFileDone />,
    title: "System Config",
    breadcrumb: [{ title: 'System Management', path: '', isLast: false }, { title: 'System Config', path: '', isLast: true }]
  },
  {
    path: '/mail-message',
    element: <MailMessage />,
    icon: <AiOutlineFileDone />,
    title: "Mail Message",
    breadcrumb: [{ title: 'System Management', path: '', isLast: false }, { title: 'Mail Message', path: '', isLast: true }]
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />,
    icon: <AiOutlineFileDone />,
    title: "Terms and Conditions",
    breadcrumb: [{ title: 'System Management', path: '', isLast: false }, { title: 'Terms and Conditions', path: '', isLast: true }]
  },
  
  {
    path: '/request-change-owner',
    element: <RequestChangeOwner />,
    icon: <AiOutlineInbox />,
    title: "Request to Change Owner",
    breadcrumb: [{ title: 'Request to Change Owner', path: '', isLast: true }]
  },
  {
    path: '/request-change-owner-2',
    element: <RequestChangeOwner2 />,
    icon: <AiOutlineInbox />,
    title: "Request to Change Owner",
    breadcrumb: [{ title: 'Request to Change Owner', path: '', isLast: true }]
  },
  {
    path: '/reminder-email',
    element: <ReminderEmail />,
    icon: <AiOutlineInbox />,
    title: "Reminder Email",
    breadcrumb: [{ title: 'Reminder Email', path: '', isLast: true }]
  },
  {
    path: '/statustracking-new-carton',
    element: <NewCartonRequest />,
    icon: <AiOutlineFileDone />,
    title: "New Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false },{ title: 'Status Tracking', path: '/statustracking-list', isLast: false }, { title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/statustracking-storage-carton',
    element: <StorageCarton />,
    icon: <AiOutlineFileDone />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false },{ title: 'Status Tracking', path: '/statustracking-list', isLast: false }, { title: 'Storage Carton', path: '', isLast: true }]
  },
  {
    path: '/statustracking-collect-carton',
    element: <CollectCartonRequest onBehalf={0}/>,
    icon: <AiOutlineFileDone />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false },{ title: 'Status Tracking', path: '/statustracking-list', isLast: false }, { title: 'Collect Carton', path: '', isLast: true }]
  },
  {
    path: '/statustracking-return-carton',
    element: <ReturnCarton />,
    icon: <AiOutlineFileDone />,
    title: "Return Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false },{ title: 'Status Tracking', path: '/statustracking-list', isLast: false }, { title: 'Return Carton', path: '', isLast: true }]
  },
  {
    path: '/statustracking-remove-carton',
    element: <RemoveCartonRequest />,
    icon: <AiOutlineFileDone />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false },{ title: 'Status Tracking', path: '/statustracking-list', isLast: false }, { title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/statustracking-destruction-carton',
    element: <DestructionCartonRequest onBehalf={0}/>,
    icon: <AiOutlineFileDone />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'My Task', path: '', isLast: false },{ title: 'Status Tracking', path: '/statustracking-list', isLast: false }, { title: 'Destruction Carton', path: '', isLast: true }]
  },
  {
    path: '/report-all-transaction',
    element: <ReportAllTransaction />,
    icon: <AiOutlineFileDone />,
    title: "All Transaction",
    breadcrumb: [{ title: 'Report', path: '', isLast: false }, { title: 'All Transaction', path: '', isLast: true }]
  },
  {
    path: '/report-total-carton-list',
    element: <ReportTotalCartonList />,
    icon: <AiOutlineFileDone />,
    title: "Total Carton List",
    breadcrumb: [{ title: 'Report', path: '', isLast: false }, { title: 'Total Carton List', path: '', isLast: true }]
  },
  {
    path: '/report-destruction-carton-list',
    element: <ReportDestructionCartonList />,
    icon: <AiOutlineFileDone />,
    title: "Destruction Carton List",
    breadcrumb: [{ title: 'Report', path: '', isLast: false }, { title: 'Destruction Carton List', path: '', isLast: true }]
  },
  {
    path: '/report-carton-quantity',
    element: <ReportCartonQuantity />,
    icon: <AiOutlineFileDone />,
    title: "Carton Quantity by Request Type",
    breadcrumb: [{ title: 'Report', path: '', isLast: false }, { title: 'Carton Quantity by Request Type', path: '', isLast: true }]
  },
  {
    path: '/report-destruction-carton-quantity',
    element: <ReportDestructionCartonQuantity />,
    icon: <AiOutlineFileDone />,
    title: "Destruction Carton Quantity",
    breadcrumb: [{ title: 'Report', path: '', isLast: false }, { title: 'Destruction Carton Quantity', path: '', isLast: true }]
  },
  {
    path: '/new-carton-view',
    element: <NewCartonView />,
    icon: <AiOutlineInbox />,
    title: "New Carton",
    breadcrumb: [{ title: 'New Carton', path: '/new-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/storage-carton-view',
    element: <StorageCartonView />,
    icon: <AiOutlineInbox />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Storage Carton', path: '/storage-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/collect-carton-view',
    element: <CollectCartonView />,
    icon: <AiOutlineInbox />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'Collect Carton', path: '/collect-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/return-carton-view',
    element: <ReturnCartonView />,
    icon: <AiOutlineInbox />,
    title: "Return Carton",
    breadcrumb: [{ title: 'Return Carton', path: '/return-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/remove-carton-view',
    element: <RemoveCartonView />,
    icon: <AiOutlineInbox />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Remove Carton', path: '/remove-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/destruction-carton-view',
    element: <DestructionCartonView />,
    icon: <AiOutlineInbox />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Destruction Carton', path: '/destruction-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/extend-carton-view',
    element: <ExtendCartonView />,
    icon: <AiOutlineInbox />,
    title: "Extend Carton",
    breadcrumb: [{ title: 'Extend Carton', path: '/extend-carton-view', isLast: false }, { title: 'View', path: '', isLast: true }]
  },
  {
    path: '/querydata-new-carton',
    element: <QueryDataNewCarton id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "New Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'New Carton', path: '', isLast: true }]
  },
  {
    path: '/querydata-storage-carton',
    element: <QueryDataStorageCarton id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "Storage Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'Storage Carton', path: '', isLast: true }]
  },
  {
    path: '/querydata-collect-carton',
    element: <QueryDataCollectCarton id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "Collect Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'Collect Carton', path: '', isLast: true }]
  },
  {
    path: '/querydata-return-carton',
    element: <QueryDataReturnCarton id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "Return Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'Return Carton', path: '', isLast: true }]
  },
  {
    path: '/querydata-remove-carton',
    element: <QueryDataRemoveCarton id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/querydata-remove-carton-storage',
    element: <QueryDataRemoveCartonStorage id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "Remove Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'Remove Carton', path: '', isLast: true }]
  },
  {
    path: '/querydata-destruction-carton',
    element: <QueryDataDestructionCarton id="" onBack={null}/>,
    icon: <AiOutlineFileDone />,
    title: "Destruction Carton",
    breadcrumb: [{ title: 'Query data', path: '/query-data', isLast: false }, { title: 'Destruction Carton', path: '', isLast: true }]
  }, 
  {
    path: '/manual-update-900',
    element: <ManualUpdate3 />,
    icon: <AiOutlineFileDone />,
    title: "Manual Update",
    breadcrumb: [{ title: 'Manual Update', path: '/manual-update-900', isLast: false }, { title: 'manual-update-3', path: '', isLast: true }]
  },
];

export default AppRoutes;
