import { useEffect,useState } from 'react';
import { Card,Row,Col,Select } from 'antd';
import { Label } from 'reactstrap';
import MessageCommon from '../../enum/Message';
import CollectCartonRequest from '../CollectCarton/CollectCartonRequest';
import ReturnCartonRequest from '../ReturnCarton/ReturnCartonRequest';
import DestructionCartonRequest from '../DestructionCarton/DestructionCartonRequest';
import { User} from '../../models';
import  secureLocalStorage  from  "react-secure-storage";
import { useNavigate } from 'react-router-dom';

const OnBehalfRequest = () => {
    const [requestType,setRequestType] = useState(0)
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();

    useEffect(() => {
        if(user.permissions.onBehalf === 0){
            navigate('/permissiondenided')
        }

    }, [])

    const onRequestTypeChange = (value:number) => {
        setRequestType(value)
    }
 
    return (
    <Card >
        
            <Row>
                <Col md={2} ><Label>Request Type</Label></Col>
                <Col md={1} ></Col>
                <Col md={8} >
                   
                        <Select placeholder={MessageCommon.TitleSelect} onChange={onRequestTypeChange} className='w100c'
                            options={[
                                { value: 1, label: 'Collect Carton' },
                                { value: 2, label: 'Return Carton' },
                                { value: 3, label: 'Destruction Carton' }
                            ]}
                        />
                   
                </Col>
            </Row>
            <div className='margin-top-20'>
                {requestType === 1 && <CollectCartonRequest onBehalf={1}/>}
                {requestType === 2 && <ReturnCartonRequest onBehalf={1}/>}
                {requestType === 3 && <DestructionCartonRequest onBehalf={1}/>}
            </div>
        
  </Card>
  );

}

export default OnBehalfRequest
