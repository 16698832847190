import axios from 'axios'
import { config } from '../config'
import { DataObjectResponse,PageDataResponse } from '../models/PageResponse'
import { Project } from '../models/MasterDataDto'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class MasterDataService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
    }
    async getManageProjectName(page:number,limit:number,projectName:string,locationId:number,active:number){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
            params: {
                page: page,
                limit: limit,
                projectName: encodeURI(projectName),
                locationId: locationId,
                active:active
            }
        }

        
        const { data }  = await axios.get<PageDataResponse<Project>>(`${apiUrl}/v1/master-data/getProjects`, options )
        
        return data;
    }

    async updateManageProjectName(project: Project) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }

        const { data }  = await axios.post<DataObjectResponse<Project>>(`${apiUrl}/v1/master-data/updateProjects`, JSON.stringify(project),options )
        
        return data;
    }

    async createManageProjectName(body: any) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }

        const { data }  = await axios.post<DataObjectResponse<Project>>(`${apiUrl}/v1/master-data/createProjects`, body,options )
        
        return data;
    }


}