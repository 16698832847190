import { useEffect, useState } from 'react';
import { Card, Tabs,Modal } from 'antd';
import type { TabsProps } from 'antd';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import StorageCartonRequest from './StorageCartonRequest'
import StorageCartonStatusTracking from './StorageCartonStatusTracking';
import StorageCartonSaveDraft from './StorageCartonSaveDraft';
import { TermsConditionService } from '../../services';
import { RequestType } from '../../enum'
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const StorageCarton = () => {
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [termActive,setTermActive] = useState(0)
  const [termText,setTermText] = useState("")
  const [modal, contextHolder] = Modal.useModal();
  const [activeKey, setActiveKey] = useState("1");

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    const getData = async () => {
        let token = await getToken()
        let result = await new TermsConditionService(token).getTermsConditionById(RequestType.StorageCarton)
        console.log(result)
        if(result && result.success)
        {
            if(result.data.active === 1){
                setTermActive(1)
                setTermText(result.data.information)
                setTimeout(() => {
                    dragElement()
                }, 500);
       
            }
        }
    }

    useEffect(() => {
        getData()

    }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Storage`,
      children: <StorageCartonRequest/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <StorageCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <StorageCartonSaveDraft/>,
    },

  ];

  const saveDraft = (page:string) => {
    secureLocalStorage.setItem('saveStorage',page)
  
    let saveDraftBtn1 = document.getElementById('storage-save-draft1') as HTMLButtonElement
    if(saveDraftBtn1){
      saveDraftBtn1.click()
    }
    else{
      let saveDraftBtn2 = document.getElementById('storage-save-draft2') as HTMLButtonElement
      if(saveDraftBtn2){
        saveDraftBtn2.click()
      }
    }
  }

  const changePage = (page:string) => {
    secureLocalStorage.setItem("saveStorage","")
    removeStorageLink()
    navigate('/' + page)
  }

  
  const removeStorageLink = () => {
    let elements = document.getElementsByClassName("ant-menu-item") as HTMLCollection
   
     setTimeout(() => {
         let length = elements.length
         for(let i =0; i< length ;i++){
           let id = elements[i].getAttribute("data-menu-id")
      
           if(id && id.indexOf("-storage-carton") > -1 && elements[i].classList.contains('ant-menu-item-selected')){
             elements[i].classList.remove('ant-menu-item-selected')
           }
         }
     }, 1000);
 }

  const closePosit = () => {
    let element = document.getElementById('sticky-container') as HTMLDivElement
    element.style.display = 'none'
  }

  function dragElement() {
    let elmnt = document.getElementById("sticky-container") as HTMLDivElement
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      elmnt.onmousedown = dragMouseDown;
    
  
    function dragMouseDown(e:any) {
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e:any) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }
  
    function closeDragElement() {
     
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/storage-carton')
      setActiveKey(tab)
    }
    else{
       let tabstr = tab === '2' ? 'storage-carton-tracking' : 'storage-carton-save'
        let saveStorage = secureLocalStorage.getItem('saveStorage')
        console.log(saveStorage)
        if(saveStorage){
          setActiveKey("1")
          modal.confirm({
            title: 'Confirm to save draft before leaving this page?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Save Draft',
            cancelText: 'Cancel',
            width: 430,
            onCancel: () => changePage(tabstr),
            onOk: () => saveDraft(tabstr)
          });
     
      }
      else{
        secureLocalStorage.setItem("saveStorage","")
        navigate('/' + tabstr)
        setActiveKey(tab)
      }
        
    }
  }


  return (

    <div>
      <Card >
        <Tabs activeKey={activeKey} items={items} onTabClick={onTabChange} destroyInactiveTabPane/>
      </Card>

      { termActive ?
      <div id="sticky-container" className="sticky-container" >
        <div className="sticky-outer">
          <div className="sticky">
            <svg width="0" height="0">
              <defs>
                <clipPath id="stickyClip" clipPathUnits="objectBoundingBox">
                  <path d="M 0 0 Q 0 0.69, 0.03 0.96 0.03 0.96, 1 0.96 Q 0.96 0.69, 0.96 0 0.96 0, 0 0" strokeLinejoin="round" strokeLinecap="square"/>
                </clipPath>
              </defs>
            </svg>
            <div className="sticky-content" >
                <div>
                <AiOutlineCloseCircle size={24} className='pointer float-right margin-right-10' onClick={closePosit}/>
                <br/>
                  {termText}
              </div>
            </div>
          </div>
        </div>
      </div>:<></>
    }
     {contextHolder}
    </div>
  );

}

export default StorageCarton
