import axios from 'axios'
import { config } from '../config'
import { DataObjectResponse } from '../models/PageResponse'
import MailMessageDto from '../models/MailMessageDto'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class MailMessageService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
    }
    async getMailMessage(requestType:number,versionType:number){

        if (!this.token) return null
        
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
            params:{requestType:requestType,versionType:versionType}
        }
        
        const { data }  = await axios.get<DataObjectResponse<MailMessageDto[]>>(`${apiUrl}/v1/mail/message/messages`, options )
        
        return data;
    }
    async updateMailMessage(req: MailMessageDto) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<MailMessageDto[]>>(`${apiUrl}/v1/mail/message/update`, req, options )
        
        return data;
    }

}