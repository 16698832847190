import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Spin, Modal, Row, Select, Pagination } from 'antd';
import {  AiOutlineSearch, AiOutlineSync ,AiOutlineRotateLeft ,AiFillInteraction,AiFillEye ,AiOutlineRight ,AiOutlineDown} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import ReturnCartonModal from './ReturnCartonModal';
import ExtendCartonModal from './ExtendCartonModal';
import { Parameter,CartonList,Item, User} from '../../models'
import { Helper, InitHelper ,StickyHelper} from '../../helpers';
import { CollectCartonService,PropertyService,HrWebService} from '../../services';
import dayjs from 'dayjs'
import { CartonStatus } from '../../enum'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import CartonDetail from '../Share/CartonDetail';


interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  itemNoList:string[]
  isLoading:boolean
}

interface Props{
  onBehalf:number
}

const ReturnCartonRequest = (props: Props) => {
  let topPos = props.onBehalf ? 150 : 50
    // let spaceTop = props.onBehalf ? 128 : 128
    // let spaceTop2 = props.onBehalf ? 443 : 417
    // let resultTop = props.onBehalf ? 159 : 159
    // let resultTop2 = props.onBehalf ? 475 : 450
    // let dataTop = props.onBehalf ? 231 : 219
    // let dataTop2 = props.onBehalf ? 549 : 525

  const stickyHelper = new StickyHelper("return",topPos,150)
  const stickyElement = stickyHelper.getElement()

  const returnIfElse = (flag: any, obj1: any, obj2: any) => {
    return flag ? obj1 : obj2;
}

    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
  const [returnType,setReturnType] = useState(true)
  const [requesters, setRequesters] = useState<Item[]>([])
  const [extendStatus,setExtendStatus] = useState(true)
  const [searchStatus,setSearchStatus] = useState(false)
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
      perpage: returnIfElse(props.onBehalf === 1, 100 ,10),
    items: [],
    itemNoList:[],
    isLoading : !props.onBehalf 
  })
  const [requestNo,setRequestNo] = useState("")
  const [box,setBox] = useState("")
  const [clientHeight,setClientHeight] = useState(0)

  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

  useEffect(() => {
    if(!props.onBehalf){
      
      initial()
  }

  }, [])

  const initial = () => {
     setSearchStatus(true)
      setSelectItems([])
      let parameter = InitHelper.InitialParameter()
      parameter.requesters = []
      parameter.owners = []
      parameter.userConfidential = user.employeeId
      parameter.requesterOrgUnit = user.orgUnit
      parameter.employeeLevel = user.level
    
      getData(1,appState.perpage,parameter)

      stickyHelper.removeEvent()
    stickyHelper.addEvent()
    window.addEventListener("resize", () => { 
      onSetClientHeight()
  });
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const showModal = (_returnType:boolean) => {
    setReturnType(_returnType)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

 
  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    let _extendStatus = true
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
        if(item && (item.extendAmt == item.extendableTimes || dayjs(item.returnDate).format("YYYYMMDD") == dayjs(item.extendablePeriod).format("YYYYMMDD")))
        {
            _extendStatus = false
        }
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = false
      })
    }
    setExtendStatus(_extendStatus)
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = e.target.value
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    let _extendStatus = true

    if(checked){
        items.push(value)
        let cartonItems = appState.items.filter(x => items.indexOf(x.cartonNo) > -1)
        cartonItems.forEach(item => {
            if(item && (item.extendAmt == item.extendableTimes || dayjs(item.returnDate).format("YYYYMMDD") == dayjs(item.extendablePeriod).format("YYYYMMDD")))
            {
                _extendStatus = false
            }
        })
        
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length === 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

    console.log(items)
    setExtendStatus(_extendStatus)
    setSelectItems(items)
   
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    let parameter = InitHelper.InitialParameter();
    
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
    }
    else
    {
        parameter.requesters =  []
        parameter.owners = []
        parameter.userConfidential = user.employeeId
        parameter.requesterOrgUnit = user.orgUnit
        parameter.employeeLevel = user.level
        
    }
    
    if(field.requestNo){
        parameter.requestNoList = field.requestNo.trim().split(',')
    }

    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(requesters.length > 0){
      let requestConfidential = requesters.filter(x => x.key == user.employeeId).length
      if(requestConfidential === 0){
        parameter.userConfidential = ""
      }
       parameter.requesters = requesters.map(item => item.key)
    }

    if(orgunits.length > 0){
        parameter.orgUnits = orgunits.map(item => item.key)
     }

    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    if(field.cartonNoList){
        parameter.cartonNo = field.cartonNoList.trim()
    }

    if(field.cartonNo){
        parameter.cartonNo = field.cartonNo.trim()
    }

    console.log(parameter)
    setSearchStatus(true)
    getData(1,appState.perpage,parameter)
    
  }
  const reset = () => {
    if(!searchStatus){
        return
    }
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""

    dataForm.resetFields()
    setRequesters([])
    setOrgUnits([])
    setRequestStartDate(null)
    setRequestEndDate(null)

    let parameter = InitHelper.InitialParameter()
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
    }
    else{
       parameter.requesters = []
      parameter.owners = []
      parameter.userConfidential = user.employeeId
      parameter.requesterOrgUnit = user.orgUnit
      parameter.employeeLevel = user.level
    }
   
    getData(1,appState.perpage,parameter)
  }

 

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)

    let parameter = Object.assign({},saveParameter)
    if(_parameter !== null){
        parameter = _parameter;
    }
    
    parameter.page = currentPage
    parameter.limit = perpage
    parameter.statusIds = [CartonStatus.Done]
    parameter.cartonStatusIds = [CartonStatus.Collect]
    
      let token = await getToken()
      let result = await new CollectCartonService(token).SearchReturnRequest(parameter)
      console.log(result)
      if(result && result.success)
      {
        let {page,limit,total,items,itemNoList} = result.data
      
        let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.perpage = limit
          state.items = items
          state.itemNoList = itemNoList
          state.isLoading = false

        setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
            setTimeout(() => {
              onSetClientHeight()
            }, 100);
        }, 500);
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
      

  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {

    if(perpage === appState.perpage){
        getData(page,appState.perpage)
    }
    else{
      getData(1,perpage)
    }

  }

  const openDetail = (_requestNo:string, _box:string) => {
    console.log([_requestNo,_box])
  setRequestNo(_requestNo)
  setBox(_box)
  setIsModalCartonOpen(true);
}


const handleCancelCarton = () => {
  setIsModalCartonOpen(false);

};

const onRequesterSelect = (item:any) => {
  setRequesters(item)
  stickyHelper.updatePosition()
}

const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
    stickyHelper.updatePosition()
}

const onSearchEmployee = async(key:string) => {

    let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee(key)
  if(result && result.success)
  {
    if(props.onBehalf === 1)
    {
        return result.data
    }
    else{
        let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
    }
  }
  else{
    console.log(result?.message)
    return []
  }
}

const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}


const onRequestStartDateChange = (date: any) => {
  setRequestStartDate(date);
}

const onRequestEndDateChange = (date: any) => {
  setRequestEndDate(date)
}
const renderPagination =() =>{
  if(appState.total > 0){
    if(props.onBehalf===1){
      return( <Pagination 
        key={Helper.Uniqid()}
        current={appState.currentPage} 
        pageSize={appState.perpage}
        total={appState.total} 
        onChange={onPageChange} 
        showSizeChanger={false}
      />)
    }
    return (<Pagination 
    key={Helper.Uniqid()}
    current={appState.currentPage} 
    pageSize={appState.perpage}
    total={appState.total} 
    onChange={onPageChange} 
    pageSizeOptions={pageSizeOptions}
    showSizeChanger={true}
  />)
  }
 return (<></>)

}

const reload = () => {
    initial()
}

  return (

    <div>
        <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>
      
        <div id={stickyElement.openSearch} className='carton-search-bottom'>
       
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
                <Col md={11} >
                    <Form.Item name="range" label="Carton No:(Range)" >
                        <table className='w100c'>
                          <tbody>
                            <tr>
                                <td>
                                    <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 23PSC001'/>
                                </td>
                                <td>
                                    <Label className='margin-left-10 margin-right-5'>To</Label>
                                </td>
                                <td>
                                    <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 23PSC001'/>
                        
                                </td>
                                </tr>
                              </tbody>
                        </table>
                    </Form.Item>
                    <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 23PSC001,03PCS002,03PCS003'/>
                    </Form.Item>
                    <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 23PSC001'/>
                    </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="requestNo" label="Collect Requested No." >
                        <Input placeholder='ex. CL230004,CL230005' />
                    </Form.Item>
                    <Form.Item name="collectRequestedDate" label="Collect Requested Date">
                        <table className='w100c'>
                          <tbody>
                            <tr>
                                <td>
                                    <DatePicker placeholder={MessageCommon.TitleDate} format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestStartDateChange}/>
                                </td>
                                <td>
                                    <Label className='margin-left-10 margin-right-10'>To</Label>
                                </td>
                                <td>
                                    <DatePicker placeholder={MessageCommon.TitleDate} format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestEndDateChange}/>
                                </td>
                            </tr>
                          </tbody>
                        </table>
                    </Form.Item>
                    <Form.Item name="requester" label="Collect by" >
                        <EmployeeAutoComplete
                            placeholder='Search by Name'
                                      selectedItems={returnIfElse(requesters, requesters, [] ) }
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
                { props.onBehalf ?
                    <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                                          selectedItems={returnIfElse(orgunits, orgunits, [] ) }
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>:<></>
                }
                    <Form.Item name="cartonStatus" label="Carton Status"  >
                        <Select placeholder={MessageCommon.TitleSelect} defaultValue={'1'} disabled
                            options={[{ value: '1', label: 'Collect' }]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
              <Col md={11} >
               
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
            
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                
              </Col>
            </Row>
    

          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>
    
    <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
    <div className='float-left'>
          <Button type="primary" className='btn-green mr-btn' onClick={() => showModal(true)} disabled={selectItems.length === 0 }   >
            <AiOutlineRotateLeft className='icn-btn' /> Return
          </Button>
          <Button type="primary" className='btn-blue mr-btn' onClick={() => showModal(false)} disabled={selectItems.length === 0 || !extendStatus}  >
              <AiFillInteraction className='icn-btn' /> Extend
          </Button>
                              
                           
        
        </div>
                      {returnIfElse(appState.total > 0 && props.onBehalf === 1
                          , 
                          <div className='float-right'>
                              <SelectPagination onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                              <span className='margin-left-10'>/ Page</span>
                          </div>
                          , 
                          <></>
                      )
                      }
                      <br/><br/>
    </div>
    
    { searchStatus ?
    <>
      <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
        <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
              <tr>
                <th className="thfix1 text-center w50">
                                              {returnIfElse(appState.items.length > 0
                                                  ,
                                                  <div className="form-check">
                                                      <input type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)} />
                                                      <label className="form-check-label" htmlFor={'check-item-all'} />
                                                  </div>
                                                  , 
                                                  <></>
                                              ) 
                }
                </th>
                
                <th className="s-th thfix2">Carton No.</th>
                <th className="s-th">Collect Requested No.</th>
                <th className="s-th">Collect By</th>
                <th className="s-th">Received By</th>
                <th className="s-th">Expected Return Date</th>
                <th className="s-th">Extend Status</th>
                <th className="s-th">Extend Reason</th>
                <th className="s-th">Carton Status</th>
                <th className="w100">Detail</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:CartonList,index:number) => (
                <tr>
                  <td className="tdfix1 text-center w50">
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.cartonNo} value={item.cartonNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo}/>
                        </div>
                  </td>
                 
                  <td className="s-td tdfix2 text-center">
                  {item.cartonNo}
                  </td>
                  <td className="s-td text-center">
                   {item.collectRequestNo}
                  </td>
                  <td className="s-td text-center">{item.requesterName}</td>
                  <td className="s-td text-center">{item.receivedByName}</td>
                  <td className="s-td text-center">{returnIfElse(item.returnDate, dayjs(item.returnDate).format("DD MMM YYYY")  , "" ) }</td>
                  <td className="s-td text-center">{returnIfElse(item.extendAmt == item.extendableTimes || dayjs(item.returnDate).format("YYYYMMDD") == dayjs(item.extendablePeriod).format("YYYYMMDD"), 'Return only', 'Extendable' ) }</td>
                  <td className="s-td text-center break-word">{item.extendReason ? item.extendReason : "-"}</td>
                  <td className="s-td text-center">{item.cartonStatus}</td>
                  <td className="w100 text-center">
                    <a onClick={() => openDetail(item.storageRequestNo,item.cartonNo)}>
                       <AiFillEye className='icon-table'></AiFillEye>
                    </a>
                  </td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div className='margin-top-10 text-right'>
            { renderPagination()}
        </div>
      </div>
      
    </>
    :<></>
    }

<input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>


      <Modal title="Request Detail" open={isModalOpen} footer={''}  width={1550} onCancel={handleCancel} maskClosable={false}>
              {returnIfElse(returnType
                  ,
                  <ReturnCartonModal key={Helper.Uniqid()} returnId={0} items={selectItems} page={returnIfElse(props.onBehalf, 'onBehalf', "return")} onBehalf={props.onBehalf} onReload={reload}/>
                  ,
                  <ExtendCartonModal key={Helper.Uniqid()} returnId={0} items={selectItems} page="return" onReload={reload}/>
              ) 
        }

      </Modal>
       
        <Modal key={Helper.Uniqid()} title="" open={isModalCartonOpen} footer={''} width={1200} onCancel={handleCancelCarton}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
      </Modal>
     
    </div>
  );

}

export default ReturnCartonRequest
