import { Element } from '../models'

type stickyObj = {
    [key: string]: number 
}

type stickyObj2 = {
    [key: string]: number | string
}

export default class StickyHelper {
    protected element:Element = {
        container:"",
        search: "",
        space: "",
        space2: "",
        space3: "",
        clientHight:"",
        result: "",
        dataFixed: "",
        data: "",
        openSearch: "",
        openAdd:""
    }
    protected elementTop:stickyObj = {
        space: 70,
        search: 80,
        search2: 128,
    }
    protected elementInc:stickyObj2 = {
        search: 4,
        space: 20,
        space2: 20,
        result: 0,
        data: 0
    }
    protected elementStatus:stickyObj = {
        openSearch: 1,
        openAdd: 0,
        warehouseAdd : 0
    }
    protected thElement = "s-th"
    protected tdElement = "s-td"
    protected width = 0
    protected min = 0;
    protected minus = 0;
    protected openStatus = false
    protected lastTop = 0
    protected minHight = 690
    protected timeout:any = null;
    protected requestypeId = -1
    protected incDataTop = 0
    
    constructor(prefix:string, min:number,minus:number){
        this.min = min
        this.minus = minus
        this.element = {
            container: prefix + "-container",
            search: prefix + "-search",
            space: prefix + "-space",
            space2: prefix + "-space2",
            space3: prefix + "-space3",
            clientHight: prefix + "-client-hight",
            result: prefix + "-result",
            dataFixed: prefix + "-data-fixed",
            data: prefix + "-data",
            openSearch: prefix + "-open-search",
            openAdd: prefix + "-open-add"
        }
        this.removeEvent()
        this.addEvent()
    }

    getElement(){
        return this.element
    }
    
    getElementTop(){
        return this.elementTop
    }

    getElementInc(){
        return this.elementInc
    }

    getOpen(id:string){
        let ele = document.getElementById(id) as HTMLInputElement
        let value = ele && ele.value ? parseInt(ele.value) : 0
        return value
    }

    setElementTop(key:string,value:number){
        this.elementTop[key] = value
    }

    setElementInc(key:string,value:number | string){
        this.elementInc[key] = value
    }

    setElementStatus(key:string,value:number){
        this.elementStatus[key] = value
    }

    setOpenStatus(value:boolean){
        this.openStatus = value
    }

    setThElement(thName:string){
        this.thElement = thName
    }

    setTdElement(tdName:string){
        this.tdElement = tdName
    }

    setSpace3Hight = (value:number) => {
          let element = document.getElementById(this.element.space3) as HTMLDivElement
          let h = value > 3 ? 380 : 450
          element.style.height = h + 'px'
           
    }
    setMinHight = (value:number) =>{
        this.minHight = value
    }

    setIncDataTop = (value:number) =>{
        this.incDataTop = value
    }

    setCollapse = () => {
        
        let openSearchElement = document.getElementById(this.element.openSearch) as HTMLDivElement
        let dataFixedElement = document.getElementById(this.element.dataFixed) as HTMLDivElement
        if(openSearchElement){
            let value = !openSearchElement.classList.contains('display-none') ? 0 : 1
            if(value === 1){
                if(dataFixedElement){
                    dataFixedElement.style.opacity = '0'
                }
                this.removeTableFix()
                this.addTitle()
                setTimeout(() => {
                    this.openStatus = true
                    this.setCollapseArrow(1)
                    this.setCollapseBottom(openSearchElement,1)
                    if(dataFixedElement){
                        dataFixedElement.style.opacity = '1'
                    }
                }, 50);
            }
            else{
                this.openStatus = false
                this.setCollapseArrow(0)
                this.setCollapseBottom(openSearchElement,0)
                this.updatePosition(true)
            }
           
        } 
    }

    setCollapse2 = (inc:number) => {
        
        let openSearchElement = document.getElementById(this.element.openSearch) as HTMLDivElement
        let dataFixedElement = document.getElementById(this.element.dataFixed) as HTMLDivElement
        if(openSearchElement){
            let value = !openSearchElement.classList.contains('display-none') ? 0 : 1
            if(value === 1){
                this.incDataTop = inc
                if(dataFixedElement){
                    dataFixedElement.style.opacity = '0'
                }
                this.removeTableFix()
                this.addTitle()
                setTimeout(() => {
                    this.openStatus = true
                    this.setCollapseArrow(1)
                    this.setCollapseBottom(openSearchElement,1)
                    if(dataFixedElement){
                        dataFixedElement.style.opacity = '1'
                    }
                    this.updatePosition(true)
                }, 50);
            }
            else{
                this.incDataTop = 0
                this.openStatus = false
                this.setCollapseArrow(0)
                this.setCollapseBottom(openSearchElement,0)
                this.updatePosition(true)
            }
           
        } 
    }

    setCloseCollapse = () => {
     
        let ele = document.getElementById(this.element.openSearch) as HTMLDivElement
        if(ele){
            this.openStatus = false
            this.setCollapseArrow(0)
            this.setCollapseBottom(ele,0)
        } 
    }

    setCollapseArrow = (value:number) => {
        let ele = document.getElementById('arrow-right') as HTMLInputElement
        let ele2 = document.getElementById('arrow-down') as HTMLInputElement
        if(value === 0){
            this.toggleClass(ele,'display-none','display-inline')
            this.toggleClass(ele2,'display-inline','display-none')
        }
        else{
            this.toggleClass(ele,'display-inline','display-none')
            this.toggleClass(ele2,'display-none','display-inline')
        }
       
    
    }
    
    setCollapseBottom = (ele:HTMLDivElement,value:number) => {
        if(value === 0){
            this.toggleClass(ele,'display-block','display-none')
        }
        else{
            this.toggleClass(ele,'display-none','display-block')
        }
    }

    toggleClass(ele:HTMLDivElement | HTMLInputElement,oldClassName:string,newClassName:string){
        ele.classList.remove(oldClassName)
        ele.classList.add(newClassName)
    }

    setCloseWarehouseAdd = () => {
        this.setOpenAdd(false)
    }

    setOpenAdd = (status:boolean) => {
        let ele = document.getElementById(this.element.openAdd) as HTMLDivElement
        let ele2 = document.getElementById(this.element.result) as HTMLDivElement
        let ele3 = document.getElementById(this.element.dataFixed) as HTMLDivElement
        this.elementStatus.warehouseAdd = status ? 1 : 0

        if(status){
            this.toggleClass(ele,'display-none','display-block')
            this.toggleClass(ele2,'display-block','display-none')
            this.toggleClass(ele3,'display-block','display-none')
            this.setCloseCollapse()
        }
        else{
            this.toggleClass(ele,'display-block','display-none')
            this.toggleClass(ele2,'display-none','display-block')
            this.toggleClass(ele3,'display-none','display-block')
        }
    }
    
    setClientHight = (inc:number = 0) =>{
        let element = document.getElementById(this.element.space3) as HTMLInputElement
        let value = (document.body.clientHeight - window.innerHeight) + inc
        if(value < 50 ){
            value = 50
        }
        
        if(element)
            element.value = value  + 'px'
    }

    addEvent(){
        window.addEventListener("scroll",() => this.updatePosition());
       
    }

    removeEvent(){
        window.removeEventListener("scroll", () => this.updatePosition());
        window.removeEventListener("resize", () => this.updatePosition());
    }
    
    updatePosition(force:boolean = false){
      
        if(document.body.clientWidth > 1400){ 
            this.setWidth()
            let element = document.getElementById(this.element.clientHight) as HTMLInputElement
            if(element){
                let pos = window.scrollY;
                    if(pos >= this.min){
                     
                       if(parseInt(element.value) > this.minHight)
                       {
                            this.removeTitle()
                            this.addTableFix(force)
                        }
                    }
                 
                    else{
                        this.removeTableFix()
                        this.addTitle()
                    }
                    
                    this.removeTableSticky()
                    this.removeOverFlowSize()
                
            }
        }
        else{
            this.removeTableFix()
            this.addTableSticky()
            this.addOverflowSize()
        }
        
    
    }

    getWidth(length:number = 0)  {
       
        let cols = document.getElementsByClassName(this.thElement) as HTMLCollection
        let element = document.getElementById(this.element.container) as HTMLDivElement
        let w50c = document.getElementsByClassName("w50c") as HTMLCollection
        let s_minus = 0
        if(w50c.length > 0){
            if(element)
                s_minus = element.clientWidth * 0.5
        }

        length = cols.length
        
        if(length > 0 && element && element.clientWidth > 0){

            return (element.clientWidth - this.minus - s_minus)  / length
        }
        else{
            return 0
        }
    }

    setWidth(){
        let width = this.getWidth()
       
        if(width != this.width){
            this.setWidthByClass(this.thElement,width)
            this.setWidthByClass(this.tdElement,width)
            this.width = width
        }
    }

    setWidthByClass(className:string,width:number){
        
        let elements = document.getElementsByClassName(className) as HTMLCollection
            
            let length = elements.length
            for(let i =0; i< length;i++)
            {
                let element:any = elements[i]
                element.style.width = width + 'px';
            }
    }

    setPX(value:number){
        return value + "px"
    }

    getWidthHead(inc:number | string | null= null){
        let width = ""
        let clientWidth = 0
        let element = document.getElementById(this.element.dataFixed) as HTMLDivElement
        if(element){
            clientWidth = element.clientWidth 
            if(typeof inc == 'number'){
                width = clientWidth + inc + "px"
            }
            if(typeof inc == 'string'){
                width = inc
            }
        }
        return width
    }

    getHeightHead(){
        let clientHeight = 0
        let element = document.getElementById(this.element.dataFixed) as HTMLDivElement
        if(element){
            clientHeight = element.clientHeight 
        }
        return clientHeight
    }
    addTableFix(force:boolean = false){
     
        let topSpace2 = this.elementTop.space2
        let topResult = this.elementTop.result
        let topData = this.elementTop.data

        let elementOpenSearch = document.getElementById(this.element.openSearch) as HTMLDivElement
        if(elementOpenSearch)
        {
            let elementResult = document.getElementById(this.element.result) as HTMLDivElement
            topSpace2 = this.elementTop.search2 +  elementOpenSearch.clientHeight 
            topResult = topSpace2 + 33
            topData = topResult + elementResult.clientHeight + this.incDataTop
        }
     
                this.addFixElementById(this.element.search,this.elementTop.search,this.getWidthHead(this.elementInc.search),null,force)
                this.addFixElementById(this.element.openSearch,this.elementTop.search2,this.getWidthHead(this.elementInc.search),null,force)
                this.addFixElementById(this.element.space,this.elementTop.space,this.getWidthHead(this.elementInc.space),'inline',force)
                this.addFixElementById(this.element.space2,topSpace2,this.getWidthHead(this.elementInc.space2),'inline',force)
                this.addFixElementById(this.element.result,topResult,this.getWidthHead(this.elementInc.result),null,force)
                this.addFixElementById(this.element.data,topData,this.getWidthHead(this.elementInc.data),null,force)
                this.removeBorderRight(this.element.result)
               
    }

    addTitle(){
        
        let elements =  Array.from(document.getElementsByClassName('page-title-box'))
        if(elements.length > 0)
        {
            let element = elements[0] as HTMLDivElement
            if(element){
                element.style.display = 'flex !important'
            }
        }
       
    }

    addFixElementByClass(className:string,top:number,width:string,bgColor:string | null = null){
        let elements =  Array.from(document.getElementsByClassName(className))
        if(elements.length > 0)
        {
            let element = elements[0] as HTMLDivElement
            if(element && !element.classList.contains('table-thead-fixed')){
                element.classList.add('table-thead-fixed')
                element.style.top = this.setPX(top)
                element.style.width = width 
                if(bgColor){
                    element.style.backgroundColor = bgColor
                }
            }
        }
       
    }

    addFixElementById(id:string,top:number,width:string,display:string|null = null,force:boolean = false){

        let element = document.getElementById(id) as HTMLDivElement
       
        if(element && !element.classList.contains('table-thead-fixed')){
            
            element.classList.add('table-thead-fixed')
            element.style.top = top + 'px'
            element.style.width = width
            if(display){
                element.style.display = display
            }
        }

        if(force){
             element.style.top = top + 'px'
        }
    }

    addBorderRight(id:string){
        let element = document.getElementById(id) as HTMLDivElement
        if(element && !element.classList.contains('sticky-border-right')){
            element.classList.add('sticky-border-right')
        }
    }
    
    removeTitle(){
        
        let elements =  Array.from(document.getElementsByClassName('page-title-box'))
        if(elements.length > 0)
        {
            let element = elements[0] as HTMLDivElement
            if(element){
                element.style.display = 'none !important'
            }
        }
       
    }

    removeTableFix(){
        this.removeFixElementById(this.element.search)
        this.removeFixElementById(this.element.openSearch)
        this.removeFixElementById(this.element.space)
        this.removeFixElementById(this.element.space2,'none')
        this.removeFixElementById(this.element.result)
        this.removeFixElementById(this.element.data)
        this.addBorderRight(this.element.result)
    }

    removeFixElementByClass(className:string){
        let elements =  Array.from(document.getElementsByClassName(className))
        if(elements.length > 0)
        {
            let element = elements[0] as HTMLDivElement
            if(element && element.classList.contains('table-thead-fixed')){
                element.classList.remove('table-thead-fixed')
                element.style.top = ""
                element.style.width = ""
            }
        }
       
    }

    removeFixElementById(id:string, display:string|null = null){
        let element = document.getElementById(id) as HTMLDivElement
        if(element && element.classList.contains('table-thead-fixed')){
            element.classList.remove('table-thead-fixed')  
             element.style.top = ""
             element.style.width = ""
             if(display){
                element.style.display = display
            }
        }
    }

    removeBorderRight(id:string){
        let element = document.getElementById(id) as HTMLDivElement
        if(element && element.classList.contains('sticky-border-right')){
            element.classList.remove('sticky-border-right')
           
        }
    }

    addTableSticky(){
        let element = document.getElementById(this.element.result) as HTMLTableElement
        if(!element.classList.contains('head-sticky')){
            element.classList.add('head-sticky')
        }

        this.addTdFix('thfix1','th-td-fix1')
        this.addTdFix('thfix2','th-td-fix2')
        this.addTdFix('tdfix1','td-fix1')
        this.addTdFix('tdfix2','td-fix2')
        
    }

    removeTableSticky(){
        let element = document.getElementById(this.element.result) as HTMLTableElement
        if(element && element.classList.contains('head-sticky')){
            element.classList.remove('head-sticky')
        }

        this.removeTdFix('thfix1','th-td-fix1')
        this.removeTdFix('thfix2','th-td-fix2')
        this.removeTdFix('tdfix1','td-fix1')
        this.removeTdFix('tdfix2','td-fix2')
    }

    addTdFix(idName:string,name:string){
        let elements = document.getElementsByClassName(idName) as HTMLCollection
            let length = elements.length
            for(let i =0; i< length;i++)
            {
                let ele:any = elements[i]
                if(ele && !ele.classList.contains(name)){
                    ele.classList.add(name)
                }
            }
    }

    removeTdFix(idName:string,name:string){
        let elements = document.getElementsByClassName(idName) as HTMLCollection
        let length = elements.length
        for(let i =0; i< length;i++)
        {
            let ele:any = elements[i]
            if(ele && ele.classList.contains(name)){
                ele.classList.remove(name)
            }
        }
    }

    addOverflowSize(){
        let value = 0

        let ele = document.getElementById(this.element.openSearch) as HTMLInputElement
        value = ele && ele.value ? parseInt(ele.value) : 0

        let element = document.getElementById(this.element.container) as HTMLDivElement
        if(element){
            if(!element.classList.contains('overflow-size')){
                element.classList.add('overflow-size')
            }
             
            element.style.height = value === 0 ? '400px' : '300px'
        }
        
    }

    removeOverFlowSize(){
        let element = document.getElementById(this.element.container) as HTMLDivElement
        if(element){
            element.style.height = 'auto'
            element.classList.remove('overflow-size')
        }
        
    }


    updateQueryData(){
        let elementSpace2 = document.getElementById(this.element.space2)
        let elementResult = document.getElementById(this.element.result)
        let elementData = document.getElementById(this.element.data)
      

            if(elementSpace2)
              elementSpace2.style.top = ""
      
            if(elementResult)
              elementResult.style.top =""
      
            if(elementData)
              elementData.style.top = ""
         
    }

}