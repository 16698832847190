import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ManualUpdate from './ManualUpdate';
import ArchiveOfficerUpdate from './ArchiveOfficerUpdate';
import ManualUpdateHistory from './ManualUpdateHistory';
import AutoChange from './AutoChange';

const ManageOrgUnit = () => {

  const itemsTab: TabsProps['items'] = [
    {
        key: '1',
        label: `Manual Update`,
        children: <ManualUpdate/>,
    },
    {
      key: '2',
      label: `Archive Officer Update`,
      children: <ArchiveOfficerUpdate/>,
   },
    {
        key: '3',
        label: `Auto Change`,
        children: <AutoChange/>,
      },
     
     {
      key: '4',
      label: `Update History`,
      children: <ManualUpdateHistory/>,
  },
  ];

  
  useEffect(() => {
   
  }, [])

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="1" items={itemsTab}  destroyInactiveTabPane/>
      </Card>

    </div>
  );

}

export default ManageOrgUnit
