import { useEffect, useState } from 'react';
import { Collapse, Form, message,Modal,Row,Spin,Card,Col,Popconfirm,Input,Pagination,Button,Select,DatePicker  } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import { Label } from 'reactstrap';
import {CartonStatus,ExportType } from '../../enum';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { AiOutlineSync, AiOutlineSearch,AiOutlinePrinter,AiOutlineRight,AiOutlineDown} from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item,RequestChangeOrgUnits} from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { HrWebService,PropertyService,ChangeOrgUnitService,FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { useNavigate } from 'react-router-dom';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { OrgUnitAutoComplete2 } from '../../autocomplete/OrgUnitAutoComplete2';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';


interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    orgUnits:string[]
    isLoading:boolean
  }

const ArchiveOfficerUpdate = () => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

  
    const stickyHelper = new StickyHelper('archive-officer-update',80,50)
    const stickyElement = stickyHelper.getElement()

    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
   

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
    const [dataForm] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter())
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      orgUnits:[],
      isLoading : false
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [searchPrevOwners, setSearchPrevOwners] = useState<Item[]>([])
    const [searchOwners, setSearchOwners] = useState<Item[]>([])
    const [searchPrevOrgUnits, setSerachPrevOrgUnits] = useState<Item[]>([])
    const [searchNewOrgUnits, setSerachNewOrgUnits] = useState<Item[]>([])
    const [newOwners, setNewOwners] = useState<Item[]>([])
    const [newOrgUnits, setNewOrgUnits] = useState<Item[]>([])
    const [selectItems, setSelectItems] = useState<number[]>([])
    const [itemNoList, setItemNoList] = useState<number[]>([])
    const [isLoadingPrint, setIsLoadingPrint] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [firstLoad,setFirstLoad] = useState(false)
    const [searchStatus,setSearchStatus] = useState(false)
    const [clientHeight,setClientHeight] = useState(0)

    const onSetClientHeight = () => {
    
        setTimeout(() => {
          stickyHelper.updatePosition()
          window.scrollTo(0,0)
          let element = document.getElementById(stickyElement.container) as HTMLInputElement
          if(element){
            let value = element.clientHeight
            setClientHeight(value)
          }
        }, 100);
      
      }
      
    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }
       
       // let parameter = InitHelper.InitialParameter();
        // getData(parameter).then(() => {
        //     stickyHelper.updatePosition()
        
        // })

        setIsLoadingPrint(false)
        stickyHelper.removeEvent()
        stickyHelper.addEvent()

        window.addEventListener("resize", () => { 
           onSetClientHeight()
        });
      
        
      
        
    }, [])
    
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    
    
    const getData = async (_parameter:Parameter,checkAll:boolean = true) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},_parameter); 
        parameter.statusIds = [CartonStatus.Done]
    
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).SearchStorage(parameter)
        
        if(result && result.success)
        {
            let _itemNoList = result.data.itemNoList.map(x => parseFloat(x))
            if(_itemNoList.length == 0){
                _itemNoList = itemNoList
            }
            let state = Object.assign({},appState)
            state.total = result.data.total
            state.currentPage = parameter.page
            state.items = result.data.items
            state.isLoading = false
            state.perpage = parameter.limit
            setAppState(state)
            setSaveParameter(parameter)
            setItemNoList(_itemNoList)
            if(checkAll){
                setSelectItems(_itemNoList)
                setTimeout(() => {
                    onSetClientHeight()
                  }, 100);
                setTimeout(() => {
                    setCheckAllItem(true)
                }, 500);
            }
            else{
                setSelectItems([])
                setTimeout(() => {
                    onSetClientHeight()
                  }, 100);
                setTimeout(() => {
                    setCheckAllItem(false)
                }, 500)
            }
          
           
           
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
        
        let field = dataForm.getFieldsValue()
        let parameter = InitHelper.InitialParameter()
        parameter.limit = appState.perpage
        
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }

        if(searchPrevOwners.length > 0){
            parameter.prevOwners = searchPrevOwners.map(item => item.key)
        }

        if(searchOwners.length > 0){
            parameter.owners = searchOwners.map(item => item.key)
        }
    
        if(searchPrevOrgUnits.length > 0){
            parameter.prevOrgUnits = searchPrevOrgUnits.map(item => item.key)
        }

        if(searchNewOrgUnits.length > 0){
            parameter.orgUnits = searchNewOrgUnits.map(item => item.key)
        }
        
        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
        parameter.confidentialLevel = field.confidential
        getData(parameter).then(() => {
            stickyHelper.updatePosition()
          
            setSearchStatus(true)
        }) 
        
        
    }

    const reset = () => {
        dataForm.resetFields()
        let state:AppState = {
            currentPage : 1,
            total : 0,
            perpage: 100,
            items: [],
            orgUnits:[],
            isLoading : false
        }
        setAppState(state)
        setSearchPrevOwners([])
        setSearchOwners([])
        setSerachPrevOrgUnits([])
        setSerachNewOrgUnits([])
        setRequestStartDate(null)
        setRequestEndDate(null)
        setItemNoList([])
        setSelectItems([])
        setFirstLoad(false)
        setSearchStatus(false)
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee2(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    
    const handleSave = async () => {
        
        let requestChangeOwner:RequestChangeOrgUnits = {
            recordIds: selectItems,
            statusId: 10,
            newOwner: newOwners.length > 0 ? newOwners[0].key : "",
            newOrgUnitId: newOrgUnits.length> 0 ? newOrgUnits[0].key: "",
            newOrgUnit: newOrgUnits.length> 0 ? newOrgUnits[0].name: "",
            
        }
        
        
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).AdminUpdateStorage(requestChangeOwner);
          
        let element = document.getElementById(stickyElement.container) as HTMLElement
        if(result && result.success){
      
            messageApi.open({
              type: 'success',
              content: 'Save success',
              duration: 1,
              style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
            });
            setTimeout(() => {
             
                getData(saveParameter,false)
                setModalOpen(false)
            }, 500);
        }
        else{
            Modal.error({
                icon: <CloseCircleOutlined />,
                title: 'This is an error message',
                content: result?.message,
            });
        }
    };
    
    const onPageChange = (page:number,perpage:number) => {
        let parameter = Object.assign({},saveParameter)
        
        if(perpage !== appState.perpage){
            parameter.limit = perpage
        }
        else{
            parameter.page = page
        }

        getData(parameter)
    }

    const onCheckAll = (checked:boolean) => {
        let items = checked ? itemNoList : []
        setCheckAllItem(checked)
        setSelectItems(items)
        
      };

      const setCheckAllItem = (checked:boolean,indeterminate:boolean = false) => {
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(element){
            element.checked = checked
            element.indeterminate = indeterminate
        }
           
      };

    
      const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked
        let value = parseFloat(e.target.value)
        let items = Object.assign([],selectItems)
      
        if(checked){
            items.push(value)
        }
        else{
          let index = items.indexOf(value);
          if (index !== -1) {
            items.splice(index, 1);
          }
    
        }
        
        if(items.length > 0){
            let checkStatus = (itemNoList.length === items.length) ? true : false
            console.log(checkStatus)
            setCheckAllItem(checkStatus,!checkStatus)
        }
        else{
            setCheckAllItem(false,false)
        }
    
        setSelectItems(items)
    }
    
    const onSearchPrevOwnerChange = (item:any) => {
        setSearchPrevOwners(item)
        stickyHelper.updatePosition()
    }
    
    const onSearchOwnerChange = (item:any) => {
        setSearchOwners(item)
        stickyHelper.updatePosition()
    }

    const onSearchPrevOrgUnitChange = (item:any) => {
        setSerachPrevOrgUnits(item)
        stickyHelper.updatePosition()
    }

    const onSearchNewOrgUnitChange = (item:any) => {
        setSerachNewOrgUnits(item)
        stickyHelper.updatePosition()
    }

    const onNewOwnerChange = (item:any) => {
        setNewOwners(item)
    }

    const onNewOrgUnitChange = (item:any) => {
        setNewOrgUnits(item)
    }

    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
        if(requestEndDate && date > requestEndDate){
            setRequestEndDate(null)
        }
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const disabledDate = (value:any) => {
        if(requestStartDate)
            return Helper.disabledDate(value, dayjs(requestStartDate).format('DD MMM YYYY'))
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    const onSearchHrOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

   const OpenReplace = () => {
    setNewOrgUnits([])
    setNewOwners([])
    setModalOpen(true)
   }

   const onFinishPrint = async () => {
    setIsLoadingPrint(true)
    let token = await getToken()
    if(!saveParameter)return

    try{
        saveParameter.recordIds = selectItems
        await new FileManageService(token).DownloadReport(saveParameter,ExportType.ARCHIVE_OFFICER_UPDATE_ORG_UNIT);
    }catch(error){
        console.log("🚀 ~ file: ManualUpdate.tsx:392 ~ onFinishPrint ~ error:", error)

    }finally{
        setIsLoadingPrint(false)
    }
}

    

    return (
    <div>
        <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
              {...layout}
              form={dataForm}
              name="orgunit-form"
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="Request Date">
                      <table className='w100c'>
                      <tbody>
                        <tr>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate}  
                                format={'DD MMM YYYY'} 
                                className='date-picker-full' 
                                onChange={onRequestStartDateChange}
                            />
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker 
                                value={requestEndDate ?  dayjs(requestEndDate) : null}
                                placeholder={MessageCommon.TitleDate}  
                                format={'DD MMM YYYY'} 
                                className='date-picker-full' 
                                onChange={onRequestEndDateChange}
                                disabledDate={disabledDate}
                            />
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Storage Request No."  >
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                    <Form.Item name="confidential" label="Confidentiality Level"  initialValue={-1}>
                        <Select placeholder={MessageCommon.TitleSelect} 
                            options={[
                            { value: -1, label: 'All' },
                            { value: 1, label: 'Confidential' },
                            { value: 0, label: 'Non-Confidential' },
                
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="prevOwner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Owner'
                            selectedItems={searchPrevOwners ? searchPrevOwners : []}
                            onChange={item => onSearchPrevOwnerChange(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                    </Form.Item>

                    <Form.Item name="owner" label="New Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by New Owner'
                            selectedItems={searchOwners ? searchOwners : []}
                            onChange={item => onSearchOwnerChange(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                    </Form.Item>
                    <Form.Item name="prevOrgUnit" label="Previous Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Previous Org. Unit'
                            selectedItems={searchPrevOrgUnits ? searchPrevOrgUnits : []}
                            onChange={item => onSearchPrevOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    
                    <Form.Item name="orgUnit" label="New Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by New Org. Unit'
                            selectedItems={searchNewOrgUnits ? searchNewOrgUnits : []}
                            onChange={item => onSearchNewOrgUnitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                  
                </Col>
              </Row>
      
                </Form>
                <div className='text-center'>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
  
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>
    
    { searchStatus ?
        <>
        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
            <div className='margin-bottom-25'>
                   <Button type="primary" disabled={ selectItems.length == 0} onClick={OpenReplace}>
                         Replace Org. Unit & Replace Owner
                    </Button>
                    <Button type="primary" className='mr-btn margin-left-10' onClick={()=>onFinishPrint()}>
                        <AiOutlinePrinter className='icn-btn' />Print
                    </Button>
                    { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='mar margin-left-8'>/ Page</span>
                    </div>:<></>}
                </div>
                
                <div className='search-result'>Search found { appState.total } record(s).</div>

        </div>
      
        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
            <div id={stickyElement.container}>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <div>
                    { appState.total > 0 ?
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                                 <th className="thfix1 text-center w50">
                                    <div className="form-check">
                                        <input  
                                            type="checkbox" 
                                            id={'check-item-all'} 
                                            className="form-check-input check-item" 
                                            onChange={(e) => onCheckAll(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor={'check-item-all'}/>
                                    </div>
                      
                                </th>
                            
                                <th className="s-th thfix2">Storage Request No.</th>
                                <th className="s-th">Request Date</th>
                                <th className="s-th">Owner</th>
                               
                                <th className="s-th">New Owner</th>
                                <th className="s-th">Previous Org. Unit</th>
                                <th className="s-th">New Org. Unit</th>
                                <th className="s-th">Confidentiality Level</th>
                                
                                
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                    <td  className="tdfix1 text-center w50">
                                        <div className="form-check">
                                            <input 
                                                checked={selectItems.indexOf(item.recordNo) > -1 ? true : false} 
                                                type="checkbox" 
                                                id={'check-item-' + item.recordNo} 
                                                value={item.recordNo} 
                                                className="form-check-input check-item" 
                                                onChange={(e) => onCheckChange(e)}
                                            />
                                            <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                                        </div>
                                    </td>
                                    <td className="s-td tdfix2 text-center">{item.requestNo }</td>
                                    <td className="s-td text-center">
                                        {dayjs(item.requestDate).format('DD MMM YYYY')}
                                    </td>
                                    <td className="s-td text-left-20">{item.prevOwnerName ? item.prevOwnerName : item.ownerName}</td>
                                   
                                    <td className="s-td text-left-20">{item.prevOwnerName ? item.ownerName : ""}</td>
                                    <td className="s-td text-center">{item.prevOrgUnit ? item.prevOrgUnit : item.orgUnit}</td>
                                    <td className="s-td text-center">{item.prevOrgUnit ? item.orgUnit : ""}</td>
                                   
                                    <td className="s-td text-center">
                                          <span>{returnIfElse(item.confidentialLevel === 0, 'Non-Confidential', 'Confidential'  )  }</span>
                                    </td>
                        
                                   
                                   
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                    :<></>
                        }
                    </div>
                }
                </div>
                <div className='margin-top-10 text-right'>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        
                
            
        </div>

        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
        
        </>
        :
        <></>
}

     
            {modal ? <span></span> : <></>}

        <Modal title="Replace Org. Unit & Replace Owner" open={modalOpen} footer={''} width={600} onCancel={() => setModalOpen(false)} maskClosable={false}>
          
              <Row className='margin-top-25'>
                <Col md={8}>
                    <label>Please input new Owner:</label>
                </Col>
                <Col md={16} >
                   <EmployeeAutoComplete
                        key={Helper.Uniqid()}
                        placeholder='Search by Owner'
                        selectedItems={newOwners ? newOwners : []}
                        onChange={item => onNewOwnerChange(item)}
                        service={onSearchEmployee}
                        minimumTextLength={3}
                        pickOnlyOne
                    />
                </Col>
               </Row>
               <Row className='margin-top-15'>
                <Col md={8}>
                    <label>Please Input new Org. Unit:</label>
                </Col>
                <Col md={16} >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Org. Unit'
                            selectedItems={newOrgUnits ? newOrgUnits : []}
                            onChange={item => onNewOrgUnitChange(item)}
                            service={onSearchHrOrgUnit}
                            minimumTextLength={2}
                            pickOnlyOne
                        />
                </Col>
              </Row>
      
            
                <div className='text-center margin-top-25'>
                    
                        <Button type="default" className='w150' onClick={() => setModalOpen(false)}>
                                Cancel
                            </Button>
                        <Popconfirm
                                title="Update the task"
                                description="Confirm to update?"
                                icon={<QuestionCircleOutlined className='c-red'/>}
                                onConfirm = { () =>  handleSave()}
                        >
                            
                            <Button type="primary" className='w150 margin-left-10' disabled={newOrgUnits.length == 0 && newOwners.length == 0}>
                                Update
                            </Button>
                        </Popconfirm>
                   
                </div>
  
        </Modal>
        {contextHolderMessage}
        {contextHolder}
      
    </div>
    );
  
  }
  
  export default ArchiveOfficerUpdate
  