import React, { useEffect, useState } from 'react';
import { Button, Spin, Modal, Popconfirm, message, Pagination } from 'antd';
import {  AiFillEdit,AiFillDelete } from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import DestructionCartonExtenModal from './DestructionCartonExtenModal';
import DestructionCartonDestroyModal from './DestructionCartonDestroyModal';
import { RequestUpdateStatus, User,QueryDataModel} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { DestructionCartonService } from '../../services';
import dayjs from 'dayjs'
import { CartonStatus ,RequestType} from '../../enum'
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:QueryDataModel[]
  itemNoList:string[]
  isLoading:boolean
}

const DestructionCartonSaveDraft = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [selectItems, setSelectItems] = useState<number[]>([])
  const [recordNo,setRecordNo] = useState(0)
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: 10,
    items: [],
    itemNoList:[],
    isLoading : true
  })

  useEffect(() => {
    initial()

}, [])

    const initial = () => {
        getData(1,appState.perpage)
    }

    const onReload = () => {
        return null
    }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelCarton = () => {
    setIsModalOpen(true);
    setIsModalCartonOpen(false);

  }
  

  const handleEdit = (_recordNo:number,_requestTypeId:number) => {
    
     setRecordNo(_recordNo)
     setRequestTypeId(_requestTypeId)
     setIsModalOpen(true)
  }

  const handleDelete = async (recordIds:number[]) => {
    let token = await getToken()
    let requestUpdateStatus:RequestUpdateStatus = {
      recordIds: recordIds,
      statusId: CartonStatus.Delete
    }
    
    let result = await new DestructionCartonService(token).UpdateStatus(requestUpdateStatus)
   
    if(result && result.success){
      let element = document.getElementById('table-container') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
       
        getData(1,appState.perpage)
        
      }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:number[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        if(!element.disabled)
        {
            element.checked = true
            items.push(parseFloat(element.value))
        }
       
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = parseFloat(e.target.value)
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length === 0){
        let element = document.getElementById('check-item-all') as HTMLInputElement
        element.checked = false
        element.indeterminate = false
      }
    }

   
    setSelectItems(items)
  }


  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter = InitHelper.InitialParameter();
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.requester = user.employeeId
      
      let token = await getToken()
      let result = await new DestructionCartonService(token).Searchdraft(parameter)
      
      if (!result)
          return null;
      let {page,limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.items = items
          state.isLoading = false
          state.perpage = limit

        setTimeout(() => {
          setAppState(state)
        }, 500);
       
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(perpage !== appState.perpage){
      getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }

  return (
    <div>
      <div className='margin-right-10 margin-top-15 margin-bottom-5'>
      { selectItems.length > 0 ?
        <Popconfirm
            title="Delete the task"
            description="Confirm to delete?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => handleDelete(selectItems) }
          >
           <Button type="primary" className='btn-red mr-btn'>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        </Popconfirm>
        :
        <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        }
      </div>
      <div id="table-container">
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th className='text-left w50'>
                      <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                        
                </th>
               
             <th >Requested No.</th>
             <th >Requested date</th>
             <th >Destruction Type</th>
             <th >Carton Quantity</th>
             <th >Edit</th>
             <th>Delete</th>
              
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                  <td className='text-left w50'>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.recordNo} value={item.recordNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)} disabled={user.employeeId !== item.requester  || item.requester !== item.onBehalf}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                        </div>
                      </td>
                     
                      <td className='text-center'>
                      { item.requester === item.onBehalf ?
                        <a className='a-table' onClick={() => handleEdit(item.recordNo,item.requestTypeId)}> { item.requestNo ? item.requestNo : item.tempNo }</a>
                        :
                        <span>{item.requestNo ? item.requestNo : item.tempNo }</span>
                      }
                      </td>
                      <td className='text-center'>{ dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                      <td className='text-center'>{item.requestTypeId === RequestType.ExtendCarton ? 'Extend' : 'Destroy'}</td>
                      <td className='text-center'>{item.quantity}</td>
                      <td className='text-center'>
                      { item.requester === item.onBehalf ?
                          <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo,item.requestTypeId)} className='pointer'/>
                          :
                          <span className='c-red'>On Behalf</span>
                        }
                      </td>
                      <td className='text-center'>
                      { item.requester === item.onBehalf ?
                        <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined className='c-red' />}
                          onConfirm = { () => handleDelete([item.recordNo]) }
      
                        >
                          <AiFillDelete size={20} color="red" className='pointer'/>
                        </Popconfirm>:<></>
                      }
                        
                      </td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div className='margin-top-10 text-right'>
            { appState.total > 0 ?
                <Pagination 
                    key={Helper.Uniqid()}
                    current={appState.currentPage} 
                    pageSize={appState.perpage}
                    total={appState.total} 
                    onChange={onPageChange} 
                    pageSizeOptions={pageSizeOptions}
                    showSizeChanger={true}
                />:<></>
            }
          </div>

      <Modal title={requestTypeId === RequestType.ExtendCarton ? 'Extend' : 'Destroy'}  open={isModalOpen} footer={''} width={1550} onCancel={handleCancel} maskClosable={false} >
        { requestTypeId === RequestType.ExtendCarton ?
            <DestructionCartonExtenModal  key={Helper.Uniqid()} destroyId={recordNo} items={[]} page="destruction" onBehalf={0} onReload={initial}/>:<></>
        }
         { requestTypeId === RequestType.DestructionCarton ?
            <DestructionCartonDestroyModal  key={Helper.Uniqid()} destroyId={recordNo} items={[]} page="destruction" onBehalf={0} onReload={initial}/>:<></>
        }
       
      </Modal>

      <Modal title="Carton Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        {/* <CartonDetail></CartonDetail> */}
      </Modal>
      <Modal title="Request Detail"
          width={1550}
          >
      {/* <StorageCartonRequestDetail status={"H"} roomType='normal' approveLevel={0}/> */}
      </Modal>

      {contextHolderMessage}
    </div >
  );

}

export default DestructionCartonSaveDraft
