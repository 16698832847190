import { useEffect } from 'react';
import { Card,  Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import RemoveCartonRequest from './RemoveCartonRequest';
import RemoveCartonStatusTracking from './RemoveCartonStatusTracking';
import RemoveCartonSaveDraft from './RemoveCartonSaveDraft';

const RemoveCarton3 = () => {
  const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Remove`,
      children: <RemoveCartonRequest/>,
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <RemoveCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <RemoveCartonSaveDraft/>,
    },

  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/remove-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
      navigate('/remove-carton-' + tabstr)
    }
  }

  useEffect(() => {

  }, [])

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="3" items={items} onChange={onTabChange} destroyInactiveTabPane/>
      </Card>
    </div>
  );

}

export default RemoveCarton3
