import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import ReturnCartonRequest from './ReturnCartonRequest'
import ReturnCartonStatusTracking from './ReturnCartonStatusTracking';
import ReturnCartonSaveDraft from './ReturnCartonSaveDraft';

const ReturnCarton2 = () => {
  const navigate = useNavigate();
 
  useEffect(() => {
    
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Return`,
      children: <ReturnCartonRequest onBehalf={0}/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <ReturnCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <ReturnCartonSaveDraft/>,
    },

  ];
  
  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/return-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
      navigate('/return-carton-' + tabstr)
    }
  }

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="2" items={items}  onChange={onTabChange} destroyInactiveTabPane/>
      </Card>

    </div>
  );

}

export default ReturnCarton2
