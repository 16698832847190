import axios from 'axios'
import { DataArrayResponse } from '../models/PageResponse'
import { Project, Warehouse } from '../models'
import OrgUnit  from '../models/OrgUnitData'
import { config } from '../config'

const apiUrl = config.API_URL

export default class PropertyService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async GetProjects(){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }
        
        const { data }  = await axios.get<DataArrayResponse<Project>>(`${apiUrl}/v1/properties/project`, options )
        
        return data;
    }


    async SearchProject(key:string ){
        if(!this.token) return null

        let options = {
            headers:this.headers,  
        }
        
        const { data }  = await axios.get<DataArrayResponse<Project>>(`${apiUrl}/v1/properties/search/project?key=${key}`, options )
        
        return data;
    }

    async GetWarehouses(){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }
        
        const { data }  = await axios.get<DataArrayResponse<Warehouse>>(`${apiUrl}/v1/properties/warehouse`, options )
        
        return data;
    }

    async GetOrgUnits(key:string){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }
        
        const { data }  = await axios.get<DataArrayResponse<string>>(`${apiUrl}/v1/properties/orgunit?key=` + encodeURI(key), options )
        
        return data;
    }

    async GetHrOrgUnits(key:string){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }
        
        const { data }  = await axios.get<DataArrayResponse<OrgUnit>>(`${apiUrl}/v1/properties/hrorgunit?key=` + encodeURI(key), options )
        
        return data;
    }

}