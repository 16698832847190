import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Radio, Row ,Spin ,Popconfirm, message} from 'antd';
import {  AiOutlineCheck, AiOutlineClose,AiFillDelete ,AiFillSave ,AiOutlineCloseCircle, AiOutlinePrinter} from 'react-icons/ai';
import { Label } from 'reactstrap';
import {  CloseCircleOutlined ,QuestionCircleOutlined } from '@ant-design/icons';
import CartonList from '../Share/CartonList'
import { RequestDetail,UpdateApproval, User } from '../../models';
import { DestructionCartonService, FileManageService } from '../../services';
import { InitHelper, Helper } from '../../helpers';
import { CartonStatus ,Message, RequestType } from '../../enum';
import secureLocalStorage  from  "react-secure-storage";
import DisplayLogNote from '../Share/DisplayLogNote';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import CartonDetail from '../Share/CartonDetail';
import dayjs from 'dayjs';

interface Props{
    recordNo: number
    cartonNumber:string
  approveLevel: number
  disableDetail?: boolean
  onBehalf?:boolean
  noHeadDetail?:boolean
}

const DestructionCartonRequestDetail = (props: Props) => {
    const { instance, accounts } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [printForm] = Form.useForm();
  const [dataForm] = Form.useForm();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isLoading, setIsLoading] = useState(true)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)

  const location = useLocation();

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
    setIsLoading(true)
    let token = await getToken()
    let result = await new DestructionCartonService(token).GetDetail(props.recordNo)
    
    if(result && result.success){
      setDetail(result.data)
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  const handleReject = () => {
    
    let errorElement = document.getElementById('note-destruct-reject-error') as HTMLDivElement
    if(errorElement.classList.contains("display-inline")){
      errorElement.classList.remove('display-inline')
    }
    errorElement.classList.add("display-none")
    let element = document.getElementById('note-destruct-reject') as HTMLTextAreaElement

    if(element && element.value){
      handleAction(CartonStatus.Reject) 
    }
    else{
      if(errorElement.classList.contains("display-none")){
        errorElement.classList.remove('display-none')
      }
      errorElement.classList.add('display-inline')
      return false
    }
    
  }

  const handleAction = async (approveStatusId: number) => {
    let element = document.getElementById('note-destruct-reject') as HTMLTextAreaElement
    let _noteReject = element && element.value ? element.value : ""

    let token = await getToken()
    let requestUpdateStatus:UpdateApproval = {
      recordIds: [props.recordNo],
      statusId: 0,
      approveStatusId:approveStatusId,
      noteReject: _noteReject,
      updateBy: user.employeeId,
      updateDate: new Date()
    }
   
    let result = await new DestructionCartonService(token).UpdateApproval(requestUpdateStatus)
    if(result && result.success){
        let element = document.getElementById('destroy-form') as HTMLElement
        messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
            handleCancel()
        }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const handleCancel = () => {
      let elements = document.getElementsByClassName('ant-modal-close') as HTMLCollection
      let ele_len = elements.length
      let i = 0
      while (i < ele_len) {
          let btnClose = elements[i] as HTMLButtonElement
          btnClose.click()
          i++
      }
    
    setIsModalOpen(false);
    setIsModalRejectOpen(false)
}


  const download = async (_requestNo:string,filename:string) => {
      
    let token = await getToken()
    new FileManageService(token).downloadFile(_requestNo,filename);
}

const handleRejectOpen = () => {
    
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let errorElement = document.getElementById('note-destruct-reject-error') as HTMLDivElement
        if(errorElement)
            errorElement.classList.add('display-none')

        let element = document.getElementById('note-destruct-reject') as HTMLTextAreaElement
        if(element)
          element.value = ""
    }, 300);
   
 }
  const showPrintModal = () => {

    setIsPrintModalOpen(true);
  };
  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };
  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)
      let token = await getToken()
      try{
        await new FileManageService(token).DownloadRequestDestruction(detail,values.print);
      }catch(error){
        console.log("🚀 ~ file: DestructionCartonRequestDetail.tsx:182 ~ onFinishPrint ~ error:", error)
      }finally{
        setIsLoadingPrint(false)
      }
  }

  const PrintButton = () =>{
    if(location.pathname==="/destruction-carton-tracking" || location.pathname==="/status-tracking"){
      return(
        <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal}>
          <AiOutlinePrinter className='icn-btn' />Print
        </Button>
      )
    }
  }
  return (

    <div>
      <Card >
      { isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>:<></>
      }
       {isLoadingPrint?<Loading/>:<></>}
    {props.noHeadDetail ? <></> :
    <>
        <Form
          {...layout}
          form={dataForm}
          name="destroy-form"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item name="requester" label="Requester" labelCol={{ md: 3 }}  >

                <Label className='label-det'>{detail.requesterName}</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{detail.orgUnit}</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{detail.position}</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{detail.tel}6</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item  label="Request No"  >
                <Label className='label-det '>{detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>
    { detail.memo ?
        <Row>
        <Col md={11} >
          <Form.Item  label="Org. Unit Reference/Memo"  >
            <Label className='label-det '>{detail.memo}</Label>
          </Form.Item>
        </Col>
        <Col md={2} >

        </Col>
        <Col md={11} >
          <Form.Item label="Attached File"  >
            <Label className='label-det'>
            { 
              detail.files.map((file) => 
                    (<div><a className='a-table' onClick={() => download("D" + detail.recordNo,file.name)}>{file.name}</a></div>)
            )}
            </Label>
          </Form.Item>
        </Col>
        </Row>:<></>
    }

          <Row>
            <Col md={11} >
              <Form.Item label="Carton Quantity"  >
                <Label className='label-det'>{detail.quantity} carton(s)</Label>
              </Form.Item>
              { detail.requester !== detail.onBehalf && props.onBehalf ?
                          <Form.Item name="onbehaft" label="On Behalf"  >
                              <Label className='label-det'>{detail.onBehalfName}</Label>
                          </Form.Item>:<></>
                        }
                         {detail.serviceDate?
              <Form.Item  label="Service Date"  >
                <Label className='label-det'>{ dayjs(detail.serviceDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>:<></>
            }

                          {detail.statusId === CartonStatus.Reject ?
                              <Form.Item name="noteReject" label="Rejected Reason"  >
                                  <Label className='label-det'>{detail.notApproveReason}</Label>
                              </Form.Item> : <></>
                          }
                <Form.Item name="note" label="Note" >
                  { detail.note.length > 0 ?
                <>
                    <Label className='label-det label-det-warp'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                </>:<></>
                }
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="owner" label="Request Type"  >
                <Label className='label-det'>{detail.requestTypeId === RequestType.ExtendCarton ? 'Extend' : 'Destroy'}</Label>
              </Form.Item>
              
              <Form.Item name="Verifier" label={ detail.requestDate && parseInt(dayjs(detail.requestDate).format("YYYY")) >= 2023 ? "Verifier" : "Approver"}  >
                <Label className='label-det '>{  detail.approverName } </Label>
              </Form.Item>
            
            {detail.approverDate2 != null ?
              <Form.Item name="approver" label="Approver"  >
                <Label className='label-det '>{  detail.approverName2 } </Label>
              </Form.Item>:<></>
            }
            </Col>
          </Row>
        
                
        </Form>
    
        <hr></hr>
    </>
}
        { props.approveLevel === 0 ?
            PrintButton()
            :
            <div className='text-center'>
                 <Button type="default" className='mr-btn w140' onClick={handleCancel}  >
                    <AiOutlineCloseCircle className='icn-btn' />Cancel
                </Button>
                <Button type="primary" className='btn-red mr-btn w140' onClick={() => handleRejectOpen()}>
                    <AiFillDelete className='icn-btn' /> Reject
                  </Button>
                <Popconfirm
                  title="Approve the task"
                  description="Confirm to approve?"
                  icon={<QuestionCircleOutlined className='c-red' />}
                  onConfirm = { () => handleAction(CartonStatus.Approve) }
                >
                <div className="idoc-tooltip">
                  <span className="tooltiptext">
                    <ul>
                      <li className='text-left'>
                        {Message.Verify}
                      </li>
                      <li className='text-left'>
                          {Message.Approve}
                      </li>
                    </ul>
                  </span>
                <Button type="primary" className='w140'>
                    <AiFillSave className='icn-btn' /> Verify/Approve
                </Button>
              </div>
              </Popconfirm>

            </div>
              }
              
              {detail.requestNo !== "" && props.cartonNumber !== "" ?
                  <CartonDetail key={Helper.Uniqid()} requestNo={detail.requestNo} box={props.cartonNumber} />
                  : 
                  <CartonList requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={props.disableDetail} statusId={detail.statusId}/>
              }
      </Card>
     

      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            initialValues={{print:1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" >
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
      {/* <Modal title="Carton Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        <CartonDetail></CartonDetail>
      </Modal> */}
      
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.DestructionCarton}/>
      </Modal>

      <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => setIsModalRejectOpen(false)} maskClosable={false}>
        <br></br>
       
          <Row>
          <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
            <textarea className='text-confirm' id="note-destruct-reject" rows={5} defaultValue=""/>
              <div id="note-destruct-reject-error" className='error display-none'>Note is required.</div>
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn w100' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn w100'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>

      {contextHolderMessage}
    </div>
  );

}

export default DestructionCartonRequestDetail
