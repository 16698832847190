
enum UserRole {
    SuperAdmin = 1,
    SystemAdmin = 2,
    ArchiveOfficer = 3,
    UserValveRoom = 4,
    ReportViewer = 5,
    NormalUser = 6
}

export default UserRole