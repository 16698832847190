import { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Pagination,Spin } from 'antd';
import {  AiOutlinePrinter, AiOutlineSearch, AiOutlineSync,AiOutlineRight,AiOutlineDown} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import {RequestType, RequestTypeOption, CartonStatus, ExportType } from '../../enum';
import { QueryDataModel ,Parameter ,Item, Option,User } from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { HrWebService,QueryService,PropertyService,WarehouseService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import QueryDataCollectCarton from './QueryDataCollectCarton';
import QueryDataDestructionCarton from './QueryDataDestructionCarton';
import QueryDataNewCarton from './QueryDataNewCarton';
import QueryDataRemoveCarton from './QueryDataRemoveCarton';
import QueryDataRemoveCartonStorage from './QueryDataRemoveCartonStorage';
import QueryDataReturnCarton from './QueryDataReturnCarton';
import QueryDataStorageCarton from './QueryDataStorageCarton';


interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const QueryData = () => {
  const stickyHelper = new StickyHelper("query",50,0)
  stickyHelper.setThElement('query-s-th')
  stickyHelper.setTdElement('query-s-td')
  const stickyElement = stickyHelper.getElement()

  const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
  }

    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const initialRequestTypeIds:number[] = [
    RequestType.NewCarton, 
    RequestType.StorageCarton, 
    RequestType.CollectCarton,
    RequestType.ReturnCarton, 
    RequestType.RemoveCarton,  
    RequestType.RemoveCartonStorage, 
    RequestType.DestructionCarton,
    RequestType.ExtendCarton
  ]
 
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [displayTypeId,setDisplayTypeId] = useState(0)
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
  const [requestTypeMenu,setRequestTypeMenu] = useState<number[]>(initialRequestTypeIds)
  const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [projectOptions,setProjectOptions] = useState<Option[]>([])
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [recordNo,setRecordNo] = useState("")
  const [activeKey,setActiveKey] = useState("0")
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 100,
    items: [],
    isLoading : true
  })
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [isPrint,setIsprint] = useState(true)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [display,setDisplay] = useState(false)
  const [clientHeight,setClientHeight] = useState(0)

  const onFinish = (values: any) => {
  };



  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onDisplayChange =(status : boolean) =>{
    setDisplay(false)
    if(status){
        stickyHelper.setCollapse()
        getData(1,appState.perpage)
    }
  }
 
  const nextPage = (_recordNo:number,_requestTypeId: number) => {
        setDisplay(true)
        setDisplayTypeId(_requestTypeId)
        setRecordNo(_recordNo.toString())
  }

  const onRequestTypeChange = (_requestTypeId: number) => {
    setRequestTypeId(_requestTypeId);
    if(_requestTypeId === 0){
      setRequestTypeIds(initialRequestTypeIds)
    }
    else{
        if(_requestTypeId === RequestType.DestructionCarton)
        {
            setRequestTypeIds([RequestType.DestructionCarton,RequestType.ExtendCarton])
        }
        else if(_requestTypeId === RequestType.RemoveCarton)
        {
            setRequestTypeIds([RequestType.RemoveCarton,RequestType.RemoveCartonStorage])
        }
        else{
            setRequestTypeIds([_requestTypeId])
        }
      
    }
    stickyHelper.removeTableFix()
    let pos = window.scrollY -1
    if(pos < 0){
      pos = 0
    }
    window.scrollTo(0,pos)
  };

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
        _state.isLoading = true
        setAppState(_state)

       let parameter = Object.assign({},saveParameter)
       if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
            
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.onBehalf = -1

      console.log(parameter)
      let token = await getToken()
      try{
        let result = await new QueryService(token).QuerySearch(parameter)
        console.log(result)
        if(result && result.success)
        {
          let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items
          state.isLoading = false

          if(result.data.total>0){
            setIsprint(false)
          }else{
            setIsprint(true)
          }
          setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
            setTimeout(() => {
              onSetClientHeight()
            }, 100);
          }, 500);
        }
        else{
          let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
        }
      }
      catch(error)
      {
        console.log(error)
        let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
      }
      
      

  }

  const onRequesterSelect = (item:any) => {
    setRequesters(item)
    stickyHelper.updatePosition()
  }
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
    stickyHelper.updatePosition()
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
    stickyHelper.updatePosition()
  }

  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        return result.data
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

const getProjects = async () => {
    let token = await getToken();
    Promise.all([
        new PropertyService(token).GetProjects(),
        new WarehouseService(token).GetAllWarehouses()
      ])
      .then((result) => {
        if(result[0] && result[0].success){
            let options:Option[] = [{ label:'All' , value: 0 }]
            let projectOptions:Option[] = result[0].data.map( item => { return { label:item.projectName , value: item.projectId }})
            setProjectOptions(options.concat(projectOptions))
        }
        if(result[1] && result[1].success){
            let options:Option[] = [{ label:'All' , value: '' }]
            let Locationoptions:Option[] = result[1].data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
            setWarehouseOptions(options.concat(Locationoptions))
        }
        
      })
  }

  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

  useEffect(() => {
    if(user.permissions.queryData === 0){
        navigate('/permissiondenided')
    }
    getProjects()

    let parameter = InitHelper.InitialParameter()
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.statusIds = [CartonStatus.WaitAdmin,CartonStatus.Inprogress]
    
 
    getData(1,appState.perpage,parameter).then(() => {
      stickyHelper.updatePosition()
  
    })

    stickyHelper.removeEvent()
    stickyHelper.addEvent()
    window.addEventListener("resize", () => { 
     onSetClientHeight()
  });
  }, [])

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
   
    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }

    const fieldParameterMap = (field: any, parameter: any) => {

        if (field.requestNo) {
            parameter.requestNoList = field.requestNo.trim().split(",")
        }
        
        if (field.statusIds && field.statusIds.length > 0) {
            parameter.statusIds = field.statusIds
        }

        if (field.cartonLocation) {
            parameter.cartonLocation = field.cartonLocation
        }

        if (field.projectId) {
            parameter.projectId = field.projectId
        }

        if (field.description) {
            parameter.description = field.description
        }

        if (field.destructionTypeId) {
            parameter.destructionTypeId = field.destructionTypeId
        }

        if (field.cartonNoList) {
            parameter.cartonNo = field.cartonNoList
        }

        if (field.cartonNo) {
            parameter.cartonNo = field.cartonNo
        }


    }

  const searchData = async () => {
  
    let field = dataForm.getFieldsValue()
   
      let parameter = InitHelper.InitialParameter();
      parameter.statusIds = [CartonStatus.WaitAdmin, CartonStatus.Inprogress, CartonStatus.Done, CartonStatus.Reject,CartonStatus.Cancel,CartonStatus.Delete]
      fieldParameterMap(field, parameter)
        parameter.page =1
        parameter.limit = appState.perpage
        parameter.requestTypeIds =  [
            RequestType.NewCarton, 
            RequestType.StorageCarton, 
            RequestType.CollectCarton,
            RequestType.ReturnCarton, 
            RequestType.RemoveCarton,  
            RequestType.RemoveCartonStorage, 
            RequestType.DestructionCarton,
            RequestType.ExtendCarton
          ]
    
    if(requestTypeIds.length > 0){
        parameter.requestTypeIds = requestTypeIds
    }
    
    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(requesters.length > 0){
      parameter.requesters = requesters.map(item => item.key)
    }
    
    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

      if (orgunits.length > 0) {
          parameter.orgUnits = orgunits.map(item => item.key)
    }

    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
      parameter.cartonNo = cartonNoStart + '&' + cartonNoEnd
    }

    console.log(parameter)
    setRequestTypeMenu(parameter.requestTypeIds)
    getData(1,appState.perpage,parameter)
  }

  const reset = () => {
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    if(elementStart){
        elementStart.value = ""
    }
    if(elementEnd){
        elementEnd.value = ""
    }

    dataForm.resetFields()
    setRequestTypeId(0)
    setRequestTypeIds(initialRequestTypeIds)
    setOwners([])
    setOrgUnits([])
    setRequesters([])
    setRequestStartDate(null)
    setRequestEndDate(null)

    let parameter = InitHelper.InitialParameter()
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.statusIds = [CartonStatus.WaitAdmin,CartonStatus.Inprogress]

    getData(1,appState.perpage,parameter)
  }

  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }

  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }
  const onFinishPrint = async () => {
    setIsLoadingPrint(true)

    let token = await getToken()

    try{

      await new FileManageService(token).DownloadReport(saveParameter,ExportType.QUERY_DATA);
    }catch(error){
    console.log("🚀 ~ file: QueryData.tsx:464 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)

    }
    }

    const cartonStatusEvaluate = (item: any) => {
        if (item.requestTypeId === 2) {
            return "Storage"
        } else if (item.requestTypeId === RequestType.CollectCarton) {
            if (item.statusId === CartonStatus.Done)
                return "Collect"
            else
                return "Storage"
        } else if (item.requestTypeId === RequestType.RemoveCarton) {
            if (item.statusId === CartonStatus.Done)
                return "Remove"
            else
                return "Storage"
        } else if (item.requestTypeId === RequestType.ReturnCarton) {
            if (item.statusId === CartonStatus.Done)
                return "Storage"
            else
                return "Collect"
        } else if (item.requestTypeId === RequestType.DestructionCarton) {
            if (item.statusId === CartonStatus.Done && item.destructionType === "Destroy") {
                return "Destruction"
            } else {
                return "Storage"
            }
        } else if (item.requestTypeId === RequestType.ExtendCarton) {
            if (item.statusId === CartonStatus.Done && item.destructionType === "Extend") {
                return "Storage"
            } else {
                return "Storage"
            }
        }
    }
    
  return (
    <div id="carton-panel">
{ !display ?
    <>
        <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-none'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-display'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>
        <div id={stickyElement.openSearch} className='carton-search-bottom'>
        <Form
                {...layout}
                form={dataForm}
                name="search-panel"
                onFinish={onFinish}
                validateMessages={validateMessages}
                labelAlign="left"
                colon={false}
                labelWrap={true}
              >
                <Row>
                  <Col md={11} >
                    <Form.Item name="requestType" label="Request Type" initialValue={0} >
                      <Select 
                        placeholder={MessageCommon.TitleSelect} 
                        onChange={onRequestTypeChange}
                        options={ RequestTypeOption }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >
                  <Form.Item name="requester" label="Requester"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                  </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={11} >
                   
                    <Form.Item name="requestNo" label="Request No."  >
                      <Input placeholder="ex. B230001,B230002,B230003" />
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={11} >
                    <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >
                      <Form.Item name="statusIds" label="Status">
                        <Select 
                          mode="multiple"
                          allowClear
                          placeholder={MessageCommon.TitleSelect}
                          options={[
                            { value: CartonStatus.WaitAdmin, label: 'Wait for Admin Service' },
                            { value: CartonStatus.Inprogress, label: 'On Going' },
                            { value: CartonStatus.Done, label: 'Completed Service' },
                            { value: CartonStatus.Reject, label: 'Rejected' },
                            { value: CartonStatus.Cancel, label: 'Cancel' },
                            { value: CartonStatus.Delete, label: 'Delete' }
                          ]}
                        />
                      </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={11} >

                    <Form.Item name="requestDate" label="Request Date">
                      <table className='w100c'>
                        <tbody>
                        <tr>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestStartDateChange}/>
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker placeholder={MessageCommon.TitleDate} format={'DD MMM YYYY'} className='date-picker-full' onChange={onRequestEndDateChange}/>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >
                  {requestTypeId !== RequestType.NewCarton ? 
                        <Form.Item name="cartonLocation" label="Carton Location"  initialValue={''}>
                            <Select 
                           
                                showSearch
                                placeholder="Please select..." 
                                options={warehouseOptions} 
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                   :<></>
                  }
                  </Col>
                </Row>
                
                  <Row>
                    <Col md={11} >
                    {requestTypeId !== RequestType.NewCarton ? 
                      <Form.Item label="Carton No:(Range)" >
                      <table className='w100c'>
                        <tbody>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001' />
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-5'>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001' />
                        
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      </Form.Item>
                      :<></>
                    }
                    </Col>
                    <Col md={2} >
                    </Col>
                    <Col md={11} >
                    {requestTypeId !== RequestType.NewCarton ? 
                    <Form.Item name="projectId" label="Project Name" initialValue={0}>
                        <Select
                            showSearch
                            placeholder={MessageCommon.TitleSelect} 
                            options={projectOptions} 
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                     </Form.Item>
                      :<></>
                          }
                    </Col>
                  </Row>
                
           {requestTypeId !== RequestType.NewCarton ?
                <Row>
                    <Col md={11} >
                      <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                      </Form.Item>
                    </Col>
                    <Col md={2} >
                    </Col>
                    <Col md={11} >
                    <Form.Item name="description" label="Description" >
                        <Input.TextArea rows={5} disabled={requestTypeId === RequestType.NewCarton}/>
                      </Form.Item>
                    </Col>
                </Row>
                :<></>
           }
                {requestTypeId !== RequestType.NewCarton ? 
                  <Row>
                    <Col md={11} >
                      <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001' />
                      </Form.Item>
                    </Col>
                    <Col md={2} >
                    </Col>
                    <Col md={11} >
                      
                   {requestTypeId == RequestType.All || requestTypeId === RequestType.DestructionCarton ?
                  
                                          <Form.Item name="destructionTypeId" label="Destruction Type" initialValue={0}  >
                        <Select placeholder={MessageCommon.TitleSelect} 
                          options={[
                            { value: 0, label: 'All' }, 
                            { value: RequestType.ExtendCarton, label: 'Extend' }, 
                            { value: RequestType.DestructionCarton, label: 'Destroy' }
                          ]}
                        />
                      </Form.Item>:<></>
                  
                    }
                    </Col>
                  </Row>
                  :<></>
          }
                
              
              
             
              </Form>
              <div className='text-center'>
                <Button type="default" className='mr-btn' onClick={() => reset()}  disabled={appState.isLoading}>
                  <AiOutlineSync className='icn-btn' /> Reset
                </Button>
                <Button type="primary" id="search-btn"   onClick={() => searchData()} disabled={appState.isLoading}>
                  <AiOutlineSearch className='icn-btn'/> Search
                </Button>


              </div>

    
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-grey'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-grey'></div>
        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
          
                <div>
                <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
                    <AiOutlinePrinter className='icn-btn' />Print
                </Button>
                { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
             
                </div>
           
              <div className='search-result'>Search found { appState.total } record(s).</div>
        </div>
       
      <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
        <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
            <tr>
                <th className="query-s-th thfix1">Request No.</th>
                <th className="query-s-th">Request Type</th>
                { requestTypeMenu.length == 1 && requestTypeMenu.indexOf(RequestType.NewCarton) > -1 ? <></> :
                <th  className="query-s-th">Carton Status</th>
                }
                <th  className="query-s-th">Requester</th>
                { requestTypeMenu.indexOf(0) > -1 || requestTypeMenu.indexOf(RequestType.StorageCarton) > -1 ?
                <th className="query-s-th">Owner</th>:<></>
                }
              
                <th className="query-s-th">Org. Unit</th>
                
                <th className="query-s-th">User Expected Date</th>
                <th className="query-s-th">Service Date</th>
                <th className="query-s-th">Carton Quantity</th>
                <th className="query-s-th">Destruction Type</th>
                <th className="query-s-th">Status</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                    <td className="query-s-td tdfix1 text-center">
                        <div>
                            {returnIfElse(((item.requestTypeId === RequestType.RemoveCarton ||  item.requestTypeId === RequestType.RemoveCartonStorage || item.requestTypeId === RequestType.DestructionCarton) && item.statusId === CartonStatus.Done)
                                ,
                                <a className='a-table c-red' onClick={() => nextPage(item.recordNo, item.requestTypeId)}>
                                    {item.requestNo}
                                </a>
                                ,
                                <a className='a-table' onClick={() => nextPage(item.recordNo, item.requestTypeId)}>
                                    {item.requestNo}
                                </a>
                            )
                        }
                        </div>
                        {returnIfElse(
                            (item.requestTypeId === RequestType.CollectCarton
                                || item.requestTypeId === RequestType.ReturnCarton
                                || item.requestTypeId === RequestType.DestructionCarton
                                || item.requestTypeId === RequestType.ExtendCarton
                            ) && item.requester !== item.onBehalf
                            ,
                            <div className='c-red'>
                                (On Behalf)
                            </div>
                            ,
                            <></>
                        )
                        }
                    </td>
                    <td className="query-s-td text-center" >{item.requestType}</td>
                    { requestTypeMenu.length == 1 && requestTypeMenu.indexOf(RequestType.NewCarton) > -1 ? <></> :
                    <td className="query-s-td text-center" >{item.cartonStatusInPrcess}</td>
                    }
                    <td className="query-s-td text-left break-word" >{item.requesterName}</td>
                    { requestTypeMenu.indexOf(0) > -1 || requestTypeMenu.indexOf(RequestType.StorageCarton) > -1 ?
                    <td className="query-s-td text-left break-word" >{returnIfElse(item.ownerName, item.ownerName , '' ) }</td>:<></>
                    }
                    
                    <td className="query-s-td text-center" >{returnIfElse(item.orgUnit, item.orgUnit  , '' ) }</td>
                    
                    <td className="query-s-td text-center" >
                        {returnIfElse(
                            item.requestTypeId === RequestType.NewCarton
                            || item.requestTypeId === RequestType.StorageCarton
                            || item.requestTypeId === RequestType.CollectCarton
                            ,
                            <span>
                                {returnIfElse(dayjs(item.receiveDate).isValid(), dayjs(item.receiveDate).format("DD MMM YYYY"), "")}
                            </span>
                            ,
                            <></>
                        )
                        }
                        {returnIfElse(item.requestTypeId === RequestType.ReturnCarton
                            ,
                            <span>
                                {returnIfElse(dayjs(item.returnDate).isValid(), dayjs(item.returnDate).format("DD MMM YYYY"), "")}
                            </span>
                            ,
                            <></>
                        )
                        }
                        {returnIfElse(item.requestTypeId === RequestType.RemoveCarton 
                            ,
                            <span>
                                {returnIfElse(dayjs(item.requestDate).isValid(), dayjs(item.requestDate).format("DD MMM YYYY"), "")}
                            </span>
                            ,
                            <></>
                        )
                        }
                        {returnIfElse(item.requestTypeId === RequestType.RemoveCartonStorage 
                            ,
                            <span>
                                {returnIfElse(dayjs(item.returnDate).isValid(), dayjs(item.returnDate).format("DD MMM YYYY"), "")}
                            </span>
                            ,
                            <></>
                        )
                        }
                        {returnIfElse(
                          
                            item.requestTypeId === RequestType.DestructionCarton
                            || item.requestTypeId === RequestType.ExtendCarton
                            ,
                            <span>
                                {returnIfElse(dayjs(item.requestDate).isValid(), dayjs(item.requestDate).format("DD MMM YYYY"), "")}
                            </span>
                            , 
                            <></>
                        )
                        }
                    </td>
                    <td className="query-s-td text-center" >
                        {returnIfElse(item.serviceDate != null && dayjs(item.serviceDate).isValid(), dayjs(item.serviceDate).format("DD MMM YYYY"), "")}
                  </td>
                  <td className="query-s-td text-center" >{item.quantity}</td>
                    <td className="query-s-td text-center">{returnIfElse(item.destructionType, item.destructionType, '-' ) }</td>
                  <td className="query-s-td text-center" >{item.status}</td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                showSizeChanger={false}
                onChange={onPageChange} 
              />:<></>
        }
        </div>
        
      </div>

      <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
      <input type="hidden" id="query-request-type" defaultValue={requestTypeId}/>
      
    </>
   :
   <>
    { displayTypeId === RequestType.NewCarton ?
          <QueryDataNewCarton id={recordNo} onBack={onDisplayChange}/>:<></>
    }
    { displayTypeId === RequestType.StorageCarton ?
            <QueryDataStorageCarton id={recordNo} onBack={onDisplayChange}/> : <></>
    }
    { displayTypeId === RequestType.CollectCarton ?
        <QueryDataCollectCarton id={recordNo} onBack={onDisplayChange}/>: <></>
    }
    { displayTypeId === RequestType.ReturnCarton ?
            <QueryDataReturnCarton id={recordNo} onBack={onDisplayChange}/>: <></>
    }
    { displayTypeId === RequestType.RemoveCarton ?
            <QueryDataRemoveCarton id={recordNo} onBack={onDisplayChange}/>: <></>
    }
    { displayTypeId === RequestType.RemoveCartonStorage ?
            <QueryDataRemoveCartonStorage id={recordNo} onBack={onDisplayChange}/>: <></>
    }
    { displayTypeId === RequestType.ExtendCarton ||  displayTypeId === RequestType.DestructionCarton ?
         
            <QueryDataDestructionCarton id={recordNo} onBack={onDisplayChange}/>: <></>
    }
    
    </>
}
    
    </div >
  );

}

export default QueryData
