import { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select,Pagination,Spin } from 'antd';
import {  AiOutlinePrinter, AiOutlineSearch, AiOutlineSync, AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import {RequestType, CartonStatus, ExportType } from '../../enum';
import { QueryDataModel ,Parameter ,Item ,Option, User} from '../../models'
import { Helper, InitHelper ,StickyHelper} from '../../helpers';
import { HrWebService,QueryService,PropertyService,WarehouseService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ReportDestructionCartonList = () => {
  const stickyHelper = new StickyHelper('destruct-carton-list',100,50)
  stickyHelper.setMinHight(900)
  const stickyElement = stickyHelper.getElement()
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [destructTypeId,setDestructTypeId] = useState(0)
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [width,setWidth] = useState(0)
  const [saveParamter,setSaveParameter] = useState(InitHelper.InitialParameter())
  const [warehouse, setWarehouse] = useState("")
  const [activeKey,setActiveKey] = useState('0')
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [requesters, setRequesters] = useState<Item[]>([])
  const [owners, setOwners] = useState<Item[]>([])
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [isPrint,setIsprint] = useState(true)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [clientHeight,setClientHeight] = useState(0)

  const onFinish = (values: any) => {
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
 


  const onDestructTypeChange = (_destructTypeId: number) => {
    setDestructTypeId(_destructTypeId);
   
  };

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = Object.assign({},saveParamter)
    if(_parameter !== null){
        parameter = Object.assign({},_parameter);
    }
      parameter.requestTypeIds = [RequestType.DestructionCarton, RequestType.ExtendCarton]
      if (parameter.statusIds.length === 0) {

          parameter.statusIds = [
              CartonStatus.WaitApproval
              , CartonStatus.WaitAdmin
              , CartonStatus.Inprogress
              , CartonStatus.Done
              , CartonStatus.Reject
              , CartonStatus.Cancel
          ]
      }


      parameter.page = currentPage
      parameter.limit = perpage
      parameter.totalCarton = 1
    
      console.log(parameter)
      let token = await getToken()
      try{
        let result = await new QueryService(token).SearchTransaction(parameter)

        console.log(result)
        if(result && result.success)
        {
          let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items
          state.isLoading = false

        if(result.data.total>0){
          setIsprint(false)
        }else{
          setIsprint(true)
        }
          setTimeout(() => {
            setSaveParameter(parameter)
            setAppState(state)
            setTimeout(() => {
              onSetClientHeight()
            }, 100);
          }, 500);
        }
        else{
          let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
        }
      }
      catch(error)
      {
        console.log(error)
        let state = Object.assign({},appState)
          state.isLoading = false
          setAppState(state)
      }
      
      

  }

  const onRequesterSelect = (item:any) => {
    setRequesters(item)
    stickyHelper.updatePosition()
  }
  
  const onOwnerSelect = (item:any) => {
    setOwners(item)
    stickyHelper.updatePosition()
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
    stickyHelper.updatePosition()
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        return result.data
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

    const getWarehouses = async () => {
        let token = await getToken()
    let result = await new WarehouseService(token).GetActiveWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: "" }]
        let Locationoptions:Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
        setWarehouseOptions(options.concat(Locationoptions))
    }
  }
  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

  useEffect(() => {
   
    getWarehouses()
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.cartonLocation = ""

 
    getData(1,appState.perpage,parameter).then(() => {
      stickyHelper.updatePosition()
  
    })

    stickyHelper.removeEvent()
    stickyHelper.addEvent()

        window.addEventListener("resize", () => { 
           onSetClientHeight()
        });
   
  }, [])

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
   
    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }

    const fieldParameterMap = (field: any, parameter: any) => {
        if (field.statusId && field.statusId.length > 0) {
            parameter.statusIds = field.statusId
        }

        if (field.requestNo) {
            parameter.requestNoList = field.requestNo.trim().split(',')
        }

        if (field.cartonNoList) {
            parameter.cartonNo = field.cartonNoList.trim()
        }

        if (field.cartonNo) {
            parameter.cartonNo = field.cartonNo.trim()
        }

    }
  const searchData = async () => {
  
    let field = dataForm.getFieldsValue()
   
      let parameter = InitHelper.InitialParameter();
      fieldParameterMap(field, parameter)
        parameter.page =1
        parameter.limit = appState.perpage
        
       parameter.cartonLocation = warehouse
        
        parameter.destructionTypeId = destructTypeId
       
    if(requesters.length > 0){
      parameter.requesters = requesters.map(item => item.key)
    }
    
    if(owners.length > 0){
      parameter.owners = owners.map(item => item.key)
    }

    if(orgunits.length > 0){
      parameter.orgUnits = orgunits.map(item => item.key)
    }
    
    if(documentStartYear && documentStartYear > 0 )
    {
        parameter.requestStartDate = documentStartYear + "-01-01"
        //parameter.documentStartYear = documentStartYear
    }

    if(documentEndYear && documentEndYear > 0)
    {
        parameter.requestEndDate = documentEndYear + "-12-31"
        //parameter.documentEndYear = documentEndYear
      }


    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    console.log(parameter)
    getData(1,appState.perpage,parameter).then(() => {
      stickyHelper.updatePosition()
  
    })
   
    
  }
  const reset = () => {
    

    dataForm.resetFields()
    setOwners([])
    setOrgUnits([])
    setRequesters([])
      setWarehouse("")
      let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
      let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
      elementStart.value = "";
      elementEnd.value = "";
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.cartonLocation = ""
    
    getData(1,appState.perpage,parameter)
  }

  
  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
  }
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
  }
  const onFinishPrint = async () => {
    setIsLoadingPrint(true)
    let token = await getToken()

    try{

      await new FileManageService(token).DownloadReport(saveParamter,ExportType.DESTRUCTION_CARTON_LIST);
    }catch(error){
     console.log("🚀 ~ file: ReportDestructionCartonList.tsx:406 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)
    }
  }
  
  const onWarehouseChange = (value:string) => {
    setWarehouse(value)
}

const collapseChange = () => {
  let key = activeKey === "1" ? "0" : "1"
  setActiveKey(key)
  let element = document.getElementById("open-search") as HTMLInputElement
  element.value = key
  stickyHelper.updatePosition()
}

  return (

    <div>
        <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
            {isLoadingPrint?<Loading/>:<></>}
            <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
              <Form.Item name="destructionType" label="Destruction Type"  >
                        <Select placeholder={MessageCommon.TitleSelect} 
                            defaultValue={0}
                          onChange={onDestructTypeChange}
                          options={[
                            { value :0 ,label:'All'},
                            { value: RequestType.ExtendCarton, label: 'Extend' }, 
                            { value: RequestType.DestructionCarton, label: 'Destroy' }
                          ]}
                        />
                      </Form.Item>
                  
                    
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="statusId" label="Status"   >
                  <Select placeholder={MessageCommon.TitleSelect} 
                   mode="multiple"
                   allowClear
                    options={[
                      { value: CartonStatus.WaitApproval, label: 'Wait for Approval' },
                      { value: CartonStatus.WaitAdmin, label: "Wait for Admin Service" },
                      { value: CartonStatus.Inprogress, label: 'On Going' },
                      { value: CartonStatus.Done, label: 'Completed Service' },
                      { value: CartonStatus.Reject, label: 'Rejected' },
                      { value:CartonStatus.Cancel, label: 'Cancel' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="requestNo" label="Request No."  >
                  <Input placeholder="ex. A230001,B230002,B230004"></Input>
                </Form.Item>
             

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <Form.Item name="documentyear" label="Year">
                  <table className='w100c'>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          className='w100c' 
                          onChange={onDocumentStartYearChange}
                        />
                      </td>
                      <td>
                        <Label className='margin-left10 margin-right10 vertical-align-top'>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          className='w100c'
                          onChange={onDocumentEndYearChange}
                        />
                      </td>
                    </tr>
                  </table>


                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
              </Col>
            </Row>


            <Row>
              <Col md={11} >
              <Form.Item label="Carton No:(Range)" >
                      <table className='w100c'>
                        <tr>
                          <td>
                            <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-5'>To</Label>
                          </td>
                          <td>
                            <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                        
                          </td>
                        </tr>
                      </table>
                      </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
               <Form.Item name="requester" label="Requester"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={requesters ? requesters : []}
                            onChange={item => onRequesterSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                  </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='ex. 03PCS001,03PCS002,03PCS003'/>
                      </Form.Item>
             
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
                <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                      </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              </Col>
            </Row>
           
          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-grey'></div>

        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
              <div>
                  <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}>
                    <AiOutlinePrinter className='icn-btn' />Print
                  </Button>
              </div>
              <div className='search-result'>Search found {appState.total} record(s).</div>
        </div>
      <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
        <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
        					
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
              <tr>
                <th className="thfix1 text-center w50 font070em">No.</th>
                <th className="s-th thfix2 font070em">Carton No.</th>
                <th className="s-th text-center font070em">Carton Location</th>
                <th className="s-th text-center font070em">Request No.</th>
                <th className="s-th text-center font070em">Request Date</th>
                <th className="s-th text-center font070em">Document Year</th>
                <th className="s-th text-center font070em">Requester</th>
                <th className="s-th text-center font070em">Owner</th>
                <th className="s-th text-center font070em">Org. Unit</th>
                <th className="s-th text-center font070em">Storage Request No.</th>
                <th className="s-th text-center font070em">Storage Request Date</th>
                <th className="s-th text-center font070em">Destruction Due Date</th>
                <th className="s-th text-center font070em">Destruction Date (Destroy)</th>
                <th className="s-th text-center font070em">Destruction Date (Extention)</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel,index) => (
                <tr>
                  <td className="tdfix1 text-center w50 font070em">{((appState.currentPage -1) * appState.perpage) + index + 1} </td>
                  
                    <td className={((item.requestTypeId === RequestType.RemoveCarton || item.requestTypeId === RequestType.RemoveCartonStorage || item.requestTypeId === RequestType.DestructionCarton) && item.statusId === CartonStatus.Done) ? 's-td tdfix2 text-center font070em c-red' : 's-td tdfix2 text-center font070em' }>{item.cartonNo}</td>
                  <td className="s-td text-left break-word font070em">{item.cartonLocation}</td>
                  <td className="s-td text-center font070em">{item.requestNo}</td>
                  <td className="s-td text-center break-word font070em">{ dayjs(item.requestDate).format('DD MMM YYYY') }</td>

                    <td className="s-td text-center break-word font070em">{dayjs(item.documentYear).format('MMM YYYY')}</td>
                  <td className="s-td text-left font070em">{item.requesterName}</td>
                  <td className="s-td text-left font070em">{item.ownerName}</td>
                  <td className="s-td text-center font070em">{item.orgUnit}</td>
                  <td className="s-td text-center font070em">
                    { item.storageRequestNo}
                  </td>
                  <td className="s-td text-center break-word font070em">
                    { item.storageRequestDate ? dayjs(item.storageRequestDate).format('DD MMM YYYY') : ""}
                  </td>
                 
                  <td className="s-td text-center break-word font070em">
                  { item.expireDate ? dayjs(item.expireDate).format('DD MMM YYYY') : ""}
                  </td>

                  <td className="s-td text-center break-word font070em">
                  { item.requestTypeId === RequestType.DestructionCarton && item.statusId == CartonStatus.Done ? dayjs(item.expireDate).format('DD MMM YYYY') : ""}
                  </td>
                  <td className="s-td text-center break-word font070em">
                  { item.requestTypeId === RequestType.ExtendCarton && item.latestExtensionDate ? dayjs(item.latestExtensionDate).format('DD MMM YYYY') : ""}
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                showSizeChanger={true} 
                pageSizeOptions={pageSizeOptions}
                onChange={onPageChange} 
              />:<></>
        }
        </div>
      </div>
      

      <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
      
    </div>
  );

}

export default ReportDestructionCartonList
