import React, {  useEffect, useState } from 'react';
import { Button, Card,  Col, DatePicker, Form, Input, Pagination, Modal,Spin, Popconfirm, Row, message } from 'antd';
import {  AiFillDelete, AiFillSave, AiOutlineCloseCircle, AiOutlineSave ,AiOutlinePlusCircle} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CartonList , RequestRemoveCarton, Item,User} from '../../models';
import {RemoveCartonService, HrWebService,StorageCartonService} from '../../services';
import { CartonStatus, RequestType } from '../../enum';
import { Helper ,InitHelper} from '../../helpers';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import  secureLocalStorage  from  "react-secure-storage";
import { useNavigate } from 'react-router-dom';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import RemoveCartonAdd from "./RemoveCartonAdd"

interface Props{
  removeId:number
  items: string[]
  page:string
  onReload:any
}

interface Error
{
    owner: number,
    place: number,
    receiveDate:number,
}

const RemoveCartonModalStorage = (props: Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }

    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const orgUnit = user.orgUnit ? user.orgUnit : ""
  const position = user.position ? user.position : ""
  const directPhone = user.directPhone ? user.directPhone : ""
  const currentDate = secureLocalStorage.getItem('date')
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [dataForm] = Form.useForm();
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(user.systemConfig.tablePageSize[0]);
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [checkAll, setCheckAll] = useState(false)
  const [modal, contextHolder] = Modal.useModal();
  const [owners, setOwners] = useState<Item[]>([])
  const [isLoading ,setIsLoading] = useState(true)
  const [isAdd,setIsAdd] = useState(false)
  const [error,setError] = useState<Error>({
    owner: 0,
    place: 0,
    receiveDate: 0
  })
  const [ownersBgColor,setOwnersBgColor] = useState("")
  const onFinish = (values: any) => {
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const onOwnerSelect = async (items:any) => {
    console.log(items)
    setOwners(items)
    if(items.length > 0)
    {

      dataForm.setFieldsValue({
        contactBy: items[0].key,
        location: items[0].location,
        place:items[0].location
      });
    } 
    
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        return result.data
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const validate = () => {
    let errorData:Error = {
      owner: 0,
      place: 0,
      receiveDate: 0
    }

    let errorCount = 0
    setOwnersBgColor('')

    if(owners.length === 0)
    {
      setOwnersBgColor('red')
       errorCount++
    }

    if(!dataForm.getFieldValue('place')){
      errorCount++
      errorData.place = 1
    }

    if(!dataForm.getFieldValue('returnDate')){
      errorCount++
      errorData.receiveDate = 1
     
    }

    setError(errorData)

    if(errorCount !== 0){
      let element = document.getElementById('remove-carton-form') as HTMLFormElement
      messageApi.open({
        type: 'error',
        content: 'Please fill required fields',
        duration: 1.5,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      return false
    }
    else{
      return true;
    }
   
  }

const handleSave = async (saveType: boolean) => {
  if(validate())
  {
    let requestCarton:RequestRemoveCarton = {
      recordNo:dataForm.getFieldValue('recordNo'),
      requestTypeId: RequestType.RemoveCartonStorage,
      requester:user.employeeId,
      removeBy:user.employeeId,
      approveBy: user.approverId,
      approveLevel: user.approverLevel,
      contactBy:dataForm.getFieldValue('contactBy'),
      location:dataForm.getFieldValue('location'),
      floor:dataForm.getFieldValue('floor'),
      place:dataForm.getFieldValue('place'),
      returnDate:dataForm.getFieldValue('returnDate').format('YYYY-MM-DD'),
        statusId: returnIfElse(saveType, CartonStatus.WaitApproval, CartonStatus.SaveDraft)  ,
      cartons:allCartonItems
   }
      console.log(requestCarton)
      let token = await getToken()
    let result:any = null
  
    if(requestCarton.recordNo === 0){
      result = await new RemoveCartonService(token).Create(requestCarton);
    }
    else{
      result = await new RemoveCartonService(token).Update(requestCarton);
    }
    console.log(result)
    let element = document.getElementById('remove-carton-form') as HTMLElement
    if(result && result.success){

      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
        handleCancel()

          doIfElse(props.page === 'remove' ,
              function () {

                  doIfElse(saveType ,
                      function () {
                          navigate('/remove-carton-tracking')
                      }
                      ,
                      function () {
                        navigate('/remove-carton-save')
                      }
                  );
              }
              ,
              null
          );

      }, 1000);
    }
    else{
      Modal.error({
          icon: <CloseCircleOutlined />,
          title: 'This is an error message',
          content: result?.message,
      });
    }
  }

};

  const handleDelete = async () => {
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    selectItems.forEach(item => { 
      let index = allCartonItems.findIndex( x => x.cartonNo === item)
      if(index > -1){
        _allCartonItems.splice(index,1)
      }
    })
    console.log(selectItems)
    console.log(_allCartonItems)
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
    setPage(1)
    setSelectItems([])
    onCheckAll(false)
    
  }

  const handleDeleteByItem = async (cartonNo:string) => {
  
    let _allCartonItems:CartonList[] = Object.assign([],allCartonItems)
    console.log(cartonNo)
    let index = allCartonItems.findIndex( item => item.cartonNo === cartonNo)
    console.log(index)
    if(index > -1)
    {
      _allCartonItems.splice(index,1)
      let items = Helper.Chunk(_allCartonItems,perpage)
      setAllCartonItems(_allCartonItems)
      setCartonItems(items)
      setPage(1)
      setSelectItems([])
      onCheckAll(false)
    }
    
    let element = document.getElementById('remove-carton-form') as HTMLElement
      messageApi.open({
        type: 'success',
        content: 'Delete success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
    });
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

  

  const confirmSave = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleSave(true)
    });
  };

  const handleCancel = () => {
    let element = document.getElementsByClassName('ant-modal-close') as HTMLCollection
    let btnClose = element[0] as HTMLButtonElement
    btnClose.click()
  };

  const getRemoveData = async () => {

      let token = await getToken()
      let result = await new RemoveCartonService(token).GetRequest(props.removeId)
      console.log(result)
      if(result && result.success && result.data)
      {
        let data = result.data
        let _allCartonItems = data.cartonList
        if(_allCartonItems.length > 0)
        {
          let items = Helper.Chunk(_allCartonItems,perpage)
          setAllCartonItems(_allCartonItems)
          setCartonItems(items)
          setIsLoading(false)
        }
        
        let owners:Item[] = [{
          key:data.contactPerson,
          location:data.location,
          name: data.contactPersonName,
          floor: "",
          orgUnit:""
        }]
        onOwnerSelect(owners)

        dataForm.setFieldsValue({
          recordNo: props.removeId,
          requester: user.employeeId,
          approver: user.approverId,
          approverName: user.approverName,
          approverLevel: user.approverLevel,
          requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
          location:data.location,
          place: data.place,
          floor:data.floor,
          returnDate:data.returnDate ?  dayjs(data.returnDate) : undefined
        })

        
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
       
      }
      else{
        setIsLoading(false)
      }
     

  }

 
  const getStorageData = async () => {

      let token = await getToken()
  let parameter = InitHelper.InitialParameter();
    parameter.page =1
    parameter.limit = 0
    parameter.cartonNoList = props.items
    parameter.statusIds = [CartonStatus.Done]
    parameter.cartonStatusIds = [CartonStatus.Storage]

  let result = await new StorageCartonService(token).SearchCarton(parameter)
  
  console.log(result)
  if(result && result.success && result.data)
  {
    let data = result.data
    let _allCartonItems = data.items
    if(_allCartonItems.length > 0)
    {
      let items = Helper.Chunk(_allCartonItems,perpage)
      setAllCartonItems(_allCartonItems)
      setCartonItems(items)
      setIsLoading(false)
    }
    
    let date = currentDate? currentDate : new Date()
    dataForm.setFieldsValue({
      recordNo: props.removeId,
      requester: user.employeeId,
      approver: user.approverId,
      approverName: user.approverName,
      approverLevel: user.approverLevel,
      requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
      location:"",
      floor:"",
      place:"",
      returnDate: dayjs(date.toString()).add(3 ,'days')
    })
    
  }
  else{
    setIsLoading(false)
  }
 

}

  useEffect(() => {
    
    if(props.removeId > 0){
        getRemoveData()
    }
    else{
        getStorageData()
    }

   

  }, [])

  const onCheckAll = (checked:boolean) => {
    if(checked){
      setCheckAll(true)
      let _selectItems = allCartonItems.map( x => x.cartonNo)
      setSelectItems(_selectItems)
    }
    else{
      setCheckAll(false)
      setSelectItems([])
    }
  };

  const onCheckChange = (checked:boolean,cartonNo:string) => {
    let checkAllElement = document.getElementById('check-item-all') as HTMLInputElement
    let _selectItems:string[] = Object.assign([],selectItems)
    if(checked){
        _selectItems.push(cartonNo)
        if(_selectItems.length === allCartonItems.length){
          checkAllElement.checked = true
          checkAllElement.indeterminate = false
        }
        else{
          checkAllElement.checked = false
          checkAllElement.indeterminate = true
        }
        
    }
    else{
      let index = _selectItems.indexOf(cartonNo)
      if (index > -1) {
        _selectItems.splice(index, 1);
      }

      if(_selectItems.length === 0){
        checkAllElement.checked = false
        checkAllElement.indeterminate = false
      }
      else{
        checkAllElement.checked = false
        checkAllElement.indeterminate = true
      }
    }

    console.log(_selectItems)
    setSelectItems(_selectItems)
  }

  const onPageChange = (_page:number,_perpage: number) => {
    
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
    else{
      setPage(_page)
    }
  }

  const disabledDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disabledDate(value,date)
  }

  const handleAdd = () =>{
    setIsAdd(true)
  }

  const AddItem = (items:string[]) => {
        console.log(items)
        setIsAdd(false)
        if(items.length > 0)
        {
            getStorageData2(items)
        }
       
  }

  const getStorageData2 = async (items:string[]) => {

    let token = await getToken()
    let parameter = InitHelper.InitialParameter();
  parameter.page =1
  parameter.limit = 0
  parameter.cartonNoList = items
  parameter.statusIds = [CartonStatus.Done]
  parameter.cartonStatusIds = [CartonStatus.Storage]
  
let result = await new StorageCartonService(token).SearchCarton(parameter)

console.log(result)
if(result && result.success && result.data)
{
  let data = result.data
  let storageItems = data.items
  if(storageItems.length > 0)
  {
    let _allCartonItems = storageItems.concat(allCartonItems)
    let items = Helper.Chunk(_allCartonItems,perpage)
    setAllCartonItems(_allCartonItems)
    setCartonItems(items)
    setIsLoading(false)
  }
  
}
else{
  setIsLoading(false)
}


}

  return (

    <div>
          { isAdd ?
            <RemoveCartonAdd  
                requestTypeId={CartonStatus.Storage}
                onSelect={AddItem} 
                items={allCartonItems}
            />
        :
      <Card >
      { isLoading ?
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
           :
          <></>
      }
      <Form
          {...layout}
          form={dataForm}
          name="remove-carton-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >

          <Row>
            <Col md={22} >
              <Form.Item name="requesterName" label="Requester" labelCol={{ md: 3 }}   >
                <Input disabled className='widows-100c' />

              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
   
                <Form.Item name="contactPerson" label="Contact Person" rules={[{ required: true }]} tooltip='Please change name of contact person to pick up carton, if required.' >
                <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                    selectedItems={owners ? owners : []}
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />
                </Form.Item>
             


            </Col>
            <Col md={2} >
            </Col>
            <Col md={11} >
                <Form.Item name="approverName" label="Approver">
                <Input disabled/>
                </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="floor" label="Floor">
              <Input  placeholder={MessageCommon.TitleInput} />
              </Form.Item>

              <Form.Item name="place" label="Place" rules={[{ required: true }]} validateStatus={error.place ? "error" : undefined}>
                <Input.TextArea placeholder='Please specify pick up location...' />
              </Form.Item>



            </Col>
            <Col md={2} ></Col>
            <Col md={11} >

              <Form.Item name="location" label="Location">
              <Input disabled />
              </Form.Item>

              <Form.Item name="returnDate" label="Expected Recive Date" rules={[{ required: true }]} validateStatus={error.receiveDate ? "error" : undefined}>
                <DatePicker placeholder={MessageCommon.TitleDate} format={'DD-MMM-YYYY'} disabledDate={disabledDate}/>
              </Form.Item>

            </Col>

          </Row>
        </Form>

        <div className='margin-right-10 margin-top-15 margin-bottom-5'>

{ selectItems.length > 0 ?
<Popconfirm
    title="Delete the task"
    description="Confirm to delete?"
    icon={<QuestionCircleOutlined className='c-red' />}
     onConfirm = { () => handleDelete() }
  >
   <Button type="primary" className='btn-red mr-btn'>
    <AiFillDelete className='icn-btn' /> Delete
  </Button>
</Popconfirm>
:
<Button type="primary" className='btn-red mr-btn' disabled>
    <AiFillDelete className='icn-btn' /> Delete
  </Button>
}
<Button key={Helper.Uniqid()} type="primary" className='btn-blue mr-btn w100' onClick={handleAdd}>
                <AiOutlinePlusCircle className='icn-btn' />Add
          </Button>
</div>
<br></br>
        <div id="table-container">
       
       <table className='idoc-table'>
         <thead id="head-table-fixed">
           <tr>
             <th className='text-left w50'>
                     <div className="form-check">
                       <input  type="checkbox" id={'check-item-carton-all'} className="form-check-input check-item" onChange={e => onCheckAll(e.target.checked)}/>
                       <label className="form-check-label" htmlFor={'check-item-carton-all'}/>
                     </div>
                   
             </th>
             <th >Carton No.</th>
             <th >Collect Requested No.</th>
             <th >Requested Date</th>
             <th >Owner</th>
             <th >Requester</th>
             <th >Collect By</th>
             <th >Carton Status</th>
             <th>Delete</th>
           </tr>
         </thead>
        
         <tbody >
         { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList) => (
             <tr>
               <td className='text-left w50'>
                     <div className="form-check">
                     <input  
                            type="checkbox" 
                            checked={checkAll || selectItems.indexOf(item.cartonNo) > -1}
                            id={'check-item-carton-' + item.cartonNo} 
                            value={item.cartonNo} 
                            className="form-check-input check-item" 
                            onChange={ e => onCheckChange(e.target.checked,item.cartonNo)}
                          />
                       <label className="form-check-label" htmlFor={'check-item-carton-' + item.cartonNo}/>
                     </div>
                   </td>
                   <td className='text-center w120'>{item.cartonNo}</td>
                  <td className='text-center'>{item.requestTypeId === RequestType.CollectCarton ? item.collectRequestNo : item.requestNo}</td>
                  <td className='text-center'>{item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : "" }</td>
                  <td className='text-center'>{item.ownerName}</td>
                  <td className='text-center'>{item.requesterName }</td>
                  <td className='text-center'>{item.requestTypeId === RequestType.CollectCarton ? item.collectByName : "-" }</td>
                  <td className='text-center'>{item.cartonStatus }</td>
                  <td className='text-center'>
                      <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined className='c-red' />}
                          onConfirm = { () => handleDeleteByItem(item.cartonNo) }
      
                        >
                          <AiFillDelete size={20} color="red" className='pointer'/>
                        </Popconfirm>
                  </td>
             </tr>
           ))
         }
         
         </tbody>

       </table>
     
       </div>
       <div className='margin-top-10 text-right'>
       { allCartonItems.length > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
       }
       </div>


        <div className='text-center'>
          <Button type="default" className='mr-btn' onClick={handleCancel}  >
            <AiOutlineCloseCircle className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn' onClick={() => handleSave(false)} disabled={cartonItems.length === 0 ? true : false}>
            <AiOutlineSave className='icn-btn' />Save Draft
          </Button>
          <Button type="primary" onClick={confirmSave} disabled={cartonItems.length === 0 ? true : false}>
            <AiFillSave className='icn-btn' />Submit
          </Button>
        </div>



      </Card>
}
      {contextHolder}
      {contextHolderMessage}
    </div>
  );

}

export default RemoveCartonModalStorage
