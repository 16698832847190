import { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ApproveStorageCartonRequest from './ApproveStorageCartonRequest'
import ApproveRemoveCartonRequest from './ApproveRemoveCartonRequest'
import ApproveDestructionCartonRequest from './ApproveDestructionCartonRequest'
import { useNavigate } from 'react-router-dom';

const Approval = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tid:string| null| undefined = urlParams.get('t')
    const tab = tid ? tid : "storage"
    const storageTab = { key: 'storage', label: `Storage Request`,children: <ApproveStorageCartonRequest/>}
    const removeTab =   { key: 'remove',label: `Remove Request`,children: <ApproveRemoveCartonRequest/>}
    const destructionTab =   { key: 'destruction',label: `Destruction Request`,children: <ApproveDestructionCartonRequest/>}
    const itemsTab = [storageTab,removeTab,destructionTab]

    useEffect(() => {
       
    }, [])

    
  return (

    <div>
      <Card >
        <Tabs defaultActiveKey={tab} items={itemsTab}  destroyInactiveTabPane/>
      </Card>
    
    </div>
  );

}

export default Approval
