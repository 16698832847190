import axios from 'axios'
import { config } from '../config'
import { DataObjectResponse } from '../models/PageResponse'
import TermsCondition from '../models/TermsCondition'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class TermsConditionService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
    }

    async getTermsCondition(){
        let options = {
            headers: this.headers,
           
        }
        console.log(options)
        
        const { data }  = await axios.get<DataObjectResponse<TermsCondition[]>>(`${apiUrl}/v1/termconditions/all`, options )
        
        return data;
    }

    async getTermsConditionById(id:number){
        let options = {
            headers: this.headers,
           
        }
        console.log(options)
        const { data }  = await axios.get<DataObjectResponse<TermsCondition>>(`${apiUrl}/v1/termconditions/term?id=${id}`, options )
        
        return data;
    }

    async updateTermsCondition(body: TermsCondition) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<TermsCondition[]>>(`${apiUrl}/v1/termconditions/update`,body, options )
        
        return data;
    }
}