import axios from 'axios'
import { DataArrayResponse } from '../models/PageResponse'
import BarCodeItem  from '../models/BarCodeItem'

import { config } from '../config'


const apiUrl = config.API_URL

interface FileResponse {
    success: boolean
    data: string,
    message: string | null
}

export default class PrintService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    protected headerFile = {}
    

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }

    }

    async Print(selectItems:string[]){
        if(!this.token) return null

        let options = {
            headers:this.headers
        }

        const { data }  = await axios.get<DataArrayResponse<BarCodeItem>>(`${apiUrl}/v1/print/barcode?item=` + selectItems.join(','), options )

        return data;
    }

}