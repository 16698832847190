import { useEffect, useState } from 'react';
import { Collapse, Form,Row,Spin,Card,Col,Input,Pagination,Button,DatePicker } from 'antd';
import { Label } from 'reactstrap';
import { CartonStatus, ExportType } from '../../enum';
import { AiOutlineSync, AiOutlineSearch, AiOutlinePrinter,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item} from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { HrWebService,PropertyService,ChangeOrgUnitService, FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { SelectPagination } from '../../components/SelectPagination';
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    orgUnits:string[]
    isLoading:boolean
  }

const AutoChange = () => {
    const stickyHelper = new StickyHelper('auto-change',50,0)
    const stickyElement = stickyHelper.getElement()

    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      orgUnits:[],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [searchOwners, setSearchOwners] = useState<Item[]>([])
    const [searchPrevOrgunits, setSearchPrevOrgUnits] = useState<Item[]>([])
    const [searchNewOrgunits, setSearchNewOrgUnits] = useState<Item[]>([])
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [isPrint,setIsPrint] = useState(false)
    const [isLoading,setIsloading] = useState(false)
    const [clientHeight,setClientHeight] = useState(0)

    const onSetClientHeight = () => {
    
        setTimeout(() => {
          stickyHelper.updatePosition()
          window.scrollTo(0,0)
          let element = document.getElementById(stickyElement.container) as HTMLInputElement
          if(element){
            let value = element.clientHeight
            setClientHeight(value)
          }
        }, 100);
      
      }

    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }

        getData(1,appState.perpage,InitHelper.InitialParameter())

        stickyHelper.removeEvent()
        stickyHelper.addEvent()
        
        window.addEventListener("resize", () => { 
            onSetClientHeight()
        });

    }, [])
    
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
     
    
    
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.Done]
        parameter.system = 1
        
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).SearchRequest(parameter)
        
       
        if(result && result.success)
        {

            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items =result.data.items
              state.isLoading = false
              state.perpage = perpage
            
            if(result.data.total>0){
              setIsPrint(false)
            }else{
              setIsPrint(true)
            }
            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
                setTimeout(() => {
                    onSetClientHeight()
                  }, 100);
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
        
        let field = dataForm.getFieldsValue()
        
        let parameter = InitHelper.InitialParameter();
           
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }
        
        if(searchOwners.length > 0){
            parameter.owners = searchOwners.map(item => item.key)
        }
    
        if(searchPrevOrgunits.length > 0){
            parameter.orgUnits = searchPrevOrgunits.map(item => item.key)
        }

        if(searchNewOrgunits.length > 0){
            parameter.newOrgUnits = searchNewOrgunits.map(item => item.key)
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
    
       
        getData(1,appState.perpage,parameter).then(() => {
            stickyHelper.updatePosition()
            
        }) 
        
    }

    const reset = () => {
      
        dataForm.resetFields()
        setSearchOwners([])
        setSearchPrevOrgUnits([])
        setSearchNewOrgUnits([])
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    
    
    const onPageChange = (page:number,perpage:number) => {
      
        if(perpage !== appState.perpage){
            getData(1,perpage)
        }
        else{
            getData(page,appState.perpage)
        }
    }


    const onSearchOwnerChange = (item:any) => {
        setSearchOwners(item)
        stickyHelper.updatePosition()
    }


    const onSearchPrevOrgunitChange = (value:any) => {
        setSearchPrevOrgUnits(value)
        stickyHelper.updatePosition()
    }

    const onSearchNewOrgunitChange = (value:any) => {
        setSearchNewOrgUnits(value)
        stickyHelper.updatePosition()
     }
 

    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }
    const onFinishPrint = async () => {
      setIsloading(true)
      let token = await getToken()
      if(!saveParameter)return

      try{

          await new FileManageService(token).DownloadReport(saveParameter,ExportType.AUTO_CHANGE_ORG_UNIT);
      }catch(error){
          console.log("🚀 ~ file: ManualUpdate.tsx:392 ~ onFinishPrint ~ error:", error)

      }finally{
        setIsloading(false)
      }
  }


    return (
    <div>
         <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
              {...layout}
              form={dataForm}
              name="change-owner-form"
              validateMessages={validateMessages}
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="System Change Date">
                      <table className='w100c'>
                        <tr>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate} 
                                className='date-picker-full' 
                                onChange={onRequestStartDateChange}
                            />
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate} 
                                className='date-picker-full' 
                                onChange={onRequestEndDateChange}
                            />
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Storage Request No."  >
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Owner'
                            selectedItems={searchOwners ? searchOwners : []}
                            onChange={item => onSearchOwnerChange(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                <Form.Item name="orgUnit" label="Previous Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Previous Org. Unit'
                            selectedItems={searchPrevOrgunits ? searchPrevOrgunits : []}
                            onChange={item => onSearchPrevOrgunitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="newOrgUnit" label="New Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by New Org. Unit'
                            selectedItems={searchNewOrgunits ? searchNewOrgunits : []}
                            onChange={item => onSearchNewOrgunitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                </Col>
              </Row>
      
                </Form>
                <div className='text-center'>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>

        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
            <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()} >
                <AiOutlinePrinter className='icn-btn' />Print
            </Button>
               { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                    
                  <div className='search-result'>Search found { appState.total } record(s).</div>
                 
        </div>

        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
            <div id={stickyElement.container} className='margin-top-20'>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                    <thead id={stickyElement.data}>
                        <tr>
                            <th className="s-th thfix1">Storage Request No.</th>
                            <th className="s-th">Owner</th>
                            <th className="s-th">Previous Org. Unit</th>
                            <th className="s-th">New Org. Unit</th>
                            <th className="s-th">System Change Date</th>
                        </tr>
                    </thead>
       
                    <tbody >
                        { appState.items.map((item:ChangeOwner) => (
                            <tr>
                                <td  className="tdfix1 text-center">{item.requestNo }</td>
                                <td className="s-td text-left-20">{item.ownerName}</td>
                                <td className="s-td text-center">{item.orgUnit}</td>
                                <td className="s-td text-center">{item.newOrgUnit}</td>
                                <td className="s-td text-center">
                                    {dayjs(item.requestDate).format('DD MMM YYYY')}
                                </td>
                              
                            </tr>
                        ))
                        }
        
                    </tbody>

                </table>
                }
                </div>
                <div className='margin-top-10 text-right'>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange} 
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        
        </div>
    
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
            
    </div>
    );
  
  }
  
  export default AutoChange
  