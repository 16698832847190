import axios from 'axios'
import { PageDataResponse ,PageResponse,DataObjectResponse, DataArrayResponse } from '../models/PageResponse'
import { Parameter,QueryDataModel,RequestDestroyCarton,RequestDetail , 
    RequestUpdateStatus ,UpdateDestroy ,DataNote,RequestUpdateAdminStatus,CartonList, UpdateApproval} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class DestructionCartonService {
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token: string | null) {
        if (token) {
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }

    }

    async GetRequest(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestDestroyCarton>>(`${apiUrl}/v1/destruction/request?id=${id}`,{}, options )
        
        return data;
    }

    async GetDetail(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestDetail>>(`${apiUrl}/v1/destruction/detail?id=${id}`,{}, options )
        
        return data;
    }

    async GetCartonList(id:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<CartonList>>(`${apiUrl}/v1/destruction/cartonlist?id=${id}`, options )
        
        return data;
    }

    async GetNote(id: number) {

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<DataNote>>(`${apiUrl}/v1/destruction/note?id=${id}`, options )
        
        return data;
    }

    async Search(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/destruction/search`,JSON.stringify(parameter),options )
        return data;
    }

    async Searchdraft(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/destruction/searchdraft`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchApprover(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/destruction/search/approver`,JSON.stringify(parameter),options )
        return data;
    }

    async Create(request: RequestDestroyCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/create`,JSON.stringify(request),options )
        return data;
    }


    async Update(request: RequestDestroyCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/update`,JSON.stringify(request),options )
        return data;
    }

    async UpdateAdmin(request: UpdateDestroy) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/admin/update`,JSON.stringify(request),options )
        return data;
    }
    async UpdateApproval(request: UpdateApproval) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/approval/update`,JSON.stringify(request),options )
        return data;
    }

    async UpdateAdminStatus(requestUpdateStatus: RequestUpdateAdminStatus) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/admin/status/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }


    async UpdateStatus(requestUpdateStatus: RequestUpdateStatus) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/status/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }



    async DeleteCarton(ids: number[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/destruction/deletecarton`,JSON.stringify(ids),options )
        return data;
    }

}