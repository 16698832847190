import React, {  useEffect, useState } from 'react';
import {  Card,  Typography,  Input,  Modal, Popconfirm, Select,  message, Spin } from 'antd';
import { AiFillEdit } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import TermsConditionService from '../../services/TermsConditionService';
import TermsCondition from '../../models/TermsCondition';
import moment from 'moment';
import { ExclamationCircleOutlined ,CloseCircleOutlined} from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { Helper, StickyHelper } from '../../helpers';
import { User } from '../../models';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";


const TermsAndConditions = () => {
  const stickyHelper = new StickyHelper("term-and-condition",50,0)
  stickyHelper.setElementTop('data',70)
  const stickyElement = stickyHelper.getElement()
    const { instance, accounts } = useMsal();
  const [modal, contextHolder] = Modal.useModal();
  const [editId,setEditId] = useState(0)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [errorMessage, setErrorMessage] = useState('');
  const [items,setItems]= useState<TermsCondition[]>([])
  const [isLoad,setIsLoad] = useState(true)
  const [clientHeight,setClientHeight] = useState(0)
  const getToken = async () => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if (acc) {
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}

  const getTersCondition = async () =>{
    let token = await getToken()
    const {data} = await new TermsConditionService(token).getTermsCondition()
    console.log(data)
    setItems(data)
    setIsLoad(false)
    setTimeout(() => {
      onSetClientHeight()
    }, 300);
}

  const onConfirmUpdate = (record:TermsCondition) => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => updateTermById(record)
    });
    
  };

  

  const cancel = () => {
    setEditId(0)
  };

  
  const onEdit = (requestTypeId:number) => {
      setEditId(requestTypeId)
  };



  const updateTermById =async (record:TermsCondition) => {
    let body = Object.assign({},record)
    let information =  getInformation()
    
    if(information){
      body.information = getInformation()
    }
    else{
        setErrorMessage('Information Detail is required!')
        return
    }

    body.active = getActive()
    body.updateBy = user.employeeId
    
    console.log(body)
    let token = await getToken()
      const res = await new  TermsConditionService(token).updateTermsCondition(body)
     if(res && res.success)
     {
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
      setEditId(0);
      getTersCondition()
    
    document.body.style.overflowY = "auto"
   
     }
    else{
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.message,
      });
    } 

  }

  const getInformation = () => {
      let element = document.getElementById('text-information') as HTMLTextAreaElement
      return element ? element.value : ""
    
  }

  const getActive = () => {
    let element = document.getElementById('select-information') as HTMLSelectElement
    return element ? parseInt(element.value) : 0
  
}


  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

  useEffect(() => {
    const onInit = async () =>{
      await getTersCondition()
      stickyHelper.updatePosition()
    }
    onInit()

    stickyHelper.removeEvent()
    stickyHelper.addEvent()
    
    window.addEventListener("resize", () => { 
      onSetClientHeight()
  });
  }, [])
  
  return (
    <div>

     

        <br></br>
        <Card >
      
          {isLoad?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
            :
            <>
              <div id={stickyElement.dataFixed}>
            <div id={stickyElement.container} >
        {isLoad?
              <div className='margin-auto padding-bottom-30'>
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              </div>
              :
            
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
              <tr>
                <th className="thfix1 w50c">Information Detail</th>
                <th className="s-th">Request Type</th>
                <th className="s-th">Updated Date</th>
                <th className="s-th">Updated By</th>
                <th className="s-th">Status</th>
                <th className="s-th">Edit</th>
              </tr>
            </thead>
            <tbody >
      
            { items.map((item:TermsCondition) => (
                <tr key={Helper.Uniqid()}>
                  <td className="tdfix1 text-left w50c break-word">
                    { editId === item.requestTypeId ?
                        <> 
                        <Input.TextArea id="text-information" placeholder={MessageCommon.TitleInput} className='min-height-150' defaultValue={item.information}>
                             
                        </Input.TextArea>
                        {errorMessage && <div className='c-red'>{errorMessage}</div>}
            
                      </>
                      :
                      <span>{item.information}</span>
                    
                    }
                   
                  </td>      
                  <td className="s-td text-center">{ item.requestType }</td>
                  <td className="s-td text-center">{ moment(item.updateDateTime).format("DD-MMM-YYYY")}</td>
                  <td className="s-td text-left break-word">{ item.updateBy }</td>
                  <td className="s-td text-center">
                    { editId === item.requestTypeId ?
                        <select id="select-information" className='select-term'>
                          <option value="1" selected={item.active===1}>Active</option>
                          <option value="0" selected={item.active===0}>Inactive</option>
                        </select>
                        :
                        <span>{item.active===1 ? "Active" : "Inactive" }</span>
                    
                    }


                  </td>

                  <td className="s-td text-center">
                    { editId === item.requestTypeId ?
                      <>
                        <Typography.Link className='margin-right-8' onClick={()=>onConfirmUpdate(item)} disabled={errorMessage.length>0?true:false}>
                           Update
                        </Typography.Link>
                        <Popconfirm title="Confirm to cancel?" onConfirm={cancel}>
                          <a>Cancel</a>
                        </Popconfirm>
          
                      </>
                      :
                      <AiFillEdit className='icon-table' onClick={() => onEdit(item.requestTypeId)}></AiFillEdit>
                    }
                  </td>
                </tr>
              ))
            }
            
            </tbody>
          </table>
}
        </div>
      <div className='height-100'></div>
      </div>
           
              </>
          }
          </Card>
          <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
          
      {contextHolder}
      {contextHolderMessage}


    </div >
  );

}

export default TermsAndConditions
