import { useEffect, useState } from 'react';
import { Button, Card, Spin, Col, DatePicker, Form, Input,message, InputNumber, Modal, Popconfirm, Radio, Row } from 'antd';
import {  AiFillSave, AiOutlineArrowLeft, AiOutlineCheck, AiOutlineClose,AiOutlineMessage,AiOutlinePrinter  } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import dayjs from 'dayjs';
import { Label } from 'reactstrap';
import { QuestionCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FileManageService, NewCartonService } from '../../services';
import { RequestDetail, UpdateNewCarton ,User} from '../../models';
import { Helper,InitHelper } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { CartonStatus, RequestType } from '../../enum';
import  secureLocalStorage  from  "react-secure-storage";
import DisplayLogNote from '../Share/DisplayLogNote';
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
    id:string
    onBack:any
}

interface DataField
{
  assignQuantity: number | undefined,
  serviceDate: any | undefined,
  note:string | undefined
}

const QueryDataNewCarton = (props:Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const pid:string| null| undefined = urlParams.get('id')
  const recordNo = props.id ? props.id : pid
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const [printForm] = Form.useForm();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(true)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [assignQuantityNumber,setAssignQuantityNumber] = useState(0)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [isErrorNote,setIsErrorNote] = useState(false)
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
 
  const prevStep = () => {
    props.onBack(false)
}
  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };

  const showPrintModal = () => {
    setIsPrintModalOpen(true);
  };
  
  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };
  
  const accept = async (statusId: number) => {
    let assignQuantity = dataForm.getFieldValue('assignQuantity') !== undefined ? dataForm.getFieldValue('assignQuantity') : 0
    let noteElement = document.getElementById('note') as HTMLInputElement
    let note = ""
    if(noteElement && noteElement.value){
        note = noteElement.value
    }
    
    let requestNewCarton :UpdateNewCarton = {
      recordNo: detail.recordNo,
      assignQuantity: assignQuantity,
      serviceDate: dataForm.getFieldValue('serviceDate') !== undefined ? dataForm.getFieldValue('serviceDate').format('YYYY-MM-DD') : null,
      note: note,
      noteReject: "",
      statusId:statusId,
      updateBy:user.employeeId,
      updateDate: new Date()
    }
    console.log(requestNewCarton)
    let token = await getToken()
    let result = await new NewCartonService(token).UpdateAdmin(requestNewCarton);
    
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        setTimeout(() => {
          if(statusId !== CartonStatus.Done && statusId !== CartonStatus.Reject ){
            getData()
          }
          else{
            props.onBack(true)
          }
        }, 1000);
        
       

    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const update = async (statusId: number,noteReject:string| null = null) => {
    let assignQuantity = dataForm.getFieldValue('assignQuantity') !== undefined ? dataForm.getFieldValue('assignQuantity') : 0
    let noteElement = document.getElementById('note') as HTMLInputElement
    let note = ""
    if(noteElement && noteElement.value){
        note = noteElement.value
    }
    
    if(statusId !== CartonStatus.Reject && assignQuantityNumber > 0 && note === ""){
        let element = document.getElementById('action-panel') as HTMLDivElement
        messageApi.open({
            type: 'error',
            content: 'Please fill note',
            duration: 1.5,
            style: { marginTop: element.getBoundingClientRect().top - 100 , marginLeft: element.getBoundingClientRect().left - 100  }
      });
      return false
    }

    let requestNewCarton :UpdateNewCarton = {
      recordNo: detail.recordNo,
      assignQuantity: assignQuantity,
      serviceDate: dataForm.getFieldValue('serviceDate') !== undefined ? dataForm.getFieldValue('serviceDate').format('YYYY-MM-DD') : null,
      note: note,
      statusId:statusId,
      noteReject:noteReject ? noteReject : "",
      updateBy:user.employeeId,
      updateDate: new Date()
    }
    console.log(requestNewCarton)
    let token = await getToken()
    let result = await new NewCartonService(token).UpdateAdmin(requestNewCarton);
    
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        setTimeout(() => {
          if(statusId !== CartonStatus.Done && statusId !== CartonStatus.Reject ){
            getData()
          }
          else{
            props.onBack(true)
          }
        }, 1000);
        
       

    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)

    let token = await getToken()
    try{

      await new FileManageService(token).DownloadNewBox(detail,values.print);
    }catch(error){
    console.log("🚀 ~ file: QueryDataNewCarton.tsx:191 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)

    }

  }

  useEffect(() => {
    if(user.permissions.queryData === 0){
        navigate('/permissiondenided')
    }
   getData()
  }, [])

  const getData = async () => {
    console.log('data')
    if(recordNo){
      let token = await getToken()
      let result = await new NewCartonService(token).GetDetail(parseFloat(recordNo))
      console.log(result)
      if(result && result.success){
        setDetail(result.data)
        let _assignQuantityNumber = result.data.quantity - result.data.assignDelivery
        setAssignQuantityNumber(_assignQuantityNumber)
        let dataField:DataField = {
          assignQuantity: undefined,
          serviceDate: result.data.serviceDate ? dayjs(result.data.serviceDate) : dayjs(result.data.receiveDate),
          note: undefined
        }
        dataForm.setFieldsValue(dataField);
      }
      setIsLoading(false)
      

    }
   
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const disabledDate = (current:any) => {
    return current && current.valueOf() < Date.now();
  }

  const onAssignQuantityChange = (value:any) => {
    let assignQuantityNumber = detail.outstandingQuantity - value
    setAssignQuantityNumber(assignQuantityNumber)
  }

  const handleRejectOpen = () => {
    setIsErrorNote(false)
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let element = document.getElementById('note-reject') as HTMLTextAreaElement
        element.value = ""
    }, 100);
   
 }

 const handleReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
    if(element && element.value){
      update(CartonStatus.Reject,element.value)
    }
    else{
      setIsErrorNote(true)
      return false
    }
    
  }

  const handleCancelReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
     element.value = ""
     setIsModalRejectOpen(false)
  }

  return (

    <div>
      { isLoading ?
         <Spin tip="Loading" size="large">
            <div className="content" />
        </Spin>
        :
      <Card title="New Carton Detail">
                  {returnIfElse(isLoadingPrint, <Loading />, <></>  )}
        <Form
          {...layout}
          form={dataForm}
          name="new-carton-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row className='margin-bottom-10'>
            <Col md={22} >
               
                <Label className='mr-label margin-left-0'>Requester</Label>
                <Label className='label-det'>{ detail.requesterName}</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit}</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position}</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel}</Label>
             
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="requestNo" label="Request No"  >
                <Label className='label-det'>{detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                <Form.Item name="status" label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="deliveryTo" label="Deliver to.."  >
                <Label className='label-det'>{detail.ownerName}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            
              <Form.Item name="location" label="Location"  >
                <Label className='label-det'>{detail.location}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="floor" label="Floor"  >
                <Label className='label-det'>{detail.floor}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="place" label="Place"  >
                <Label className='label-det'>{detail.place}</Label>
              </Form.Item>
            </Col>
          </Row>
         
          <Row>
            <Col md={11} >
              <Form.Item name="quantity" label="Carton Quantity"  >
                <Label className='label-det'>{detail.quantity}</Label>
              </Form.Item>
              <Form.Item name="assignDelivery" label="Assign Delivery"  >
                <Label className='label-det'>{ detail.assignDelivery }</Label>
              </Form.Item>
              <Form.Item name="outstandingQuantity" label="Outstanding Quantity"  >
                <Label className='label-det'>{ assignQuantityNumber }</Label>
              </Form.Item>
              <Form.Item name="assignQuantity" label="Assign Quantity"  >
                                  {returnIfElse(detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress
                                      ,
                                      <InputNumber 
                                        defaultValue={0} 
                                        placeholder={MessageCommon.TitleInput} 
                                        max={detail.outstandingQuantity} 
                                        onChange={onAssignQuantityChange} 
                                        disabled={detail.outstandingQuantity == 0}
                                     />
                                      ,  
                                      <></>
                                  )

              }
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
           
              <Form.Item name="requestDate" label="Request Date"  >
                <Label className='label-det'>{ dayjs(detail.requestDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>
              <Form.Item name="receiveDate" label="Expected Receive Date"  >
                <Label className='label-det'>{ dayjs(detail.receiveDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>
              <Form.Item name="serviceDate" label="Service Date"  >
                                  {returnIfElse(detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress
                                      ,
                                      <DatePicker placeholder={MessageCommon.TitleDate} disabledDate={disabledDate} format={'DD MMM YYYY'} defaultValue={dayjs(detail.receiveDate)} />
                                      ,
                                      <Label className='label-det'>{returnIfElse(detail.serviceDate, dayjs(detail.serviceDate).format('DD MMM YYYY'), ""  ) }</Label>
                                  ) 
                                  }
                              </Form.Item>

              
            { detail.statusId === CartonStatus.Reject ?
                 <Form.Item name="noteReject" label="Rejected Reason"  >
                        <Label className='label-det'>{detail.notApproveReason}</Label>
                    </Form.Item>:<></>
            }
            
              
                                  {returnIfElse(detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress
                                      ,
                                      <Form.Item name="note" label="Note"  >
                                      <Input.TextArea id="note" placeholder={MessageCommon.TitleInput} />
                                      </Form.Item>
                                      ,
                                      <></>
                                  )
              }
              
            </Col>
          </Row>
   

          <Row>
            <Col md={11} >
              <Form.Item name="lastNote" label="Lasted Note"  >
                                  {returnIfElse(detail.note.length > 0
                                      , 
                                      <>
                                          <Label className='label-det'>{detail.note[detail.note.length - 1]}</Label>
                                          <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                                      </>
                                      ,  
                                      <></>
                                  )
                }

              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              
            </Col>
          </Row>


        </Form>
        <br></br>
        <div id="action-panel" className='text-center'>
        { detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress ?
        <>
          <Button type="primary" htmlType="button"  className='mr-btn' onClick={() => accept(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.Inprogress || isLoading}>
            <AiFillSave className='icn-btn' />Accept
          </Button>
          <Button type="primary" htmlType="button"   className='mr-btn' onClick={() => update(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.WaitAdmin || (detail.statusId === CartonStatus.Inprogress && assignQuantityNumber === 0) || isLoading}>
            <AiOutlineMessage className='icn-btn' />Update
          </Button>

          <Popconfirm
            title="Close the task"
            description="Confirm to close?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => update(CartonStatus.Done) }
          >
           <Button type="primary" htmlType="button" className='mr-btn' disabled={ detail.statusId === CartonStatus.WaitAdmin || assignQuantityNumber > 0 || isLoading}>
            <AiFillSave className='icn-btn' />Close Job
            </Button>
          </Popconfirm>

          <Button type="primary" htmlType="button" className='mr-btn' disabled={isLoading} onClick={() => handleRejectOpen()}>
                <AiOutlineClose className='icn-btn' />Reject
              </Button>
      </>:<></>    
      }   
          <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal} disabled={isLoading}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
      
          <Button type="primary" htmlType="button" onClick={prevStep} className='mr-btn' disabled={isLoading}>
            <AiOutlineArrowLeft className='icn-btn' />Back
          </Button>
        </div>
      </Card>
      }

      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            validateMessages={validateMessages}
            initialValues={{'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.NewCarton}/>
          </Modal>

          <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
            <textarea className='text-confirm' id="note-reject" rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
          {modal ? <span></span> : <></>}
      {contextHolder}
      {contextHolderMessage}
    </div>
  );

}

export default QueryDataNewCarton
