import React, { useState } from 'react';
import { Button, Divider, Input, Select, Space } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import secureLocalStorage from 'react-secure-storage';

interface Option {
  value: number;
  label: number;
}

interface SelectPaginationProps {
  onSelectChange: (currentPage:number,perpage:number ) => void; 
  currentPage:number
  defaultValue:number
}

export const SelectPagination: React.FC<SelectPaginationProps> = ({ onSelectChange,currentPage,defaultValue }) => {
  const pagination = secureLocalStorage.getItem('pagination');
  const option: Option[] = pagination !== null ? JSON.parse(pagination.toString()) : null;

  const [options, setOptions] = useState<Option[]>(option);
  const [val, setVal] = useState<number | undefined>();
  const [isDisable,setIsDisable] = useState(true)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  

    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];
    const allowedKeysFirst = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];
    if (!allowedKeys.includes(e.key) ) {
      e.preventDefault();
    }

    if ((val===undefined || val<1) && !allowedKeysFirst.includes(e.key)) {
      e.preventDefault();
    }

  };

  const addItem = () => {
    if (!options) return;
    const newOption: Option = { value: val || 0, label: val || 0 };
    const updatedOptions: Option[] = [...options, newOption];
    setOptions(updatedOptions);
    setVal(undefined);
    secureLocalStorage.setItem('pagination', JSON.stringify(updatedOptions));

    setIsDisable(true)
  };

  const onValChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>1000){
      event.preventDefault();
      return
    }
    if(Number(event.target.value)===0){
      setVal(undefined);
      setIsDisable(true)
    }else{
      setVal(Number(event.target.value));
      setIsDisable(false)
    }
  };
  const handleChange = (value: number) => {
    onSelectChange(currentPage,value);
  };
  const sortedOptions = [...options].sort((a, b) => a.value - b.value);
  return (
    <Select
      className='w150'
      defaultValue={defaultValue}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <>
          <Space className='space-padding'>
            <Input
              type="text"
              value={val}
              onChange={onValChange}
              onKeyDown={handleKeyPress}
            />
            <Button type="primary"  onClick={addItem} disabled={isDisable}>
              <AiOutlinePlus className="icn-btn" />
            </Button>
          </Space>

          <Divider className='divider-padding' />
          {menu}
        </>
      )}
      options={sortedOptions}
    />
  );
};
