import React, {  useEffect, useRef, useState } from 'react';
import { Button, Card,  Col, Collapse, DatePicker, Form, Radio,  Modal,  Row, Select } from 'antd';
import {  AiOutlineClose, AiOutlineCheck,  AiOutlinePrinter,  AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import dayjs from 'dayjs';
import { Label } from 'reactstrap';
import SummaryReport from '../../models/SummaryReport';
import SummaryDestruction from '../../models/SummaryDestruction';
import SummaryYear from '../../models/SummaryYear';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { InitHelper ,Helper} from '../../helpers';
import {RequestType, CartonStatus } from '../../enum';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { Option , Item,Parameter} from '../../models'
import { QueryService, PropertyService, WarehouseService, FileManageService } from '../../services';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import Loading from '../../components/Loading';

interface ICounterProps { }
interface ICounterState {
  currentCount: number;
}
interface DataType {
  key: string;
  request_no: string;
  request_date: string;
  expecrted_receive_date: string;
  box_quantity: string;
  status: string;
}

let barChart:Chart
let myChart:Chart
let cartonQtyPiechart:any = null
let cartonQtyBarchart:any = null

const ReportDestructionCartonQuantity = () => {
    const { instance, accounts } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [isModalStorageCartonOpen, SetIsModalStorageCartonOpen] = useState(false);
  const [destructTypeId,setDestructTypeId] = useState(0)
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [documentStartYear, setDocumentStartYear] = useState<number | null | undefined>((new Date().getFullYear() - 4));
    const [documentEndYear, setDocumentEndYear] = useState<number | null | undefined>((new Date().getFullYear()));
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [warehouseId, setWarehouseId] = useState("")
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [printForm] = Form.useForm();
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

  const getWarehouses = async () => {
    let token = await getToken()
      let result = await new WarehouseService(token).GetAllWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: "" }]
        let Locationoptions: Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
        setWarehouseOptions(options.concat(Locationoptions))
    }
  }


  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}

  const handleCancelStorageCarton = () => {
    SetIsModalStorageCartonOpen(false);
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
   console.log(field)
    let parameter = InitHelper.InitialParameter();
      //parameter.warehouseId = warehouseId > 0 ? warehouseId : -1
      parameter.cartonLocation = warehouseId + ""
      parameter.destructionTypeId = destructTypeId > 0 ? destructTypeId : -1

      parameter.requestTypeIds = destructTypeId > 0 ?
          [
              destructTypeId
          ]
          :
          [
          RequestType.ExtendCarton,
          RequestType.DestructionCarton
          ] 

        parameter.statusIds = [
            CartonStatus.WaitApproval,
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.Done,
            CartonStatus.Reject,
            CartonStatus.Cancel
      ]
      if (field.statusId && field.statusId.length>0 ){
            parameter.statusIds = field.statusId
      }
    if(orgunits.length > 0){
      parameter.orgUnits = orgunits.map(item => item.key)
    }

      if (documentStartYear && documentStartYear > 0 && documentEndYear && documentEndYear > 0) {
          parameter.requestStartDate = documentStartYear + "-01-01"
          parameter.requestEndDate = documentEndYear + "-12-31"
          //parameter.documentYear1 = documentStartYear
          //parameter.documentYear2 = documentEndYear
      } else {
          parameter.requestStartDate = (new Date().getFullYear() - 4) + "-01-01"
          parameter.requestEndDate = new Date().getFullYear() + "-12-31"
      }

      /*
    if(documentStartYear && documentStartYear > 0 )
    {
        
        parameter.documentYear1 = documentStartYear
        parameter.requestStartDate = documentStartYear + "-01-01"
    }

      if (documentEndYear && documentEndYear > 0) {
          parameter.documentYear2 = documentEndYear
          parameter.requestEndDate = documentEndYear + "-12-31"
      }*/

    console.log(parameter)
    getData(parameter)
    
  }


  const reset = () => {
    
    dataForm.resetFields()
    
    setOrgUnits([])
    setDocumentStartYear(new Date().getFullYear() - 4)
    setDocumentEndYear(new Date().getFullYear())
    setWarehouseId("")
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.destructionTypeId = -1
    parameter.cartonLocation = ""
    //parameter.warehouseId = -1
      parameter.requestTypeIds = 
          [
              RequestType.ExtendCarton,
              RequestType.DestructionCarton
            ]
        parameter.requestStartDate = (new Date().getFullYear() - 4) + "-01-01"
          parameter.requestEndDate = new Date().getFullYear() + "-12-31"

        parameter.statusIds = [
            CartonStatus.WaitApproval,
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.Done,
            CartonStatus.Reject,
            CartonStatus.Cancel
        ]
    getData(parameter)
  }

  const onWarehouseIdChange = (value:string) => {
    setWarehouseId(value)
}

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelCarton = () => {
    setIsModalOpen(true);
    setIsModalCartonOpen(false);

  };

  const createBarChart = (data:SummaryDestruction[]) => {
    if(cartonQtyBarchart)
    {
      cartonQtyBarchart.clearChart()
    }
    
    let element = document.getElementById('destruction-quantity-barchart')
    let element2 = document.getElementById('destruction-quantity-barchart-no-data')

    if(element2)
         element2.style.display = 'none'

   
    let title = 'Destruction Carton Quantity By Destruction Type'
    let labels = data.map( x => x.year).sort()
    let chartData:any= []

    if(data.length > 0)
    {
      chartData.push(['Year', 'Extend','Destroy'])
      labels.forEach( year => {
      let item:SummaryDestruction|undefined = data.find(x =>x.year == year)
      if(item)
      {
        let extend = item.extend > 0 ? item.extend : 0
        let destroy = item.destroy > 0 ? item.destroy : 0
        chartData.push([item.year.toString(),extend,destroy])
      }
       

      })

      console.log(chartData)
    
      cartonQtyBarchart = CreateCartonQtyBarChart(element,title,chartData,['#36A2EB','#FF6384'])
    }
    else{
      if(element2){
        element2.style.display = 'inline'
        element2.innerHTML = 'No Data'
    }
    }
}

const createPieChart = (data:SummaryYear[]) => {
  if(cartonQtyPiechart)
  {
    cartonQtyPiechart.clearChart()
  }
  
  let element = document.getElementById('destruction-quantity-piechart')
  let element2 = document.getElementById('destruction-quantity-piechart-no-data')
  
  if(element2)
    element2.style.display = 'none'

    let title =  'Destruction Carton Quantity By Year'
    let colors = ['#36A2EB','#FF6384','#4BC0C0','#FF9F40','#FFCD56']
    let chartData:[[string,string|number]] = [['RequestType', 'Qty']]
    let sumData = 0
      console.log(data)
    
      data.forEach( (item:SummaryYear) => {
          sumData = sumData + item.total
          chartData.push([item.year.toString() +" (" + comma(item.total) + ")",item.total])
      })

  if(sumData > 0){
      cartonQtyPiechart = CreateCartonQtyPieChart(element,title, chartData,colors)
  }
  else{
    if(element2){
      element2.style.display = 'inline'
      element2.innerHTML = 'No Data'
    }
  }
}

const comma =(x:number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const getData = async (parameter:Parameter) => {
    let token = await getToken()
    let result = await new QueryService(token).GetSummaryDestruction(parameter);
    console.log(result)
    if(result && result.success)
    {
      let data = result.data
      let gData:any = genData(data)
      createPieChart(result.data.destruction)
      createBarChart(result.data.destructions)
      
    }
  }

    const sortByYear = (a: any, b: any) => {
        return a.year > b.year ? 1 : -1;
    }

  const genData = (data:SummaryReport,_requestType:number| null = null) => {
      console.log(data)
      data.destruction.sort(sortByYear);
      data.destructions.sort(sortByYear);
    let gData:any = []
    
    
    
      data.destruction.forEach(item =>{
        if(gData[item.year] === undefined){
          gData[item.year] = 0
        }
        gData[item.year] = gData[item.year] + item.total
      })
    

    return gData
  }

  const exportChart = async () =>{
    let a = document.createElement('a');
    let pie = myChart.toBase64Image();
    let bar = barChart.toBase64Image();

    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (!context) return;
    //context.clearRect(0,0,2000,2000)
    const image1 = new Image();
    image1.src = pie;
    const image2 = new Image();
    image2.src = bar;
    let mergedImageDataURL = ""

    const image1Promise = new Promise<HTMLImageElement>((resolve) => {
      image1.onload = () => resolve(image1);
    });

    const image2Promise = new Promise<HTMLImageElement>((resolve) => {
      image2.onload = () => resolve(image2);
    });

    const [loadedImage1, loadedImage2] = await Promise.all([image1Promise, image2Promise]);
    context.drawImage(loadedImage1, 0, 0, loadedImage1.width,loadedImage1.height);
    context.drawImage(loadedImage2, loadedImage1.width, 0, loadedImage2.width, loadedImage2.height);

    mergedImageDataURL = canvas.toDataURL('image/png');

    a.href = mergedImageDataURL
    a.download = 'DestructionCartonQuantity.png';
    a.click();
  }

    useEffect(() => {
    getWarehouses()
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.destructionTypeId = -1
    parameter.cartonLocation = ""
    //parameter.warehouseId = -1
      parameter.requestTypeIds = 
          [
              RequestType.ExtendCarton,
              RequestType.DestructionCarton
            ]
        parameter.requestStartDate = (new Date().getFullYear() - 4) + "-01-01"
          parameter.requestEndDate = new Date().getFullYear() + "-12-31"

        parameter.statusIds = [
            CartonStatus.WaitApproval,
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.Done,
            CartonStatus.Reject,
            CartonStatus.Cancel
        ]
    getData(parameter)
  }, [])

  const onDestructTypeChange = (_destructTypeId: number) => {
    setDestructTypeId(_destructTypeId);
   
  };

    const onDocumentStartYearChange = (value: any) => {
        let year = parseInt(dayjs(value).format('YYYY'))
        setDocumentStartYear(year)
        if (documentEndYear == null || (year + 4) < parseInt(documentEndYear + "")) {
            setDocumentEndYear(year + 4)
        }
    }


    const onDocumentEndYearChange = (value: any) => {
        let year = parseInt(dayjs(value).format('YYYY'))
        setDocumentEndYear(year)
        if (documentStartYear == null || (year - 4) > parseInt(documentStartYear + ""))
            setDocumentStartYear(year - 4)
    }

    const handlePrintModalCancel = () => {
      setIsPrintModalOpen(false);
    };
    const onFinishPrint = async (values:any) => {
      console.log("Print")
      setIsLoadingPrint(true)
      try{
  
        let a = document.createElement('a');
        let pie = cartonQtyPiechart.getImageURI()
        let bar = cartonQtyBarchart.getImageURI()
    
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        context.clearRect(0,0,2000,2000)
    
        const image1 = new Image();
        image1.src = pie;
        const image2 = new Image();
        image2.src = bar;
        let mergedImageDataURL = ""
    
        const image1Promise = new Promise<HTMLImageElement>((resolve) => {
          image1.onload = () => resolve(image1);
        });
    
        const image2Promise = new Promise<HTMLImageElement>((resolve) => {
          image2.onload = () => resolve(image2);
        });
    
        const [loadedImage1, loadedImage2] = await Promise.all([image1Promise, image2Promise]);
        context.drawImage(loadedImage1, 0, 0, loadedImage1.width,loadedImage1.height);
        context.drawImage(loadedImage2, loadedImage1.width, 0, loadedImage2.width, loadedImage2.height);
        mergedImageDataURL = canvas.toDataURL('image/png');
    
        if(values.print === 1){
          let token = await getToken()
          let body ={content:mergedImageDataURL}
          await new FileManageService(token).DownloadPdfImgage(body,"DestructionCartonQuantity");
        }else{
    
          a.href = mergedImageDataURL
          a.download = 'Destruction Carton Quantity.png';
          a.click();
        }
      }catch(error){
      console.log("🚀 ~ file: Destruction Carton Quantity.tsx:568 ~ onFinishPrint ~ error:", error)
  
      }finally{
        setIsLoadingPrint(false)
      }
    }
    const showPrintModal = () => {
      setIsPrintModalOpen(true);
    };
  return (

    <div>
      <canvas className='display-none' ref={canvasRef} width={1470} height={425}></canvas>
      <Collapse>
      {isLoadingPrint?<Loading/>:<></>}
        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
              <Form.Item name="destructionType" label="Destruction Type"  >
                        <Select placeholder={MessageCommon.TitleSelect} 
                            defaultValue={0}
                          onChange={onDestructTypeChange}
                          options={[
                            { value :0 ,label:'All'},
                            { value: RequestType.ExtendCarton, label: 'Extend' }, 
                            { value: RequestType.DestructionCarton, label: 'Destroy' }
                          ]}
                        />
                      </Form.Item>
                  
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >

              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="documentyear" label="Year">
                  <table className='w100c'>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          className='w100c' 
                                                  onChange={onDocumentStartYearChange}
                                                  value={dayjs(documentStartYear + "-01-01")}
                        />
                      </td>
                      <td>
                        <Label className='margin-left10 margin-right10 vertical-align-top'>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          className='w100c'
                                                  onChange={onDocumentEndYearChange}
                                                  value={dayjs(documentEndYear + "-12-31")}
                        />
                      </td>
                    </tr>
                  </table>


                </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="statusId" label="Status"   >
                  <Select 
                    placeholder={MessageCommon.TitleSelect} 
                    mode="multiple"
                    allowClear
                    options={[
                      { value: CartonStatus.WaitApproval, label: 'Wait for Approval' },
                      { value: CartonStatus.WaitAdmin, label: "Wait for Admin Service" },
                      { value: CartonStatus.Inprogress, label: 'On Going' },
                      { value: CartonStatus.Done, label: 'Completed Service' },
                      { value: CartonStatus.Reject, label: 'Rejected' },
                      { value: CartonStatus.Cancel, label: 'Cancel' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseIdChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
              </Col>
            </Row>



          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => showPrintModal()}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          <br></br>
          <br></br>
          <Row>
              <Col md={11} >
                <div  id="destruction-quantity-piechart-no-data" className="no-data"></div>
                <div  id="destruction-quantity-piechart" className="w100c height-400"></div>
               
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                <div  id="destruction-quantity-barchart-no-data" className="no-data"></div>
                <div  id="destruction-quantity-barchart" className="w100c height-400"></div>
              </Col>
          </Row>
        </Panel>
      </Collapse>
      <Modal title="Carton Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        {/* <CartonDetail></CartonDetail> */}
      </Modal>
      <Modal title="Request Detail ( Request Type: Storage Carton ) " open={isModalStorageCartonOpen} footer={''} width={1550} onCancel={handleCancelStorageCarton}>
        {/* <StorageCartonRequestDetail status={"H"} roomType='normal' approveLevel={0}/> */}
      </Modal>
      <Modal title="Export Type" open={isModalOpen} footer={''} width={600} onCancel={handleCancel}>
        <Card >
            <div className='text-center'>
            <Form.Item name="owner" label="" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>PDF</Radio>
                    <Radio value={2}>Image</Radio>
                  </Radio.Group>
                </Form.Item>
            </div>

          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={handleCancel}  >
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'  >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>



        </Card>
      </Modal>

      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            validateMessages={validateMessages}
            initialValues={{'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>PNG</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );

}

export default ReportDestructionCartonQuantity
