import { Element } from '../models'

export default class StickyHelperx {
    protected element:Element = {
        container:"",
        search: "",
        space: "",
        space2: "",
        space3: "",
        clientHight:"",
        result: "",
        dataFixed: "",
        data: "",
        openSearch: "",
        openAdd:""
    }
    protected thElement = "s-th"
    protected tdElement = "s-td"
    protected openStatus = false
    protected max = 0;
    protected minus = 0;

    constructor(prefix:string, max:number,minus:number){
        this.element = {
            container: prefix + "-container",
            search: prefix + "-search",
            space: prefix + "-space",
            space2: prefix + "-space2",
            space3: prefix + "-space3",
            clientHight: prefix + "-client-hight",
            result: prefix + "-result",
            dataFixed: prefix + "-data-fixed",
            data: prefix + "-data",
            openSearch: prefix + "-open-search",
            openAdd: prefix + "-open-add"
        }
        this.max = max
        this.minus = minus
    }
    
    getElement(){
        return this.element
    }
    
    setThElement(thName:string){
        this.thElement = thName
    }

    setTdElement(tdName:string){
        this.tdElement = tdName
    }

    
    addEvent(){
      
        window.scroll({ top: 0, behavior: "smooth"});
        window.addEventListener("scroll", () => this.updatePosition());
        window.addEventListener("resize", () => this.updatePosition());
    }

    removeEvent(){
        
        window.removeEventListener("scroll", () => this.updatePosition());
        window.removeEventListener("resize", () => this.updatePosition());
    }


    updatePosition(){
        let element = document.getElementById(this.element.result) as HTMLDivElement
        let maxPos = element ? element.offsetTop  :  this.max
       
        if(document.body.clientWidth > 1400){ 
            this.setWidth()
           
            let pos = window.scrollY;
            
            if(pos >= maxPos){
                
                this.addTableFix()
            }
            else{
                this.removeTableFix()
            }
            
            this.removeTableSticky()
            this.removeOverFlowSize()
        }
        else{
            this.removeTableFix()
            this.addTableSticky()
            this.addOverflowSize()
        }
    }

    getWidth(length:number = 0)  {
       
        let cols = document.getElementsByClassName(this.thElement) as HTMLCollection
        let element = document.getElementById(this.element.container) as HTMLDivElement

        length = cols.length
        if(length > 0 && element && element.clientWidth > 0){
            return (element.clientWidth - this.minus)  / length
        }
        else{
            return 0
        }
    }

    setWidth(){
        let width = this.getWidth()
        if(width > 0){
            this.setWidth_Th(width)
            this.setWidth_Td(width)
        }
    }

    setWidth_Th(width:number){
        
        let elements = document.getElementsByClassName(this.thElement) as HTMLCollection
            
            let length = elements.length
            for(let i =0; i< length;i++)
            {
                let element:any = elements[i]
                element.style.width = width + 'px';
            }
    }

    setWidth_Td(width:number){
        
        let elements = document.getElementsByClassName(this.tdElement) as HTMLCollection
          
            let length = elements.length
            for(let i =0; i< length;i++)
            {
                let element:any = elements[i]
                element.style.width = width + 'px';
            }
    }

    setBodyHight = (value:number = 0) => {
        let element = document.getElementById(this.element.dataFixed) as HTMLDivElement
        let element2 = document.getElementById(this.element.space3) as HTMLDivElement
        element2.style.height = (element.clientHeight - ( element.clientHeight * 0.65) ) + value + 'px'
    }

    setCollapse = () => {
        
        let ele = document.getElementById(this.element.openSearch) as HTMLDivElement
        if(ele){
            let value = !ele.classList.contains('display-none') ? 0 : 1
            if(value === 1){
               
               // setTimeout(() => {
                    this.openStatus = true
                    this.setCollapseArrow(1)
                    this.setCollapseBottom(ele,1)
               // }, 500);
            }
            else{
                this.openStatus = false
                this.setCollapseArrow(0)
                this.setCollapseBottom(ele,0)
            }
           
        } 
    }

    setCloseCollapse = () => {
     
        let ele = document.getElementById(this.element.openSearch) as HTMLDivElement
        if(ele){
            this.openStatus = false
            this.setCollapseArrow(0)
            this.setCollapseBottom(ele,0)
        } 
    }

    setCollapseArrow = (value:number) => {
        let ele = document.getElementById('arrow-right') as HTMLInputElement
        let ele2 = document.getElementById('arrow-down') as HTMLInputElement
        if(value === 0){
            this.toggleClass(ele,'display-none','display-inline')
            this.toggleClass(ele2,'display-inline','display-none')
        }
        else{
            this.toggleClass(ele,'display-inline','display-none')
            this.toggleClass(ele2,'display-none','display-inline')
        }
       
    
    }

    toggleClass(ele:HTMLDivElement | HTMLInputElement,oldClassName:string,newClassName:string){
        ele.classList.remove(oldClassName)
        ele.classList.add(newClassName)
    }

    
    setCollapseBottom = (ele:HTMLDivElement,value:number) => {
        if(value === 0){
            this.toggleClass(ele,'display-block','display-none')
        }
        else{
            this.toggleClass(ele,'display-none','display-block')
        }
    }

    addTableFix(){
        let element = document.getElementById(this.element.dataFixed) as HTMLDivElement
        if(element && !element.classList.contains('table-thead-fixed')){
            element.classList.add('table-thead-fixed')
            element.style.top = '70px'
        }
    }

    removeTableFix(){
        let element = document.getElementById(this.element.dataFixed) as HTMLDivElement
        if(element && element.classList.contains('table-thead-fixed'))
        {
            element.classList.remove('table-thead-fixed')
            element.style.top = ''
        }
    }

    addTableSticky(){
        let element = document.getElementById(this.element.dataFixed) as HTMLTableElement
        if(!element.classList.contains('head-sticky')){
            element.classList.add('head-sticky')
        }

        this.addTdFix('thfix1','th-td-fix1')
        this.addTdFix('thfix2','th-td-fix2')
        this.addTdFix('tdfix1','td-fix1')
        this.addTdFix('tdfix2','td-fix2')
        
    }

    removeTableSticky(){
        let element = document.getElementById(this.element.dataFixed) as HTMLTableElement
        if(element && element.classList.contains('head-sticky')){
            element.classList.remove('head-sticky')
        }

        this.removeTdFix('thfix1','th-td-fix1')
        this.removeTdFix('thfix2','th-td-fix2')
        this.removeTdFix('tdfix1','td-fix1')
        this.removeTdFix('tdfix2','td-fix2')
    }

    addTdFix(idName:string,name:string){
        let elements = document.getElementsByClassName(idName) as HTMLCollection
            let length = elements.length
            for(let i =0; i< length;i++)
            {
                let ele:any = elements[i]
                if(ele && !ele.classList.contains(name)){
                    ele.classList.add(name)
                }
            }
    }

    removeTdFix(idName:string,name:string){
        let elements = document.getElementsByClassName(idName) as HTMLCollection
        let length = elements.length
        for(let i =0; i< length;i++)
        {
            let ele:any = elements[i]
            if(ele && ele.classList.contains(name)){
                ele.classList.remove(name)
            }
        }
    }

    addOverflowSize(){
        let element = document.getElementById(this.element.container) as HTMLDivElement
        if(element){
            if(!element.classList.contains('overflow-size')){
                element.classList.add('overflow-size')
            }
             
            element.style.height = '400px'
        }
        
    }

    removeOverFlowSize(){
        let element = document.getElementById(this.element.container) as HTMLDivElement
        if(element){
            element.style.height = 'auto'
            element.classList.remove('overflow-size')
        }
        
    }

}