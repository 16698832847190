import {  useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, DatePicker,message, Form, Input, Modal,Spin, Radio,Popconfirm, Row, Select,Pagination  } from 'antd';
import {  AiFillSave, AiOutlineArrowLeft, AiOutlineCheck, AiOutlineClose, AiOutlineMessage, AiOutlinePrinter } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {  CloseCircleOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import CartonStatus  from '../../enum/CartonStatus';
import { RequestDetail,User ,UpdateStorage,Carton ,Option,Warehouse, QueryCarton} from '../../models';
import { StorageCartonService, FileManageService,PrintService,WarehouseService } from '../../services';
import { InitHelper, Helper ,StickyHelper2} from '../../helpers';
import DisplayLogNote from '../Share/DisplayLogNote';
import { ConfidentialType ,WarehouseType , RequestType, ExportType} from '../../enum';
import CartonDetail from '../Share/CartonDetail'
import BarCodeItem from '../../models/BarCodeItem';
import DisplayLogOwner from '../Share/DisplayLogOwner';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { DataArrayResponse } from '../../models/PageResponse'

interface WarehouseSelect{
  warehouseId:number
  locationNo:string
  unitCost:number
  roomTypeId:number
}

interface Props{
    id:string
    onBack:any
}

const QueryDataStorageCarton = (props:Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }


    const doIfElse = (flag: any, f1: any, f2: any) => {
        if (flag) {
            if (f1 != null) {
                f1();
            }
        }
        else {
            if (f2 != null) {
                f2();
            }
        }
    }

    const stickyHelper = new StickyHelper2("query-data-storage",800,0)
    const stickyElement = stickyHelper.getElement()
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const pid:string| null| undefined = urlParams.get('id')
  const id = props.id ? props.id : pid
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const currentDate = secureLocalStorage.getItem('date')
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [autoExtendChecked,setAutoExtendChecked] = useState(false)
  const [dataForm] = Form.useForm();
  const [locationBorder,setLocationBorder] = useState('')
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(100);
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail())
  const [warehouses, setWarehouses] = useState<Warehouse[]>([])
  const [warehouseSelect,setWarehouseSelect] = useState<WarehouseSelect>({warehouseId: 0,locationNo:"", roomTypeId: 1,unitCost: 0})
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const [width,setWidth] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [allCartonItems,setAllCartonItems] = useState<QueryCarton[]>([])
  const [cartonItems,setCartonItems] = useState<QueryCarton[][]>([])
  const [selectItems,setSelectItems] = useState<string[]>([])
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
  const [isModalLogOwnerOpen, setIsModalLogOwnerOpen] = useState(false);
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [isErrorNote,setIsErrorNote] = useState(false)
  const [box ,setBox] = useState("")
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };
  
  const [printForm] = Form.useForm();
  const onFinishPrint = async (values:any) => {

    if(id){
   setIsLoadingPrint(true)
      let token = await getToken()
      try{

        switch (values.owner) {
          case 1:
            const cartons = cartonItems.flat(1);
            let body = {
              Datail:detail,
              Cartons:cartons
            }
            await new FileManageService(token).DownloadStorageAdmin(body,values.print);
            break;
          case 2:
            await new FileManageService(token).DownloadStorageUser(detail,values.print);
            break;
          default:
            break;
        }
      }catch(error){
      console.log("🚀 ~ file: QueryDataStorageCarton.tsx:88 ~ onFinishPrint ~ error:", error)

      }finally{
        setIsLoadingPrint(false)
      }

      
    }
    


  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
    const wareHouseCondition = async (
        _warehouseId: number
        , cartonResult: QueryCarton[]
        , detailResult_data: RequestDetail
        , token:string
        
    ) => {

        let warehouseResult = await new WarehouseService(token).GetWarehouses(_warehouseId,detailResult_data.valveRoom)
        console.log(warehouseResult)
        if (warehouseResult && warehouseResult.success) {

            let _warehouseOptions: Option[] = warehouseResult.data.map((item) => { return { value: item.warehouseId, label: item.warehouseName } })
            setWarehouseOptions(_warehouseOptions)
            setWarehouses(warehouseResult.data)

            if (_warehouseId > 0) {
                console.log('no warehouse')
                let _warehouse = warehouseResult.data.find(x => x.warehouseId === _warehouseId)
                if (_warehouse) {

                    let _warehouseSelect: WarehouseSelect = {
                        warehouseId: _warehouse.warehouseId,
                        locationNo: _warehouse.warehouseName,
                        unitCost: _warehouse.unitCost2 ? parseFloat(_warehouse.unitCost2) : 0,
                        roomTypeId: _warehouse.roomTypeId
                    }
                    setWarehouseSelect(_warehouseSelect)
                }

                let items = Helper.Chunk(cartonResult, perpage)
                console.log("🚀 ~ file: QueryDataStorageCarton.tsx:175 ~ .then ~ items:", items)
                setAllCartonItems(cartonResult)
                setCartonItems(items)
                setPage(1)
            }
            else {
                console.log('warehouse')
                let _warehouse = warehouseResult.data.find(x => x.defaultLocation === 1)
                console.log("🚀 ~ file: QueryDataStorageCarton.tsx:184 ~ .then ~ _warehouse:", _warehouse)
                if (_warehouse) {
                    let _warehouseSelect: WarehouseSelect = {
                        warehouseId: _warehouse.warehouseId,
                        locationNo: _warehouse.warehouseName,
                        unitCost: _warehouse.unitCost2 ? parseFloat(_warehouse.unitCost2) : 0,
                        roomTypeId: _warehouse.roomTypeId
                    }

                    let allItems: QueryCarton[] = cartonResult.map((item) => {
                        item.cost = item.cost > 0 ? item.cost : _warehouseSelect.unitCost
                        item.locationNo = returnIfElse(_warehouse?.warehouseName, _warehouse?.warehouseName, "");
                        return item
                    })

                    console.log(_warehouseSelect)
                    let items = Helper.Chunk(allItems, perpage)
                    console.log("🚀 ~ file: QueryDataStorageCarton.tsx:198 ~ .then ~ items:", items)
                    setWarehouseSelect(_warehouseSelect)
                    setAllCartonItems(allItems)
                    setCartonItems(items)
                    setPage(1)
                }
            }
        }
    }


  const getData = async () => {
    if(id){
      setIsLoading(true)
      let token = await getToken()
      let recordNo = parseFloat(id)
      let result = await new StorageCartonService(token).GetDetailAndCartonList(recordNo)
       console.log(result)
    
        
        if(result && result.success){
            result.data.detail.orgUnit = result.data.detail.ownerOrgUnit
            setDetail(result.data.detail)
            dataForm.setFieldsValue({
                serviceDate: returnIfElse(result.data.detail.serviceDate, dayjs(result.data.detail.serviceDate), dayjs(result.data.detail.pickupDate)),
              approver:result.data.detail.approverName
            })
            setAutoExtendChecked(returnIfElse(result.data.detail.autoExtend, true, false)  )
            
            let _warehouseId = returnIfElse(result.data.detail.warehouseId > 0, result.data.detail.warehouseId ,0) 
              await  wareHouseCondition (_warehouseId, result.data.cartonList, result.data.detail, token)
        }
        setIsLoading(false)
      
    }
    
  }

  // const getDatax= async () => {
  //   if(id)

  //   {
  //     setIsLoading(true)
  //     let token = await getToken()
  //     let recordNo = parseFloat(id)
  //     Promise.all([
  //       new StorageCartonService(token).GetDetail(recordNo),
  //       new StorageCartonService(token).QueryCartonList(recordNo),
  //     ])
  //     .then(async (result) => {
  //       console.log(result)
  //       let [detailResult,cartonResult] = result
        
  //       if(detailResult && detailResult.success){
  //           detailResult.data.orgUnit = detailResult.data.ownerOrgUnit
  //           setDetail(detailResult.data)
  //           dataForm.setFieldsValue({
  //               serviceDate: returnIfElse(detailResult.data.serviceDate, dayjs(detailResult.data.serviceDate), dayjs(detailResult.data.pickupDate)),
  //             approver:detailResult.data.approverName
  //           })
  //           setAutoExtendChecked(returnIfElse(detailResult.data.autoExtend, true, false)  )
            
  //           if(cartonResult && cartonResult.success){
             
  //               let _warehouseId = returnIfElse(detailResult.data.warehouseId > 0, detailResult.data.warehouseId ,0) 
  //               await  wareHouseCondition (
  //                     _warehouseId
  //                     , cartonResult
  //                     , detailResult.data
  //                     , token
  //                 )
                
              
  //           }
            
  //       }
  //       setIsLoading(false)
  //     })
  //   }
    
  // }

  const getCarton = async (cartonNumber:string) => {
    setBox(cartonNumber)
    setIsModalCartonDetailOpen(true);
  }

  const handleCartonDetailCancel = () => {
    setIsModalCartonDetailOpen(false);
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  useEffect(() => {
    
    if(user.permissions.queryData === 0){
        navigate('/permissiondenided')
    }
    getData()

    stickyHelper.removeEvent()
    stickyHelper.addEvent()

  }, [])

  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };

  const showPrintModal = () => {
    setIsPrintModalOpen(true);
  };
  
  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };
  
  const updateAllItems = () => {
    let valveRoom = 0
    if(warehouseSelect.roomTypeId === WarehouseType.ValveRoom){
      valveRoom = 1
    }
    let items = allCartonItems.map( item => {

          item.warehouseBarcode = getWarehouseBarcode(item.recordNo,item.warehouseBarcode)   
          if(warehouseSelect.roomTypeId === WarehouseType.ValveRoom){
            item.sealNo = getSealNo(item.recordNo,item.sealNo) 
          }
          item.cost = getCost(item.recordNo,item.cost) 
          item.valveRoom = valveRoom
      return item
    })
    return items
  }

  const accept = async (statusId: number) => {
    
    setLocationBorder('')
    setIsLoading(true)
    

    let autoExtend = returnIfElse(autoExtendChecked ,1  ,0)  
    if(warehouseSelect.roomTypeId === WarehouseType.GeneralRoom){
      autoExtend = 0
    }

    let noteElement = document.getElementById('note') as HTMLInputElement
      let note = returnIfElse(noteElement && noteElement.value, noteElement.value,"")
    let requestCarton:UpdateStorage = {
      recordNo: detail.recordNo,
        serviceDate: returnIfElse(dataForm.getFieldValue('serviceDate') !== undefined, dataForm.getFieldValue('serviceDate').format('YYYY-MM-DD'), null)   ,
      note: note,
      statusId:statusId,
      noteReject:  "",
        warehouseId: returnIfElse(warehouseSelect.warehouseId, warehouseSelect.warehouseId ,0)  ,
        locationNo: warehouseSelect.locationNo,
      autoExtend: autoExtend,
      cartons:updateAllItems(),
      updateBy:user.employeeId,
      updateDate: new Date()
    }
    console.log(requestCarton)
    let token = await getToken()
    let result = await new StorageCartonService(token).UpdateAdmin(requestCarton);
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        setTimeout(() => {

            doIfElse(statusId === 0 || statusId === CartonStatus.WaitAdmin || statusId === CartonStatus.Inprogress ,
                function () {
                    getData()
                }
                ,
                function () {
                  setIsLoading(false)
                    props.onBack(true)
                }
            );
        }, 1000);
        
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const update = async (statusId: number,noteReject:string| null = null) => {
    
    setLocationBorder('')
    setIsLoading(true)
    if(statusId === CartonStatus.Done){
      if(warehouseSelect.warehouseId === 0){
        let element = document.getElementById('panel-action') as HTMLDivElement
        messageApi.open({
          type: 'error',
          content: 'Carton location is required.',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setLocationBorder('1px solid red')
        return false
      }
    }

      let autoExtend = returnIfElse(autoExtendChecked ,1  ,0)  
    if(warehouseSelect.roomTypeId === WarehouseType.GeneralRoom){
      autoExtend = 0
    }

    let noteElement = document.getElementById('note') as HTMLInputElement
      let note = returnIfElse(noteElement && noteElement.value, noteElement.value,"")
    let requestCarton:UpdateStorage = {
      recordNo: detail.recordNo,
        serviceDate: returnIfElse(dataForm.getFieldValue('serviceDate') !== undefined, dataForm.getFieldValue('serviceDate').format('YYYY-MM-DD'), null)   ,
      note: note,
      statusId:statusId,
      noteReject: noteReject ? noteReject : "",
        warehouseId: returnIfElse(warehouseSelect.warehouseId, warehouseSelect.warehouseId ,0)  ,
        locationNo: warehouseSelect.locationNo,
      autoExtend: autoExtend,
      cartons:updateAllItems(),
      updateBy:user.employeeId,
      updateDate: new Date()
    }
    console.log(requestCarton)
    let token = await getToken()
    let result = await new StorageCartonService(token).UpdateAdmin(requestCarton);
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        setTimeout(() => {

            doIfElse(statusId === 0 || statusId === CartonStatus.WaitAdmin || statusId === CartonStatus.Inprogress ,
                function () {
                  getData()
              }
                ,
                function () {
                  setIsLoading(false)
                    props.onBack(true)
                }
            );
        }, 1000);
        
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const updateCartonLocation = async () => {
    setLocationBorder('')

    
      if(warehouseSelect.warehouseId === 0){
        let element = document.getElementById('panel-action') as HTMLDivElement
        messageApi.open({
          type: 'error',
          content: 'Carton location is required.',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setLocationBorder('1px solid red')
        return false
      }
    

      let autoExtend = returnIfElse(autoExtendChecked ,1  ,0)
    
    if(warehouseSelect.roomTypeId === WarehouseType.GeneralRoom){
      autoExtend = 0
    }

    let requestCarton:UpdateStorage = {
      recordNo: detail.recordNo,
        serviceDate: detail.serviceDate ? detail.serviceDate: new Date()  ,
        note: "",
        statusId: CartonStatus.Done,
        noteReject:  "",
        warehouseId: returnIfElse(warehouseSelect.warehouseId, warehouseSelect.warehouseId ,0)  ,
        locationNo: warehouseSelect.locationNo,
        autoExtend: autoExtend,
        cartons:updateAllItems(),
        updateBy:user.employeeId,
        updateDate: new Date()
    }
    console.log(requestCarton)
    let token = await getToken()
    let result = await new StorageCartonService(token).UpdateCartonLocation(requestCarton);
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });
        
        getData()
      
    } 
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }


  const getWarehouseBarcode= (recordNo:number,warehouseBarcode:string) => {
    let element = document.getElementById('warehouse-barcode-' + recordNo.toString()) as HTMLInputElement
    if(element){
      return element.value
    }
    else{
      return warehouseBarcode
    }
}

const getSealNo = (recordNo:number,sealNo:string) => {
  let element = document.getElementById('sealNo-' + recordNo.toString()) as HTMLInputElement
  if(element){
    return element.value
  }
  else{
    return sealNo
  }
}

  const getCost = (recordNo:number,cost:number) => {
      let element = document.getElementById('cost-' + recordNo.toString()) as HTMLInputElement
      if(element){
        return (element.value !== "" || element.value !== null || element.value !== undefined) ? parseFloat(element.value) : 0
      }
      else{
        return cost
      }
  }

  const onAutoExtendChange = (e: CheckboxChangeEvent) => {
      setAutoExtendChecked(e.target.checked)
  }

  const onCartonLocationChange = (value:number) => {
    let warehouse = warehouses.find( x => x.warehouseId === value)
    let locationNo = warehouse ? warehouse.warehouseName : ""
    
    if(warehouse){
      let _warehouseSelect:WarehouseSelect = {
        warehouseId: warehouse.warehouseId,
        locationNo: locationNo,
        unitCost: warehouse.unitCost2 ? parseFloat(warehouse.unitCost2) : 0,
        roomTypeId: warehouse.roomTypeId
      }

      let items:QueryCarton[] = allCartonItems.map( (item) => { 
            item.cost =  _warehouseSelect.unitCost
            item.locationNo = locationNo; 
            return item 
     })

     console.log(_warehouseSelect)
     console.log(items)
      setWarehouseSelect(_warehouseSelect)
      setAllCartonItems(items)
    }
    
  }

 

  const handleCancelReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
     element.value = ""
     setIsModalRejectOpen(false)
  }

  const handleReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
    if(element && element.value){
      update(CartonStatus.Reject,element.value)
    }
    else{
      setIsErrorNote(true)
      return false
    }
    
  }

  const onPageChange = (_page:number,_perpage: number) => {
   
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      console.log("🚀 ~ file: QueryDataStorageCarton.tsx:439 ~ onPageChange ~ items:", items)
      setCartonItems(items)
      setPerpage(_perpage)
    }
    else{
      setPage(_page)
    }
  }
  
  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {

    let checked = e.target.checked
    let value = e.target.value
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
    }
    else{
      let index = items.indexOf(value);
      if (index > -1) {
        items.splice(index, 1);
      }
    }

    console.log(items)
    setSelectItems(items)
  }

  const onPrintBarcode = async () => {
    let token = await getToken()
    let _selectItems:string[] = Object.assign([],selectItems)
    if(selectItems.length === 0){
      _selectItems = allCartonItems.map(x => x.cartonName)
    }
    var container = `<style nonce="nonce-wPt6PoYbODyQ7sevRM">
    @media print {
        .print-sticker{
            width:13cm !important; 
            height:8cm !important; 
            margin-bottom: 2mm !important;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            margin-top:130px;
            margin-left:-120px
        }
    
        .print-text{
            font-size:50px;
            font-weight:bold;
            font-family:tahoma;
        }
    
        .sticker-bar{
            width:8cm;
            height: 2cm
        }
        .pagebreak { page-break-before: always; }
        
        @page {
            size: 210mm 297mm !important;
            -webkit-print-color-adjust: none !important; 
            print-color-adjust: none !important;
            background-color: white !important;
        }
        
      }

      .pagebreak { page-break-before: always; }
      
      .print-sticker{
        width:10cm !important; 
        height:5cm !important; 
        margin-bottom: 2mm !important;
    }

    .print-text{
        font-size:50px;
        font-weight:bold;
        font-family:tahoma;
    }

    .sticker-bar{
        width:8cm;
        height: 2cm
    }
      </style>`

   let result = await new PrintService(token).Print(_selectItems)
    console.log(result)
    if(result && result.success)
    {
        let length = result.data.length -1
        container += `<div class="print-top">`
  
                result.data.map((item:BarCodeItem,index) => {
                    container += `
                        <div class='print-row print-sticker'>
                            <div>
                                <div class='print-text'>
                                    ${item.cartonNo}
                                </div>
                                <div>
                                    <img src="data:image/png;base64,${item.barCode}" class='sticker-bar'/>
                                </div>
                            </div>
                        </div>`
                        if(index < length ){
                          container += '<div class="pagebreak"></div>'
                      }   
                })
        
            container += '</div>'
    
        var printWindow = window.open("", 'PrintBarcode','width=800,height=518,visible=none');
        if(printWindow && container)
        {
            printWindow.document.write('<title>Print Barcode</title>');
            printWindow.document.writeln(container);
            printWindow.document.body.scrollIntoView(false)
            setTimeout(() => {
                if(printWindow){
                   printWindow.moveTo(500,200)
                   printWindow.document.body.style.overflow = 'hidden'
                   printWindow.print();
                   printWindow.close()
                   
                }
            }, 100);

            
        }
    }
    
    
  }

  const prevStep = () => {
    props.onBack(false)
}

  const disabledDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disabledDate(value,date)
  }

  
  const onExport = async () => {
    setIsLoadingPrint(true)

    let token = await getToken()
    if(!id)return
    let recordNo = parseFloat(id)
    try{
      
        await new FileManageService(token).DownloadReport(null,ExportType.EXPORT_STORAGE_LIST,recordNo);
      
    }catch(error){
    console.log("🚀 ~ file: QueryData.tsx:464 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)

    }
  }

  const handleRejectOpen = () => {
    setIsErrorNote(false)
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let element = document.getElementById('note-reject') as HTMLTextAreaElement
        element.value = ""
    }, 100);
   
 }


  return (

    <div>
      <Card title="Storage Carton Detail">
              {returnIfElse(isLoading
                  ,
                  <Spin tip="Loading" size="large">
                      <div className="content" />
                  </Spin>
                  ,
                  <></>
              ) 
      }
              {returnIfElse(isLoadingPrint, <Loading />, <></>  )}

        <Form
          {...layout}
          form={dataForm}
          name="storage-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={11} >
              <Form.Item name="requestNo" label="Request No"  >
                <Label className='label-det'>{ detail.requestNo }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Label className='label-det'>{ detail.status }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="requester" label="Requester"  >
                <Label className='label-det'>{ detail.requesterName }</Label>


              </Form.Item>

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="owner" label="Owner"  >
                <table>
                  <tr>
                    <td >
                      <Label className='label-det'>{ detail.ownerName }</Label>
                    </td>
                    <td>
                        <a className='a-table ml-label' onClick={() => setIsModalLogOwnerOpen(true)}>Display Log</a>
                    </td>
                  </tr>
                </table>

              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="owner" label="Contact Person"  >
                <Label className='label-det'>{ detail.contactPersonName }</Label>
              </Form.Item>

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="document_year" label="Confidentiality Level" >
                              <Label className='label-det'>{returnIfElse(detail.confidentialityLevel == 0, ConfidentialType.NonConfident, ConfidentialType.Confident )  }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="project" label="Project Name"  >
                <Label className='label-det'>{ detail.projectName }</Label>
              </Form.Item>

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="location" label="Location">
                <Label className='label-det'>{ detail.location }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="floor" label="Floor">
                <Label className='label-det'>{ detail.floor }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              
              <Form.Item name="place" label="Place" >
                <Label className='label-det label-det-warp'>{ detail.place }</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="total_qty" label="Total Carton Quantity" >
                <Label className='label-det'>{ detail.quantity } caton(s)</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="documentYear" label="Document Year" >
                <Label className='label-det'>{ dayjs(detail.documentYear).format('MMM YYYY') }</Label>

              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="total_storage_year" label="Total Storage Year" >
                <Label className='label-det'>{ detail.totalStorageYear } year(s)</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="destruction_due_date_month" label="Destruction Due Date" >
                <Label className='label-det'>{ dayjs(detail.destructionDueDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="expected_pickup_date" label="Expected Pick up Date" >
                <Label className='label-det'>{ dayjs(detail.pickupDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
            </Col>
            <Col md={2} ></Col>
            <Col md={11} >
              <Form.Item name="serviceDate" label="Service Date"  >
                <DatePicker 
                    placeholder={MessageCommon.TitleDate}  
                    format={'DD-MMM-YYYY'}
                    disabled = { detail.statusId === CartonStatus.Done || detail.statusId === CartonStatus.Reject || detail.statusId === CartonStatus.Cancel || detail.statusId === CartonStatus.Delete}
                    disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="remark" label="Remark"  >
                <Label className='label-det label-det-warp'>{ detail.remark } </Label>
              </Form.Item>
            
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
             
              <Form.Item name="approver" label="Approver" >
                <Label className='label-det'>{detail.approverName}</Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="lastNote" label="Lasted Note"  >
                              {returnIfElse(detail.note.length > 0
                                  ,
                                  <>
                                      <Label className='label-det'>{detail.note[detail.note.length - 1]}</Label>
                                      <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                                  </>
                                  , 
                                  <></>
                              ) 
                }

              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                
            { detail.statusId === CartonStatus.Reject ?
                 <Form.Item name="noteReject" label="Rejected Reason"  >
                        <Label className='label-det'>{detail.notApproveReason}</Label>
                    </Form.Item>:<></>
            }
                          {returnIfElse(detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress
                              ,
                              <Form.Item name="note" label="Note"  >
                                  <Input.TextArea id="note" placeholder={MessageCommon.TitleInput} />
                              </Form.Item>
                              , 
                              <></>
                          )
            }
            </Col>
          </Row>

          <Row>
            <Col md={11} >
             
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                          {returnIfElse(warehouseSelect.roomTypeId === WarehouseType.ValveRoom
                              ,
                              <Form.Item name="autoExtend" label="Auto Extend">
                                  <Checkbox checked={autoExtendChecked} onChange={onAutoExtendChange}></Checkbox>
                              </Form.Item>
                              ,
                              <></>
                          )
            }
            </Col>
          </Row>
        </Form>
        
        <div id="panel-action2" className='padding-bottom-15'>
          <hr></hr>

          <div className='float-left'>
            <Button type="primary" htmlType="button" className='mr-btn' onClick={()=>onExport()}  disabled={ isLoading }>
              <AiOutlinePrinter className='icn-btn' />Export
            </Button>
          </div>
          <div className='text-center'>
        { detail.statusId === CartonStatus.Done ?
             <Button type="primary" htmlType="button" className='mr-btn' onClick={() => updateCartonLocation()} disabled={isLoading}>
             <AiOutlineMessage className='icn-btn' />Update
           </Button>
           :<></>
        }

        { detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress ?
          <>
            <Button type="primary" htmlType="button" className='mr-btn' onClick={() => accept(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.Inprogress || isLoading}>
              <AiFillSave className='icn-btn' />Accept
            </Button>
            <Button type="primary" htmlType="button" className='mr-btn' onClick={() => update(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.WaitAdmin || isLoading}>
              <AiOutlineMessage className='icn-btn' />Update
            </Button>

            <Popconfirm
              title="Close the task"
              description="Confirm to close?"
              icon={<QuestionCircleOutlined className='c-red' />}
              onConfirm = { () => update(CartonStatus.Done) }
            >
            <Button type="primary" htmlType="button" className='mr-btn' disabled={detail.statusId === CartonStatus.WaitAdmin || isLoading}>
              <AiFillSave className='icn-btn' />Close Job
              </Button>
            </Popconfirm>

            
              <Button type="primary" htmlType="button" className='mr-btn' disabled={isLoading} onClick={() => handleRejectOpen()}>
                <AiOutlineClose className='icn-btn' />Reject
              </Button>
            
            <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal}  disabled={isLoading} >
              <AiOutlinePrinter className='icn-btn' />Print
            </Button>

            
              <Button type="primary" htmlType="button" className='mr-btn' onClick={onPrintBarcode} disabled={isLoading} >
                  <AiOutlinePrinter className='icn-btn' />Print Sticker
              </Button>

            <Button type="primary" htmlType="button" onClick={prevStep} className='mr-btn'  disabled={isLoading} >
                  <AiOutlineArrowLeft className='icn-btn' />Back
            </Button>
          </>
          :
          <>
            <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal}  disabled={isLoading} >
                <AiOutlinePrinter className='icn-btn' />Print
            </Button>
           
              <Button type="primary" htmlType="button" className='mr-btn' onClick={onPrintBarcode}  disabled={isLoading} >
                  <AiOutlinePrinter className='icn-btn' />Print Sticker
              </Button>
            
             <Button type="primary" htmlType="button" onClick={prevStep} className='mr-btn'  disabled={isLoading} >
                <AiOutlineArrowLeft className='icn-btn' />Back
            </Button>
          </> 
        } 
          
          </div>
          <br></br>
          <h6>Carton Detail</h6>
          <br></br>
        
        { detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress ||  detail.statusId === CartonStatus.Done ?
        <>
          <div className='float-left'>
                              {returnIfElse(!isLoading
                                  ,
                                  <>
                                      Carton Location :
                                      <Select
                                          placeholder="Please select..."
                                          defaultValue={warehouseSelect.warehouseId}
                                          className={locationBorder == "" ? "select-carton-location" : "select-carton-location border-red"}
                                          options={warehouseOptions}
                                          onChange={onCartonLocationChange}
                                      />
                                  </>
                                  ,  
                                  <></>
                              ) 
            }
          </div>
                          {returnIfElse(cartonItems.length > 0 && !isLoading
                              , 
                              <div className='float-right'>
                                  <SelectPagination onSelectChange={onPageChange} currentPage={page} defaultValue={perpage}/>
                                  <span className='margin-left-10'>/ Page</span>
                              </div>
                              ,
                              <></>
                          )
                                }
           <br></br>
           <br></br>
        </>:<></>
        }
         
        </div>
        <div id={stickyElement.container}>
          <table className='idoc-table'>
            <thead id={stickyElement.dataFixed}>
              <tr >
                {/* <th className="thfix1 w50">No.</th> */}
                <th className="s-th thfix2">Carton No.</th>
                <th className="s-th">Carton Location</th>
                <th className="s-th">Warehouse Barcode</th>
                <th className="s-th">Carton Seal No.</th>
                <th className="s-th">Cost</th>
                <th className="s-th">Print Sticker</th>
              </tr>
            </thead>
            <tbody >
            { cartonItems.length > 0 && cartonItems[page -1].map((item:QueryCarton,index:number) => (
                <tr key={Helper.Uniqid()}>
                  {/* <td className="tdfix1 w50 text-center">{((page -1) * perpage) + (index + 1)}</td> */}
                  <td className="s-td tdfix2 text-center">
                    <a className={item.cartonStatusId === CartonStatus.Remove || item.cartonStatusId === CartonStatus.Destruction ? 'a-table c-red' : 'a-table'} onClick={() => getCarton(item.cartonName)}>{item.cartonName}</a>
                  </td>
                  <td className="s-td text-center">{item.locationNo}</td>
                  <td className="s-td text-center">
                    <Input 
                        id={'warehouse-barcode-' + item.recordNo} 
                        placeholder={MessageCommon.TitleInput} 
                        defaultValue={item.warehouseBarcode} 
                        disabled={detail.statusId === CartonStatus.Reject || detail.statusId === CartonStatus.Cancel || detail.statusId === CartonStatus.Delete}
                    />
                </td>
                <td className="s-td text-center">
                    <Input 
                        id={'sealNo-' + item.recordNo} 
                        placeholder={ detail.statusId === CartonStatus.Reject || detail.statusId === CartonStatus.Cancel || detail.statusId === CartonStatus.Delete || warehouseSelect.roomTypeId === WarehouseType.GeneralRoom ? "" : MessageCommon.TitleInput} 
                        defaultValue={warehouseSelect.roomTypeId === WarehouseType.ValveRoom ? item.sealNo : ""} 
                        disabled={detail.statusId === CartonStatus.Reject || detail.statusId === CartonStatus.Cancel || detail.statusId === CartonStatus.Delete || warehouseSelect.roomTypeId === WarehouseType.GeneralRoom}
                    />
                       
                </td>
                  <td className="s-td text-center">
                        <Input 
                            id={'cost-' + item.recordNo} 
                            placeholder={MessageCommon.TitleInput} 
                            defaultValue={item.cost}
                            disabled={detail.statusId === CartonStatus.Reject || detail.statusId === CartonStatus.Cancel || detail.statusId === CartonStatus.Delete}
                        />
                       
                  </td>
                  <td className="s-td text-center">
                   
                        <div className="form-check margin-left-45c">
                          <input  
                              checked={selectItems.indexOf(item.cartonName) > -1}
                              type="checkbox" 
                              id={'check-item-' + item.cartonName} 
                              value={item.cartonName} 
                              className="form-check-input check-item" 
                              onChange={(e) => onCheckChange(e)}
                            />
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonName}/>
                        </div>
                    
                  </td>
                </tr>
              ))
            }
            
            </tbody>
          </table>
        </div>
        <div className='margin-top-10 text-right'>
        { allCartonItems.length > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange}
                showSizeChanger={false}
              />:<></>
        }
        </div>
      </Card>

      
      <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={detail.requestNo} box={box} />
      </Modal>

      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.StorageCarton}/>
      </Modal>

      <Modal title="Display Log" open={isModalLogOwnerOpen} footer={''} width={900} onCancel={() => setIsModalLogOwnerOpen(false)}>
        <DisplayLogOwner key={Helper.Uniqid()} recordNo={detail.recordNo} />
      </Modal>

      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            validateMessages={validateMessages}
            initialValues={{'owner':1,'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="owner" label="Print type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" >
                    <Radio value={1}>Archive officer format</Radio>
                    <Radio value={2}>User format</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" >
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
      <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
            <textarea className='text-confirm' id="note-reject" rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
  

      {contextHolderMessage}
    </div>
  );

}

export default QueryDataStorageCarton
