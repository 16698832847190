import { useEffect, useState } from 'react';
import {  Button,message,Modal, Typography,Pagination,Spin,Popconfirm } from 'antd';
import { CartonStatus } from '../../enum';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { ChangeOwner ,Item, User,RequestChangeOwner,QueryDataModel} from '../../models'
import { Helper, InitHelper } from '../../helpers';
import { ChangeOwnerService ,HrWebService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ChangeOwnerStatusTracking = () => {
    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [recordNo, setRecordNo] = useState(0);
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [selectItems, setSelectItems] = useState<number[]>([])
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })

  const [newOwners, setNewOwners] = useState<Item[]>([])

  
  useEffect(() => {
    getData(1,appState.perpage)

  }, [])

  const handleEdit = (_recordNo:number) => {
    setRecordNo(_recordNo)
 }



  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
  
      let token = await getToken()
      let parameter = InitHelper.InitialParameter();
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.statusIds = [CartonStatus.WaitAdmin]
      parameter.userConfidential = user.employeeId
      parameter.orgUnitConfidential = user.orgUnit
      

      let result:any = await new ChangeOwnerService(token).Search(parameter)
     
      if(result && result.success)
      {
        let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.items = result.data.items
          state.isLoading = false
          state.perpage = perpage

        setTimeout(() => {
          setAppState(state)
        }, 500);
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
      
  }

 
 const onSearchEmployee = async(key:string) => {
   
  let token = await getToken()
  let result = await new HrWebService(token).SearchEmployee(key)
  if(result && result.success)
  {
    return result.data
  }
  else{
    console.log(result?.message)
    return []
  }
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const handleSave = async (statusId:number) => {
    if(newOwners.length === 0){
      return false
    }

      let requestChangeOwner:RequestChangeOwner = {
          recordNo: recordNo,
          recordNos: [],
        storageRecordNo: 0,
        requester:user.employeeId,
        onBehalfBy:user.employeeId,
        newOwner: newOwners[0].key,
        statusId: statusId
     }
      
      let token = await getToken()
      let result = await new ChangeOwnerService(token).UpdateChangeOwner(requestChangeOwner);
      
      
      let element = document.getElementById('table-container') as HTMLElement
      if(result && result.success){
  
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setRecordNo(0)
        getData(1,appState.perpage)
        
     }
      else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
      }
    
   
  };

    const handleDelete = async (_recordNo: number[]) => {
        
    
        let requestChangeOwner: RequestChangeOwner = {
            recordNo: 0,
        recordNos: _recordNo,
        storageRecordNo: 0,
        requester:"",
        onBehalfBy:"",
        newOwner: "",
        statusId: CartonStatus.Delete
     }
      
      let token = await getToken()
      let result = await new ChangeOwnerService(token).UpdateChangeOwner(requestChangeOwner);
      
      
      let element = document.getElementById('table-container') as HTMLElement
      if(result && result.success){
          setSelectItems([]);
          appState.items.forEach(item => {
              let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
              element.checked = false
          })
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
            setRecordNo(0)
            getData(1,appState.perpage)
        }, 1000);
    
     }
      else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
      }
    
   
  };
  const cancelSave = () => {
    setRecordNo(0)
    setNewOwners([])
  }
  

  const onPageChange = (page:number,perpage:number) => {
    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }


  const onNewOwnerSelect = (item:any) => {
    setNewOwners(item)
  }

    const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        let items: number[] = []
        if (e.target.checked) {
            appState.items.forEach(item => {
                let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
                element.checked = true
                items.push(parseFloat(element.value))
            })
        }
        else {
            appState.items.forEach(item => {
                let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
                element.checked = false
            })
        }

      
        setSelectItems(items)
    };

    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked
        let value = parseFloat(e.target.value)
        let items = Object.assign([], selectItems)
        if (checked) {
            items.push(value)
            let element = document.getElementById('check-item-all') as HTMLInputElement
            if (items.length === appState.items.length) {
                element.checked = true
                element.indeterminate = false
            }
            else {
                element.checked = false
                element.indeterminate = true
            }

        }
        else {
            let index = items.indexOf(value);
            if (index !== -1) {
                items.splice(index, 1);
            }

            if (items.length == 0) {
                let element = document.getElementById('check-item-all') as HTMLInputElement
                element.checked = false
                element.indeterminate = false
            }
        }

        
        setSelectItems(items)
    }

 
  return (
    <div>
          <div id="table-container">
              <div>
                  {selectItems.length > 0 ?
                      <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined className='c-red' />}
                          onConfirm={() => handleDelete(selectItems)}
                      >
                          <Button type="primary" className='btn-red mr-btn'>
                              <AiFillDelete className='icn-btn' /> Delete
                          </Button>
                      </Popconfirm>
                      :
                      <Button type="primary" className='btn-red mr-btn' disabled>
                          <AiFillDelete className='icn-btn' /> Delete
                      </Button>
                  }

              </div>
              
              <div></div>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table margin-top-15'>
            <thead id="head-table-fixed">
                          <tr>
                              <th className='text-left w50'>
                                  <div className="form-check">
                                      <input type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)} />
                                      <label className="form-check-label" htmlFor={'check-item-all'} />
                                  </div>

                              </th>
                <th>Change Owner Request No.</th>
                <th >Request No.</th>
                <th >Requested Date</th>
                <th >Requester</th>
                <th >Owner</th>
                <th >New Owner</th>
                <th >Status</th>
                <th className='min-width-130'>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                    <td className='text-left w50'>
                        <div className="form-check">
                            <input type="checkbox" id={'check-item-' + item.recordNo} value={item.recordNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)} />
                            <label className="form-check-label" htmlFor={'check-item-' + item.recordNo} />
                        </div>
                    </td>
                  <td className='text-center'>{item.requestNo}</td>
                  <td className='text-center'>{item.storageRequestNo}</td>
                  <td className='text-center'>{dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                  <td className='text-center'>{item.requesterName}</td>
                  <td className='text-center'>{item.ownerName}</td>
                  <td className='text-center'>
                    { item.recordNo === recordNo? 
                      <EmployeeAutoComplete
                        key={Helper.Uniqid()}
                        placeholder='Search by Name'
                        selectedItems={newOwners ? newOwners : []}
                        onChange={item => onNewOwnerSelect(item)}
                        service={onSearchEmployee}
                        minimumTextLength={3}
                        pickOnlyOne
                      /> :
                      <span>{item.newOwnerName}</span>
                     }
                  </td>
                  <td className='text-center'>{item.status}</td>
                  
                  <td className='text-center'>
                    { item.recordNo === recordNo ?
                      <div>
                        <span>
                          <Popconfirm title="Confirm to update?" onConfirm={() => handleSave(CartonStatus.WaitAdmin)}>
                            <Typography.Link>Update</Typography.Link>
                          </Popconfirm>
                          <a className='margin-left-10' onClick={cancelSave}>
                            Cancel
                          </a>
                        </span>
                      </div>:
                      <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo)} className='pointer'/>
                    }
                  </td>
                  <td className='text-center'>
                    <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined className='c-red' />}
                            onConfirm={() => handleDelete([item.recordNo]) }
      
                        >
                          <AiFillDelete size={20} color="red" className='pointer'/>
                        </Popconfirm>
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
        }
        </div>
        {contextHolderMessage}
    </div >
  );

}

export default ChangeOwnerStatusTracking
