import axios from 'axios'
import { DataArrayResponse,DataObjectResponse } from '../models/PageResponse'
import { Parameter,Employee} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class HrWebService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }

    async GetEmployee(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        const { data } = await axios.post<DataObjectResponse<Employee>>(`${apiUrl}/v1/hrws/employee`, JSON.stringify(parameter), options)
        return data;
    }
    async GetMasterEmployee(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        const { data } = await axios.post<DataObjectResponse<Employee>>(`${apiUrl}/v1/hrws/master`, JSON.stringify(parameter), options)
        return data;
    }
    async SearchEmployee(key:string ){
        if(!this.token) return null

        let options = {
            headers:this.headers,  
        }
        
        const { data }  = await axios.get<DataArrayResponse<Employee>>(`${apiUrl}/v1/hrws/search/employee?key=${key}`, options )
        
        return data;
    }

    async SearchEmployee2(key:string ){
        if(!this.token) return null

        let options = {
            headers:this.headers,  
        }
        
        const { data }  = await axios.get<DataArrayResponse<Employee>>(`${apiUrl}/v1/hrws/search/employee2?key=${key}`, options )
        
        return data;
    }

}