import React, { Component, PropsWithChildren } from 'react';
import { LeftBar } from './LeftBar';
import { Footer } from './Footer';
import { Link, useLocation } from 'react-router-dom';
import AppRoutes from '../AppRoutes';
import { Helper } from '../helpers';


const Title = () => {
  const location = useLocation();
  let curData = AppRoutes.find(item => item.path === location.pathname)
  return <>

    <div  className='card card-layout'>
      <div className='page-title-box d-flex align-items-center justify-content-between'>
        <h4 className="mb-0 mb0-layout">{curData?.icon} {curData?.title}</h4>
        <div className="page-title-right">
          {Breadcrumb(curData)}
        </div>
      </div>


    </div>
  </>;
}


const Breadcrumb = (data: any) => {
    if(!data){
        return 
    }

  let liList = data.breadcrumb.map(function (item: any, index: any) {
    if (!item.isLast) {
      if (item.path) {
        return <li className="breadcrumb-item"> <Link to={item.path} key={'breadcrumb-' + index}>{item.title}</Link> </li>
      } else {
        return <li className="breadcrumb-item" key={'breadcrumb-' + index}>{item.title}</li>
      }

    } else {
      return <li className="breadcrumb-item active" key={'breadcrumb-' + index}>{item.title}</li>
    }


  })


  return <>
    <ol className="breadcrumb m-0">
      {liList}
    </ol>
  </>;
}


export class Layout extends Component<PropsWithChildren> {
  static displayName = Layout.name;


  render() {
    return (
      <div>
        <div id="layout-wrapper">
          <LeftBar></LeftBar>
          <div className="main-content">
            <div className="page-content custom-page">
              <div className="container-fluid" >
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      {<Title key={Helper.Uniqid()}></Title>}

                      {/* <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item"><a >Dashonic</a></li>
                          <li className="breadcrumb-item active">Sales Analytics</li>
                        </ol>
                      </div> */}
                    </div>
                  </div>
                </div>
                {this.props.children}
              </div>

            </div>

            {/* End Page-content */}
            <Footer></Footer>
          </div>
          {/* end main content*/}
        </div>
        {/* END layout-wrapper */}
        {/* Right Sidebar */}
        <div className="right-bar">

        </div>
        {/* /Right-bar */}
        {/* Right bar overlay*/}
        <div className="rightbar-overlay" />
      </div >

    );
  }
}
