import {useEffect, useState } from 'react';
import { Pagination, Spin } from 'antd';
import dayjs from 'dayjs';
import { DisplayLogService } from '../../services';
import { LocationLog , User} from '../../models';
import { Helper } from '../../helpers';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
  recordNo:number
}

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:LocationLog[]
    isLoading:boolean
  }

const DisplayLogLocation = (props: Props) => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
    const [appState,setAppState] = useState<AppState>({
        currentPage : 1,
        total : 0,
        perpage: 20,
        items: [],
        isLoading : true
      })
    
    const getData = async (currentPage: number, perpage: number) => {
        let token = await getToken()
    let result = await new DisplayLogService(token).GetLocation(currentPage,perpage,props.recordNo)
    console.log(result)
    if(result && result.success){
        let state = Object.assign({},appState)
            state.total = result.data.total
            state.currentPage = currentPage
            state.items = result.data.items
            state.isLoading = false
  
            setTimeout(() => {
                setAppState(state)
            }, 500);
    }
    else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
    }
      
}

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


useEffect(() => {
    getData(1,appState.perpage)
}, [])

const onPageChange = (page:number,perpage:number) => {
   

    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
}

  return (

    <div className='min-height-150'>
      { appState.isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>:
        <>
          <div id="table-container" className='padding-top-15'>
          <table className='idoc-table'>
            <thead id="head-table-fixed">
              <tr>
                <th className='text-center'>Item No.</th>
                <th >Carton Location</th>
                <th >Last Update</th>
              </tr>
            </thead>
            <tbody >
              { appState.items.map((item:LocationLog,index:number) => (
                <tr>
                  <td className='text-center'>{((appState.currentPage -1) * appState.perpage) + (index + 1)}</td>
                  <td className='text-center'>{item.locationNo}</td>
                  <td className='text-center'>{ dayjs(item.createDate).format("DD MMM YYYY") }</td>
                </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                 showSizeChanger={true}
             />:<></>
        }
        </div>
        </>
      }
    
    </div>
  );

}

export default DisplayLogLocation
