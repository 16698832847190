import { useEffect, useState } from 'react';
import { Collapse, Form, message,Modal,Row,Spin,Card,Col,Popconfirm,Input,Typography,Pagination,Button,Select } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import {RequestType, CartonStatus, ExportType } from '../../enum';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { AiOutlineSync, AiOutlineSearch, AiFillSave,AiFillDelete,AiOutlinePrinter,AiFillEdit,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item, User,RequestUpdateOwner,RequestChangeOwner,Element} from '../../models'
import { Helper, InitHelper ,StickyHelper} from '../../helpers';
import { ChangeOwnerService ,FileManageService,HrWebService,PropertyService,StorageCartonService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    isLoading:boolean
  }

const RequestToChangeOwner = () => {
    const stickyHelper = new StickyHelper('changeowner',50,50)
    const stickyElement:Element = stickyHelper.getElement()
    
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
    const navigate = useNavigate();
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [modal, contextHolder] = Modal.useModal();
    const [recordNo, setRecordNo] = useState(0);
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [isPrint,setIsprint] = useState(true)
    const [activeKey,setActiveKey] = useState("0")
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      isLoading : true
    })
    
    const [owners, setOwners] = useState<Item[]>([])
    const [orgunits, setOrgUnits] = useState<Item[]>([])
    const [newOwners, setNewOwners] = useState<Item[]>([])
    const [selectItems, setSelectItems] = useState<number[]>([])
    const [requestTypeId,setRequestTypeId] = useState(RequestType.ChangeOwner)
    const [searchParam,SetSearchParam] = useState<Parameter>();
    const [isLoadingPrint,setIsLoadingPrint] = useState(false)
    const [clientHeight,setClientHeight] = useState(0)
    const onSetClientHeight = () => {
    
        setTimeout(() => {
          stickyHelper.updatePosition()
          window.scrollTo(0,0)
          let element = document.getElementById(stickyElement.container) as HTMLInputElement
          if(element){
            let value = element.clientHeight
            setClientHeight(value)
          }
        }, 100);
      
      }

    useEffect(() => {
        if(user.permissions.changeOwner === 0){
            navigate('/permissiondenided')
        }
        
        setClientHeight((document.body.clientHeight - window.innerHeight) / 2)
        getData(1,appState.perpage,InitHelper.InitialParameter()).then(() => {
            stickyHelper.updatePosition()
        
          })

        stickyHelper.removeEvent()
        stickyHelper.addEvent()

        window.addEventListener("resize", () => { 
            onSetClientHeight()
        });
      
    }, [])
    
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    
    
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
            
        let token = await getToken()
        let _requestTypeId = dataForm.getFieldValue('requestTypeId') ? dataForm.getFieldValue('requestTypeId') : RequestType.ChangeOwner
        let result:any
        parameter.requestTypeIds = [_requestTypeId];
        if(_requestTypeId === RequestType.ChangeOwner){
            result = await new ChangeOwnerService(token).SearchRequest(parameter)
        }
          
        if(_requestTypeId === RequestType.StorageCarton){
            parameter.statusIds = [CartonStatus.Done]
            parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect,CartonStatus.Return,CartonStatus.Extend]
            parameter.changeOwner = 0
            result = await new StorageCartonService(token).Search(parameter)
        }
        SetSearchParam(parameter)
       
        if(result && result.success)
        {   
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false

            if(result.data.total>0){
                setIsprint(false)
            }else{
                setIsprint(true)
            }
    
            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
                setRequestTypeId(_requestTypeId)
                setSelectItems([])
                setTimeout(() => {
                    onSetClientHeight()
                  }, 100);
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
        
        let field = dataForm.getFieldsValue()
        
        let parameter = InitHelper.InitialParameter();


        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }
           
        if(owners.length > 0){
            parameter.owners = owners.map(item => item.key)
        }
    
        if(orgunits.length > 0){
            parameter.orgUnits = orgunits.map(item => item.key)
        }
    
        
        getData(1,appState.perpage,parameter)
        
    }

    const reset = () => {
        
        dataForm.resetFields()
        setOwners([])
        setOrgUnits([])
       
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const onSearchEmployeeByOrg = async(key:string,orgUnit:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handleSave = async (statusId:number) => {
       
        let requestChangeOwner:RequestUpdateOwner = {
            recordIds: selectItems,
            approveBy:user.employeeId,
            approveStatusId: statusId
        }
       
        let token = await getToken()
        let result = await new ChangeOwnerService(token).AdminUpdate(requestChangeOwner);
          
        
        let element = document.getElementById(stickyElement.container) as HTMLElement
        if(result && result.success){
      
            messageApi.open({
              type: 'success',
              content: 'Save success',
              duration: 1,
              style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
            });
            setTimeout(() => {
             
                getData(1,appState.perpage)
              
            }, 1000);
        }
        else{
            Modal.error({
                icon: <CloseCircleOutlined />,
                title: 'This is an error message',
                content: result?.message,
            });
        }
    };
    
    const handleSaveOwner = async () => {
        if(newOwners.length === 0){
            return false
        }
      
        let index = appState.items.findIndex(x => x.recordNo === recordNo)
        let state = Object.assign({},appState)

        let requestChangeOwner:RequestChangeOwner = {
            recordNo: recordNo,
            recordNos: [],
            storageRecordNo: requestTypeId ===  RequestType.StorageCarton ? state.items[index].recordNo : 0,
            requester: requestTypeId === RequestType.StorageCarton ? user.employeeId : state.items[index].requester,
            onBehalfBy:requestTypeId === RequestType.StorageCarton ? user.employeeId : "",
            newOwner: newOwners[0].key,
            statusId: requestTypeId === RequestType.ChangeOwner ? CartonStatus.WaitAdmin : CartonStatus.Approve
        }
        
        let token = await getToken()
        let result
        if(requestTypeId === RequestType.ChangeOwner){
            result = await new ChangeOwnerService(token).UpdateChangeOwner(requestChangeOwner);
        }
        else{
            
            result = await new ChangeOwnerService(token).OnbehalfUpdate(requestChangeOwner);
        }
        
        
        let element = document.getElementById(stickyElement.container) as HTMLElement
        if(result && result.success){
    
          messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
          });
            setRecordNo(0)
            setNewOwners([])
          
          setTimeout(() => {
           
              getData(1,appState.perpage)
            
          }, 1000);
       }
        else{
          Modal.error({
              icon: <CloseCircleOutlined />,
              title: 'This is an error message',
              content: result?.message,
          });
        }
      
     
    };

    
    const cancelSave = () => {
        setRecordNo(0)
        setNewOwners([])
    }
    
    const onPageChange = (page:number,perpage:number) => {
        if(perpage !== appState.perpage){
            getData(1,perpage)
        }
        else{
            getData(page,appState.perpage)
        }
    }
    
    
    
    const onNewOwnerSelect = (item:any) => {
        setNewOwners(item)
    }
    
    const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
        let items:number[] = []
        if(e.target.checked){
          appState.items.forEach(item => {
            let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
            element.checked = true
            items.push(parseFloat(element.value))
          })
        }
        else{
          appState.items.forEach(item => {
            let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
            element.checked = false
          })
        }
        setSelectItems(items)
      };
    
      const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked
        let value = parseFloat(e.target.value)
        let items = Object.assign([],selectItems)
        if(checked){
            items.push(value)
            let element = document.getElementById('check-item-all') as HTMLInputElement
            if(items.length === appState.items.length){
              element.checked = true
              element.indeterminate = false
            }
            else{
              element.checked = false
              element.indeterminate = true
            }
            
        }
        else{
          let index = items.indexOf(value);
          if (index !== -1) {
            items.splice(index, 1);
          }
    
          if(items.length === 0){
            let element = document.getElementById('check-item-all') as HTMLInputElement
            element.checked = false
            element.indeterminate = false
          }
    }
    
        
        setSelectItems(items)
      }
    
      const handleEdit = (_recordNo:number) => {
        setRecordNo(_recordNo)
     }

     const onOwnerSelect = (item:any) => {
        setOwners(item)
      }

      const onOrgUnitSelect = (item:any) => {
        setOrgUnits(item)
      }

      const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }
    const onFinishPrint = async () => {
        setIsLoadingPrint(true)

        if(!searchParam)return
        let token = await getToken()
       
        try{

            await new FileManageService(token).DownloadReport(searchParam,ExportType.CHANGE_OWNER);
        }catch(error){
            console.log("🚀 ~ file: RequestToChangeOwner.tsx:399 ~ onFinishPrint ~ error:", error)

        }finally{
            setIsLoadingPrint(false)

        }
    }


    return (
    <div>
        <div  id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
            <Form
              {...layout}
              form={dataForm}
              name="change-owner-form"
              validateMessages={validateMessages}
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                  <Form.Item name="requestTypeId" label="Action"  >
                    <Select placeholder={MessageCommon.TitleSelect} defaultValue={RequestType.ChangeOwner} 
                      options={[
                        { value: RequestType.ChangeOwner, label: 'Request from User' },
                        { value: RequestType.StorageCarton, label: 'On Behalf by Admin' },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="requestNo" label="Storage Request No."  >
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={11} >
                    <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                <Form.Item name="owner" label="Owner"  >
                    <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                </Form.Item>
                </Col>
              </Row>
  
  
            </Form>
            <div className='text-center'>
                <Button type="default" className='mr-btn' onClick={reset} >
                    <AiOutlineSync className='icn-btn' /> Reset
                </Button>
                <Button type="primary"   onClick={searchData}>
                    <AiOutlineSearch className='icn-btn' /> Search
                </Button>
            </div>
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>
        
        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
            { requestTypeId === RequestType.ChangeOwner ?
                <div className='margin-bottom-25'>
                    { selectItems.length > 0 ?
                        <>
                            <Popconfirm
                                title="Cancel the task"
                                description="Confirm to cancel?"
                                icon={<QuestionCircleOutlined className='c-red' />}
                                onConfirm = { () =>  handleSave(CartonStatus.Cancel)}
                            >
                                <Button type="primary" className='btn-red mr-btn'>
                                    <AiFillDelete className='icn-btn' /> Cancel
                                </Button>
                            </Popconfirm>
                             <Popconfirm
                                title="Update the task"
                                description="Confirm to update?"
                                icon={<QuestionCircleOutlined className='c-red' />}
                                onConfirm = { () =>  handleSave(CartonStatus.Approve) }
                            >
           
                                <Button type="primary" >
                                    <AiFillSave className='icn-btn' /> Update
                                </Button>
          
                            </Popconfirm>
                        </>:
                        <>
                            <Button type="primary" className='btn-red mr-btn' disabled>
                                <AiFillDelete className='icn-btn' /> Cancel
                            </Button>
          
                            <Button type="primary" disabled>
                                <AiFillSave className='icn-btn' /> Update
                            </Button>
                            
                        </>
                    }
                    <Button  className='mr-btn btn-print' disabled={isPrint} onClick={()=>onFinishPrint()}>
                        <AiOutlinePrinter className='icn-btn' />Print
                    </Button>
                    { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                </div>
                :
                <div className='margin-bottom-25'>
                    { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                </div>
            }

        { requestTypeId === RequestType.ChangeOwner ?
             <div id="panel-action">
                    <div>
                        <div className='search-result'>Search found { appState.total } record(s).</div>
                    </div>
                </div>
                :
                <div id="panel-action"  className='margin-top-55'>
                    <div>
                        <div className='search-result'>Search found { appState.total } record(s).</div>
                    </div>
                </div>
            }
{/* 
                <div>
                    <label className='f-right' >ระบบจะมีการแจ้ง Pending Request List ทุกๆ 15 วัน</label>
                </div> */}
        </div>

        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
            <div id={stickyElement.container}>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                                 <th className='thfix1 text-center w50'>
                                    <div className="form-check">
                                        <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                                        <label className="form-check-label" htmlFor={'check-item-all'}/>
                                    </div>
                      
                                </th>
                            { requestTypeId === RequestType.ChangeOwner ?
                                <th className="s-th thfix2">Change Owner Request No.</th> 
                                :<></>
                            }
                                <th className={ requestTypeId !== RequestType.ChangeOwner ? 's-th thfix2' : 's-th'}>Storage Request No.</th>
                                <th className="s-th">Requested Date</th>
                                <th className="s-th">Requester</th>
                                <th className="s-th">Owner</th>
                                <th className="s-th">Org. Unit</th>
                                <th className="s-th">New Owner</th>
                                <th className="s-th">Edit</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                    <td className='text-center w50'>
                                        <div className="form-check">
                                            <input  type="checkbox" id={'check-item-' + item.recordNo} value={item.recordNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                                            <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                                        </div>
                                    </td>
                                { requestTypeId === RequestType.ChangeOwner ?
                                    <td className='s-td text-center'>{item.requestNo}</td>:<></>
                                }
                                    <td className='s-td text-center'>{requestTypeId ===  RequestType.StorageCarton ? item.requestNo : item.storageRequestNo }</td>
                                    <td className='s-td text-center'>{dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                                    <td className='s-td text-center'>{item.requesterName}</td>
                                    <td className='s-td text-center'>{item.ownerName}</td>
                                    <td className='s-td text-center'>{item.orgUnit}</td>
                                    <td className='s-td text-center'>
                                      { item.recordNo === recordNo? 
                                        <EmployeeAutoComplete
                                          key={Helper.Uniqid()}
                                          placeholder='Search by Name'
                                          selectedItems={newOwners ? newOwners : []}
                                          onChange={item => onNewOwnerSelect(item)}
                                          service={(key:string) => onSearchEmployee(key)}
                                          minimumTextLength={3}
                                          pickOnlyOne
                                      /> : <>{item.newOwnerName}</> }
                                    </td>
                                    
                                    <td className='s-td text-center'>
                                        { item.recordNo === recordNo ?
                                             <div>
                                                <span>
                                                    <Popconfirm title="Confirm to update?" onConfirm={handleSaveOwner}>
                                                        <Typography.Link>Update</Typography.Link>
                                                    </Popconfirm>
                                                        <a className='margin-left-10' onClick={cancelSave}>
                                                            Cancel
                                                        </a>
                                                </span>
                                            </div>:
                                                <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo)} className='pointer'/>
                                        }
                                    </td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
            </div>
            <div className='margin-top-10 text-right'>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
            </div>
        </div>
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
        
        {contextHolderMessage}
        {contextHolder}
       
    </div>
    );
  
  }
  
  export default RequestToChangeOwner
  