import axios from 'axios'
import { getCookie } from '../tools'
import { config } from '../config'
const apiUrl = config.API_URL

export default class CookieHelper{
    static async getCookie (token:string){
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let options = {
            headers:headers,
        }
       await axios.get(`${apiUrl}/v1/antiforgery/call`,options)
        let xsrfToken = getCookie('XSRF-TOKEN');
        
       
        return xsrfToken
    }

}