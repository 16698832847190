import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin , Pagination ,message,Popconfirm} from 'antd';
import { AiFillDelete, AiOutlineRotateRight } from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { QueryDataModel ,RequestUpdateStatus, User} from '../../models';
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus } from '../../enum';
import { NewCartonService } from '../../services';
import NewCartonRequestDetail from './NewCartonRequestDetail';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const NewCartonStatusTracking = () => {
    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const navigate = useNavigate();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [recordNo, setRecordNo] = useState(0);
  const [selectItems, setSelectItems] = useState<number[]>([])
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })

  useEffect(() => {
      getData(1,appState.perpage)
  }, [])

  const getData = async (currentPage: number,perpage: number) => {
      let parameter = InitHelper.InitialParameter();
      parameter.page = currentPage
      parameter.limit = perpage
      parameter.statusIds = [CartonStatus.WaitAdmin,CartonStatus.Inprogress,CartonStatus.WaitApproval]
      parameter.requesters = [user.employeeId]
     
      let token = await getToken()
      let result = await new NewCartonService(token).Search(parameter)
      console.log(result)
      if (!result)
          return null;
      let {page,limit,total,items} = result.data
      let state = Object.assign({},appState)
      state.total = total
      state.currentPage = page
      state.totalPgae = Math.ceil(total / limit)
      state.items = items
      state.isLoading = false
      state.perpage = perpage
      setAppState(state)
  }

  const showModalCarton = async (id:number) => {
    setRecordNo(id)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const handleRoleback = async (statusId: number) => {
    let token = await getToken()
    let requestUpdateStatus:RequestUpdateStatus = {
      recordIds: selectItems,
      statusId: statusId
    }
    let result = await new NewCartonService(token).UpdateStatus(requestUpdateStatus)
    console.log(result)
    if(result && result.success){
      let element = document.getElementById('new-carton-tracking') as HTMLDivElement
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      setTimeout(() => {
        
            appState.items.forEach(item => {
                let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
                element.checked = false
              })
              let element = document.getElementById('check-item-all') as HTMLInputElement
                element.checked = false
                element.indeterminate = false
              setSelectItems([])
            getData(1,appState.perpage)
        
      }, 1000);
      
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:number[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        if(!element.disabled){
          element.checked = true
          items.push(parseFloat(element.value))
        }
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = parseFloat(e.target.value)
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length == 0){
        let element = document.getElementById('check-item-all') as HTMLInputElement
        element.checked = false
        element.indeterminate = false
      }
    }

    console.log(items)
    setSelectItems(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(perpage !== appState.perpage){
      getData(1,perpage)
  }
  else{
      getData(page,appState.perpage)
  }
}

 
  return (
    <div>
        <div id="new-carton-tracking" className='float-left'>
        { selectItems.length > 0 ? 
        <>
          <Popconfirm
            title="Recall the task"
            description="Confirm to recall?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => handleRoleback(CartonStatus.SaveDraft) }
            >
            <Button type="primary" className='mr-btn' >
                <AiOutlineRotateRight className='icn-btn' /> Recall
            </Button>
          </Popconfirm>
          <Popconfirm
           title="Cancel the task"
           description="Confirm to cancel?"
           icon={<QuestionCircleOutlined className='c-red' />}
            onConfirm = { () => handleRoleback(CartonStatus.Cancel) }
          >
           <Button type="primary" className='btn-red mr-btn' >
               <AiOutlineRotateRight className='icn-btn' /> Cancel
           </Button>
          </Popconfirm>
        </>
        :
        <>
          <Button type="primary" className='mr-btn' disabled>
            <AiOutlineRotateRight className='icn-btn' /> Recall
          </Button>
          <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Cancel
          </Button>
        </>
        }
       
      </div>
      <div className='float-right'>
         
      </div>
      <div></div>
        { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
            <div id="table-container">
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th className='text-left w50'>
                      <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                    </th>
                    <th className='text-left'>Request No.</th>
                    <th >Requested Date</th>
                    <th >Expected Receive Date</th>
                    <th >Deliver to..</th>
                    <th >Carton Quantity</th>
                    <th >Status</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:QueryDataModel) => (
                    <tr>
                      <td className='text-left w50'>
                        <div className="form-check">
                          <input  
                            type="checkbox" 
                            id={'check-item-' + item.recordNo} 
                            disabled = { item.statusId !== CartonStatus.WaitAdmin}
                            value={item.recordNo} 
                            className={ item.statusId !== CartonStatus.WaitAdmin ? "form-check-input check-item bg-grey" : "form-check-input check-item"}
                            onChange={(e) => onCheckChange(e)}
                          />
                          <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                        </div>
                      </td>
                      <td className='text-left'>
                      <a className='a-table' onClick={() => showModalCarton(item.recordNo)}>{item.requestNo}</a>
                      </td>
                      <td className='text-center'>{ dayjs(item.requestDate).format("DD MMM YYYY")}</td>
                      <td className='text-center'>{ dayjs(item.receiveDate).format("DD MMM YYYY")}</td>
                      <td className='text-center'>{item.delivertoName}</td>
                      <td className='text-center'>{item.quantity}</td>
                      <td className='text-center'>{item.status}</td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className='margin-top-10 text-right'>
            { appState.total > 0 ?
                <Pagination 
              
                    key={Helper.Uniqid()}
                    current={appState.currentPage} 
                    pageSize={appState.perpage}
                    total={appState.total} 
                    onChange={onPageChange} 
                    pageSizeOptions={pageSizeOptions}
                    
                    showSizeChanger={true}
                 />:<></>
            }
            </div>
          </>
        }
         <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
              <NewCartonRequestDetail recordNo={recordNo}/>
        </Modal>

        {contextHolderMessage}
    </div>
  );

}

export default NewCartonStatusTracking
