import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import CollectCartonRequest from './CollectCartonRequest'
import CollectCartonStatusTracking from './CollectCartonStatusTracking';
import CollectCartonSaveDraft from './CollectCartonSaveDraft';

const CollectCarton3 = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
   
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Collect`,
      children: <CollectCartonRequest onBehalf={0}/>
    },
    {
      key: '2',
      label: `Status Tracking`,
      children: <CollectCartonStatusTracking/>,
    },
    {
      key: '3',
      label: `Save Draft`,
      children: <CollectCartonSaveDraft/>,
    },

  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/collect-carton')
    }
    else{
        let tabstr = tab === '2' ? 'tracking' : 'save'
        navigate('/collect-carton-' + tabstr )
    }
  }
  

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey={'3'} items={items} onChange={onTabChange}   destroyInactiveTabPane/>
      </Card>
    </div>
  );

}

export default CollectCarton3
