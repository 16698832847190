import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import WarehouseRequest from './WarehouseRequest';
import WarehouseList from './WarehouseList';

const ManageExternalWarehouse = () => {
  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Manage`,
      children: <WarehouseRequest/>,
    },
    {
      key: '2',
      label: `List`,
      children: <WarehouseList/>,
    },

  ];

  useEffect(() => {
 
  }, [])

  return (
    <div>
      <Card >
        <Tabs defaultActiveKey="1" items={items}  />
      </Card>




    </div >
  );

}

export default ManageExternalWarehouse
