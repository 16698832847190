import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, Form,  Modal, Radio, Row, Select } from 'antd';
import { AiOutlineCheck, AiOutlineClose, AiOutlinePrinter, AiOutlineSearch, AiOutlineSync } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
//import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { QueryService, PropertyService ,WarehouseService, FileManageService} from '../../services';
import SummaryReport from '../../models/SummaryReport';
import SummaryLocation from '../../models/SummaryLocation';
import { Option , Item,Parameter} from '../../models'
import { InitHelper,Helper } from '../../helpers';
import {RequestTypeOption, CartonStatus,RequestType } from '../../enum';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import dayjs from 'dayjs'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

let cartonQtyPiechart:any = null
let cartonQtyBarchart:any = null


const ReportCartonQuantity = () => {
  
    const { instance, accounts } = useMsal();
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [warehouseOptions, setWarehouseOptions] = useState<Option[]>([])
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
    const [documentStartYear, setDocumentStartYear] = useState<number | null | undefined>((new Date().getFullYear() - 4) );
    const [documentEndYear, setDocumentEndYear] = useState<number | null | undefined>((new Date().getFullYear()) );
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const initialRequestTypeIds:number[] = [
    RequestType.NewCarton, 
    RequestType.StorageCarton, 
    RequestType.CollectCarton,
    RequestType.ReturnCarton,  
    RequestType.RemoveCarton, 
    RequestType.DestructionCarton
  ]
  const [requestTypeIds,setRequestTypeIds] = useState<number[]>(initialRequestTypeIds)
  const [warehouseId, setWarehouseId] = useState("")
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [printForm] = Form.useForm();
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
 


  const createBarChart = (data:SummaryLocation[]) => {
      if(cartonQtyBarchart)
      {
        cartonQtyBarchart.clearChart()
      }
      
      let element = document.getElementById('carton-quantity-barchart')
      let element2 = document.getElementById('carton-quantity-barchart-no-data')

      if(element2)
           element2.style.display = 'none'

      let _requestTypeId = dataForm.getFieldValue("requestType")
      if (!_requestTypeId) {
          _requestTypeId = 0
      }
      let temp = RequestTypeOption.filter(x => x.value === parseInt(_requestTypeId));
      let _requestType = "Selected Carton";
      let field = dataForm.getFieldsValue()
      if (!field.requestType)
          _requestType = "All Carton";
      else if (field.requestType.length <= 1 && temp.length > 0)
          _requestType = temp[0].label 
         
      let title = `Quantity Of ${_requestType} By Carton Location`
      let labels = data.map( x => x.year).sort()
      let chartData:any= []

      if(data.length > 0)
      {
        chartData.push(['Year', 'Normal','ValveRoom'])
        labels.forEach( year => {
        let item:SummaryLocation|undefined = data.find(x =>x.year == year)
        if(item){
          let normal = item.normal > 0 ? item.normal : 0
          let valveRoom = item.valveRoom > 0 ? item.valveRoom : 0
          chartData.push([item.year.toString(),normal,valveRoom])
        }
         

        })

        console.log(chartData)
      
        cartonQtyBarchart = CreateCartonQtyBarChart(element,title,chartData,['#36A2EB','#FF6384'])
      }
      else{
        if(element2){
            element2.style.display = 'inline'
            element2.innerHTML = 'No Data'
        }
          
      }
  }

  const createPieChart = (data:any[]) => {
    if(cartonQtyPiechart)
    {
      cartonQtyPiechart.clearChart()
    }
    
    let element = document.getElementById('carton-quantity-piechart')
    let element2 = document.getElementById('carton-quantity-piechart-no-data')
    
    if(element2)
      element2.style.display = 'none'

    let backgroundColors = ['#36A2EB','#cc65fe','#4BC0C0','#FF9F40','#FFCD56','#FF6384','#3C5B6F','#A79277','#40679E']
    
      let field = dataForm.getFieldsValue()
     
      let colors:string[] = []
      let allKeys =  Object.keys(data)
      let allValues = Object.values(data)
      let chartData:[[string,string|number]] = [['RequestType', 'Qty']]
      let sumData = 0
      let requestType = "All Carton";
      let requestTypeFlag = 0
      if(field.requestType && field.requestType.length > 0)
      {
        requestTypeFlag = 1
      } 
      if( !requestTypeFlag || field.requestType.indexOf(RequestType.NewCarton) > -1){
        requestType = allKeys[0]+" Carton"
        sumData =sumData + allValues[0]
        chartData.push([allKeys[0]+" Carton (" + comma(allValues[0]) + ")",allValues[0]])
        colors.push(backgroundColors[0])
      }
      if(!requestTypeFlag || field.requestType.indexOf(RequestType.StorageCarton) > -1){
        requestType = allKeys[1]+" Carton"
        sumData =sumData + allValues[1]
        chartData.push([allKeys[1] +" (" + comma(allValues[1]) + ")",allValues[1]])
        colors.push(backgroundColors[1])
      }

      if(!requestTypeFlag || field.requestType.indexOf(RequestType.CollectCarton) > -1){
        requestType = allKeys[2]+" Carton"
        sumData =sumData + allValues[2]
        chartData.push([allKeys[2] +" (" + comma(allValues[2]) + ")",allValues[2]])
        colors.push(backgroundColors[2])
      }
      if(!requestTypeFlag || field.requestType.indexOf(RequestType.ReturnCarton) > -1){
        requestType = allKeys[3]+" Carton"
        sumData =sumData + allValues[3]
        chartData.push([allKeys[3] +" (" + comma(allValues[3]) + ")",allValues[3]])
        colors.push(backgroundColors[3])
      }
      if(!requestTypeFlag || field.requestType.indexOf(RequestType.RemoveCarton) > -1){
        requestType = allKeys[4]+" Carton"
        sumData =sumData + allValues[4]
        chartData.push([allKeys[4] +" (" + comma(allValues[4]) + ")",allValues[4]])
        colors.push(backgroundColors[4])
      }
      if(!requestTypeFlag || field.requestType.indexOf(RequestType.DestructionCarton) > -1){
        requestType = allKeys[5]+" Carton"
        sumData =sumData + allValues[5]
        chartData.push([allKeys[5] +" (" + comma(allValues[5]) + ")",allValues[5]])
        colors.push(backgroundColors[5])
      }
      
   
    let title =  `Quantity Of ${requestType} By Year`
      console.log(data)
    if(sumData > 0){
        console.log(chartData)
        cartonQtyPiechart = CreateCartonQtyPieChart(element,title, chartData,colors)
    }
    else{
      if(element2){
        element2.style.display = 'inline'
        element2.innerHTML = 'No Data'
    }
    }
  }

  const comma =(x:number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }



  const getData = async (_parameter:Parameter) => {
    setIsLoading(true)
    let token = await getToken()
    let result = await new QueryService(token).GetSummary(_parameter);
    console.log(result)
    if(result && result.success)
    {
      let data = result.data
      let gData:any = genData(data)
      createPieChart(gData)
      createBarChart(result.data.locations)
    
    }

    setIsLoading(false)
  }

  const genData = (data:any) => {
    let gData:any = []
     
        gData["New"] =  data.newcarton
        gData["Storage"] =  data.storage
        gData["Collect"] =  data.collect
        gData["Return"] =  data.return
        gData["Remove"] =  data.remove
        gData["Destruction"] =  data.destruction
      
    
    return gData
  }

  const getWarehouses = async () => {
    let token = await getToken()
      let result = await new WarehouseService(token).GetAllWarehouses()
    if(result && result.success){
        let options:Option[] = [{ label:'All' , value: "" }]
        let Locationoptions: Option[] = result.data.map((item) => { return { value: item.warehouseName, label: item.warehouseName  }})
        setWarehouseOptions(options.concat(Locationoptions))
    }
  }


    useEffect(() => {
        //console.log("Your screen resolution is: " + window.screen.width * window.devicePixelRatio + "x" + window.screen.height * window.devicePixelRatio);
      
       
        setRequestTypeIds(initialRequestTypeIds)
    getWarehouses()
    searchData()
    
  }, [])

  const printParam = (parameter:Parameter) => {
    var obj:any = {
      cartonLocation : parameter.cartonLocation,
      statusIds: parameter.statusIds,
      requestTypeIds: parameter.requestTypeIds,
      orgunits :  parameter.orgUnits,
      requestStartDate: parameter.requestStartDate,
      requestEndDate: parameter.requestEndDate
    }
    console.log(obj)
  }

  const searchData = async () => {
    let field = dataForm.getFieldsValue()
    let parameter = InitHelper.InitialParameter();
      //parameter.warehouseId =  0
      parameter.cartonLocation = warehouseId+""
      
        parameter.statusIds = [
            CartonStatus.WaitApproval,
            CartonStatus.WaitAdmin,
            CartonStatus.Inprogress,
            CartonStatus.Done,
            CartonStatus.Reject,
            CartonStatus.Cancel
        ]

        parameter.requestTypeIds = initialRequestTypeIds
        
    if(field.requestType  && field.requestType.length > 0){
        parameter.requestTypeIds = field.requestType
    }
   
    if(field.statusId && field.statusId.length > 0){
      parameter.statusIds = field.statusId
    }

    if(orgunits.length > 0){
      parameter.orgUnits = orgunits.map(item => item.key)
    }

      if (documentStartYear && documentStartYear > 0 && documentEndYear && documentEndYear > 0) {
          parameter.requestStartDate = documentStartYear + "-01-01"
          parameter.requestEndDate = documentEndYear + "-12-31"
          //parameter.documentYear1 = documentStartYear
          //parameter.documentYear2 = documentEndYear
      } else {
          parameter.requestStartDate = (new Date().getFullYear()-4) + "-01-01"
          parameter.requestEndDate = new Date().getFullYear() + "-12-31"
      }
printParam(parameter)
    getData(parameter)
    
  }

  const reset = () => {
    
    dataForm.resetFields()
    
    setOrgUnits([])
    setDocumentStartYear(new Date().getFullYear() - 4)
    setDocumentEndYear(new Date().getFullYear())
    setWarehouseId("")
    let parameter = Object.assign({},InitHelper.InitialParameter())
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.statusIds = [
        CartonStatus.WaitApproval,
        CartonStatus.WaitAdmin,
        CartonStatus.Inprogress,
        CartonStatus.Done,
        CartonStatus.Reject,
        CartonStatus.Cancel
    ]
    
      parameter.requestStartDate = documentStartYear + "-01-01"
      parameter.requestEndDate = documentEndYear + "-12-31"
     
    parameter.warehouseId = 0
    getData(parameter)
  }

  const onWarehouseIdChange = (value:string) => {
    setWarehouseId(value)
}
  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };

  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
      setDocumentStartYear(year)
      if (documentEndYear == null || (year + 4) < parseInt(documentEndYear + "")) {
          setDocumentEndYear(year + 4)
      }
  }

  const showPrintModal = () => {
    setIsPrintModalOpen(true);
  };
  
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
      setDocumentEndYear(year)
      if (documentStartYear == null || (year - 4) > parseInt(documentStartYear + ""))
          setDocumentStartYear(year - 4)
  }

  const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
  }

  const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
  }


  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)
    try{

      let a = document.createElement('a');
      let pie = cartonQtyPiechart.getImageURI()
      let bar = cartonQtyBarchart.getImageURI()
       
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      if (!context) return;
      
    
      const image1 = new Image();
      image1.src = pie;
      const image2 = new Image();
      image2.src = bar;
      let mergedImageDataURL = ""
  
      const image1Promise = new Promise<HTMLImageElement>((resolve) => {
        image1.onload = () => resolve(image1);
      });
  
      const image2Promise = new Promise<HTMLImageElement>((resolve) => {
        image2.onload = () => resolve(image2);
      });
  
      const [loadedImage1, loadedImage2] = await Promise.all([image1Promise, image2Promise]);
      context.drawImage(loadedImage1, 0, 0, loadedImage1.width,loadedImage1.height);
      context.drawImage(loadedImage2, loadedImage1.width, 0, loadedImage2.width, loadedImage2.height);
      mergedImageDataURL = canvas.toDataURL('image/png');
  
      if(values.print === 1){
        let token = await getToken()
        let body ={content:mergedImageDataURL}
        await new FileManageService(token).DownloadPdfImgage(body,"CartonQuantitybyRequestType");
      }else{
  
        a.href = mergedImageDataURL
        a.download = 'CartonQuantity.png';
        a.click();
      }
    }catch(error){
    console.log("🚀 ~ file: ReportCartonQuantity.tsx:568 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)
    }


  }

  return (

    <div>
      <canvas className='display-none' ref={canvasRef} width={1470} height={425}></canvas>
      <Collapse>
      {isLoadingPrint?<Loading/>:<></>}

        <Panel header="Search" key="1">
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="requestType" label="Request Type" >
                      <Select 
                       mode="multiple"
                        placeholder={MessageCommon.TitleSelect} 
                       
                        options={ [
                            { value: RequestType.NewCarton, label: 'New Carton' },
                            { value: RequestType.StorageCarton, label: 'Storage Carton' },
                            { value: RequestType.CollectCarton, label: 'Collect Carton' },
                            { value: RequestType.ReturnCarton, label: 'Return Carton' },
                            { value: RequestType.RemoveCarton, label: 'Remove Carton' },
                            { value: RequestType.DestructionCarton, label: 'Destruction Carton' },
                          ] }
                      />
                    </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >

              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="documentyear" label="Year">
                  <table className='w100c'>
                    <tr>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          className='w100c' 
                          onChange={onDocumentStartYearChange}
                                                  value={dayjs(documentStartYear+"-01-01")}
                        />
                      </td>
                      <td>
                        <Label className='margin-left10 margin-right10 vertical-align-top'>To</Label>
                      </td>
                      <td>
                        <DatePicker 
                          placeholder={MessageCommon.TitleDate} 
                          picker='year' 
                          className='w100c'
                                                  onChange={onDocumentEndYearChange}
                                                  value={dayjs(documentEndYear + "-12-31")}
                        />
                      </td>
                    </tr>
                  </table>


                </Form.Item>

              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="statusId" label="Status"   >
                  <Select 
                    placeholder={MessageCommon.TitleSelect} 
                    mode="multiple"
                    allowClear
                    options={[
                      { value: CartonStatus.WaitApproval, label: 'Wait for Approval' },
                      { value: CartonStatus.WaitAdmin, label: "Wait for Admin Service" },
                      { value: CartonStatus.Inprogress, label: 'On Going' },
                      { value: CartonStatus.Done, label: 'Completed Service' },
                      { value: CartonStatus.Reject, label: 'Rejected' },
                      { value: CartonStatus.Cancel, label: 'Cancel' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={11} >
              <Form.Item name="cartonLocation" label="Carton Location"  initialValue={""}>
                    <Select 
                        showSearch
                        onChange={onWarehouseIdChange}
                        placeholder="Please select..." 
                        options={warehouseOptions} 
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                 </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
              <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={orgunits ? orgunits : []}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
              </Col>
            </Row>



          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={reset}>
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary"  onClick={searchData} >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>

        </Panel>
      </Collapse>


      <br></br>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Data Result" key="1" >
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => showPrintModal()}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          { isLoading ? <Loading/> : <></> }
          <br></br>
          <br></br>
        
          <Row >
              <Col md={11}>
                <div  id="carton-quantity-piechart-no-data" className="no-data"></div>
                <div  id="carton-quantity-piechart" className="w100c height-400"></div>
               
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                  <div  id="carton-quantity-barchart-no-data" className="no-data"></div>
                  <div  id="carton-quantity-barchart" className="w100c height-400"></div>
                
              </Col>
          </Row>
        
      
        </Panel>
      </Collapse>
    
      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            validateMessages={validateMessages}
            initialValues={{'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>PNG</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>
    
    </div>
  )

}

export default ReportCartonQuantity
