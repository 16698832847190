import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Radio, Row ,Spin} from 'antd';
import {  AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';
import { Label } from 'reactstrap';
import CartonList from '../Share/CartonList'
import { RequestDetail } from '../../models';
import { ReturnCartonService } from '../../services';
import { InitHelper } from '../../helpers';
import dayjs from 'dayjs'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User } from '../../models'
import { CartonStatus } from '../../enum';

interface Props{
  recordNo:number
  approveLevel: number
  disableDetail?: boolean
  onBehalf?:boolean
}

const ReturnCartonRequestDetail = (props: Props) => {
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const { instance, accounts } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isLoading, setIsLoading] = useState(true)
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  

    useEffect(() => {
        setIsModalOpen(false)
    getData()
  }, [])
  
  const getData = async () => {
      setIsLoading(true)
      let token = await getToken()
    let result = await new ReturnCartonService(token).GetDetail(props.recordNo)
    console.log(result)
    if(result && result.success){
      setDetail(result.data)
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  return (

    <div className='min-height-150'>
    { isLoading ?
       <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        :
      <Card >
        <Form
          {...layout}
          form={dataForm}
          name="nest-messages"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item name="requester" label="Requester" labelCol={{ md: 3 }}  >

              <Label className='label-det'>{ detail.requesterName }</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{ detail.orgUnit }</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{ detail.position }</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{ detail.tel }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="requestNo" label="Request No"  >
                <Label className='label-det '>{detail.requestNo}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>


          <Row>
            <Col md={11} >
                <Form.Item name="quantity" label="Carton Quantity"  >
                <Label className='label-det'>{detail.quantity} carton(s)</Label>
              </Form.Item>
              
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                <Form.Item name="location" label="Location"  >
                <Label className='label-det'>{detail.location}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
            <Form.Item name="floor" label="Floor"  >
                <Label className='label-det'>{detail.floor}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="place" label="Place"  >
                <Label className='label-det'>{detail.place}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
                <Form.Item name="returnDate" label="Return Date"  >
                <Label className='label-det'>{dayjs(detail.returnDate).format('DD MMM YYYY')}</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="remark" label="Remark"  >
                <Label className='label-det'>{detail.remark}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >

                              {detail.statusId === CartonStatus.Reject ?
                                  <Form.Item name="noteReject" label="Rejected Reason"  >
                                      <Label className='label-det'>{detail.notApproveReason}</Label>
                                  </Form.Item> : <></>
                              }
              <Form.Item name="note" label="Note"  >
              { detail.note.length > 0 ?
                <>
                    <Label className='label-det'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label'>Display Log</a>
                </>:<></>
                }
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            {detail.serviceDate?
              <Form.Item name="serviceDate" label="Service Date"  >
                <Label className='label-det'>{ dayjs(detail.serviceDate).format("DD MMM YYYY")  }</Label>
              </Form.Item>:<></>
                              }

            </Col>
          </Row>

                      <Row>
                          <Col md={11} >
                          { detail.requester !== detail.onBehalf && props.onBehalf ?
                              <Form.Item name="onbehaft" label="On Behalf"  >
                                  <Label className='label-det'>{detail.onBehalfName}</Label>
                              </Form.Item>:<></>
                            }
                          </Col>
                          <Col md={2} >

                          </Col>
                          <Col md={11} >
                          { detail.extendReason ?
                <Form.Item label="Extend Reason"  >
                <Label className='label-det'>{detail.extendReason}</Label>
              </Form.Item>:<></>
            }
                          </Col>
                      </Row>



        </Form>
        <hr></hr>
        <CartonList requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={props.disableDetail} statusId={detail.statusId}/>

      </Card>
    }

      <Modal title="" open={isModalOpen} footer={''} width={600} >
        <br></br>
        <Card >
          <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="owner" label="Request No" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>Archive officer format</Radio>
                    <Radio value={2}>User format</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}  >
                <Form.Item name="owner" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>PDF</Radio>
                    <Radio value={2}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn'  >
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'  >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>



        </Card>
      </Modal>
          {modal ? <span></span> : <></>}
      {contextHolder}
    </div>
  );

}

export default ReturnCartonRequestDetail
