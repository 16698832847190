import axios from 'axios'
import { PageDataResponse ,PageResponse,DataObjectResponse, DataArrayResponse } from '../models/PageResponse'
import { Parameter,QueryDataModel,RequestStorageCarton,RequestDetail ,
    RequestUpdateStatus ,UpdateStorage ,Carton,CartonList,
    UpdateApproval,
    QueryCarton} from '../models'
import RequestCartonDetail from '../models/RequestCartonDetail'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class StorageCartonService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async GetDetail(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestDetail>>(`${apiUrl}/v1/storage/detail?id=${id}`,{}, options )
        
        return data;
    }

    async GetDetailAndCartonList(id:number ){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<DataObjectResponse<RequestCartonDetail>>(`${apiUrl}/v1/storage/detailandcartonlist?id=${id}`,{}, options )
        
        return data;
    }

    async GetCartonList(refId:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<Carton>>(`${apiUrl}/v1/storage/cartonlist?refId=${refId}`, options )
        
        return data;
    }

    

    async QueryCartonList(refId:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<QueryCarton>>(`${apiUrl}/v1/storage/querycartonlist?refId=${refId}`, options )
        
        return data;
    }

    async GetCartonDetail(requestNo:string, box:string ){
        if(!this.token) return null
        let options = {
                headers:this.headers,
                params: {
                    requestNo: requestNo,
                    box:box
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<Carton>>(`${apiUrl}/v1/storage/cartondetail`, options )
        
        return data;
    }

    async GetNote(id:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<string>>(`${apiUrl}/v1/storage/note?id=${id}`, options )
        
        return data;
    }

    async Search(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/storage/search`,JSON.stringify(parameter),options )
        return data;
    }

   
    async SearchSaveDraft(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/storage/searchdraft`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchCarton(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/searchcarton`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchCarton_withGroupByCarton(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if (!this.token) return null
        let options = {
            headers: this.headers
        }

        const { data } = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/searchcarton_withGroupByCarton`, JSON.stringify(parameter), options)

        return data;
    }
    async SearchMyDoc(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/search/mydoc`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchCartonWithoutDocument(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if (!this.token) return null
        let options = {
            headers: this.headers
        }

        const { data } = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/searchcarton_without_document`, JSON.stringify(parameter), options)

        return data;
    }

    async SearchAdminDestructionDueDate(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if (!this.token) return null
        let options = {
            headers: this.headers
        }

        const { data } = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/search/admin/destruction/duedate`, JSON.stringify(parameter), options)

        return data;
    }

    async SearchCollectRequest(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/search/collect/request`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchRemoveRequest(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/search/remove/request`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchDestructRequest(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/search/destruction/request`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchDestructDuedateReadonly(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/search/destruction/duedate/readonly`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchChangeOwner(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/storage/search/change/owner`, JSON.stringify(parameter),options )
        
        return data;
    }

    async SearchCartonUser(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.post<PageDataResponse<CartonList>>(`${apiUrl}/v1/storage/searchcartonuser`, JSON.stringify(parameter),options )
        
        return data;
    }

    async Create(request: RequestStorageCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/create`,JSON.stringify(request),options )
        return data;
    }


    async Update(request: RequestStorageCarton) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/update`,JSON.stringify(request),options )
        return data;
    }

    async UpdateAdmin(request: UpdateStorage) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/admin/update`,JSON.stringify(request),options )
        return data;
    }

    async UpdateCartonLocation(request: UpdateStorage) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/admin/update/location`,JSON.stringify(request),options )
        return data;
    }

    async UpdateApproval(request: UpdateApproval) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/approval/update`,JSON.stringify(request),options )
        return data;
    }

    async UpdateStatus(requestUpdateStatus: RequestUpdateStatus) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/status/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }

    async UpdateEmail(cartonlist: string[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/email/send`,JSON.stringify(cartonlist),options )
        return data;
    }

    async UpdateEmail2(cartonlist: string[]) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/storage/email/send2`,JSON.stringify(cartonlist),options )
        return data;
    }

}