import { useEffect, useState } from 'react';
import { Spin,Card,Pagination,Button,Modal } from 'antd';
import { AiOutlinePrinter } from 'react-icons/ai';
import {CartonStatus, ExportType } from '../../enum';
import { ChangeOwner, Parameter } from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { ChangeOwnerService, FileManageService } from '../../services';
import dayjs from 'dayjs'
import secureLocalStorage  from  "react-secure-storage";
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail'
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    isLoading:boolean
  }

const HistoricalList = () => {
    const stickyHelper = new StickyHelper('historyical',50,0)
    stickyHelper.setElementTop('result',71)
    stickyHelper.setElementTop('data',188)
   
    stickyHelper.setThElement('his-s-th')
    stickyHelper.setTdElement('his-s-td')
    const stickyElement = stickyHelper.getElement()
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
    const [recordNo, setRecordNo] = useState(0);
    const [modal, contextHolder] = Modal.useModal();
    const [isPrint,setIsPrint] = useState(true)
    const [param,setParam] = useState<Parameter>()
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      isLoading : true
    })
    const [clientHeight,setClientHeight] = useState(0)

    const onSetClientHeight = () => {
    
      setTimeout(() => {
        stickyHelper.updatePosition()
        window.scrollTo(0,0)
        let element = document.getElementById(stickyElement.container) as HTMLInputElement
        if(element){
          let value = element.clientHeight
          setClientHeight(value)
        }
      }, 100);
    
    }

    useEffect(() => {
        if(user.permissions.changeOwner === 0){
            navigate('/permissiondenided')
        }

     
        getData(1,appState.perpage).then(() => {
          stickyHelper.updatePosition()
      
        })

        stickyHelper.removeEvent()
        stickyHelper.addEvent()
        window.addEventListener("resize", () => { 
          onSetClientHeight()
      });
    }, [])
    
      const getData = async (currentPage: number,perpage: number) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)
      
          let token = await getToken()
          let parameter = InitHelper.InitialParameter();
            parameter.page = currentPage
            parameter.limit = perpage
            setParam(parameter)
          let result:any = await new ChangeOwnerService(token).SearchHistory(parameter)
        
          if(result && result.success)
          {
            
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            if(result.data.total > 0){
              setIsPrint(false)
            }else{
              setIsPrint(true)
            }
            setTimeout(() => {
              setAppState(state)
              setTimeout(() => {
                onSetClientHeight()
              }, 100);
            }, 500);
          }
          else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
          }
          
      }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    const onPageChange = (page:number,perpage:number) => {
      if(perpage !== appState.perpage){
        getData(1,perpage)
      }
      else{
        getData(page,appState.perpage)
      }
    }
    
    const showModalCarton = async (_recordNo:number) => {
        setRecordNo(_recordNo)
        setIsModalCartonOpen(true);
    };
    
    const handleCancelCarton = () => {
        setIsModalCartonOpen(false);
    
    };
    
    const onFinishPrint = async () => {
  
      let token = await getToken()
      if(!param)return
      try{
  
        await new FileManageService(token).DownloadReport(param,ExportType.HISTORY);
      }catch(error){
        console.log("🚀 ~ file: MytaskTracking.tsx:327 ~ onFinishPrint ~ error:", error)
        
      }
    }
    return (
    <div>
        
        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
          <div className='margin-bottom-25'>
                    
                    <Button type="primary" htmlType="button" className='mr-btn' disabled={isPrint} onClick={()=>onFinishPrint()}  >
                      <AiOutlinePrinter className='icn-btn' />Print
                    </Button>
                    { appState.total>0?<div className='float-right'>
                            <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                            <span className='margin-left-10'>/ Page</span>
                        </div>:<></>}
                    </div>
    
                  <div className='search-result' >Search found { appState.total } record(s).</div>
        </div>
      
        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
        <div id={stickyElement.container}>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                               
                                <th  className="his-s-th thfix1 text-center">Request No.</th>
                                <th className="his-s-th">Requested Date</th>
                                <th className="his-s-th">Requester</th>
                                <th className="his-s-th">Current Owner</th>
                                <th className="his-s-th">New Owner</th>
                                <th className="his-s-th">Org. Unit</th>
                                <th className="his-s-th">Action</th>
                                <th className="his-s-th">Update Date</th>
                                <th className="his-s-th">Status</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                   
                                    <td className="his-s-td tdfix1 text-center">
                                    <a className='a-table' onClick={() => showModalCarton(item.storageRecordNo)}>{item.storageRequestNo}</a>
                                    </td>
                                    <td className="his-s-td text-center">{dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                                    <td className="his-s-td text-center">{item.requesterName}</td>
                                    <td className="his-s-td text-center">{item.ownerName}</td>
                                    
                                    <td className="his-s-td text-center">{item.newOwnerName}</td>
                                    <td className="his-s-td text-center">{item.orgUnit}</td>
                                    <td className="his-s-td text-center">{item.requestType }</td>
                                    <td className="his-s-td text-center">{dayjs(item.updateDate).isValid() ? dayjs(item.updateDate).format('DD MMM YYYY') : ""}</td>
                                    <td className="his-s-td text-center">{item.status}</td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
                </div>
                <div className='margin-top-10 text-right'>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        </div>
           
        
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
        
           
                <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={recordNo}  
                        approveLevel={0} cartonNumber={""}
            />
        </Modal>

          
            {modal ? <span></span> : <></>}
        {contextHolder}

    </div>
    );
  
  }
  
  export default HistoricalList
  