import React, {  useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Typography, Form, Input, Modal, Popconfirm, Row, Select, Spin, message,Pagination } from 'antd';
import { AiFillEdit, AiOutlinePlusSquare, AiOutlineCloseSquare, AiOutlineSave, AiOutlineSearch, AiOutlineSync, AiOutlinePrinter ,AiOutlineRight,AiOutlineDown} from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import Color from '../../enum/Color';
import MasterDataService from '../../services/MasterDataService';
import { Project } from '../../models/MasterDataDto';
import { ExclamationCircleOutlined,CloseCircleOutlined } from '@ant-design/icons';
import secureLocalStorage from 'react-secure-storage';
import { SearchProjectName, User, Element } from '../../models';
import moment from 'moment';
import { Helper, StickyHelper } from '../../helpers';
import { SelectPagination } from '../../components/SelectPagination';
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../configurations/authConfig";
import { useNavigate } from 'react-router-dom';
import { FileManageService } from '../../services';
import { ExportType } from '../../enum';
import Loading from '../../components/Loading';

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:Project[]
  isLoading:boolean
}

interface ProjectParameter {
  page:number
  perpage:number
  projectName:string
  locationId:number
  active:number
}

const ManageProjectName = () => {
  const stickyHelper = new StickyHelper('manage-project-name',120,0)
  const stickyElement:Element = stickyHelper.getElement()

    const navigate = useNavigate();
const { instance, accounts } = useMsal();
  const urlParams = new URLSearchParams(window.location.search);
  const id:string| null| undefined = urlParams.get('id')
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [dataForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const initialProject:Project={
    projectId: 0,
    projectName: "",
    projectLocationId: 0,
    projectLocationName: "",
    updateDate: null,
    updateBy: null,
    active: 1
  }
  const [project,setProject] =  useState<Project>(initialProject);
  const initialParameter:ProjectParameter={
    page: 1,
    perpage:100,
    projectName: "",
    locationId: 0,
    active: -1
  }
  const [appState,setAppState] = useState<AppState>({
    currentPage : initialParameter.page,
    total : 0,
    perpage: initialParameter.perpage,
    items: [],
    isLoading : true
  })
  const [saveParameter,setSaveParameter] = useState<ProjectParameter>(initialParameter)
  const { Panel } = Collapse;
  const [isAdd,setIsAdd] = useState(false)
  const [isLoad,setIsLoad]= useState(false)
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [activeKey,setActiveKey] = useState('0')
  const [clientHeight,setClientHeight] = useState(0)

  const cancel = () => {
    setProject(initialProject);
  };
  
  const getToken = async () => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if (acc) {
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}

  const getData = async (parameter:ProjectParameter) =>{
    try {
     
      let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

      let token = await getToken()
      let {page,perpage,projectName,locationId,active} = parameter
      let result = await new MasterDataService(token).getManageProjectName(page,perpage,projectName,locationId,active);
      
      if(result && result.data)
      {
          let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = result.data.page
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage
            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
                setTimeout(() => {
                  onSetClientHeight()
                }, 100);
            }, 500);
      }
        
      
    } catch (error) {
      
    }finally{
      setIsLoad(false)
    }
  }
  
  const searchData = async () => {
    
    let field = searchForm.getFieldsValue()
    
    let parameter = Object.assign({},saveParameter)
       
    if(field.name){
      parameter.projectName = field.name
    }

    parameter.locationId = field.location ?  field.location : 0
    parameter.active = field.status ? field.status : -1

    getData(parameter).then(() => {
        stickyHelper.updatePosition()
    
    }) 
}
  
const handleResetSearch = async () =>{

  searchForm.setFieldsValue({
    name: "",
    location: 0,
    status: -1
  })
  await getData(initialParameter)
}



  const onAddProject = (values: any) => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => onFinishAdd(values)
    });
    
  };
  const onFinishAdd = async (values: any) => {
    let body = {...values,updateBy:user.employeeId}
    let token = await getToken()
      const res = await new MasterDataService(token).createManageProjectName(body)
      if (!res)
          return null;
    if(!res.success){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.data.projectName
      });
    }else{
      
     
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
    }
    handleReset()
    setTimeout(async () => {
      onAddChange(false)
      await getData(initialParameter)
    }, 500);
  
  };

  const handleReset = () => {
    dataForm.setFieldsValue({
      projectname: undefined,
      projectlocationid: 1,
      active: 1
    });
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const onConfirmUpdate = () => {
  
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm to submit?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => onUpdateProject()
    });
    
  };

  const onUpdateProject = async () =>{
    let token = await getToken()
    project.updateBy = user.employeeId
    let res = await new MasterDataService(token).updateManageProjectName(project);
     
      if (!res)
          return null;
    if(!res.success){
      Modal.error({
        icon: <CloseCircleOutlined />,
        title: 'This is an error message',
        content: res?.data.projectName,
      });
    }else{
      
     
      messageApi.open({
        type: 'success',
        content: 'Save success',
        duration: 1,
      });
    }
    
    setProject(initialProject)
    getData(initialParameter)
  }

  const handleEdit = (index:number) => {
    setProject( appState.items[index])
  }

  const onProjectNameChange = (key:string) => {
     let item = Object.assign({},project)
     item.projectName = key
     setProject(item)
    
  }

  const onProjectLocationChange = (key:number) => {
    let item = Object.assign({},project)
    item.projectLocationId = key
    setProject(item)
   
 }

 const onProjectStatusChange = (key:number) => {
  let item = Object.assign({},project)
  item.active = key
  setProject(item)
 
}

const onFinishPrint = async () => {
  setIsLoadingPrint(true)

  let token = await getToken()
  
  try{

    let data = searchForm.getFieldsValue()
    let body:SearchProjectName={
      name :data.name===undefined?"":data.name,
      location:data.location===undefined?0:data.location
    }
   
    await new FileManageService(token).DownloadReport(body,ExportType.EXPORT_PROJECTNAME_LIST);

  }catch(error){
      console.log("🚀 ~ file: WarehouseRequest.tsx:441 ~ onFinishPrint ~ error:", error)
      
  }finally{
      setIsLoadingPrint(false)

  }
}

const onSetClientHeight = () => {
    
  setTimeout(() => {
    stickyHelper.updatePosition()
    window.scrollTo(0,0)
    let element = document.getElementById(stickyElement.container) as HTMLInputElement
    if(element){
      let value = element.clientHeight
      setClientHeight(value)
    }
  }, 100);

}

  useEffect(() => {
    if(user.permissions.masterData === 0){
        navigate('/permissiondenided')
    }
    const onInit = async()=>{
      
     await getData(saveParameter)
     stickyHelper.updatePosition()
    }

    onInit()

    stickyHelper.removeEvent()
    stickyHelper.addEvent()
    window.addEventListener("resize", () => { 
     onSetClientHeight()
  });
  }, [])

  const onPageChange = (page:number,perpage:number) => {
    
    if(perpage !== appState.perpage){
      let parameter = Object.assign({},saveParameter)
      parameter.page = 1
      parameter.perpage = perpage
        getData(parameter)
    }
    else{
      let parameter = Object.assign({},saveParameter)
      parameter.page = page
        getData(parameter)
    }
}

  const onAddChange = (status:boolean) => {
    stickyHelper.setOpenAdd(status)
      
  }
  return (
    <div>
        <div  id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
            {...layout}
            form={searchForm}
            name="nest-messages"
            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
              <Row>
                  <Col md={11} >
                    <Form.Item name="name" label="Project Name"  >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >
                    <Form.Item name="location" label="Project Location"  >
                      <Select placeholder={MessageCommon.TitleSelect} defaultValue={0} 
                        options={[
                          { value: 0, label: 'All' },
                          { value: 1, label: 'Oversea' },
                          { value: 2, label: 'Domestic' }
                        
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={11} >
                  <Form.Item name="status" label="Status"  >
                      <Select placeholder={MessageCommon.TitleSelect} defaultValue={-1} 
                        options={[
                          { value: -1, label: 'All' },
                          { value: 0, label: 'Inactive' },
                          { value: 1, label: 'Active' }
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} >
                  </Col>
                  <Col md={11} >

                  </Col>
                </Row>


                <div className='text-center'>
                  <Button type="default" className='mr-btn' onClick={handleResetSearch} >
                    <AiOutlineSync className='icn-btn' /> Reset
                  </Button>
                  <Button type="primary" htmlType='submit' onClick={searchData}>
                    <AiOutlineSearch className='icn-btn' /> Search
                  </Button>
                </div>
              </Form>
        </div>

  
      <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
      <div id={stickyElement.space2} className='sticky-space2 sticky-bg-grey'></div>
      
      <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
      {isLoad?
        <div className='margin-auto padding-bottom-30'>
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        </div>:<></>
      }
          <div className='pointer' onClick={() => onAddChange(true)}>
            <AiOutlinePlusSquare size={25} color='#009FDA' className='vertical-align-bottom'/>
            <span className='padding-left-5 c-light-blue'>Add</span>
          </div>
          { appState.total > 0?
        <>
          <div className='float-left margin-top-20'>
          <Button type="primary" htmlType="button" className='mr-btn' disabled={appState.total===0} onClick={()=>onFinishPrint()}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          </div>
          { appState.total>0?<div className='float-right margin-top-20'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
        </>
        :
        <></>
        }
       <br/><br/>
        <div className='search-result'>Search found { appState.total } record(s).</div>  
      </div>

      <div id={stickyElement.openAdd} className='carton-search-bottom sticky-border-top display-none' >
                <div className='margin-top-5 padding-bottom-15'>
                  <div className='margin-bottom-10'>
                      <span className='pointer' onClick={() => onAddChange(false)}>
                        <AiOutlineCloseSquare  size={25} color={Color.Grey} className='vertical-align-bottom'/>
                        <span className='padding-left-5'>Close</span>
                    </span>
                  </div>
           
                  <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onAddProject}
            initialValues={{"projectlocationid":1,"active":1}}

            validateMessages={validateMessages}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={11} >
                <Form.Item name="projectlocationid" label="Project Location"  >
                  <Select placeholder={MessageCommon.TitleSelect} className='w100c' 
                    options={[{ value: 1, label: 'Oversea' }, { value: 2, label: 'Domestic' }]}
                  />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                

              </Col>
            </Row>

            <Row>
              <Col md={11} >
                <Form.Item name="projectname" label="Project Name" rules={[{ required: true, message: 'Please input your Project name!' }]} >
                  <Input placeholder={MessageCommon.TitleInput} 
                  
                 />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                

              </Col>
            </Row>
           
            <Row>
              <Col md={11} >
                <Form.Item name="active" label="Status"  >
                  <Select placeholder={MessageCommon.TitleSelect} className='w100c' 
                    options={[{ value: 0, label: 'Inactive' }, { value:1, label: 'Active' }]}
                  />
                </Form.Item>
              </Col>
              <Col md={2} >
              </Col>
              <Col md={11} >
                

              </Col>
            </Row>

          <div className='text-center'>
            
            <Button type="default" className='mr-btn' onClick={handleReset}  >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" htmlType='submit' >
              <AiOutlineSave className='icn-btn' /> Add
            </Button>

          </div>
          </Form>
              </div>
      </div>
      
      <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
      <div id={stickyElement.container}>
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                                <th className="s-th thfix1 text-center">Project Name</th>
                                <th className="s-th ">Project Location</th>
                                <th className="s-th">Update By</th>
                                <th className="s-th">Update Date</th>
                                <th className="s-th">Status</th>
                                <th className="s-th">Edit</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:Project,index:number) => (
                                <tr>
                                    <td className="s-td tdfix1 text-left break-word">
                                      { project.projectId === item.projectId ?
                                           <Input placeholder={MessageCommon.TitleInput} defaultValue={item.projectName} onChange={(e)=> onProjectNameChange(e.target.value)}/> 
                                          :
                                            <span>{item.projectName}</span>
                                      }
                                    </td>
                                    <td className="s-td text-center">
                                    { project.projectId === item.projectId ?
                                        <Select placeholder={MessageCommon.TitleSelect} defaultValue={item.projectLocationId} onChange={onProjectLocationChange}
                                          options={[
                                            { value: 1, label: 'Oversea' },
                                            { value: 2, label: 'Domestic' },
                                          ]}
                                        />
                                        :
                                          <span>{item.projectLocationName}</span>
                                    }
                                    </td>
                                    <td className="s-td text-center">{item.updateBy}</td>
                                    <td className="s-td text-center">
                                      <span>{item.updateDate ? moment(item.updateDate).format("DD-MMM-YYYY") : null}</span>
                                    </td>
                                    <td className="s-td text-center">
                                    { project.projectId === item.projectId ?
                                        <Select placeholder={MessageCommon.TitleSelect} defaultValue={item.active} onChange={onProjectStatusChange}
                                          options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' },
                                          ]}
                                        />
                                        :
                                        <span>{item.active==1?"Active":"Inactive"}</span>
                                    }
                                    </td>
                                    <td className="s-td text-center">
                                        { project.projectId === item.projectId ?
                                          <span>
                                            <Typography.Link className='mar margin-right-8' onClick={()=>onConfirmUpdate()}>
                                                Update
                                            </Typography.Link>
                                            <Popconfirm title="Confirm to cancel?" onConfirm={cancel}>
                                              <a>Cancel</a>
                                            </Popconfirm>
                                          </span>
                                          :
                                          <AiFillEdit className='icon-table' onClick={() => handleEdit(index)} ></AiFillEdit>
                                        }

                                    </td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
          </div>
          <div className='margin-top-10 text-right'>
               { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }

          </div>
        
      </div>
      <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
      
   
       
      {contextHolder}
      {contextHolderMessage}
    
    
    </div>
  );

}

export default ManageProjectName
