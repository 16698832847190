import { useEffect } from 'react';

const PermissionDenied = () => {
   
    useEffect(() => {
     
    },[])


    return (
      <div className="container-fluid">
        <span className='c-red'>Permission Denied, Please contact administrator.</span>
      </div>
 
    );

}

export default PermissionDenied