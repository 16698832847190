import axios from 'axios'
import { PageDataResponse ,PageResponse } from '../models/PageResponse'
import { RequestChangeOwner,ChangeOwner,RequestUpdateOwner,Parameter, QueryDataModel} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ChangeOwnerService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async Search(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/owners/search`,JSON.stringify(parameter),options )
        return data;
    }
    
    async SearchRequest(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/owners/search/request`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchHistory(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<QueryDataModel>>(`${apiUrl}/v1/owners/search/history`,JSON.stringify(parameter),options )
        return data;
    }

    async CreateChangeOwner(requestUpdateStatus: RequestChangeOwner) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/owners/create`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }

    async UpdateChangeOwner(requestUpdateStatus: RequestChangeOwner) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/owners/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }

    async AdminUpdate(requestUpdateOwner: RequestUpdateOwner) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/owners/admin/update`,JSON.stringify(requestUpdateOwner),options )
        return data;
    }

    async OnbehalfUpdate(requestUpdateStatus: RequestChangeOwner) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/owners/onbehalf/update`,JSON.stringify(requestUpdateStatus),options )
        return data;
    }
}