import axios from "axios"
import { DataObjectResponse } from "../models/PageResponse"
import SystemConfig from "../models/SystemConfig"
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class SystemConfigService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
    }

    async getSystemConfig(){
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.get<DataObjectResponse<SystemConfig>>(`${apiUrl}/v1/systemconfig/config`, options )
        
        return data;
    }
    async updateSystemConfig(body: SystemConfig) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers,
        }
        
        const { data }  = await axios.post<DataObjectResponse<SystemConfig>>(`${apiUrl}/v1/systemconfig/update`,body, options )
        
        return data;
    }
}