import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Spin, Row,Popconfirm, Radio, Card, message } from 'antd';
import {  AiOutlinePrinter,AiFillDelete ,AiFillSave,AiOutlineCloseCircle, AiOutlineClose, AiOutlineCheck} from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined } from '@ant-design/icons';
import { Label } from 'reactstrap';
import DisplayLogLocation from '../Share/DisplayLogLocation';
import DisplayLogOwner from '../Share/DisplayLogOwner';
import CartonList from '../Share/CartonList'
import { RequestDetail ,UpdateApproval, User} from '../../models';
import { FileManageService, StorageCartonService } from '../../services';
import { InitHelper, Helper } from '../../helpers';
import DisplayLogNote from '../Share/DisplayLogNote';
import dayjs from 'dayjs'
import { CartonStatus ,Message, RequestType } from '../../enum';
import secureLocalStorage  from  "react-secure-storage";
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import CartonDetail from '../Share/CartonDetail';

interface Props{
    recordNo: number
    cartonNumber: string
  approveLevel: number
  disableDetail?: boolean
}

const StorageCartonRequestDetail = (props: Props) => {
    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [isModalLogOwnerOpen, setIsModalLogOwnerOpen] = useState(false);
  const [isModalLogLocationOpen, setIsModalLogLocationOpen] = useState(false);
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [dataForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [printForm] = Form.useForm();
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const location = useLocation();
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const showPrintModal = () => {
    console.log('call this');
    
    setIsPrintModalOpen(true);
  };
  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };
  

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  
  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)
      let token = await getToken()
      try{

        await new FileManageService(token).DownloadStorageUser(detail,values.print);
      }catch(error){
      console.log("🚀 ~ file: StorageCartonRequestDetail.tsx:67 ~ onFinishPrint ~ error:", error)

      }finally{
        setIsLoadingPrint(false)
      }
  }
  useEffect(() => {
    getData()
  }, [])
  
  const getData = async () => {
      setIsLoading(true)
      let token = await getToken()
    let result = await new StorageCartonService(token).GetDetail(props.recordNo)
    console.log(result)
    if(result && result.success){
      setDetail(result.data)
    }
    setIsLoading(false)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const handleNoteModalCancel = () => {

    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  const handleReject = () => {
    
    let errorElement = document.getElementById('note-storage-reject-error') as HTMLDivElement
    if(errorElement.classList.contains('display-inline')){
      errorElement.classList.remove('display-inline')
    }
    errorElement.classList.add('display-none')
    let element = document.getElementById('note-storage-reject') as HTMLTextAreaElement
    
    if(element && element.value){
      handleAction(CartonStatus.Reject,CartonStatus.Reject) 
    }
    else{
      if(errorElement.classList.contains('display-none')){
        errorElement.classList.remove('display-none')
      }
      errorElement.classList.add('display-inline')
      return false
    }
    
  }

  const handleAction = async (statusId: number, approveStatusId: number) => {
    let element = document.getElementById('note-storage-reject') as HTMLTextAreaElement
    let _noteReject = element && element.value ? element.value : ""

      let token = await getToken()
    let requestUpdateStatus:UpdateApproval = {
      recordIds: [props.recordNo],
      statusId: statusId,
      approveStatusId:approveStatusId,
      noteReject: _noteReject,
      updateBy: user.employeeId,
      updateDate: new Date()
    }
   
    let result = await new StorageCartonService(token).UpdateApproval(requestUpdateStatus)
    console.log(result)
    if(result && result.success){
        let element = document.getElementById('request-detail-form') as HTMLElement
        messageApi.open({
            type: 'success',
            content: 'Save success',
            duration: 1.5,
            style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
            handleCancel()
        }, 1000);
      
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

    const handleCancel = () => {
        let elements: any = Array.from(document.getElementsByClassName('ant-modal-close'))
        elements.forEach((el: any) => {
            el.click()
        })
    setIsModalRejectOpen(false)
    };


    const DisplayCondition = (detail: RequestDetail) => {
        return detail.statusId === CartonStatus.Done && detail.cartonLocation != null

    };
  const handleRejectOpen = () => {
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let errorElement = document.getElementById('note-storage-reject-error') as HTMLDivElement
        if(errorElement)
            errorElement.style.display = 'none'

        let element = document.getElementById('note-storage-reject') as HTMLTextAreaElement
        if(element)
          element.value = ""
    }, 300);
   
 }

 const PrintButton = () =>{
  if(location.pathname==="/storage-carton-tracking" || location.pathname==="/status-tracking"){
    return(
      <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal}>
        <AiOutlinePrinter className='icn-btn' />Print
      </Button>
    )
  }
 
  return (
    <></>
  )
  }
  return (

    <div className='min-height-150'>
      { isLoading ?
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        :
      <>
        {isLoadingPrint?<Loading/>:<></>}

        <Form
          {...layout}
          form={dataForm}
          name="request-detail-form"
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={11} >
              <Form.Item name="requestNo" label="Request No"  >
                <Label className='label-det '>{ detail.requestNo }</Label>
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="status" label="Status"  >
                <Label className='label-det'> { detail.status } </Label>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={11} >
              <Form.Item name="requester" label="Requester"  >
                <Label className='label-det'>{ detail.requesterName }</Label>

              </Form.Item>

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item name="owner" label="Owner"  >
                <Label className='label-det'>{ detail.ownerName }</Label>
                <a className='a-table ml-label' onClick={() => setIsModalLogOwnerOpen(true)}>Display Log</a>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
            <Form.Item name="contactPerson" label="Contact Person"  >
                <Label className='label-det'>{ detail.contactPersonName }</Label>
              </Form.Item>
              <Form.Item name="floor" label="Floor">
                <Label className='label-det'>{ detail.floor }</Label>
              </Form.Item>
             

            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="location" label="Location">
                <Label className='label-det'>{ detail.location }</Label>
              </Form.Item>
              <Form.Item name="place" label="Place" >
                <Label className='label-det label-det-warp'>{ detail.place }</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
          <Col md={11} >
            
            <Form.Item name="project" label="Project Name"  >
              <Label className='label-det'>{ detail.projectName }</Label>
            </Form.Item>
            <Form.Item name="approver" label="Carton Location" >
              <Label className='label-det'>{ detail.statusId === CartonStatus.Done ? detail.cartonLocation : "" }</Label>
                                  {DisplayCondition(detail) ?
                <a className='a-table ml-label' onClick={() => setIsModalLogLocationOpen(true)} >Display Log</a>:<></>
              }
            </Form.Item>
            <Form.Item name="document_year" label="Confidentiality Level" >
              <Label className='label-det'>{ detail.confidentialityLevel === 0 ? 'Non-Confidential' : 'Confidential'}</Label>
            </Form.Item>
            <Form.Item name="approver" label="Approver" >
              <Label className='label-det'>{ detail.approverName }</Label>
            </Form.Item>
            <Form.Item name="remark" label="Remark" >
              <Label className='label-det label-det-warp'>{ detail.remark }</Label>
            </Form.Item>

                              {detail.statusId === CartonStatus.Reject ?
                                  <Form.Item name="noteReject" label="Rejected Reason"  >
                                      <Label className='label-det'>{detail.notApproveReason}</Label>
                                  </Form.Item> : <></>
                              }
              <Form.Item name="note" label="Note" >
                  { detail.note.length > 0 ?
                <>
                    <Label className='label-det label-det-warp'>{detail.note[ detail.note.length - 1 ]}</Label>
                    <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                </>:<></>
                }
              </Form.Item>
          </Col>
          
            <Col md={2} ></Col>
            <Col md={11} >
              <Form.Item name="total_qty" label="Total Carton Quantity" >
                <Label className='label-det'>{ detail.quantity } carton(s)</Label>
              </Form.Item>
              <Form.Item name="total_storage_year" label="Total Storage Year" >
                <Label className='label-det'>{ detail.totalStorageYear } year(s)</Label>
              </Form.Item>
              <Form.Item name="document_year" label="Document Year" >
                <Label className='label-det'>{ dayjs(detail.documentYear).format('MMM YYYY') }</Label>
              </Form.Item>
              <Form.Item name="destruction_due_date_month" label="Destruction Due Date" >
                <Label className='label-det'>{ dayjs(detail.destructionDueDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
              <Form.Item name="expected_pickup_date" label="Expected Pick up Date" >
                <Label className='label-det'>{ dayjs(detail.pickupDate).format('DD MMM YYYY') }</Label>
              </Form.Item>
              { detail.serviceDate ?
              <Form.Item name="expected_pickup_date" label="Service Date" >
                <Label className='label-det'>{ dayjs(detail.serviceDate).format('DD MMM YYYY') }</Label>
              </Form.Item>:<></>
                }
            </Col>
          </Row>

        </Form>
        { props.approveLevel === 0 ?
           
            PrintButton()

            :
            <div className='text-center'>
                 <Button type="default" className='mr-btn w140' onClick={handleCancel}>
                    <AiOutlineCloseCircle className='icn-btn' />Cancel
                </Button>
                  <Button type="primary" className='btn-red mr-btn w140' onClick={() => handleRejectOpen()}>
                    <AiFillDelete className='icn-btn' /> Reject
                  </Button>
                <Popconfirm
                  title="Approve the task"
                  description="Confirm to approve?"
                  icon={<QuestionCircleOutlined className='c-red' />}
                  onConfirm = { () => handleAction(CartonStatus.WaitAdmin,CartonStatus.Approve) }
                >
                <div className="idoc-tooltip">
                  <span className="tooltiptext">
                    <ul>
                      <li className='text-left'>
                        {Message.Verify}
                      </li>
                      <li className='text-left'>
                          {Message.Approve}
                      </li>
                    </ul>
                  </span>
                <Button type="primary" >
                    <AiFillSave className='icn-btn' /> Verify/Approve
                </Button>
              </div>
              </Popconfirm>
          

              <Button type="primary" htmlType="button" className='mr-btn w140 margin-left-5' onClick={showPrintModal}>
                <AiOutlinePrinter className='icn-btn' />Print
              </Button>

            </div>
        }
                  <hr></hr>
                  {detail.requestNo !== "" && props.cartonNumber !== "" ?
                      <CartonDetail key={Helper.Uniqid()} requestNo={detail.requestNo} box={props.cartonNumber} />
                      :
                      <CartonList key={Helper.Uniqid()} requestNo={detail.requestNo} cartons={detail.cartons} disableDetail={props.disableDetail} statusId={detail.statusId}/>
                  }
      </>
    }
      <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
        <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.StorageCarton}/>
      </Modal>

      <Modal title="Display Log" open={isModalLogOwnerOpen} footer={''} width={900} onCancel={() => setIsModalLogOwnerOpen(false)}>
        <DisplayLogOwner recordNo={detail.recordNo} />
      </Modal>

      <Modal title="Display Log" open={isModalLogLocationOpen} footer={''} width={900} onCancel={() => setIsModalLogLocationOpen(false)}>
        <DisplayLogLocation recordNo={detail.recordNo} />
      </Modal>

      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            initialValues={{print:1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup" >
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
        </Card>
      </Modal>

      <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => setIsModalRejectOpen(false)} maskClosable={false}>
        <br></br>
       
          <Row>
          <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
              
              <textarea className='text-confirm' id="note-storage-reject" rows={5} defaultValue=""/>
              <div id="note-storage-reject-error" className='error display-none'>Note is required.</div>
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn w100' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn w100'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>

          {modal ? <span></span> : <></>}
      {contextHolderMessage}
      {contextHolder}
    </div>
  );

}

export default StorageCartonRequestDetail
