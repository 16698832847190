import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, message,Modal, Pagination,Spin, Card } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import {  QuestionCircleOutlined } from '@ant-design/icons';
import secureLocalStorage  from  "react-secure-storage";
import {RequestType, CartonStatus } from '../../enum';
import { QueryDataModel , User} from '../../models'
import { Helper, InitHelper,StickyHelper2 } from '../../helpers';
import { QueryService ,EmailService} from '../../services';
import dayjs from 'dayjs'
import NewCartonRequestDetail from '../NewCarton/NewCartonRequestDetail'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import ReturnCartonRequestDetail from '../ReturnCarton/ReturnCartonRequestDetail';
import RemoveCartonRequestDetail from '../RemoveCarton/RemoveCartonRequestDetail'
import DestructionCartonRequestDetail from '../DestructionCarton/DestructionCartonRequestDetail';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

const ReminderEmail = () => {
  const stickyHelper = new StickyHelper2("remonder-email",150,50)
    const stickyElement = stickyHelper.getElement()
    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [recordNo, setRecordNo] = useState(0);
  const [requestTypeId,setRequestTypeId] = useState(0)
    const [modal, contextHolder] = Modal.useModal();
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [selectItems, setSelectItems] = useState<string[]>([])

    
  useEffect(() => {
    getData(1,appState.perpage)

    stickyHelper.removeEvent()
    stickyHelper.addEvent()
  }, [])

 
  const showModalCarton = async (_recordNo:number,_requestTypeId: number) => {
    setRecordNo(_recordNo)
    setRequestTypeId(_requestTypeId)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
    let parameter = InitHelper.InitialParameter();
   
    parameter.orderBy = "StatusId"
    parameter.requestTypeIds = [RequestType.StorageCarton,RequestType.RemoveCarton,RequestType.RemoveCartonStorage,RequestType.DestructionCarton]
    parameter.statusIds = [ CartonStatus.WaitApproval]
    parameter.page = currentPage
    parameter.limit = perpage
    parameter.requesters = [user.employeeId]
    parameter.requesterOrgUnit = user.orgUnit
     
      let token = await getToken()
      let result = await new QueryService(token).Search(parameter)
      console.log(result)
      if (!result)
          return null;
      let {limit,total,items} = result.data
     
      let state = Object.assign({},appState)
          state.total = total
          state.perpage = perpage
          state.currentPage = currentPage
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false


        setTimeout(() => {
          setAppState(state)
          
        }, 500);
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(perpage !== appState.perpage){
      getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }

const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.requestNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.requestNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let value = e.target.value
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    if(checked){
        items.push(value)
       
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length == 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

    console.log(items)
    setSelectItems(items)
  }

  const sendEmail = async (_selectItems:string[]) => {
    if(_selectItems.length == 0)
    {
        return
    }
    let token = await getToken()
    let result = await new EmailService(token).sendToApproverAllRequest(_selectItems);
    if(result && result.success)
    {
        let element = document.getElementById('idoc-table') as HTMLElement
        messageApi.open({
          type: 'success',
          content: 'Send Success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });

      let elementCheckAll = document.getElementById('check-item-all') as HTMLInputElement
      elementCheckAll.checked = false
      elementCheckAll.indeterminate = false
      
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.requestNo) as HTMLInputElement
        element.checked = false
      })
    
        setSelectItems([])
    }
  }

  return (
    <div id="carton-panel">
          <Card>
       
          <div id={stickyElement.result} className='bg-white padding-top-10 padding-bottom-10'>
        { selectItems.length > 0 ?
            <Popconfirm
                title="Send Email"
                description="Confirm to send email?"
                icon={<QuestionCircleOutlined className='c-red' />}
                onConfirm = { () => sendEmail(selectItems) }
            >
               <Button type="primary" className='mr-btn'>
                <AiOutlineMail className='icn-btn' /> Send Email
                </Button>
            </Popconfirm>:
                <Button type="primary" className='mr-btn' disabled>
                <AiOutlineMail className='icn-btn' /> Send Email
                </Button>
        }
            

          </div>

        <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table id="idoc-table" className='idoc-table'>
            <thead id={stickyElement.dataFixed}>
              <tr>
                <th className="thfix1 text-center w50">
                { appState.items.length > 0 ?
                  <div className="form-check">
                    <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                    <label className="form-check-label" htmlFor={'check-item-all'}/>
                  </div>:<></>
                }
                </th>
               
                <th className="s-th thfix2">Request No.</th>
                <th className="s-th">Requester</th>
                <th className="s-th">Org. Unit</th>
                <th className="s-th">Requested Date</th>
                <th className="s-th">Owner</th>
                <th className="s-th">Request Type</th>
                <th className="s-th">Carton Quantity</th>
                <th className="s-th">Approver</th>
                <th className="s-th">Status</th>
                <th className="s-th">Send Email</th>
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:QueryDataModel) => (
                <tr>
                     <td className="tdfix1 w50 text-center">
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.requestNo} value={item.requestNo} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.requestNo}/>
                        </div>
                  </td>
                  <td className="s-td tdfix2 text-center"><a className='a-table'onClick={() => showModalCarton(item.recordNo,item.requestTypeId)} >{item.requestNo}</a></td>
                  <td className="s-td text-center">{item.requesterName}</td>
                  <td className='s-td text-center'>{item.orgUnit}</td>
                  <td className='s-td text-center'>{ item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                  <td className='s-td text-center'>{item.ownerName}</td>
                  <td className='s-td text-center'>{item.requestType}</td>
                  <td className='s-td text-center'>{item.quantity}</td>
                  <td className='s-td text-center'>{item.approveDate ? item.approverName2 : item.approverName }</td>
                  <td className='s-td text-center'>{item.status}</td>
                  <td className='s-td text-center'>
                  <Popconfirm
                        title="Send Email"
                        description="Confirm to send email?"
                        icon={<QuestionCircleOutlined className='c-red' />}
                        onConfirm = { () => sendEmail([item.requestNo]) }
                    >
                        <AiOutlineMail className='icon-table'></AiOutlineMail>
                    </Popconfirm>
                   
                  </td>
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
        </div>
        <div className='margin-top-10 text-right'>
        { appState.total > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
        }
        </div>

      
          </Card>
    
          

      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
        {
          requestTypeId === RequestType.NewCarton ?
          <NewCartonRequestDetail  key={Helper.Uniqid()} recordNo={recordNo}/>:<></>
        }
          { requestTypeId === RequestType.StorageCarton ?
                  <StorageCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""} approveLevel={0}/>:<></>
        }
        { requestTypeId === RequestType.CollectCarton ?
          <CollectCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo}/>:<></>
        }
        { requestTypeId === RequestType.ReturnCarton ?
          <ReturnCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} approveLevel={0}/>:<></>
        }
        {
          requestTypeId === RequestType.RemoveCarton || requestTypeId === RequestType.RemoveCartonStorage ?
          <RemoveCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} approveLevel={0}/>:<></>
        }
        {
          requestTypeId === RequestType.ExtendCarton || requestTypeId === RequestType.DestructionCarton ?
                      <DestructionCartonRequestDetail key={Helper.Uniqid()} recordNo={recordNo} cartonNumber={""} approveLevel={0}/>:<></>
        }
        
          </Modal>
          {modal ? <span></span> : <></>}
        {contextHolderMessage}
        {contextHolder}
    </div >
  );

}

export default ReminderEmail
