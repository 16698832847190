import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, DatePicker, Form, Input, Spin, Modal, Row, Pagination } from 'antd';
import {  AiOutlineSearch, AiOutlineSync ,AiFillInteraction,AiFillDelete,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import { Label } from 'reactstrap';
import CartonDetail from '../Share/CartonDetail';
import DestructionCartonExtenModal from './DestructionCartonExtenModal';
import DestructionCartonDestroyModal from './DestructionCartonDestroyModal';
import { Parameter,CartonList, Item, User} from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { StorageCartonService, PropertyService,HrWebService} from '../../services';
import dayjs from 'dayjs'
import { CartonStatus, RequestType} from '../../enum'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import secureLocalStorage  from  "react-secure-storage";
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'

interface AppState {
  currentPage:number
  perpage:number
  total: number
  items:CartonList[]
  itemNoList:string[]
  isLoading:boolean
}

interface Props{
  onBehalf:number
}

const DestructionCartonRequest = (props: Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }
    
    const elementName = props.onBehalf === 1 ? "onbehalf-destruct" : "destruct"
    
     let topPos = props.onBehalf ? 150 : 50
    // let spaceTop = props.onBehalf ? 128 : 128
    // let spaceTop2 = props.onBehalf ? 441 : 415
    // let resultTop = props.onBehalf ? 159 : 159
    // let resultTop2 = props.onBehalf ? 474 : 445
    // let dataTop = props.onBehalf ? 293 : 222
    // let dataTop2 = props.onBehalf ? 610 : 508
    const stickyHelper = new StickyHelper(elementName,topPos,50)
    // stickyHelper.setElementTop('search',80,80)
    // stickyHelper.setElementTop('search2',128,128)
    // stickyHelper.setElementTop('space',70,70)
    // stickyHelper.setElementTop('space2',spaceTop,spaceTop2)
    // stickyHelper.setElementTop('result',resultTop,resultTop2)
    // stickyHelper.setElementTop('data',dataTop,dataTop2)
    const stickyElement = stickyHelper.getElement()

    const { instance, accounts } = useMsal();
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const dateStore = secureLocalStorage.getItem('date')
  const currentdate = dateStore ? dayjs(dateStore.toString()) : dayjs()
  const pageSizeOptions = user.systemConfig.tablePageSize
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm] = Form.useForm();
  const { Panel } = Collapse;
  const [isModalStorageCartonOpen, setIsModalStorageCartonOpen] = useState(false);
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
  const [requestStartDate, setRequestStartDate] = useState<Date|null|undefined>(null);
  const [requestEndDate, setRequestEndDate] =  useState<Date|null|undefined>(null);
  const [documentStartYear, setDocumentStartYear] = useState<number|null|undefined>(undefined);
  const [documentEndYear, setDocumentEndYear] = useState<number|null|undefined>(undefined);
  const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
  const [recordNo,setRecordNo] = useState(0)
  const [requestNo ,setRequestNo] = useState("")
  const [owners, setOwners] = useState<Item[]>([])
  const [box,setBox] = useState("")
  const [selectItems, setSelectItems] = useState<string[]>([])
  const [checkAll,setCheckAll] = useState(false)
  const [requestTypeId,setRequestTypeId] = useState(0)
  const [searchStatus,setSearchStatus] = useState(false)
  const [orgunits, setOrgUnits] = useState<Item[]>([])
  const [extendStatus,setExtendStatus] = useState(true)
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
      perpage: returnIfElse(props.onBehalf === 1, 100, 10) ,
    items: [],
    itemNoList:[],
    isLoading : !props.onBehalf 
  })

  const [clientHeight,setClientHeight] = useState(0)

  const onSetClientHeight = () => {
    
    setTimeout(() => {
      stickyHelper.updatePosition()
      window.scrollTo(0,0)
      let element = document.getElementById(stickyElement.container) as HTMLInputElement
      if(element){
        let value = element.clientHeight
        setClientHeight(value)
      }
    }, 100);
  
  }

  useEffect(() => {
    initial()
}, [])

    const initial =() => {
      
        if(!props.onBehalf){
           
            setSearchStatus(true)
            let parameter = InitHelper.InitialParameter()
            parameter.owners = []
            parameter.requesters = []
            parameter.requesterOrgUnit = user.orgUnit
            parameter.employeeLevel = user.level
            parameter.userConfidential = user.employeeId
            parameter.expire = 1
            getData(1,appState.perpage,parameter)

            stickyHelper.removeEvent()
          stickyHelper.addEvent()
          window.addEventListener("resize", () => { 
          onSetClientHeight()
        });
        
        }
    }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };

  const showModal = (_requestTypeId:number) => {
    setRequestTypeId(_requestTypeId)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectItemAll = () => {
    setSelectItems(appState.itemNoList)
    setCheckAll(true)
    setItemAllChecked(appState.itemNoList)
   
  }

const setItemAllChecked = (_selectItems:string[]) => {
    let elementAll = document.getElementById('check-item-all') as HTMLInputElement
    elementAll.checked = true

    _selectItems.forEach(item => {
        let element = document.getElementById('check-item-' + item) as HTMLInputElement
        if(element)
            element.checked = true
     })
 }

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:string[] = []
    let _extendStatus = true
    
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = true
        items.push(element.value)
        if(item && (item.autoExtend === 1 || dayjs(item.expireDate) > currentdate))
        {
            _extendStatus = false
        }
        items.push(element.value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.cartonNo) as HTMLInputElement
        element.checked = false
      })
    }
    setExtendStatus(_extendStatus)
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    
    let checked = e.target.checked
    let value = e.target.value
    let element = document.getElementById('check-item-all') as HTMLInputElement
    let items = Object.assign([],selectItems)
    let _extendStatus = true

    if(checked){
        items.push(value)
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.indexOf(value);
      if (index > -1) {
   
        items.splice(index, 1);
      }

      if(items.length == 0){
        element.checked = false
        element.indeterminate = false
      }
      else{
        element.checked = false
        element.indeterminate = true
      }
    }

    let cartonItems = appState.items.filter(x => items.indexOf(x.cartonNo) > -1)
        cartonItems.forEach(item => {
            if(item && (item.autoExtend === 1 || dayjs(item.expireDate) > currentdate))
            {
                _extendStatus = false
            }
        })

    
    setExtendStatus(_extendStatus)
    setSelectItems(items)
  }

  const onSearchEmployee = async(key:string) => {
   
    let token = await getToken()
    let result = await new HrWebService(token).SearchEmployee(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}
  const searchData = async () => {
  
    let field = dataForm.getFieldsValue()
    
    let parameter = InitHelper.InitialParameter();
    parameter.expire = 0
    parameter.userConfidential = user.employeeId
    
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
    }
    else
    {
        parameter.requesters =  []
        parameter.owners = []
        parameter.requesterOrgUnit = user.orgUnit
        parameter.employeeLevel = user.level
    }
    
    if(orgunits.length > 0){
        parameter.orgUnits = orgunits.map(item => item.key)
      }
    if(owners.length > 0){
      let ownerConfidential = owners.filter(x => x.key == user.employeeId).length
      if(ownerConfidential === 0){
        parameter.userConfidential = ""
      }
        parameter.owners = owners.map(item => item.key)
      }

    if(field.requestNo){
        parameter.requestNoList = field.requestNo.trim().split(',')
    }

    if(requestStartDate && requestEndDate){
        parameter.requestStartDate = requestStartDate
        parameter.requestEndDate = requestEndDate
    }

    if(documentStartYear && documentEndYear){
        parameter.documentStartYear = documentStartYear
        parameter.documentEndYear = documentEndYear
    }
    
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    let cartonNoStart = ""
    let cartonNoEnd = ""
    if(elementStart){
      cartonNoStart = elementStart.value
    }
    if(elementEnd){
      cartonNoEnd = elementEnd.value
    }

    if(cartonNoStart && cartonNoEnd){
        parameter.cartonNo = cartonNoStart.trim() + '&' + cartonNoEnd.trim()
    }

    if(field.cartonNoList){
        parameter.cartonNo = field.cartonNoList.trim()
    }
      
    if(field.cartonNo){
        parameter.cartonNo = field.cartonNo.trim()
    }

    
    setSearchStatus(true)
    getData(1,appState.perpage,parameter)
    
  }
  const reset = () => {
    if(!searchStatus){
        return
    }
    let elementStart = document.getElementById('cartonNoStart') as HTMLInputElement
    let elementEnd = document.getElementById('cartonNoEnd') as HTMLInputElement
    elementStart.value = ""
    elementEnd.value = ""

    dataForm.resetFields()
    setRequestStartDate(undefined)
    setRequestEndDate(undefined)
    setDocumentStartYear(undefined)
    setDocumentEndYear(undefined)
    setOwners([])
    let parameter = InitHelper.InitialParameter()
    parameter.expire = 1
    if(props.onBehalf === 1)
    {
        parameter.onBehalf = 1
        parameter.confidentialLevel = 2
    }
    else{
        parameter.requesters =  []
        parameter.owners = []
        parameter.requesterOrgUnit = user.orgUnit
        parameter.employeeLevel = user.level
        parameter.userConfidential = user.employeeId
    }
    

    getData(1,appState.perpage,parameter)
  }

  const getData = async (currentPage: number,perpage: number,_parameter:Parameter | null = null) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)
       
    let parameter = Object.assign({},saveParameter)
    
    if(_parameter !== null){
        parameter = _parameter;
    }
    
    parameter.page = currentPage
    parameter.limit = perpage
    parameter.statusIds =[CartonStatus.Done]
    parameter.cartonStatusIds = [CartonStatus.Storage,CartonStatus.Collect]
    
    if(props.onBehalf){
        parameter.itemNoList = 1
    }
   
      let token = await getToken()
      let result = await new StorageCartonService(token).SearchDestructRequest(parameter)
      
      if(result && result.success)
      {
        let state = Object.assign({},appState)
          state.total = result.data.total
          state.currentPage = currentPage
          state.perpage = perpage
          state.items = result.data.items
          state.itemNoList = result.data.itemNoList
          state.isLoading = false

          setSaveParameter(parameter)
          setAppState(state)

          setTimeout(() => {
            if(checkAll){
                setItemAllChecked(selectItems)
              }
              setTimeout(() => {
                onSetClientHeight()
              }, 100);
          }, 500);
          
         
         
      }
      else{
        let state = Object.assign({},appState)
        state.isLoading = false
        setAppState(state)
      }
  }
  

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }
    const onOwnerSelect = (item:any) => {
        setOwners(item)
        stickyHelper.updatePosition()
      }

  const onPageChange = (page:number,perpage:number) => {
    
    if(perpage !== appState.perpage){
        getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
    if(checkAll){
        setItemAllChecked(selectItems)
    }
  }
  
  const handleStorageCartonOpen = (_recordNo:number) => {
    setRecordNo(_recordNo)
    setIsModalStorageCartonOpen(true)
  }
  const handleCancelStorageCarton = () => {
    setIsModalStorageCartonOpen(false)
  }

  const handleCartonDetailCancel = () => {
    setIsModalCartonDetailOpen(false);
  }
  
  const handleCartonDetailOpen = (_requestNo:string,_box:string) => {
    setRequestNo(_requestNo)
    setBox(_box)
    setIsModalCartonDetailOpen(true);
  }

  const onRequestStartDateChange = (date: any) => {
    setRequestStartDate(date);
  }
  
  const onRequestEndDateChange = (date: any) => {
    setRequestEndDate(date)
  }
  
  const onDocumentStartYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentStartYear(year)
  }
  
  const onDocumentEndYearChange = (value: any) => {
    let year = parseInt(dayjs(value).format('YYYY'))
    setDocumentEndYear(year)
  }

const onOrgUnitSelect = (item:any) => {
    setOrgUnits(item)
    stickyHelper.updatePosition()
}

const onSearchOrgUnit = async(key:string) => {
   
    let token = await getToken()
    let result = await new PropertyService(token).GetOrgUnits(key)
    if(result && result.success)
    {
      return result.data
    }
    else{
      console.log(result?.message)
      return []
    }
}
const renderPagination =() =>{
  if(appState.total > 0){
    if(props.onBehalf===1){
      return( <Pagination 
        key={Helper.Uniqid()}
        current={appState.currentPage} 
        pageSize={appState.perpage}
        total={appState.total} 
        onChange={onPageChange} 
        showSizeChanger={false}
      />)
    }
    return (<Pagination 
    key={Helper.Uniqid()}
    current={appState.currentPage} 
    pageSize={appState.perpage}
    total={appState.total} 
    onChange={onPageChange} 
    pageSizeOptions={pageSizeOptions}
    showSizeChanger={true}
  />)
  }
 return (<></>)

}

  return (

    <div>
       <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse()}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>
        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
            {...layout}
            form={dataForm}
            name="nest-messages"
            onFinish={onFinish}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
                <Col md={11} >
                    <Form.Item label="Carton No:(Range)" >
                        <table className='w100c'>
                            <tr>
                                <td>
                                    <input id="cartonNoStart" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                                </td>
                                <td>
                                    <Label className='margin-left-10 margin-right-5'>To</Label>
                                </td>
                                <td>
                                    <input id="cartonNoEnd" className='form-control form-control-sm' placeholder='ex. 03PCS001'/>
                                </td>
                            </tr>
                        </table>
                    </Form.Item>
                    <Form.Item name="cartonNoList" label="Carton No (Multiple)" >
                        <Input.TextArea rows={5} placeholder='03PCS001,03PCS002,03PCS003'/>
                    </Form.Item>
                    <Form.Item name="cartonNo" label="Carton No" >
                        <Input placeholder='ex. 03PCS001'/>
                    </Form.Item>
                </Col>
                <Col md={2} >

                </Col>
                <Col md={11} >
                
              
                    <Form.Item name="requestNo" label="Requested No."  >
                        <Input placeholder='B230004,B230005' />
                    </Form.Item>
                    <Form.Item  name="storageRequestedDate" label="Requested Date">
                        <table className='w100c'>
                            <tr>
                                <td>
                                    <DatePicker 
                                          format={'DD MMM YYYY'}
                                        placeholder={MessageCommon.TitleDate} 
                                        className='date-picker-full'
                                        onChange={onRequestStartDateChange}
                                    />
                                </td>
                                <td>
                                    <Label className='margin-left-10 margin-right-10'>To</Label>
                                </td>
                                 <td>
                                    <DatePicker 
                                          format={'DD MMM YYYY'}
                                        placeholder={MessageCommon.TitleDate} 
                                        className='date-picker-full' 
                                        onChange={onRequestEndDateChange}
                                    />
                                </td>
                            </tr>
                        </table>
                    </Form.Item>
                
                
                    <Form.Item name="documentYear" label="Document Year">
                        <table className='w100c'>
                            <tr>
                                <td>
                                    <DatePicker 
                                        placeholder={MessageCommon.TitleDate} 
                                        picker='year' 
                                        className='w100c' 
                                        onChange={onDocumentStartYearChange}
                                    />
                                </td>
                                <td>
                                    <Label className='margin-left10 margin-right10 vertical-align-top'>To</Label>
                                </td>
                                <td>
                                    <DatePicker 
                                        placeholder={MessageCommon.TitleDate} 
                                        picker='year' 
                                        className='w100c'
                                        onChange={onDocumentEndYearChange}
                                    />
                                </td>
                            </tr>
                        </table>
                    </Form.Item>
                    <Form.Item name="owner" label="Owner"  >
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                            selectedItems={owners ? owners : []}
                            onChange={item => onOwnerSelect(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                        />
                    </Form.Item>
                { props.onBehalf ?
                <>
                    <Form.Item name="orgUnit" label="Org. Unit"  >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Name'
                                              selectedItems={returnIfElse(orgunits, orgunits, [] )}
                            onChange={item => onOrgUnitSelect(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                </>:<></>
                }
                </Col>
            </Row>
          </Form>
          <div className='text-center'>
            <Button type="default" className='mr-btn' onClick={reset} >
              <AiOutlineSync className='icn-btn' /> Reset
            </Button>
            <Button type="primary" onClick={searchData}  >
              <AiOutlineSearch className='icn-btn' /> Search
            </Button>


          </div>
        </div>

    
        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>
    
    { searchStatus ?
    <>
        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
            { props.onBehalf ?
                <div  className='div-select margin-bottom-10'>
                    All {appState.items.length} cartons on this page are selected.<a className='a-table mlt-label' onClick={selectItemAll}>Select all {appState.total } cartons in Primary</a>
                </div>:<></>
            }
            <Button type="primary" className={selectItems.length === 0 || !extendStatus ? '' : 'btn-blue mr-btn'}  onClick={() => showModal(RequestType.ExtendCarton)} disabled={selectItems.length === 0 || !extendStatus}   >
                <AiFillInteraction className='icn-btn' /> Extend
              </Button>

              <Button type="primary" className={selectItems.length === 0 ? 'margin-left-10' : 'margin-left-10 btn-red mr-btn' } onClick={() => showModal(RequestType.DestructionCarton)} disabled={selectItems.length === 0 }>
                <AiFillDelete className='icn-btn' /> Destroy
              </Button>
              { appState.total>0 && props.onBehalf===1?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
          
        </div>

        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
          <div id={stickyElement.container}>
        { appState.isLoading ?
            <div className='margin-auto padding-bottom-30'>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
          :
          <table className='idoc-table'>
            <thead id={stickyElement.data}>
              <tr>
                <th className="thfix1 text-center w50">
                                              {returnIfElse(appState.items.length > 0, 
                                                  <div className="form-check">
                                                      <input type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)} />
                                                      <label className="form-check-label" htmlFor={'check-item-all'} />
                                                  </div>
                                                  ,
                                                  <></>) 
                }
                </th>
                <th className="s-th thfix2">Carton No.</th>
                <th className="s-th">Requested No.</th>
                <th className="s-th">Requester</th>
                <th className="s-th">Requested date</th>
                <th className="s-th">Org. Unit</th>
                <th className="s-th">Owner</th>
                <th className="s-th">Total Storage Year</th>
                <th className="s-th">Auto Extend Status</th>
                <th className="s-th">Previous Destruction Due Date(Extend)</th>
                <th className="s-th">Destruction Due Date</th>
              
              </tr>
            </thead>
           
            <tbody >
            { appState.items.map((item:CartonList) => (
                <tr>
                    <td className="tdfix1 text-center w50">
                      <div className="form-check">
                          <input  
                            type="checkbox" 
                            id={'check-item-' + item.cartonNo} 
                            defaultValue={selectItems} 
                            value={item.cartonNo} 
                            className="form-check-input check-item" 
                            onChange={(e) => onCheckChange(e)}
                        />
                          <label className="form-check-label" htmlFor={'check-item-' + item.cartonNo}/>
                        </div>
                    </td>
                    <td className="s-td tdfix2 text-center">
                    <a className='a-table' onClick={() => handleCartonDetailOpen(item.requestNo, item.cartonNo)}>{item.cartonNo}</a>
                    </td>
                    <td className="s-td text-center">
                    <a className='a-table' onClick={() => handleStorageCartonOpen(item.referenceRecNo)}>{item.requestNo}</a>
                    </td>
                      <td className="s-td text-center">{item.requesterName}</td>
                      <td className="s-td text-center">{ dayjs(item.requestDate).format('DD MMM YYYY')}</td>
                      <td className="s-td text-center">{item.orgUnit}</td>
                      <td className="s-td text-center">{item.ownerName}</td>
                     
                      <td className="s-td text-center">{ dayjs(item.expireDate).diff(dayjs(item.documentYear),'year')}</td>
                      <td className="s-td text-center">{item.autoExtend == 1 ? 'Enable' : '-'}</td>
                      <td className="text-center">{returnIfElse(item.latestExtensionDate, dayjs(item.latestExtensionDate).format('DD MMM YYYY'),  "-" ) }</td>
                      <td className="s-td text-center">{ dayjs(item.expireDate).format('DD MMM YYYY')}</td>
                 
                </tr>
              ))
            }
            
            </tbody>
  
          </table>
        }
          </div>
          <div className='margin-top-10 text-right'>
            {renderPagination()}
          </div>
        </div>
        
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
      
      </>
      :<></>
    }


          <Modal title={returnIfElse(requestTypeId === RequestType.ExtendCarton, 'Extend', 'Destroy' ) } open={isModalOpen} footer={''} width={1550} onCancel={handleCancel} maskClosable={false} >
        { requestTypeId === RequestType.ExtendCarton ?
            <DestructionCartonExtenModal  
              key={Helper.Uniqid()} 
              destroyId={0} 
              items={selectItems} 
                      page={returnIfElse(props.onBehalf, 'onBehalf', "destruction" )  }
              onBehalf={props.onBehalf}
              onReload={initial}
            />:<></>
        }
        { requestTypeId === RequestType.DestructionCarton ?
            <DestructionCartonDestroyModal  
            key={Helper.Uniqid()} 
            destroyId={0} 
            items={selectItems} 
                      page={returnIfElse(props.onBehalf, 'onBehalf', "destruction") }
            onBehalf={props.onBehalf}
            onReload={initial}
            />:<></>
        }
      </Modal>

        <Modal title="Request Detail" open={isModalStorageCartonOpen} footer={''} width={1550} onCancel={handleCancelStorageCarton}>
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                  recordNo={recordNo} cartonNumber={""}
              approveLevel={0}
            />
        </Modal>
        <Modal  title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
        <CartonDetail key={Helper.Uniqid()} requestNo={requestNo} box={box} />
      </Modal>

    
    </div>
  );

}

export default DestructionCartonRequest
