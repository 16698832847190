enum CartonStatus {
    SaveDraft = 1,
    WaitApproval = 2,
    WaitAdmin = 3,
    Inprogress = 4,
    Cancel = 5,
    Delete = 6,
    Reject = 7,
    WaitingUserConfirm = 8,
    WaitingCloseJob = 9,
    Done = 10,
    DueDate = 11,
    SaveDraftRecall = 12,
    Approve = 13,
    Extend = 14,
    Destroy = 15,
    WaitStorage = 100,
    Storage = 101,
    Collect = 102,
    Return = 103,
    Remove = 104,
    Destruction = 105
}

export default CartonStatus