import axios from 'axios'
import { PageDataResponse ,PageResponse } from '../models/PageResponse'
import { RequestChangeOrgUnit,RequestChangeOrgUnits,Parameter ,ChangeOwner,OrgUnitParamter} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ChangeOrgUnitService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async SearchHistory(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<ChangeOwner>>(`${apiUrl}/v1/orgunits/search/history`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchRequest(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<ChangeOwner>>(`${apiUrl}/v1/orgunits/search/request`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchStorage(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<ChangeOwner>>(`${apiUrl}/v1/orgunits/search/storage`,JSON.stringify(parameter),options )
        return data;
    }
    
    async ManualUpdate(parameter: Parameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<ChangeOwner>>(`${apiUrl}/v1/orgunits/manual/update`,JSON.stringify(parameter),options )
        return data;
    }

    async UpdateChangeOrgUnit(requestChangeOrgUnit: RequestChangeOrgUnit) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/orgunits/update`,JSON.stringify(requestChangeOrgUnit),options )
        return data;
    }

    async AdminUpdate(requestChangeOrgUnits: RequestChangeOrgUnits) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/orgunits/admin/update`,JSON.stringify(requestChangeOrgUnits),options )
        return data;
    }

    async AdminUpdateStorage(requestChangeOrgUnits: RequestChangeOrgUnits) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/orgunits/admin/update/storage`,JSON.stringify(requestChangeOrgUnits),options )
        return data;
    }
}