import { useEffect, useState } from 'react';
import { Collapse, Form, Row,Spin,Card,Col,Input,Pagination,Button,DatePicker,Select } from 'antd';
import secureLocalStorage  from  "react-secure-storage";
import { Label } from 'reactstrap';
import { CartonStatus, ExportType } from '../../enum';
import { AiOutlineSync, AiOutlineSearch,AiOutlinePrinter,AiOutlineRight,AiOutlineDown } from 'react-icons/ai';
import { ChangeOwner ,Parameter ,Item} from '../../models'
import { Helper, InitHelper,StickyHelper } from '../../helpers';
import { HrWebService,ChangeOrgUnitService,PropertyService,FileManageService} from '../../services';
import dayjs from 'dayjs'
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import MessageCommon from '../../enum/Message';
import { OrgUnitAutoComplete } from '../../autocomplete/OrgUnitAutoComplete';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { User} from '../../models';
import { useNavigate } from 'react-router-dom';

interface AppState {
    currentPage:number
    perpage:number
    total: number
    items:ChangeOwner[]
    isLoading:boolean
  }

const ManualUpdateHistory = () => {
 
    const stickyHelper = new StickyHelper('update-history', 50,0)
    const stickyElement = stickyHelper.getElement()
    stickyHelper.setIncDataTop(39)
    
    const userStore = secureLocalStorage.getItem('user')
    const user:User = userStore ? JSON.parse(userStore.toString()): null
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [dataForm] = Form.useForm();
    const { Panel } = Collapse;
    const [saveParameter,setSaveParameter] = useState<Parameter>(InitHelper.InitialParameter)
    const [appState,setAppState] = useState<AppState>({
      currentPage : 1,
      total : 0,
      perpage: 100,
      items: [],
      isLoading : true
    })
    const [requestStartDate, setRequestStartDate] = useState<Date|null>(null);
    const [requestEndDate, setRequestEndDate] =  useState<Date|null>(null);
    const [searchOwners, setSearchOwners] = useState<Item[]>([])
    const [searchPrevOrgunits, setSearchPrevOrgUnits] = useState<Item[]>([])
    const [searchNewOrgunits, setSearchNewOrgUnits] = useState<Item[]>([])
    const [clientHeight,setClientHeight] = useState(0)

    const onSetClientHeight = () => {
    
        setTimeout(() => {
          stickyHelper.updatePosition()
          window.scrollTo(0,0)
          let element = document.getElementById(stickyElement.container) as HTMLInputElement
          if(element){
            let value = element.clientHeight
            setClientHeight(value)
          }
        }, 100);
      
      }

    useEffect(() => {
        if(user.permissions.orgUnit === 0){
            navigate('/permissiondenided')
        }

        
        let parameter = InitHelper.InitialParameter()
        parameter.archive = 0
        parameter.system = 0

     
        getData(1,appState.perpage,parameter).then(() => {
            stickyHelper.updatePosition()
        
        })

        stickyHelper.removeEvent()
        stickyHelper.addEvent()
    window.addEventListener("resize", () => { 
        onSetClientHeight()
    });
  
    }, [])
    
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
     
      
    const getData = async (currentPage: number,perpage: number,_parameter:Parameter| null = null) => {
        let _state:AppState = Object.assign({},appState)
           _state.isLoading = true
           setAppState(_state)

        let parameter = Object.assign({},saveParameter)
        if(_parameter !== null){
            parameter = Object.assign({},_parameter);
        }
           
        parameter.page = currentPage
        parameter.limit = perpage
        parameter.statusIds = [CartonStatus.Done]
       
        let token = await getToken()
        let result = await new ChangeOrgUnitService(token).SearchHistory(parameter)
        
        
        if(result && result.success)
        {
            let state = Object.assign({},appState)
              state.total = result.data.total
              state.currentPage = currentPage
              state.items = result.data.items
              state.isLoading = false
              state.perpage = perpage

            setTimeout(() => {
                setSaveParameter(parameter)
                setAppState(state)
                setTimeout(() => {
                    onSetClientHeight()
                  }, 100);
            }, 500);
        }
        else{
            let state = Object.assign({},appState)
            state.isLoading = false
            setAppState(state)
        }
    }
      
    const searchData = async () => {
    
        let field = dataForm.getFieldsValue()
       
        let parameter = InitHelper.InitialParameter();
           
        if(field.requestNo){
            parameter.requestNoList = field.requestNo.trim().split(",")
        }

        parameter.archive = 0
        parameter.system = 0
        
        if(field.updateBy === 'archive'){
           parameter.archive = 1
        }

        if(field.updateBy === 'manual'){
            parameter.manual = 1
         }
 
        
         if(searchOwners.length > 0){
            parameter.owners = searchOwners.map(item => item.key)
        }
    
        if(searchPrevOrgunits.length > 0){
            parameter.orgUnits = searchPrevOrgunits.map(item => item.key)
        }

        if(searchNewOrgunits.length > 0){
            parameter.newOrgUnits = searchNewOrgunits.map(item => item.key)
        }

        if(requestStartDate && requestEndDate){
            parameter.requestStartDate = requestStartDate
            parameter.requestEndDate = requestEndDate
        }
    
       
        getData(1,appState.perpage,parameter).then(() => {
            stickyHelper.updatePosition()
            
        }) 
        
    }

    const reset = () => {
       
        dataForm.resetFields()
        setSearchOwners([])
        setSearchPrevOrgUnits([])
        setSearchNewOrgUnits([])
        setRequestStartDate(null)
        setRequestEndDate(null)
        getData(1,appState.perpage,InitHelper.InitialParameter())
    }
     
    const onSearchEmployee = async(key:string) => {
       
        let token = await getToken()
        let result = await new HrWebService(token).SearchEmployee(key)
        if(result && result.success)
        {
            return result.data
        }
        else{
            console.log(result?.message)
            return []
        }
    }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const onPageChange = (page:number,perpage:number) => {
        if(perpage !== appState.perpage){
            getData(1,perpage)
        }
        else{
            getData(page,appState.perpage)
        }
    }
    
    const onSearchOwnerChange = (item:any) => {
        setSearchOwners(item)
        stickyHelper.updatePosition()
    }

    const onSearchPrevOrgunitChange = (value:any) => {
        setSearchPrevOrgUnits(value)
        stickyHelper.updatePosition()
    }

    const onSearchNewOrgunitChange = (value:any) => {
        setSearchNewOrgUnits(value)
        stickyHelper.updatePosition()
     }

    const onRequestStartDateChange = (date: any) => {
        setRequestStartDate(date);
        if(requestEndDate && date > requestEndDate){
            setRequestEndDate(null)
        }
    }
    
    const onRequestEndDateChange = (date: any) => {
        setRequestEndDate(date)
    }

    const disabledDate = (value:any) => {
        if(requestStartDate)
            return Helper.disabledDate(value, dayjs(requestStartDate).format('DD MMM YYYY'))
    }

    const onSearchOrgUnit = async(key:string) => {
   
        let token = await getToken()
        let result = await new PropertyService(token).GetOrgUnits(key)
        if(result && result.success)
        {
          return result.data
        }
        else{
          console.log(result?.message)
          return []
        }
    }

    const onFinishPrint = async () => {
        let token = await getToken()
        if(!saveParameter)return
  
        try{
  
            await new FileManageService(token).DownloadReport(saveParameter,ExportType.UPDATE_HISTORY_ORG_UNIT);
        }catch(error){
            console.log("🚀 ~ file: ManualUpdate.tsx:392 ~ onFinishPrint ~ error:", error)
  
        }finally{
           
        }
    }

   

    return (
    <div>
          <div id={stickyElement.search} className='carton-search-top' onClick={ () => stickyHelper.setCollapse2(39)}>
          <span id="arrow-right" className='display-inline'>
              <AiOutlineRight className='carton-search-top-img'/>
          </span>
          <span id="arrow-down" className='display-none'>
            <AiOutlineDown  className='carton-search-top-img'/>
          </span>
          <span>Carton Search</span>
            
        </div>

        <div id={stickyElement.openSearch} className='carton-search-bottom' >
        <Form
              {...layout}
              form={dataForm}
              name="orgunit-form"
              labelAlign="left"
              colon={false}
              labelWrap={true}
            >
              <Row>
                <Col md={11} >
                    <Form.Item name="requestDate" label="Updated Date">
                      <table className='w100c'>
                        <tr>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate} 
                                className='date-picker-full' 
                                onChange={onRequestStartDateChange}
                            />
                          </td>
                          <td>
                            <Label className='margin-left-10 margin-right-10'>To</Label>
                          </td>
                          <td>
                            <DatePicker 
                                placeholder={MessageCommon.TitleDate} 
                                className='date-picker-full' 
                                onChange={onRequestEndDateChange}
                                disabledDate={disabledDate}
                            />
                          </td>
                        </tr>
                      </table>
                    </Form.Item>
                    <Form.Item name="requestNo" label="Storage Request No.">
                        <Input placeholder="ex. B230002,B230004" />
                    </Form.Item>
                    <Form.Item name="updateBy" label="Ref. Updated By">
                        <Select 
                            key = {Helper.Uniqid()}
                            placeholder={MessageCommon.TitleSelect} 
                            defaultValue={'All'}
                            options={[
                                { value: 'all', label: 'All' },
                                { value: 'archive', label: 'Archive Officer' }, 
                                { value: 'manual', label: 'System List' }
                            ]}
                            //onChange={onRequestTypeChange}
                            
                      />
                    </Form.Item>
                </Col>
                <Col md={2} >
                </Col>
                <Col md={11} >
                    <Form.Item name="searchPrevOrgUnit" label="Previous Org. Unit" >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Previous Org. Unit'
                            selectedItems={searchPrevOrgunits ? searchPrevOrgunits : []}
                            onChange={item => onSearchPrevOrgunitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="searchNewOrgUnit" label="New Org. Unit" >
                        <OrgUnitAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by New Org. Unit'
                            selectedItems={searchNewOrgunits ? searchNewOrgunits : []}
                            onChange={item => onSearchNewOrgunitChange(item)}
                            service={onSearchOrgUnit}
                            minimumTextLength={2}
                        />
                    </Form.Item>
                    <Form.Item name="owner" label="Owner">
                        <EmployeeAutoComplete
                            key={Helper.Uniqid()}
                            placeholder='Search by Owner'
                            selectedItems={searchOwners ? searchOwners : []}
                            onChange={item => onSearchOwnerChange(item)}
                            service={onSearchEmployee}
                            minimumTextLength={3}
                    />
                    </Form.Item>
                   
                </Col>
              </Row>
      
                </Form>
                <div className='text-center'>
                    <Button type="default" className='mr-btn' onClick={reset} >
                        <AiOutlineSync className='icn-btn' /> Reset
                    </Button>
                    <Button type="primary"   onClick={searchData}>
                        <AiOutlineSearch className='icn-btn' /> Search
                    </Button>
                </div>
        </div>

        <div id={stickyElement.space} className='sticky-space sticky-bg-white display-none'></div>
        <div id={stickyElement.space2} className='sticky-space2 sticky-bg-white'></div>

        <div id={stickyElement.result}  className='sticky-data-top sticky-border-top sticky-border-left sticky-border-right'>
                <Button type="primary" className='mr-btn' onClick={()=>onFinishPrint()}>
                    <AiOutlinePrinter className='icn-btn' />Print
                </Button>
                <br/>
                  <div className='search-result float-left'>Search found { appState.total } record(s).</div>

                { appState.total>0?<div className='float-right'>
                        <SelectPagination  onSelectChange={onPageChange} currentPage={appState.currentPage} defaultValue={appState.perpage}/>
                        <span className='margin-left-10'>/ Page</span>
                    </div>:<></>}
                 
        </div>

        <div id={stickyElement.dataFixed} className='sticky-data-bottom sticky-border-bottom sticky-border-left sticky-border-right'>
            <div id={stickyElement.container}>
                
                   
           
                { appState.isLoading ?
                    <div className='margin-auto padding-bottom-30'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                    :
                    <table className='idoc-table'>
                        <thead id={stickyElement.data}>
                            <tr>
                                <th className="s-th thfix1">Storage Request No.</th>
                                <th className="s-th">Owner</th>
                                <th className="s-th">New Owner</th>
                                <th className="s-th">Previous Org. Unit</th>
                                <th className="s-th">New Org. Unit</th>
                                <th className="s-th">Admin Action Date</th>
                                <th className="s-th">Ref. Updated By</th>
                            </tr>
                        </thead>
           
                        <tbody >
                            { appState.items.map((item:ChangeOwner) => (
                                <tr>
                                    <td className="tdfix1 text-center">{item.requestNo }</td>
                                    <td className="s-td text-left-20">{item.ownerName}</td>
                                    <td className="s-td text-left-20">{item.newOwnerName}</td>
                                    <td className="s-td text-center">{item.orgUnit}</td>
                                    <td className="s-td text-center">{item.newOrgUnit}</td>
                                    <td className="s-td text-center">
                                        { item.updateDate ? dayjs(item.updateDate).format('DD MMM YYYY') : dayjs(item.createDate).format('DD MMM YYYY')}
                                    </td>
                                    <td className="s-td text-center">
                                        { item.updateType === "archiveofficer" ? 'Archive Officer' : 'System List'}
                                    </td>
                                </tr>
                            ))
                            }
            
                        </tbody>
  
                    </table>
                }
                </div>
                <div className='margin-top-10 text-right'>
                { appState.total > 0 ?
                    <Pagination 
                        key={Helper.Uniqid()}
                        current={appState.currentPage} 
                         pageSize={appState.perpage}
                        total={appState.total} 
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />:<></>
                }
                </div>
        </div>
        
        <input type="hidden" id={stickyElement.clientHight} defaultValue={clientHeight}/>
        
    </div>
    );
  
  }
  
  export default ManualUpdateHistory
  