import { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, message,InputNumber, Modal, Row, Spin } from 'antd';
import { AiFillSave, AiOutlineSave } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import  secureLocalStorage  from  "react-secure-storage";
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { NewCartonService,HrWebService } from '../../services';
import { CartonStatus } from '../../enum';
import { RequestNewCarton,RequestDetail, User,Item } from '../../models';
import { EmployeeAutoComplete } from '../../autocomplete/EmployeeAutoComplete'
import dayjs from 'dayjs'
import { Helper } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Error
{
  quantity: number,
  deliverto: number,
  receiveDate:number,
  place:number
}

const NewCartonRequest = () => {
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id:string| null| undefined = urlParams.get('id')
  const currentDate2 =urlParams.get('date')
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const orgUnit = user.orgUnit ? user.orgUnit : ""
  const position = user.position ? user.position : ""
  const directPhone = user.directPhone ? user.directPhone : ""
  const currentDate = (currentDate2) ? currentDate2:  secureLocalStorage.getItem('date')
  const [dataForm] = Form.useForm();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isLoading,setIsLoading] = useState(true)
  const [owners, setOwners] = useState<Item[]>([])
  const [ownersBgColor,setOwnersBgColor] = useState("")
  const [error,setError] = useState<Error>({
    quantity: 0,
    deliverto: 0,
    receiveDate: 0,
    place: 0
  })
  
  const [modal, contextHolder] = Modal.useModal();

  const getData = async (id:number) => {
      let token = await getToken()
    let result = await new NewCartonService(token).GetDetail(id)
      console.log(result)
      if(result && result.success)
      {
        let item:RequestDetail = result.data
        
        dataForm.setFieldsValue({
          recordNo: item.recordNo,
          requester: user.employeeId,
          requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
          deliverto: item.owner,
          quantity: item.quantity,
          receiveDate: dayjs(item.receiveDate),
          location: item.location,
          floor: item.floor,
          place: item.place,
          statusId: item.statusId
        });

        
        let owners:Item[] = [{
            key:item.owner,
            name:item.ownerName,
            location: item.location,
            floor: item.floor,
            orgUnit:""
        }]
        onOwnerSelect(owners)
        setIsLoading(false)
      }
  }

  useEffect(() => {
    console.log(user)
      if(id){
        console.log('direct-phone=' + directPhone)
          getData(parseInt(id))
      }
      else{
        
        if(currentDate){
          dataForm.setFieldsValue({
            recordNo: 0,
            requester: user.employeeId,
            requesterName: `Name: ${user.employeeName}  Org. Unit: ${orgUnit} Position: ${position}, Officer Services Tel: ${directPhone}`,
            quantity: 1,
            receiveDate: Helper.add3Days(currentDate.toString())
          });
        }

        setIsLoading(false)
      }

  }, [])

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  
  const onOwnerSelect = (item:any) => {
    console.log(item)
    setOwners(item)
    dataForm.setFieldsValue({
      deliverto: item[0].key,
      location: item[0].location,
      place:item[0].location
    });
  }
  
  const onSearchEmployee = async(key:string) => {
   
      let token = await getToken()
      let result = await new HrWebService(token).SearchEmployee(key)
      if(result && result.success)
      {
        let _orgUnit = user.orgUnit
            if(user.orgUnit.indexOf("/") > -1) {
                _orgUnit = user.orgUnit.split("/")[0]
            }
            return result.data.filter(x => x.orgUnit.indexOf(_orgUnit) > -1)
      }
      else{
        console.log(result?.message)
        return []
      }
  }

  const handleSave = async (saveType:boolean) => {
    let statusId = (saveType) ? CartonStatus.WaitAdmin : CartonStatus.SaveDraft
   
    let requestNewCarton:RequestNewCarton = {
        recordNo: dataForm.getFieldValue('recordNo'),
        requester: dataForm.getFieldValue('requester'),
        deliverto:  dataForm.getFieldValue('deliverto'),
        quantity: dataForm.getFieldValue('quantity'),
        receiveDate: dataForm.getFieldValue('receiveDate'),
        location: dataForm.getFieldValue('location'),
        floor: dataForm.getFieldValue('floor'),
        place: dataForm.getFieldValue('place'),
        statusId: statusId,
    }
    console.log(requestNewCarton)

      let token = await getToken()
    let result:any = null
    if(requestNewCarton.recordNo === 0){
      result = await new NewCartonService(token).Create(requestNewCarton);
    }
    else{
      result = await new NewCartonService(token).Update(requestNewCarton);
    }
    console.log(result)
    let element = document.getElementById('new-carton-form') as HTMLFormElement
    if(result && result.success){
        reset()
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
          if(saveType){
            navigate('/new-carton-tracking')
          }
          else{
            navigate('/new-carton-save')
          }
        }, 1000);
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
   
  };

  const validate = () => {

    let errorData:Error = {
      deliverto: 0,
      quantity: 0,
      receiveDate: 0,
      place: 0
    }
    setOwnersBgColor("")
    let errorCount = 0
   
    if(!dataForm.getFieldValue('deliverto')){ 
      errorCount++
      errorData.deliverto = 1
    }
    
    let quantity = dataForm.getFieldValue('quantity')
    if(!quantity || quantity < 1 || quantity > user.systemConfig.maxNewCartonQty){ 
      errorCount++
      errorData.quantity = 1
    }
    
    if(!dataForm.getFieldValue('receiveDate')){ 
      errorCount++
      errorData.receiveDate = 1
    }

    if(owners.length === 0){
      setOwnersBgColor('red')
      errorCount++
    }

    if(!dataForm.getFieldValue('place')){ 
      errorCount++
      errorData.place = 1
    }

    setError(errorData)

    if(errorCount !== 0){
      let element = document.getElementById('new-carton-form') as HTMLFormElement
      let msg = "Please fill required fields"
      if(errorData.quantity)
      {
        msg ="Carton Quantity must be between 1 and " + user.systemConfig.maxNewCartonQty
      }
      messageApi.open({
        type: 'error',
        content: msg,
        duration: 1.5,
        style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
      });
      return false
    }
    else{
      return true;
    }
    
  }

  const confirmSave = (saveType:boolean) => {
    if(validate()){
      if(saveType){
        modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: 'Confirm to submit?',
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: () => handleSave(true)
        });
      }
      else{
        handleSave(false)
      }
    }
    else{
      console.log('validate failed')
    }
  }

  const reset = () => {
    
        dataForm.setFieldsValue({
          recordNo: 0,
          deliverto: "",
          quantity: 0,
          receiveDate: dayjs().add(3 ,'day'),
          location: "",
          floor: "",
          place: "",
          statusId: 0,
        });
        setOwners([])
  }

  const disabledDate = (value:any) => {
    let date = currentDate ? currentDate.toString() : new Date().toString()
    return Helper.disabledDate(value,date)
  }

  const validateMessages = {
    required: '${label} is required!',
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }
 
  return (

    <div>
       
      <Card >
         { isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>:<></>
        }
        <Form
          {...layout}
          form={dataForm}
          name="new-carton-form"
          validateMessages={validateMessages}
          onFinish={onFinish}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >

          <Row>
            <Col md={22} >
              <Form.Item name="requesterName" label="Requester" labelCol={{ md: 3 }}   >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item name="quantity" label="Carton Quantity" rules={[{ required: true, type: 'number', min: 1, max: user.systemConfig.maxNewCartonQty }]}  validateStatus={error.quantity > 0 ? "error" : undefined}>
                <InputNumber id="carton-quantity" placeholder={MessageCommon.TitleInput} />
              </Form.Item>

              <Form.Item name="receiveDate" label="Expected Receive Date" rules={[{ required: true }]} validateStatus={error.receiveDate == 1 ? "error" : undefined}>
                <DatePicker 
                format={"DD MMM YYYY"}
                  placeholder={MessageCommon.TitleDate} 
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item name="location" label="Location">
              <Input disabled/>
              </Form.Item>


            </Col>
            <Col md={2} ></Col>
            <Col md={11} >
              <Form.Item name="deliverto" label="Please deliver to..">
                <EmployeeAutoComplete
                    key={Helper.Uniqid()}
                    placeholder='Search by Name'
                    selectedItems={owners ? owners : []}
                    onChange={item => onOwnerSelect(item)}
                    service={onSearchEmployee}
                    minimumTextLength={3}
                    borderColor={ownersBgColor}
                    pickOnlyOne
                  />
              </Form.Item>
              

              <Form.Item name="floor" label="Floor">
                <Input  placeholder={MessageCommon.TitleInput} />
              </Form.Item>

              <Form.Item name="place" label="Place" rules={[{ required: true }]} validateStatus={error.place == 1 ? "error" : undefined}>
                <Input.TextArea  placeholder='Please insert...' />
              </Form.Item>
            </Col>

          </Row>
        </Form>
        <div className='text-center'>
          <Button type="primary" className='mr-btn' onClick={() => confirmSave(false)} >
            <AiOutlineSave className='icn-btn' />Save Draft
          </Button>
          <Button type="primary" onClick={() => confirmSave(true)} >
            <AiFillSave className='icn-btn' />Submit
          </Button>
        </div>



      </Card>
      {contextHolderMessage}
      {contextHolder}
    </div >
  );

}

export default NewCartonRequest
