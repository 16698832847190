import axios from 'axios'
import { PageDataResponse ,PageResponse, DataArrayResponse } from '../models/PageResponse'
import { Warehouse, WarehouseListModel, WarehouseParameter} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class WarehouseService
{
    protected headers = {
        'Content-Type': 'application/json',
        'Authorization': "",
        'X-XSRF-TOKEN': ""
    }
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-XSRF-TOKEN': ""
            }
        }
        
    }
    
    async GetWarehouses(warehouseId: number,valveRoom:number ){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<Warehouse>>(`${apiUrl}/v1/warehouses/default?warehouseId=${warehouseId}&valveRoom=${valveRoom}`, options )
        
        return data;
    }

    async GetAllWarehouses() {
        if (!this.token) return null
        let options = {
            headers: this.headers
        }

        const { data } = await axios.get<DataArrayResponse<Warehouse>>(`${apiUrl}/v1/warehouses/all`, options)

        return data;
    }

    async GetActiveWarehouses(){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<Warehouse>>(`${apiUrl}/v1/warehouses/active`, options )
        
        return data;
    }

    async GetLogs(page:number,perpage:number,id:number){
        if(!this.token) return null
        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<PageDataResponse<Warehouse>>(`${apiUrl}/v1/warehouses/logs?page=${page}&perpage=${perpage}&id=${id}`, options )
        
        return data;
    }

    async Search(parameter: WarehouseParameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<Warehouse>>(`${apiUrl}/v1/warehouses/search`,JSON.stringify(parameter),options )
        return data;
    }

    async SearchList(parameter: WarehouseParameter) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageDataResponse<WarehouseListModel>>(`${apiUrl}/v1/warehouses/list`,JSON.stringify(parameter),options )
        return data;
    }


    async Create(requestWarehouse: Warehouse) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/warehouses/create`,JSON.stringify(requestWarehouse),options )
        return data;
    }

    async Update(requestWarehouse: Warehouse) {
        if (!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/warehouses/update`,JSON.stringify(requestWarehouse),options )
        return data;
    }

}