import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin ,Pagination ,Popconfirm,message} from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import {  CloseCircleOutlined ,QuestionCircleOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { QueryDataModel ,RequestUpdateStatus, User} from '../../models';
import { Helper, InitHelper } from '../../helpers';
import { CartonStatus ,RequestType} from '../../enum';
import CollectCartonRequestDetail from '../CollectCarton/CollectCartonRequestDetail';
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail';
import RemoveCartonModalCollect from '../RemoveCarton/RemoveCartonModalCollect'
import RemoveCartonModalStorage from '../RemoveCarton/RemoveCartonModalStorage'
import DestructionCartonExtenModal from '../DestructionCarton/DestructionCartonExtenModal';
import DestructionCartonDestroyModal from '../DestructionCarton/DestructionCartonDestroyModal';
import { QueryService,NewCartonService,StorageCartonService,CollectCartonService,
    ReturnCartonService,RemoveCartonService,DestructionCartonService
} from '../../services';
import dayjs from 'dayjs'
import CollectCartonModal from '../CollectCarton/CollectCartonModal';
import ReturnCartonModal from '../ReturnCarton/ReturnCartonModal'
import secureLocalStorage from "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
  currentPage:number
  perpage:number
  total: number
  totalPgae: number
  items:QueryDataModel[]
  isLoading:boolean
}

interface StorageCarton{
  recordNo:number,
  statusId:number,
  valvRoom:number,
  approveLevel:number
}

interface SelectItem{
  recordNo:number
  requestTypeId:number
}

const MytaskSaveDraft = () => {



    const { instance, accounts } = useMsal();
    const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const pageSizeOptions = user.systemConfig.tablePageSize
  const navigate = useNavigate();
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [storageCarton, setStorageCarton] = useState<StorageCarton>({ recordNo: 0, statusId: 0, valvRoom: 0, approveLevel: 0 }); 
  const [ requestTypeId,setRequestTypeId] = useState(0)
  const [selectItems, setSelectItems] = useState<SelectItem[]>([])
  const [recordNo,setRecordNo] = useState(0)
  const [messageApi, contextHolderMessage] = message.useMessage();
  const initialRequestTypeIds:number[] = [
    RequestType.NewCarton, 
    RequestType.StorageCarton, 
    RequestType.CollectCarton,
    RequestType.ReturnCarton,  
    RequestType.RemoveCarton, 
    RequestType.DestructionCarton
  ]
  const [appState,setAppState] = useState<AppState>({
    currentPage : 1,
    total : 0,
    totalPgae : 0,
    perpage: 10,
    items: [],
    isLoading : true
  })
  const [isModalOpen, setIsModalOpen] = useState(false);

    
    useEffect(() => {
        setStorageCarton({ recordNo: 0, statusId: 0, valvRoom: 0, approveLevel: 0 })
    getData(1,appState.perpage)
  }, [])

  const getData = async (currentPage: number,perpage: number) => {
    let _state:AppState = Object.assign({},appState)
       _state.isLoading = true
       setAppState(_state)

    let parameter = InitHelper.InitialParameter();
    parameter.page = currentPage
    parameter.limit = perpage
    parameter.requestTypeIds = initialRequestTypeIds
    parameter.orderBy = "StatusId"
    parameter.statusIds = [CartonStatus.SaveDraft,CartonStatus.SaveDraftRecall,CartonStatus.Extend]
    parameter.requesters = [user.employeeId]
      let token = await getToken()
      let result = await new QueryService(token).Search(parameter)
     
      if (!result)
          return null;
      let {page,limit,total,items} = result.data
      
      let state = Object.assign({},appState)
          state.total = total
          state.currentPage = page
          state.totalPgae = Math.ceil(total / limit)
          state.items = items
          state.isLoading = false
          state.perpage = perpage

        setTimeout(() => {
          setAppState(state)
        }, 500);
  }
  
  const handleEdit = (recordNo:number,requestTypeId:number) => {
    if(requestTypeId === RequestType.NewCarton){
      navigate('/new-carton-edit?id=' + recordNo)
    }
    if(requestTypeId === RequestType.StorageCarton){
      navigate('/storage-carton-edit?id=' + recordNo)
    }
    else{
      setRecordNo(recordNo)
      setRequestTypeId(requestTypeId)
      setIsModalOpen(true)
    }
    
  }

  const handleDeleteByItem = (_recordNo:number,_requestTypeId:number) => {
    let item:SelectItem = {
      recordNo: _recordNo,
      requestTypeId: _requestTypeId
    }
    handleDelete([item])
  }

    const checkAndPush = (Items: any, result: any, index: any, errors: any) => {
        if (Items.length > 0 && (!result[index] || !result[index]?.success)) {
            if (result[index] && result[index].message)
                errors.push(result[index].message)
        }
    }

  const handleDelete = async (_selectItems:SelectItem[]) => {
    let token = await getToken()
    const newCartonItems = _selectItems.filter( x => x.requestTypeId === RequestType.NewCarton).map( x => x.recordNo)
    const storageItems = _selectItems.filter( x => x.requestTypeId === RequestType.StorageCarton).map( x => x.recordNo)
    const collectItems = _selectItems.filter( x => x.requestTypeId === RequestType.CollectCarton).map( x => x.recordNo)
    const returnItems = _selectItems.filter( x => x.requestTypeId === RequestType.ReturnCarton).map( x => x.recordNo)
    const removeItems = _selectItems.filter( x => x.requestTypeId === RequestType.RemoveCarton).map( x => x.recordNo)
    const destructionItems = _selectItems.filter( 
      x => x.requestTypeId === RequestType.ExtendCarton 
      || x.requestTypeId === RequestType.DestructionCarton
    ).map( x => x.recordNo)
    
    let newCartonUpdateStatus:RequestUpdateStatus = {
      recordIds: newCartonItems,
      statusId: CartonStatus.Delete,
    }

    let storageCartonUpdateStatus:RequestUpdateStatus = {
      recordIds: storageItems,
      statusId: CartonStatus.Delete
    }
    let collectCartonUpdateStatus:RequestUpdateStatus = {
      recordIds: collectItems,
      statusId: CartonStatus.Delete
    }

    let returnCartonUpdateStatus:RequestUpdateStatus = {
      recordIds: returnItems,
      statusId: CartonStatus.Delete
    }

    let removeCartonUpdateStatus:RequestUpdateStatus = {
      recordIds: removeItems,
      statusId: CartonStatus.Delete
    }

    let destructionCartonUpdateStatus:RequestUpdateStatus = {
      recordIds: destructionItems,
      statusId: CartonStatus.Delete
    }
    Promise.all([
      new NewCartonService(token).UpdateStatus(newCartonUpdateStatus),
      new StorageCartonService(token).UpdateStatus(storageCartonUpdateStatus),
      new CollectCartonService(token).UpdateStatus(collectCartonUpdateStatus),
      new ReturnCartonService(token).UpdateStatus(returnCartonUpdateStatus),
      new RemoveCartonService(token).UpdateStatus(removeCartonUpdateStatus),
      new DestructionCartonService(token).UpdateStatus(destructionCartonUpdateStatus),
    ]).then((result) => {
      let errors:string[] = []
        
        checkAndPush(newCartonItems, result, 0, errors)
        checkAndPush(storageItems, result, 1, errors)
        checkAndPush(collectItems, result, 2, errors)
        checkAndPush(returnItems, result, 3, errors)
        checkAndPush(removeItems, result, 4, errors)
        checkAndPush(destructionItems, result, 5, errors)
      if(errors.length == 0){
        let element = document.getElementById('table-container') as HTMLElement
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: { marginTop: element.getBoundingClientRect().top , marginLeft: element.getBoundingClientRect().left  }
        });
        setTimeout(() => {
           getData(1,appState.perpage)
        }, 1000);
      }
      else{
          Modal.error({
              icon: <CloseCircleOutlined />,
              title: 'This is an error message',
              content: errors,
          });
      }
    })

    
   
  }

  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };

  const onCheckAll = (e:React.ChangeEvent<HTMLInputElement>) => {
    let items:SelectItem[] = []
    if(e.target.checked){
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = true
        let [_recordNo,_requestTypeId] = JSON.parse(element.value)
        let value:SelectItem = {
          recordNo: _recordNo,
          requestTypeId: _requestTypeId
        }
        items.push(value)
      })
    }
    else{
      appState.items.forEach(item => {
        let element = document.getElementById('check-item-' + item.recordNo) as HTMLInputElement
        element.checked = false
      })
    }
    setSelectItems(items)
  };

  const onCheckChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked
    let [_recordNo,_requestTypeId] = JSON.parse(e.target.value)
    let value:SelectItem = {
      recordNo: _recordNo,
      requestTypeId: _requestTypeId
    }
    let items:SelectItem[] = Object.assign([],selectItems)
    if(checked){
        items.push(value)
        let element = document.getElementById('check-item-all') as HTMLInputElement
        if(items.length === appState.items.length){
          element.checked = true
          element.indeterminate = false
        }
        else{
          element.checked = false
          element.indeterminate = true
        }
        
    }
    else{
      let index = items.findIndex(x => x.recordNo === value.recordNo && x.requestTypeId === value.requestTypeId)
      if (index !== -1) {
        items.splice(index, 1);
      }

      if(items.length == 0){
        let element = document.getElementById('check-item-all') as HTMLInputElement
        element.checked = false
        element.indeterminate = false
      }
    }

   
    setSelectItems(items)
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


  const onPageChange = (page:number,perpage:number) => {
    if(perpage !== appState.perpage){
      getData(1,perpage)
    }
    else{
      getData(page,appState.perpage)
    }
  }


 const handleCancel = () => {
  setIsModalOpen(false);
};


  return (
    <div>
      
      <div className='float-left'>
        { selectItems.length > 0 ?
        <Popconfirm
            title="Delete the task"
            description="Confirm to delete?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => handleDelete(selectItems) }
          >
           <Button type="primary" className='btn-red mr-btn'>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        </Popconfirm>
        :
        <Button type="primary" className='btn-red mr-btn' disabled>
            <AiFillDelete className='icn-btn' /> Delete
          </Button>
        }
       
      </div>
      <div className='float-right'>
         
      </div>
      <div></div>
      { appState.isLoading ?
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
          :
          <>
            <div id="table-container" className='padding-top-45'>
              <table className='idoc-table'>
                <thead id="head-table-fixed">
                  <tr>
                    <th className='text-left w50'>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-all'} className="form-check-input check-item" onChange={(e) => onCheckAll(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-all'}/>
                        </div>
                      </th>
                        <th >Request No.</th>
                        <th >Requester</th>
                        <th >Owner</th>
                        <th >Org. Unit</th>
                        <th >Requested Date</th>
                        <th >Carton Quantity</th>
                        <th >Request Type</th>
                        <th >Edit</th>
                        <th >Delete</th>
                  </tr>
                </thead>
                <tbody >
                  { appState.items.map((item:QueryDataModel) => (
                    <tr>
                      <td className='text-left w50'>
                        <div className="form-check">
                          <input  type="checkbox" id={'check-item-' + item.recordNo} value={JSON.stringify([item.recordNo,item.requestTypeId])} className="form-check-input check-item" onChange={(e) => onCheckChange(e)}/>
                          <label className="form-check-label" htmlFor={'check-item-' + item.recordNo}/>
                        </div>
                      </td>
                      <td className='text-center'>
                        <a className='a-table'onClick={() => handleEdit(item.recordNo,item.requestTypeId)} >
                            {item.requestNo ? item.requestNo : item.tempNo }
                        </a>
                        </td>
                      <td className='text-center'>{item.requesterName}</td>
                      <td className='text-center'>{item.ownerName}</td>
                      <td className='text-center'>{item.orgUnit}</td>
                      <td className='text-center'>{ item.requestDate ? dayjs(item.requestDate).format("DD MMM YYYY") : ""}</td>
                      <td className='text-center'>{item.quantity}</td>
                      <td className='text-center'>{item.requestType}</td>
                      <td className='text-center'>
                          <AiFillEdit size={20} onClick={() => handleEdit(item.recordNo,item.requestTypeId)} className='pointer'/>
                      </td>
                      <td className='text-center'>
                        <Popconfirm
                          title="Delete the task"
                          description="Confirm to delete?"
                          icon={<QuestionCircleOutlined className='c-red' />}
                          onConfirm = { () => handleDeleteByItem(item.recordNo,item.requestTypeId) }
      
                        >
                          <AiFillDelete size={20} color="red" className='pointer'/>
                        </Popconfirm>
                        
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className='margin-top-10 text-right'>
            { appState.total > 0 ?
              <Pagination 
                key={Helper.Uniqid()}
                current={appState.currentPage} 
                pageSize={appState.perpage}
                total={appState.total} 
                onChange={onPageChange} 
                pageSizeOptions={pageSizeOptions}
                showSizeChanger={true}
              />:<></>
            }
            </div>
          </>
        }

      <Modal title="Request Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
         { requestTypeId === RequestType.CollectCarton ?
            <CollectCartonRequestDetail 
              key={Helper.Uniqid()}
              recordNo={storageCarton.recordNo}  
            />:<></>
        }
        { requestTypeId === RequestType.StorageCarton ?
            <StorageCartonRequestDetail 
              key={Helper.Uniqid()}
                      recordNo={storageCarton.recordNo} cartonNumber={""}
              approveLevel={storageCarton.approveLevel}
            />:<></>
        }
      </Modal>

      <Modal title="Carton Detail" open={isModalOpen} footer={''}  width={1550} onCancel={handleCancel} afterClose={() => getData(1,appState.perpage)} maskClosable={false}>
        { 
          requestTypeId === RequestType.CollectCarton ?
            <CollectCartonModal key={Helper.Uniqid()} collectId={recordNo} items={[]} page="mytask" onBehalf={0} onReload={null}/>:<></>
        }
        {
          requestTypeId === RequestType.ReturnCarton ?
          <ReturnCartonModal key={Helper.Uniqid()} returnId={recordNo} items={[]} page='mytask' onBehalf={0}  onReload={null}/>:<></>
        }
         { requestTypeId === RequestType.RemoveCarton ?
          <RemoveCartonModalCollect key={Helper.Uniqid()} removeId={recordNo} items={[]} page="mytask"  onReload={null}/>:<></>
        }
        { requestTypeId === RequestType.RemoveCartonStorage ?
          <RemoveCartonModalStorage key={Helper.Uniqid()} removeId={recordNo} items={[]} page="mytask"  onReload={null}/>:<></>
        }
        { requestTypeId === RequestType.ExtendCarton ?
            <DestructionCartonExtenModal  key={Helper.Uniqid()} destroyId={recordNo} items={[]} page="mytask" onBehalf={0} onReload={null}/>:<></>
        }
        { requestTypeId === RequestType.DestructionCarton ?
            <DestructionCartonDestroyModal  key={Helper.Uniqid()} destroyId={recordNo} items={[]} page="mytask" onBehalf={0} onReload={null}/>:<></>
        }
          </Modal>
          {modal ? <span></span> : <></>}
        {contextHolderMessage}
        {contextHolder}
    </div >
  );

}

export default MytaskSaveDraft
