import { useEffect, useState } from 'react';
import { Button, Card, Col,message, Form, Input, Modal,Spin, Radio,Popconfirm, Row,Pagination  } from 'antd';
import {  AiFillSave, AiOutlineArrowLeft, AiOutlineCheck, AiOutlineClose,AiFillEye, AiOutlineMessage, AiOutlinePrinter } from 'react-icons/ai';
import MessageCommon from '../../enum/Message';
import dayjs from 'dayjs';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CloseCircleOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import CartonStatus  from '../../enum/CartonStatus';
import { RequestDetail,User ,CartonList ,UpdateDestroy,RequestUpdateStatus} from '../../models';
import { DestructionCartonService, FileManageService } from '../../services';
import { InitHelper, Helper,StickyHelper2} from '../../helpers';
import DisplayLogNote from '../Share/DisplayLogNote';
import secureLocalStorage  from  "react-secure-storage";
import {  ExportType, RequestType} from '../../enum';
import CartonDetail from '../Share/CartonDetail'
import StorageCartonRequestDetail from '../StorageCarton/StorageCartonRequestDetail'
import Loading from '../../components/Loading';
import { SelectPagination } from '../../components/SelectPagination';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
    id:string
    onBack:any
}

const QueryDataDestructionCarton = (props:Props) => {
    const returnIfElse = (flag: any, obj1: any, obj2: any) => {
        return flag ? obj1 : obj2;
    }

    const stickyHelper = new StickyHelper2("query-data-destruct",600,50)
    const stickyElement = stickyHelper.getElement()
    const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const pid:string| null| undefined = urlParams.get('id')
  const id = props.id ? props.id : pid
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isModalCartonOpen, setIsModalCartonOpen] = useState(false);
    const [messageApi, contextHolderMessage] = message.useMessage();
    const [recordNo, setRecordNo] = useState(0)
    const [cartonNumber, setCartonNumber] = useState("")
  const [dataForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [page,setPage] = useState(1)
  const [perpage ,setPerpage] = useState(100);
  const [detail, setDetail] = useState<RequestDetail>(InitHelper.InitialRequestDetail())
  const [width,setWidth] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [allCartonItems,setAllCartonItems] = useState<CartonList[]>([])
  const [cartonItems,setCartonItems] = useState<CartonList[][]>([])
  const [isModalCartonDetailOpen, setIsModalCartonDetailOpen] = useState(false);
    const [requestNo, setRequestNo] = useState("") 
  const [box ,setBox] = useState("")
  const [printForm] = Form.useForm();
  const [isLoadingPrint,setIsLoadingPrint] = useState(false)
  const [isModalRejectOpen,setIsModalRejectOpen] = useState(false)
  const [isErrorNote,setIsErrorNote] = useState(false)
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const layout = {
    labelCol: { lg: 6 },
    wrapperCol: { lg: 18 }
  };
  



  const onFinishPrint = async (values:any) => {
    setIsLoadingPrint(true)

    if(id){
      let token = await getToken()
      try{

        await new FileManageService(token).DownloadRequestDestruction(detail,values.print);
      }catch(error){
      console.log("🚀 ~ file: QueryDataDestructionCarton.tsx:83 ~ onFinishPrint ~ error:", error)

      }finally{
        setIsLoadingPrint(false)

      }
    }
  }


  const getData = async () => {
    if(id)
    {
      setIsLoading(true)
      let token = await getToken()
      let recordNo = parseFloat(id)
      let result = await new DestructionCartonService(token).GetDetail(recordNo)
        console.log(result)
    
        if(result){
         
            setDetail(result.data)
            let items = Helper.Chunk(result.data.cartonList,perpage)
            setAllCartonItems(result.data.cartonList)
            setCartonItems(items)
            setPage(1)
         
        }
        setIsLoading(false)
      
    }
    
  }


  const handleCartonDetailCancel = () => {
    setIsModalCartonDetailOpen(false);
  }

    const getToken = async () => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if (acc) {
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }


    useEffect(() => {
        if(user.permissions.queryData === 0){
            navigate('/permissiondenided')
        }
        setRequestNo("")
        setBox("")

        getData()

        stickyHelper.removeEvent()
        stickyHelper.addEvent()
    
  }, [])

 

  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };

  const showPrintModal = () => {
    setIsPrintModalOpen(true);
  };
  
  const handleNoteModalCancel = () => {
    setIsNoteModalOpen(false);
  };

  const showNoteModal = () => {
    setIsNoteModalOpen(true);
  };
  
  const update = async (statusId: number,noteReject:string| null = null) => {
    let noteElement = document.getElementById('note') as HTMLInputElement
    let note = ""
    if(noteElement && noteElement.value){
        note = noteElement.value
    }

    let requestCarton:UpdateDestroy = {
      recordNo: detail.recordNo,
      requestTypeId:detail.requestTypeId,
      statusId:statusId,
      note: note,
      noteReject:noteReject ? noteReject : "",
      updateBy:user.employeeId,
      updateDate: new Date(),
      cartons:allCartonItems
    }
    console.log(requestCarton)
    let token = await getToken()
    let result = await new DestructionCartonService(token).UpdateAdmin(requestCarton);
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        setTimeout(() => {
          if(statusId == 0 || statusId === CartonStatus.WaitAdmin || statusId === CartonStatus.Inprogress){
            getData()
          }
          else{
           props.onBack(true)
          }
        }, 1000);
       
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const accept = async (statusId: number) => {
    let token = await getToken()
    let requestUpdateStatus:RequestUpdateStatus = {
      recordIds: [detail.recordNo],
      statusId: statusId
    }
   
    let result = await new DestructionCartonService(token).UpdateStatus(requestUpdateStatus)
    console.log(result)
    if(result && result.success){
        messageApi.open({
          type: 'success',
          content: 'Save success',
          duration: 1,
          style: {marginTop:200}
        });

        if(statusId == 0 || statusId === CartonStatus.WaitAdmin || statusId === CartonStatus.Inprogress){
          getData()
        }
        else{
          props.onBack(true)
        }
    }
    else{
        Modal.error({
            icon: <CloseCircleOutlined />,
            title: 'This is an error message',
            content: result?.message,
        });
    }
  }

  const download = async (_requestNo:string,filename:string) => {
      
    let token = await getToken()
    new FileManageService(token).downloadFile(_requestNo,filename);
}

  

  const onPageChange = (_page:number,_perpage: number) => {
   
    if(_perpage !== perpage)
    {
      let items = Helper.Chunk(allCartonItems,_perpage)
      setCartonItems(items)
      setPerpage(_perpage)
    }
    else{
      setPage(_page)
    }
  }

  const prevStep = () => {
    props.onBack(false)
}
  
    const showModalCarton = async (_cartonNo: string, _recordNo: number) => {
    setRecordNo(_recordNo)
        setCartonNumber(_cartonNo)
    setIsModalCartonOpen(true);
  };
  const handleCancelCarton = () => {
    setIsModalCartonOpen(false);

  };
  const onExport = async () => {
    setIsLoadingPrint(true)

    let token = await getToken()
    if(!id)return
    let recordNo = parseFloat(id)
    try{
      
      let result = await new DestructionCartonService(token).GetDetail(recordNo)
      if(result){
        console.log("🚀 ~ file: QueryDataDestructionCarton.tsx:313 ~ onExport ~ result:", result)
        await new FileManageService(token).DownloadReport(result.data.cartonList,ExportType.EXPORT_DESTRUCTION_LIST,recordNo);
      }
    }catch(error){
    console.log("🚀 ~ file: QueryData.tsx:464 ~ onFinishPrint ~ error:", error)

    }finally{
      setIsLoadingPrint(false)

    }
  }

  const handleRejectOpen = () => {
    setIsErrorNote(false)
    setIsModalRejectOpen(true)
    setTimeout(() => {
        let element = document.getElementById('note-reject') as HTMLTextAreaElement
        element.value = ""
    }, 100);
   
 }

 const handleReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
    if(element && element.value){
      update(CartonStatus.Reject,element.value)
    }
    else{
      setIsErrorNote(true)
      return false
    }
    
  }

  const handleCancelReject = () => {
    let element = document.getElementById('note-reject') as HTMLInputElement
     element.value = ""
     setIsModalRejectOpen(false)
  }
 
  return (

    <div>
      <Card title="Destruction Carton Detail">
              {returnIfElse(isLoading
                  ,
                  <Spin tip="Loading" size="large">
                      <div className="content" />
                  </Spin>
                  ,
                  <></>
              )
      }
      {isLoadingPrint?<Loading/>:<></>}
        <Form
          {...layout}
          form={dataForm}
          name="destruction-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          labelAlign="left"
          colon={false}
          labelWrap={true}
        >
          <Row>
            <Col md={22} >
              <Form.Item name="owner" label="Requester" labelCol={{ md: 3 }}  >

                <Label className='label-det'>{detail.requesterName}</Label>
                <Label className='mr-label' >Org. Unit</Label>
                <Label className='label-det'>{detail.orgUnit}</Label>
                <Label className='mr-label' >Position</Label>
                <Label className='label-det'>{detail.position}</Label>
                <Label className='mr-label' >Tel No.</Label>
                <Label className='label-det'>{detail.tel}</Label>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={11} >
              <Form.Item label="Request No"  >
                              {returnIfElse(detail.statusId === CartonStatus.Done && detail.requestTypeId === RequestType.DestructionCarton
                                  ,
                                  <Label className='c-red'>{detail.requestNo}</Label>
                                  ,
                                  <Label className='label-det'>{detail.requestNo}</Label>
                              )
                }
              </Form.Item>
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
              <Form.Item label="Status"  >
                <Label className='label-det'>{detail.status}</Label>
              </Form.Item>
            </Col>
          </Row>
        
          <Row>
            <Col md={11} >
              <Form.Item label="Carton Quantity"  >
                <Label className='label-det'>{detail.quantity} carton(s)</Label>
              </Form.Item>
                          {returnIfElse(detail.memo
                              ,
                              <Form.Item label="Org. Unit Reference/Memo"  >
                                  <Label className='label-det'>{detail.memo}</Label>
                              </Form.Item>
                              , 
                              <></>
                          )  
            }
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
            <Form.Item name="serviceDate" label="Service Date"  >
                             
                <Label className='label-det'>{returnIfElse(detail.serviceDate, dayjs(detail.serviceDate).format('DD MMM YYYY'), "" ) }</Label>
                              
              </Form.Item>
              <Form.Item label="Destruction Type"  >
                              <Label className='label-det'>{returnIfElse(detail.requestTypeId === RequestType.ExtendCarton, 'Extend', 'Destroy'  ) }</Label>
              </Form.Item>
                          {returnIfElse(detail.memo
                              ,
                              <Form.Item label="Attached File"  >
                                  <Label className='label-det'>
                                      {
                                          detail.files.map((file) =>
                                              (<div><a className='a-table' onClick={() => download("D" + detail.recordNo, file.name)}>{file.name}</a></div>)
                                          )}
                                  </Label>
                              </Form.Item>
                              ,
                              <></>
                          ) 
              }
            </Col>
          </Row>

          <Row>
            <Col md={11} >
           
            <Form.Item name="verifier" label={ detail.requestDate && parseInt(dayjs(detail.requestDate).format("YYYY")) >= 2023 ? "Verifier" : "Approver"}  >
                <Label className='label-det '>{  detail.approverName } </Label>
              </Form.Item>
            
            {detail.approverDate2 != null ?
              <Form.Item name="approver" label="Approver"  >
                <Label className='label-det '>{  detail.approverName2 } </Label>
              </Form.Item>:<></>
            }

             
            </Col>
            <Col md={2} >
  
            </Col>
            <Col md={11} >
           
            </Col>
          </Row>
              
          <Row>
            <Col md={11} >
            { detail.requester !== detail.onBehalf  ?
                          <Form.Item name="onbehaft" label="On Behalf"  >
                              <Label className='label-det'>{detail.onBehalfName}</Label>
                          </Form.Item>:<></>
}
                <Form.Item name="lastNote" label="Lasted Note"  >
                              {returnIfElse(detail.note.length > 0
                                  ,
                                  <>
                                      <Label className='label-det'>{detail.note[detail.note.length - 1]}</Label>
                                      <a className='a-table ml-label' onClick={showNoteModal}>Display Log</a>
                                  </>
                                  , 
                                  <></>
                              ) 
                }

              </Form.Item>
            
            </Col>
            <Col md={2} >

            </Col>
            <Col md={11} >
                
            { detail.statusId === CartonStatus.Reject ?
                 <Form.Item name="noteReject" label="Rejected Reason"  >
                        <Label className='label-det'>{detail.notApproveReason}</Label>
                    </Form.Item>:<></>
            }
            <Form.Item name="note" label="Note"  >
                              {returnIfElse(detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress
                                  ,
                                  <Input.TextArea id="note" placeholder={MessageCommon.TitleInput} />
                                  , <></>
                              ) 
              }
              </Form.Item>
            </Col>
          </Row>

        </Form>
        <div id="panel-action2" className='padding-bottom15'>
          <hr></hr>
          <div className='float-left' >
            <Button type="primary" htmlType="button" className='mr-btn' onClick={()=>onExport()}  disabled={ isLoading }>
              <AiOutlinePrinter className='icn-btn' />Export
            </Button>
          </div>
          <div className='text-center'>
      { detail.statusId === CartonStatus.WaitAdmin || detail.statusId === CartonStatus.Inprogress ?
        <>
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => update(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.Inprogress || isLoading}>
            <AiFillSave className='icn-btn' />Accept
          </Button>
          <Button type="primary" htmlType="button" className='mr-btn' onClick={() => update(CartonStatus.Inprogress)} disabled={detail.statusId === CartonStatus.WaitAdmin || isLoading}>
            <AiOutlineMessage className='icn-btn' />Update
          </Button>

          <Popconfirm
            title="Close the task"
            description="Confirm to close?"
            icon={<QuestionCircleOutlined className='c-red' />}
             onConfirm = { () => update(CartonStatus.Done) }
          >
           <Button type="primary" htmlType="button" className='mr-btn' disabled={detail.statusId === CartonStatus.WaitAdmin || isLoading}>
            <AiFillSave className='icn-btn' />Close Job
            </Button>
          </Popconfirm>

          <Button type="primary" htmlType="button" className='mr-btn' disabled={isLoading} onClick={() => handleRejectOpen()}>
                <AiOutlineClose className='icn-btn' />Reject
              </Button>
        </>:<></>
      }
          <Button type="primary" htmlType="button" className='mr-btn' onClick={showPrintModal} disabled={isLoading}>
            <AiOutlinePrinter className='icn-btn' />Print
          </Button>
          
          <Button type="primary" htmlType="button" onClick={prevStep} className='mr-btn' disabled={isLoading}>
            <AiOutlineArrowLeft className='icn-btn' />Back
          </Button>
          </div>
          <br></br>
          <h6>Carton List</h6>
          <br></br>
          { cartonItems.length > 0 && !isLoading  ?<div className='float-right'>
              <SelectPagination  onSelectChange={onPageChange} currentPage={page} defaultValue={perpage}/>
              <span className='margin-left-10'>/ Page</span>
          </div>:<></>}
          <br></br>
          <br></br>
        </div>
        <div id={stickyElement.container}>
          <table className='idoc-table'>
            <thead id={stickyElement.dataFixed}>
              <tr>
                <th className="thfix1 w50">No.</th>
                <th className="s-th thfix2">Carton No.</th>
                <th className="s-th">Carton Location</th>
                <th className="s-th">Storage Request No.</th>
                <th className="s-th">Storage Request Date</th>
                <th className="s-th">Requester</th>
                <th className="s-th">Owner</th>
                <th className="s-th">Org. Unit</th>
                <th className="s-th">
                   
                    {returnIfElse(detail.requestTypeId === RequestType.ExtendCarton
                                  ,
                                  <span> Previous Destruction Year</span>
                                  ,
                                  <span>Destruction Year</span>
                              ) 
                    }
                </th>
                              {returnIfElse(detail.requestTypeId === RequestType.ExtendCarton
                                  ,
                                  <th className="s-th">New Destruction Year (Extend)</th>
                                  ,
                                  <></>
                              ) 
                }
                <th className="s-th">Detail</th>
              </tr>
            </thead>
            <tbody >
            { cartonItems.length > 0 && cartonItems[page -1].map((item:CartonList,index) => (
                <tr>
                  <td className="tdfix1 w50" >{(index + 1)}</td>
                  <td className="s-td tdfix2 text-center" >
                    <div className={item.cartonStatusId === CartonStatus.Remove || item.cartonStatusId === CartonStatus.Destruction ? 'c-red' : ''}>
                        {item.cartonNo}
                    </div>
                  </td>
                  <td className="s-td text-center">{item.cartonLocation}</td>
                  <td className="s-td text-center">{item.requestNo}</td>
                  <td className="s-td text-center">{ item.requestDate ? dayjs(item.requestDate).format('DD MMM YYYY') : ""}</td>
                  <td className="s-td text-center">{item.requesterName}</td>
                  <td className="s-td text-center">{item.ownerName}</td>
                  <td className="s-td text-center">{item.orgUnit}</td>
                    {returnIfElse(detail.requestTypeId === RequestType.ExtendCarton
                        ,
                        <td className='s-td text-center'>{item.latestExtensionDate ? dayjs(item.latestExtensionDate).format('MMM YYYY') : dayjs(item.expireDate).format('MMM YYYY')}</td>
                        ,
                        <></>
                    ) 
                  }
                  <td className="s-td text-center">{dayjs(item.expireDate).format('MMM YYYY')}</td>
                  <td className="s-td text-center">
                    <a onClick={() => showModalCarton(item.cartonNo,item.storageRecordNo)}>
                    <AiFillEye className='icon-table'></AiFillEye>
                    </a>
                  </td>
                </tr>
              ))
            }
            
            </tbody>
          </table>
        </div>
        <div className='margin-top-10 text-right'>
        { allCartonItems.length > 0 ?
            <Pagination 
                key={Helper.Uniqid()}
                current={page} 
                pageSize={perpage}
                total={allCartonItems.length} 
                onChange={onPageChange}
                showSizeChanger={false}
              />:<></>
        }
        </div>
       
      </Card>


      <Modal title="" open={isPrintModalOpen} footer={''} width={600} onCancel={handlePrintModalCancel}>
        <br></br>
        <Card >
          <Form
            {...layout}
            form={printForm}
            name="nest-messages"
            onFinish={onFinishPrint}
            initialValues={{'print':1}}
            labelAlign="left"
            colon={false}
            labelWrap={true}
          >
            <Row>
              <Col md={24}  >
                <Form.Item name="print" label="Export type" labelCol={{ md: 6 }}  >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>PDF</Radio>
                    <Radio value={0}>Excel</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className='text-center' >
            <Button type="default" className='mr-btn'  onClick={handlePrintModalCancel}>
              <AiOutlineClose className='icn-btn' />Cancel
            </Button>
            <Button type="primary" className='mr-btn'   htmlType='submit' >
              <AiOutlineCheck className='icn-btn' />OK
            </Button>
          </div>
          </Form>
          



        </Card>
      </Modal>
        <Modal title="" open={isModalCartonDetailOpen} footer={''} width={1200} onCancel={handleCartonDetailCancel}>
            <CartonDetail key={Helper.Uniqid()} requestNo={requestNo}  box={box} />
        </Modal>
        <Modal title="Display Log" open={isNoteModalOpen} footer={''} width={900} onCancel={handleNoteModalCancel}>
            <DisplayLogNote key={Helper.Uniqid()} recordNo={detail.recordNo} requestTypeId={RequestType.DestructionCarton}/>
        </Modal>
        <Modal title="Carton Detail" open={isModalCartonOpen} footer={''} width={1550} onCancel={handleCancelCarton}>
              <CartonDetail key={Helper.Uniqid()} requestNo={""} box={cartonNumber} />
        </Modal>
        <Modal title="Confirm to reject?" open={isModalRejectOpen} footer={''} width={700} onCancel={() => handleCancelReject()} maskClosable={false}>
        <br></br>
        
          <Row>
            <Col md={2} >
              Note <span className='c-red'>*</span>
            </Col>
            <Col md={22} >
            <textarea className='text-confirm' id="note-reject" rows={5} defaultValue=""/>
            { isErrorNote ? <div className='error'>Note is required.</div> : <></> }
            </Col>
          </Row>
        
        <br></br>
        <div className='text-center'>
          <Button type="default" className='mr-btn' onClick={() => setIsModalRejectOpen(false)}  >
            <AiOutlineClose className='icn-btn' />Cancel
          </Button>
          <Button type="primary" className='mr-btn'  onClick={() => handleReject()}>
            <AiOutlineCheck className='icn-btn' />OK
          </Button>
        </div>
      </Modal>
          {modal ? <span></span> : <></>}
        {contextHolderMessage}
        {contextHolder}
    </div>
  );

}

export default QueryDataDestructionCarton
