import { useEffect } from 'react';
import { Card, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import RequestToChangeOwner from './RequestToChangeOwner';
import HistoryList from './HistoricalList';
import { useNavigate } from 'react-router-dom';

const ManageChangeOwner = () => {
  const navigate = useNavigate();
  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: `Request to Change Owner`,
      children: <RequestToChangeOwner/>,
    },
    {
      key: '2',
      label: `Historical List`,
      children: <HistoryList/>,
    }


  ];

  const onTabChange = (tab:string) => {
    if(tab === '1'){
      navigate('/manage-change-owner')
    }
    else{
      navigate('/manage-change-owner-' + tab)
    }
  }

  useEffect(() => {
   
  }, [])

  return (

    <div>
      <Card >
        <Tabs defaultActiveKey="1" items={itemsTab} onChange={onTabChange}   destroyInactiveTabPane/>
      </Card>

    </div>
  );

}

export default ManageChangeOwner
